import React from 'react';

/* Navigation Things */
import { RouteComponentProps, Router } from '@reach/router';

/* Data Things */
import { CustomersProvider } from '../../../components/Contexts/Customers';

/* Routes */
import { List } from './List';

interface IProps extends RouteComponentProps {}

export function Customers(_: IProps) {
  return (
    <CustomersProvider>
      <Router basepath="Manufacturing/Customers">
        <List default />
      </Router>
    </CustomersProvider>
  );
}

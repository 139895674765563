import { PersonDetailsEmployment } from '../../../../components/People/ProfileDetails/Employment/helper';
import { PersonDetails } from '../../../../components/People/ProfileDetails/PeopleDetails/helper';
import { EmployeeDTO } from '../../../../store/lib/Organization/Peoples/organization';

export interface IEmployee {
  personId: number;
  details: Partial<PersonDetails>;
  employment: PersonDetailsEmployment | null;
  isVisible: boolean;
  isPrivate: boolean;
  isActive: boolean;
}

export enum ActiveTab {
  Active = 0,
  All
}

export const transformEmployeeDTO = (p: EmployeeDTO, isActive: boolean, existPeople?: IEmployee): IEmployee => {
  return {
    personId: p.personId,
    isPrivate: p.isPrivate,
    isVisible: true,
    isActive,
    details: existPeople
      ? existPeople.details
      : {
          personId: p.personId,
          courtesyTitle: p.courtesyTitle || '',
          email: p.email || '',
          firstName: p.firstName || '',
          lastName: p.lastName || '',
          middleName: p.middleName || '',
          phoneNumber: p.phoneNumber || '',
          pictureUrl: p.pictureUrl || '',
          jobRoles: p.jobRoles
        },
    employment: existPeople ? existPeople.employment : null
  };
};

export const sortPersonsByName = (p: IEmployee[]): IEmployee[] => {
  return p.sort((a, b) => {
    const aName = a.details ? [a.details.lastName, a.details.firstName, a.details.middleName].filter(Boolean).join() : '';
    const bName = b.details ? [b.details.lastName, b.details.firstName, b.details.middleName].filter(Boolean).join() : '';
    return aName.localeCompare(bName, 'hu');
  });
};

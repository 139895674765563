import { OrderStatusTypes, ORDER_STATUS_MAP, PaintStatusTypes, PAINT_STATUS_MAP, ProductionSiteTypes, PRODUCTION_SITE_MAP } from '../../../../constants/enums';
import { ICustomerDTO, ISelectableRootProductDTO } from '../../../../typings/DTOs';
import { FormattedOption } from '@rmwc/select';
import { Order } from '../helper';

export const DEFAULT_ORDER: Order = {
  id: -1,
  customer: {
    id: 0,
    companyName: ''
  },
  productItem: {
    id: 0,
    title: ''
  },
  confirmationDate: null,
  notes: '',
  orderDate: null,
  orderNo: '',
  orderStatus: OrderStatusTypes.Customer,
  paintStatus: PaintStatusTypes.Factory,
  productionSite: ProductionSiteTypes.BC,
  project: null,
  quantity: 1,
  requestedShippingDate: null,
  shippingDate: null,
  supplyDate: null,
  taskJobs: null
};

export const PRODUCTION_SITE_SELECT: FormattedOption[] = Object.keys(PRODUCTION_SITE_MAP).map(x => ({ label: PRODUCTION_SITE_MAP[+x], value: x }));

export const PAINT_STATUS_SELECT: FormattedOption[] = Object.keys(PAINT_STATUS_MAP).map(x => ({ label: PAINT_STATUS_MAP[+x], value: x }));

export const ORDER_STATUS_SELECT: FormattedOption[] = Object.keys(ORDER_STATUS_MAP).map(x => ({ label: ORDER_STATUS_MAP[+x], value: x }));

export const transformISelectableRootProductDTO = (p: ISelectableRootProductDTO): FormattedOption => {
  return {
    label: p.title || '',
    value: String(p.id)
  };
};

export const transformICustomerDTO = (p: ICustomerDTO): FormattedOption => {
  return {
    label: p.companyName || '',
    value: String(p.id)
  };
};

import React, { useMemo } from 'react';

/* Data Things */
import { CalendarDay, generateDayStatusOptions, GlobalDayCss, hoursDisplay, ViewTypes } from './helper';
import { DayStatusRow } from '../../MasterData/DailyStatuses/helper';
import { isDayClosed } from '../../../constants/helperFuntions';
import { DateTime } from 'luxon';

/* Presentation Things */
import CircularProgress from '../../../components/CircularProgress';
import { Select, SelectProps } from '@rmwc/select';
import { Icon } from '@rmwc/icon';

// Styles
import { COLOR } from '../../../constants/baseStyle';

type CellProps = {
  day: CalendarDay;
  personId: number;
  dayStatuses: DayStatusRow[];
  selectedView: ViewTypes;
  isEditable: boolean;
  isCanSetLimitedStatus: boolean;
  onStatusChange(personId: number, statusId: number | null, day: CalendarDay): void;
  restrictedBefore: DateTime | null;
  isMonthRestrictedButEditable: boolean;
};

type DayStatusProps = Omit<CellProps, 'selectedView'>;

type PayRollProps = {
  day: CalendarDay;
  isIncreaseWorkTime: boolean;
  dayStatuses: DayStatusRow[];
};

type RegisteredTimeProps = {
  day: CalendarDay;
  dayStatuses: DayStatusRow[];
};

function DayStatus(props: DayStatusProps) {
  const { day, dayStatuses, isEditable, onStatusChange, isCanSetLimitedStatus, personId, isMonthRestrictedButEditable, restrictedBefore } = props;

  /* Variables */
  const selectedDaystatus = dayStatuses.find(x => x.id === day.statusId);

  const options = useMemo(() => generateDayStatusOptions(dayStatuses, day.globalDayStatus, isCanSetLimitedStatus), [dayStatuses, day.globalDayStatus, isCanSetLimitedStatus]);

  const isChangeAllowed = isEditable && day.defaultBasicHour > 0 && (!selectedDaystatus || (selectedDaystatus && !selectedDaystatus.isCodeHandled));

  function handleStatusChange(ev: React.FormEvent<SelectProps>) {
    onStatusChange(personId, ev.currentTarget.value === undefined ? null : parseInt(ev.currentTarget.value), day);
  }

  if (isChangeAllowed && isDayClosed(day.date, restrictedBefore, isMonthRestrictedButEditable)) {
    return <Icon icon="lock" className="action-icon" />;
  }

  return (
    <>
      {day.isUpdating ? (
        <CircularProgress />
      ) : (
        <span className="person-status">
          <span className="text">{day.defaultBasicHour > 0 && day.statusCode}</span>
        </span>
      )}

      {options.length > 0 && isChangeAllowed && (
        <Select defaultValue={String(day.statusId)} onChange={handleStatusChange} options={options}>
          {options.length > 1 && selectedDaystatus && <option value={undefined}>Státusz törlés</option>}
        </Select>
      )}
    </>
  );
}

function PayRoll({ day, isIncreaseWorkTime }: PayRollProps) {
  return (
    <div className="expected-time">
      {isIncreaseWorkTime ? (
        <span className="text">{day.statusCode}</span>
      ) : day.basicHours ? (
        <span className="text">
          <span style={{ color: `${day.statusColorCode}` }}>{day.statusCode + ' '}</span>
          <span>{hoursDisplay(day.basicHours)}</span>
        </span>
      ) : (
        <span className="text" />
      )}
    </div>
  );
}

function RegisteredTime({ day, dayStatuses }: RegisteredTimeProps) {
  return (
    <span className="text">
      {day.registeredSeconds ? hoursDisplay(day.registeredSeconds / 3600) : (day.statusId && dayStatuses.find(x => x.id === day.statusId)!.isIncreaseWorkTime) || day.defaultBasicHour <= 0 ? '' : ''}
    </span>
  );
}

export const Cell = React.memo(
  ({ day, dayStatuses, selectedView, isEditable, onStatusChange, personId, isCanSetLimitedStatus, isMonthRestrictedButEditable, restrictedBefore }: CellProps) => {
    /* Variables */
    const isIncreaseWorkTime = day.statusId ? dayStatuses.find(x => x.id === day.statusId)!.isIncreaseWorkTime : false;

    const containerStyle = (selectedView === 'DayStatus' || (selectedView === 'PayRoll' && isIncreaseWorkTime)) && day.statusColorCode ? { color: COLOR.primary, backgroundColor: day.statusColorCode } : undefined;

    function renderSelectedView() {
      switch (selectedView) {
        case 'DayStatus':
          return (
            <DayStatus
              day={day}
              dayStatuses={dayStatuses}
              isEditable={isEditable}
              onStatusChange={onStatusChange}
              personId={personId}
              isCanSetLimitedStatus={isCanSetLimitedStatus}
              isMonthRestrictedButEditable={isMonthRestrictedButEditable}
              restrictedBefore={restrictedBefore}
            />
          );

        case 'OverTime':
          return <span>{day.overtimeHours ? hoursDisplay(day.overtimeHours) : ''}</span>;

        case 'PayRoll':
          return <PayRoll day={day} dayStatuses={dayStatuses} isIncreaseWorkTime={isIncreaseWorkTime} />;

        case 'RegisteredTime':
          return <RegisteredTime day={day} dayStatuses={dayStatuses} />;
      }
    }

    return (
      <span className={`attendence-table__row-cell ${GlobalDayCss[day.globalDayStatus]}`} style={containerStyle}>
        {renderSelectedView()}
      </span>
    );
  },
  (prevProps, nextProps) =>
    prevProps.day === nextProps.day &&
    prevProps.isEditable === nextProps.isEditable &&
    prevProps.selectedView === nextProps.selectedView &&
    prevProps.dayStatuses === nextProps.dayStatuses &&
    prevProps.restrictedBefore === nextProps.restrictedBefore &&
    prevProps.isMonthRestrictedButEditable === nextProps.isMonthRestrictedButEditable
);

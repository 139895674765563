import { ProductItemQuantityUnit } from './enums';

export const DATEFORMAT = "yyyy'-'MM'-'dd";
export const MOBILE_SCREEN_SIZE = 1440;
export const PEOPLE_EDIT_CLAIM = 'http://schemas.prohr365.com/claims/EmberekSzerkesztese';
export const IS_LIMITED_DAY_STATUS_SETTING_CLAIM = 'http://schemas.prohr365.com/claims/LimitaltNapiStatuszBeallitas';

export const APPLICATION_USERS_ROW_HEIGHT = 50;
export const MONTHLY_ATTENDENCE_ROW_HEIGHT = 43;
export const JOB_ROLES_ROW_HEIGHT = APPLICATION_USERS_ROW_HEIGHT;
export const PROJECTS_ROW_HEIGHT = APPLICATION_USERS_ROW_HEIGHT;
export const SECURITY_GROUPS_ROW_HEIGHT = APPLICATION_USERS_ROW_HEIGHT;
export const DATETIME_INPUT_FORMAT = "yyyy'-'MM'-'dd";

export const PRODUCT_ITEM_QUANTITY_UNIT_MAP: Record<ProductItemQuantityUnit, string> = {
  [ProductItemQuantityUnit.Amount]: 'db',
  [ProductItemQuantityUnit.Nm2]: 'nm2',
  [ProductItemQuantityUnit.Set]: 'szet'
};

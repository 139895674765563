import React, { memo } from 'react';
import { TextField } from '@rmwc/textfield';

interface IProps {
  onChange(value: number | null): void;
  value: number | null;
  disabled: boolean;
}

export const CostInput = memo(
  (props: IProps) => {
    const addCommas = (num: number) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const removeNonNumeric = (num: string) => Number(num.replace(/[^0-9]/g, ''));

    const localValue = props.value !== null ? addCommas(props.value) : undefined;

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value === '' ? null : removeNonNumeric(e.target.value));

    const handleTab = e => {
      //Tabulator
      if (e.keyCode === 9) {
        if (document.activeElement) {
          var activeElement = document.activeElement.getBoundingClientRect().bottom;

          if (activeElement + 100 >= window.innerHeight) {
            window.scrollTo(0, window.scrollY + window.innerHeight / 2);
          }
        }
      }
    };

    return <TextField disabled={props.disabled} min={0} required className="costInput" type="text" placeholder="Költség" value={localValue} onInput={onChange} onKeyDown={handleTab} />;
  },
  (prev, next) => prev.value === next.value && prev.disabled === next.disabled
);

import React, { useState } from 'react';

/* Presentation Things */
import FileSelector, { SelectedFile } from '../../../../Selectors/FileSelector';
import { LinearProgress } from '@rmwc/linear-progress';
import { Button } from '@rmwc/button';

/* Data Things */
import { putAttachments } from '../../../../../store/lib/Organization/Peoples/peopleDetails';
import { PersonDetails } from '../helper';
import { useIsMounted } from '../../../../../hooks/useIsMounted';
import { formatBytes } from '../../../../../constants/helperFuntions';
import { uploadFile } from '../../../../../store/lib/upload';

type IProps = {
  close(): void;
  onEditPartialDetailsModel(p: Partial<PersonDetails>): void;
  personId: number;
};

const MAX_FILE_SIZE = 8000000; //bytes

export function EditAtatchmentsSection({ personId, onEditPartialDetailsModel, close }: IProps) {
  const _isMounted = useIsMounted();
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const isSaveAble = selectedFiles.length > 0 && !isLoading;

  const handleSave = () => {
    if (isSaveAble) {
      setIsLoading(true);
      putAttachments(personId, { tempFileIds: selectedFiles.map(x => x.locationId!) })
        .then(attachments => {
          _isMounted.current && setIsLoading(false);
          onEditPartialDetailsModel({ attachments });
          close();
        })
        .catch(() => {
          _isMounted.current && setIsLoading(false);
          _isMounted.current && setError('Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
        });
    }
  };

  const handleFileSelect = async (selectedFile: SelectedFile): Promise<{ locationId: number | null; message: string }> => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile.file);
      const [{ id, error }] = await uploadFile(formData);

      if (!error && id !== null && _isMounted.current) {
        setSelectedFiles(prev => [...prev, { ...selectedFile, locationId: id }]);
        return Promise.resolve({ locationId: id, message: '' });
      } else throw new Error(error || 'Valami hiba történt, kérlek próbáld újra később.');
    } catch (error) {
      return Promise.reject({ locationId: null, message: String(error.message || selectedFile.error || error) });
    }
  };

  const handleFileDelete = (id: string) => setSelectedFiles(prev => prev.filter(x => x.id !== id));

  return personId !== undefined ? (
    <>
      <div tabIndex={0} className="drawer-header">
        Csatolmányok feltöltése
      </div>
      {isLoading && <LinearProgress className="linear-progress" />}

      {error.length > 0 && <div className="basic-error-text">{error}</div>}

      <div className="form-content attachment-upload">
        <FileSelector label="Csatolmányok" isMultiple maxFileSize={MAX_FILE_SIZE} accept=".pdf, .doc, .docx, .xls, .txt, .jpg, .png" onSelect={handleFileSelect} onDelete={handleFileDelete} />
        <div className="file-selector-helper-text">Maximális fájlméret: {formatBytes(MAX_FILE_SIZE)}.</div>

        <Button label="Mentés" unelevated disabled={!isSaveAble} onClick={handleSave} />
      </div>
    </>
  ) : (
    <div tabIndex={0} />
  );
}

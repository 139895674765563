import React, { useContext } from 'react';

/* Presentation Things */
import { EditBasicInfoSection } from './Edit/BasicInfoSection';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';

/* Data Things */
import { PersonDetails, PersonDetailsBasicInfo } from './helper';
import { SEX_TYPES_MAP, MARITAL_STATUSES_MAP } from '../../../../constants/enums';
import { SideSheetContext } from '../../../Contexts/SideSheet';

interface Props {
  basicInfo: PersonDetailsBasicInfo;
  onEditPartialDetailsModel(p: Partial<PersonDetails>): void;
  personId: number;
  fullName: string;
}

export const BasicInfoSection = ({ basicInfo, fullName, personId, onEditPartialDetailsModel }: Props) => {
  const { open, close } = useContext(SideSheetContext);
  const onOpenEdit = () => open(<EditBasicInfoSection {...{ personId, onEditPartialDetailsModel, close }} />, 'side-sheet--custom-width');

  return (
    <>
      <div className="details-table-header">
        <Typography use="subtitle2" className="color-blue">
          Alapadatok
        </Typography>

        <div className="icon-group">
          <Icon className="edit" icon="edit" onClick={onOpenEdit} />
        </div>
      </div>

      <div className="details-table-row">
        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Privát profil
          </Typography>

          <Typography use="body2" className="secondary-text">
            {basicInfo.isPrivate ? 'Igen' : 'Nem'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Teljes Név
          </Typography>

          <Typography use="body2" className="secondary-text">
            {fullName || '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Születési Név
          </Typography>

          <Typography use="body2" className="secondary-text">
            {basicInfo.birthName || '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Születés hely
          </Typography>

          <Typography use="body2" className="secondary-text">
            {basicInfo.birthPlace || '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Születési idő
          </Typography>

          <Typography use="body2" className="secondary-text">
            {basicInfo.birthDate.setLocale('hu').toFormat('D') || '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Neme
          </Typography>

          <Typography use="body2" className="secondary-text">
            {SEX_TYPES_MAP[basicInfo.sex] || '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Anyja neve
          </Typography>

          <Typography use="body2" className="secondary-text">
            {basicInfo.motherName || '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Családi állapot
          </Typography>

          <Typography use="body2" className="secondary-text">
            {basicInfo.maritalStatus ? MARITAL_STATUSES_MAP[basicInfo.maritalStatus] : '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Szinkronicáziós kódja
          </Typography>

          <Typography use="body2" className="secondary-text">
            {basicInfo.syncId || '-'}
          </Typography>
        </div>
      </div>
    </>
  );
};

import React from 'react';

/* Presentation Things */
import { TextField } from '@rmwc/textfield';

/* Data Things */
import { AddPersonContactInfo } from '../../../routes/Organization/People/PeopleAdd/helper';
import { AddressDTO } from '../../../store/lib/Organization/Peoples/peopleDetails';

type IProps = {
  model: AddPersonContactInfo;
  onEditModel<T extends keyof AddPersonContactInfo, K extends AddPersonContactInfo[T]>(key: T, value: K): void;
  onEditAddress<T extends keyof AddressDTO, K extends AddressDTO[T]>(homeType: 'privateHomeAddress' | 'privateResidenceAddress', key: T, value: K): void;
};

export function AddContactInfoSection({ model, onEditModel, onEditAddress }: IProps) {
  return (
    <div className="form-content contact-info">
      <div className="form-content-container">
        <span className="title margin-bottom-12">I. Publikus</span>

        <div className="form-content-row">
          <TextField autoFocus type="tel" label="Mobilszám" maxLength={13} value={model ? model.publicMobilePhone : ''} onChange={ev => onEditModel('publicMobilePhone', ev.currentTarget.value)} />

          <TextField type="text" label="Vezetékes" maxLength={13} value={model ? model.publicLinePhone : ''} onChange={ev => onEditModel('publicLinePhone', ev.currentTarget.value)} />

          <TextField type="text" label="Mellék" maxLength={10} value={model ? model.publicLinePhoneExtension : ''} onChange={ev => onEditModel('publicLinePhoneExtension', ev.currentTarget.value)} />
        </div>

        <div className="form-content-row">
          <TextField type="email" label="E-mail" maxLength={100} value={model ? model.publicEmail : ''} onChange={ev => onEditModel('publicEmail', ev.currentTarget.value)} />

          <TextField type="text" label="LinkedIn Profil URL" maxLength={300} value={model ? model.linkedin : ''} onChange={ev => onEditModel('linkedin', ev.currentTarget.value)} />
        </div>
      </div>

      <div className="form-content-container">
        <div className="title">II. Privát</div>

        <div className="sub-title margin-bottom-6">Lakcím</div>

        <div className="form-content-row">
          <TextField type="text" label="Ország" maxLength={100} required value={model ? model.privateHomeAddress.country : ''} onChange={ev => onEditAddress('privateHomeAddress', 'country', ev.currentTarget.value)} />

          <TextField type="number" label="Irányítószám" maxLength={10} required value={model ? model.privateHomeAddress.zip : ''} onChange={ev => onEditAddress('privateHomeAddress', 'zip', ev.currentTarget.value)} />

          <TextField type="text" label="Város" maxLength={100} required value={model ? model.privateHomeAddress.city : ''} onChange={ev => onEditAddress('privateHomeAddress', 'city', ev.currentTarget.value)} />
        </div>

        <div className="form-content-row">
          <TextField
            type="text"
            label="Címsor 1"
            maxLength={500}
            required
            value={model ? model.privateHomeAddress.addressLine1 : ''}
            onChange={ev => onEditAddress('privateHomeAddress', 'addressLine1', ev.currentTarget.value)}
          />

          <TextField type="text" maxLength={500} label="Címsor 2" value={model ? model.privateHomeAddress.addressLine2 : ''} onChange={ev => onEditAddress('privateHomeAddress', 'addressLine2', ev.currentTarget.value)} />
        </div>

        <div className="sub-title margin-bottom-6">Tartózkodási hely</div>

        <div className="form-content-row">
          <TextField
            type="text"
            label="Ország"
            maxLength={100}
            required
            value={model ? model.privateResidenceAddress.country : ''}
            onChange={ev => onEditAddress('privateResidenceAddress', 'country', ev.currentTarget.value)}
          />

          <TextField
            type="number"
            maxLength={10}
            label="Irányítószám"
            required
            value={model ? model.privateResidenceAddress.zip : ''}
            onChange={ev => onEditAddress('privateResidenceAddress', 'zip', ev.currentTarget.value)}
          />

          <TextField type="text" label="Város" maxLength={100} required value={model ? model.privateResidenceAddress.city : ''} onChange={ev => onEditAddress('privateResidenceAddress', 'city', ev.currentTarget.value)} />
        </div>

        <div className="form-content-row">
          <TextField
            type="text"
            label="Címsor 1"
            maxLength={500}
            required
            value={model ? model.privateResidenceAddress.addressLine1 : ''}
            onChange={ev => onEditAddress('privateResidenceAddress', 'addressLine1', ev.currentTarget.value)}
          />

          <TextField
            type="text"
            maxLength={500}
            label="Címsor 2"
            value={model ? model.privateResidenceAddress.addressLine2 : ''}
            onChange={ev => onEditAddress('privateResidenceAddress', 'addressLine2', ev.currentTarget.value)}
          />
        </div>
      </div>

      <div className="form-content-row">
        <TextField type="tel" maxLength={13} label="Mobilszám" value={model ? model.privateMobilePhone : ''} onChange={ev => onEditModel('privateMobilePhone', ev.currentTarget.value)} />

        <TextField type="email" label="E-mail" maxLength={100} value={model ? model.privateEmail : ''} onChange={ev => onEditModel('privateEmail', ev.currentTarget.value)} />

        <TextField type="text" label="Facebook Profil URL" maxLength={300} value={model ? model.facebook : ''} onChange={ev => onEditModel('facebook', ev.currentTarget.value)} />
      </div>

      <div className="form-content-row">
        <TextField type="text" maxLength={200} label="Legközelebbi hozzátartozójának neve" value={model ? model.nextOfKinName : ''} onChange={ev => onEditModel('nextOfKinName', ev.currentTarget.value)} />

        <TextField type="tel" maxLength={13} label="Legközelebbi hozzátartozójának száma" value={model ? model.nextOfKinPhone : ''} onChange={ev => onEditModel('nextOfKinPhone', ev.currentTarget.value)} />
      </div>
    </div>
  );
}

import React, { useState, useContext, useMemo, useEffect } from 'react';

/* Data Things */
import { Link, RouteComponentProps, useNavigate } from '@reach/router';
import { SECURITY_GROUPS_ROW_HEIGHT } from '../../../../constants/constants';
import { SecurityGroupsContext } from '../../../../components/Contexts/SecurityGroups';
import { ISecurityGroupRow } from '../helper';

/* Presentation Things */
import CircularProgress from '../../../../components/CircularProgress';
import { WindowScroller, List, ListRowProps, AutoSizer } from 'react-virtualized';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';
import { Fab } from '@rmwc/fab';

interface Props extends RouteComponentProps {}

export const SecurityGroupsList = (_: Props) => {
  const { onSearchInGroups, ...context } = useContext(SecurityGroupsContext);
  const groups = useMemo(() => Object.values(context.groups).filter(x => x.isVisible), [context.groups]);

  //States
  const [searchText, setSearchText] = useState('');

  //Refs
  const navigate = useNavigate();

  const onClearSearch = () => setSearchText('');

  const onSearch = (ev: React.ChangeEvent<HTMLInputElement>) => setSearchText(String(ev.currentTarget.value));

  useEffect(() => onSearchInGroups(searchText), [searchText, onSearchInGroups]);

  const rowRenderer = ({ index, style, key }: ListRowProps) => {
    const row = groups[index];

    return (
      <div style={style} key={key}>
        <Row {...{ row }} />
      </div>
    );
  };

  return (
    <>
      <div className="data-table-title">
        <Typography className="title" use="body2">
          Biztonsági csoportok
        </Typography>
      </div>

      {!context.isLoading && !context.error.length && (
        <div className="fab-menu-container">
          <Fab mini icon="add" onClick={() => navigate('/Settings/SecurityGroups/Create')} />
        </div>
      )}

      <div className={`search-box ${(context.isLoading || context.error.length > 0) && 'disabled'}`}>
        <span className="search-box--start-detail">
          <span className="material-icons">search</span>
        </span>

        <input className="search-box--input" type="text" placeholder="Keresés" onChange={onSearch} value={searchText} />

        <span className="search-box--start-detail" onClick={onClearSearch}>
          <span className="material-icons">close</span>
        </span>
      </div>

      <div className="data-table users-list">
        <div className="data-table__row data-table__row--title">
          <div className="data-table__cell person">
            <Typography use="caption">Név</Typography>
          </div>
          <div className="row-spacer-header__end" />
        </div>

        <div className="data-table-body">
          {context.isLoading ? (
            <CircularProgress />
          ) : context.error.length > 0 || groups.length < 1 ? (
            <div className="basic-error-text">{context.error || 'Nincs megjeleníthető biztonsági csoport'}</div>
          ) : (
            <WindowScroller>
              {({ height, isScrolling, registerChild, scrollTop }) => (
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <div ref={el => registerChild(el)}>
                      <List
                        autoHeight
                        height={height}
                        width={width}
                        scrollTop={scrollTop}
                        rowHeight={SECURITY_GROUPS_ROW_HEIGHT}
                        rowRenderer={rowRenderer}
                        rowCount={groups.length}
                        isScrolling={isScrolling}
                        overscanRowCount={20}
                      />
                    </div>
                  )}
                </AutoSizer>
              )}
            </WindowScroller>
          )}
        </div>
      </div>
    </>
  );
};

const Row = ({ row }: { row: ISecurityGroupRow }) => {
  const { name, id } = row;

  return (
    <div className="data-table__row">
      <div className="data-table__cell">{name}</div>

      <div className="data-table__cell--end">
        <Link to={`/Settings/SecurityGroups/Details/${id}`}>
          <Icon icon="keyboard_arrow_right" />
        </Link>
      </div>
    </div>
  );
};

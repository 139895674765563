import React, { useContext, useState } from 'react';

/* Data Things */
import { OUType, transformOUTypeDTO } from '../helper';
import { AddOrEditOUTypeDTO, createOUType, editOUType } from '../../../../store/lib/Settings/ouTypes';
import { HEX_COLOR_PATTERN } from '../../../../constants/regex';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { useIsMounted } from '../../../../hooks/useIsMounted';

/* Presentation Things */
import ResponseSnackbar, { ApiResponseType } from '../../../../components/Portals/ResponseSnackbar';
import { PaletteSelector } from '../../../../components/Selectors/PaletteSelector';
import { LinearProgress } from '@rmwc/linear-progress';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';

interface Props {
  editableOUType?: OUType;
  onSetOUType(type: OUType): void;
  onAddOUType(type: OUType): void;
}

export const OUTypeAddOrEdit = (props: Props) => {
  /* Variables */
  const isEdit = Boolean(props.editableOUType);
  const ouTypeId = isEdit ? props.editableOUType!.id : null;
  const updated = isEdit ? props.editableOUType!.updated : null;

  /* States */
  const [apiResponse, setApiResponse] = useState<ApiResponseType>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [colorCode, setColorCode] = useState(isEdit ? props.editableOUType!.colorCode : '');
  const [name, setName] = useState(isEdit ? props.editableOUType!.name : '');

  /* Context */
  const { close } = useContext(SideSheetContext);

  /* Refs */
  const _isMounted = useIsMounted();

  const handleNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => setName(String(ev.currentTarget.value));

  const onSelectColor = (color: string) => setColorCode(color);

  const onSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();
    setIsLoading(true);
    try {
      const model: AddOrEditOUTypeDTO = { ouTypeId, colorCode, name, updated };
      const resp = isEdit ? await editOUType(model) : await createOUType(model);

      if (!resp.error && _isMounted.current) {
        const newType = transformOUTypeDTO(resp);
        if (isEdit) props.onSetOUType(newType);
        else props.onAddOUType(newType);

        setApiResponse({ type: 'success', message: `Sikeres típus ${isEdit ? 'szerkesztés' : 'hozzáadás'}` });
        setTimeout(() => _isMounted.current && close(), 500);
      } else throw new Error(resp.error);
    } catch (error) {
      if (_isMounted.current) {
        setApiResponse({ type: 'error', message: String(error.message || 'Sajnáljuk, valami hiba történt a típus mentése során.') });
      }
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  return (
    <>
      <ResponseSnackbar response={apiResponse} onClose={() => setApiResponse(null)} />

      <div className="drawer-header">{isEdit ? 'Típus szerkesztése' : 'Új típus'}</div>
      {isLoading && <LinearProgress />}

      <div className="form-content">
        <form onSubmit={onSubmit}>
          <div className="form-content-row">
            <TextField required maxLength={100} type="text" label="Név" value={name} onChange={handleNameChange} autoFocus />
          </div>

          <div className="form-content-row">
            <TextField maxLength={7} required label="Színkód" name="colorCode" value={'#' + colorCode.substring(1)} onChange={e => onSelectColor(e.currentTarget.value)} pattern={HEX_COLOR_PATTERN} />
          </div>

          <PaletteSelector {...{ onSelectColor }} />

          <Button unelevated label={isEdit ? 'Szerkesztés' : 'Hozzáadás'} type="submit" />
        </form>
      </div>
    </>
  );
};

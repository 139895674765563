import { ICreateInventoryDTO, IEditInventoryDTO, IInventoryDTO } from '../../../typings/DTOs';
import { QUANTITY_UNIT_MAP } from '../../../constants/enums';
import { FormattedOption } from '@rmwc/select';

export class Inventory {
  public id: number;
  public order: FormattedOption | null;
  public productItem: FormattedOption | null;
  public task: FormattedOption | null;
  public quantity: number;

  constructor(p: IInventoryDTO) {
    this.id = p.id;
    this.order = p.order
      ? {
          value: String(p.order.id),
          label: p.order.no || ''
        }
      : null;
    this.productItem = p.productItem
      ? {
          value: String(p.productItem.id),
          label: p.productItem.title || '',
          itemType: QUANTITY_UNIT_MAP[p.productItem.quantityUnit]
        }
      : null;
    this.task = p.lastFinishedTask
      ? {
          value: String(p.lastFinishedTask.id),
          label: p.lastFinishedTask.name || ''
        }
      : null;
    this.quantity = p.quantity;
  }
}

export const transformInventoryToICreateInventoryDTO = (p: Inventory): ICreateInventoryDTO => {
  return {
    orderId: Number(p.order!.value),
    productItemId: Number(p.productItem!.value),
    lastFinishedTaskId: p.task && p.task.value !== undefined ? Number(p.task.value) : null,
    quantity: p.quantity
  };
};

export const transformInventoryToIEditInventoryDTO = (p: Inventory, id: number): IEditInventoryDTO => {
  return {
    id,
    ...transformInventoryToICreateInventoryDTO(p)
  };
};

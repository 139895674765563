import { api } from '../../../../utils/api';

export interface EmployeeDTO {
  personId: number;
  pictureUrl: string | null;
  courtesyTitle: string | null;
  lastName: string | null;
  firstName: string | null;
  middleName: string | null;
  phoneNumber: string | null;
  email: string | null;
  jobRoles: string[];
  isPrivate: boolean;
}

export const getAllEmployeesActive = () => {
  return api<EmployeeDTO[]>('GET', `people/employees/active`, null);
};

export const getAllEmployees = () => {
  return api<EmployeeDTO[]>('GET', `people/employees`, null);
};

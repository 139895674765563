import { IInventoryDTO, IInventoryListDTO } from '../../../../typings/DTOs';
import { ApiResponseType } from '../../../../components/Portals/ResponseSnackbar';
import { Inventory } from '../../../../routes/Manufacturing/Inventories/helper';

export const initialState: InventoriesState = {
  inventories: {},
  apiResponse: null,
  error: '',
  search: '',
  isLoading: true,
  totalLength: 0,
  page: 0,
  offset: 0
};

export interface InventoriesState {
  inventories: { [id: number]: Inventory };
  totalLength: number;
  offset: number;
  apiResponse: ApiResponseType;
  error: string;
  page: number;
  search: string;
  isLoading: boolean;
}

type InventoriesAction =
  | { type: 'success_get_inventories'; payload: { inventories: IInventoryListDTO } }
  | { type: 'error_get_inventories'; payload: { error: string } }
  | { type: 'success_get_inventory'; payload: { inventory: IInventoryDTO } }
  | { type: 'success_post_inventory'; payload: { inventory: IInventoryDTO } }
  | { type: 'error_post_inventory'; payload: { error: string } }
  | { type: 'success_put_inventory'; payload: { inventory: IInventoryDTO } }
  | { type: 'error_put_inventory'; payload: { error: string } }
  | { type: 'set_partial_state'; payload: Partial<InventoriesState> };

export function inventoriesReducer(state: InventoriesState = initialState, action: InventoriesAction): InventoriesState {
  switch (action.type) {
    case 'success_get_inventories': {
      return {
        ...state,
        isLoading: false,
        offset: action.payload.inventories.offset,
        totalLength: action.payload.inventories.totalLength,
        inventories: action.payload.inventories.items.reduce((acc, curr) => {
          const newInventory = new Inventory(curr);

          return { ...acc, [curr.id]: newInventory };
        }, {}),
        error: ''
      };
    }

    case 'error_get_inventories': {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }

    case 'success_get_inventory': {
      const isExist = Boolean(state.inventories[action.payload.inventory.id]);
      return {
        ...state,
        inventories: { ...state.inventories, [action.payload.inventory.id]: new Inventory(action.payload.inventory) },
        totalLength: isExist ? state.totalLength + 1 : state.totalLength
      };
    }

    case 'success_post_inventory': {
      return {
        ...state,
        inventories: { ...state.inventories, [action.payload.inventory.id]: new Inventory(action.payload.inventory) },
        totalLength: state.totalLength + 1,
        apiResponse: { type: 'success', message: 'Sikeres készlet létrehozás' }
      };
    }

    case 'error_post_inventory': {
      return {
        ...state,
        apiResponse: { type: 'error', message: action.payload.error }
      };
    }

    case 'success_put_inventory': {
      return {
        ...state,
        inventories: { ...state.inventories, [action.payload.inventory.id]: new Inventory(action.payload.inventory) },
        apiResponse: { type: 'success', message: 'Sikeres készlet szerkesztés' }
      };
    }

    case 'error_put_inventory': {
      return {
        ...state,
        apiResponse: { type: 'error', message: action.payload.error }
      };
    }

    case 'set_partial_state': {
      return {
        ...state,
        ...action.payload
      };
    }

    default:
      return state;
  }
}

import React from 'react';

/* Navigation Things */
import { RouteComponentProps, Router } from '@reach/router';

/* Data Things */
import { ApplicationUsersProvider } from '../../../components/Contexts/ApplicationUsers';

/* Routes  */
import { ApplicationUserCreateAndEdit } from './Create&Edit';
import { ApplicationUsersList } from './List';

// Styles
import './ApplicationUsers.scss';

interface IProps extends RouteComponentProps {}

export function ApplicationUsers(props: IProps) {
  return (
    <ApplicationUsersProvider>
      <Router basepath="Settings/ApplicationUsers">
        <ApplicationUserCreateAndEdit path="/Details/:id" />
        <ApplicationUserCreateAndEdit path="/Create" />
        <ApplicationUsersList default />
      </Router>
    </ApplicationUsersProvider>
  );
}

import React, { useContext } from 'react';

/* Presentation Things */
import { EditPersonalInfoSection } from './Edit/PersonalInfoSection';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';

/* Data Things */
import { PersonDetails, PersonDetailsPersonalInfo } from './helper';
import { SideSheetContext } from '../../../Contexts/SideSheet';
interface Props {
  personalInfo: PersonDetailsPersonalInfo;
  onEditPartialDetailsModel(p: Partial<PersonDetails>): void;
  personId: number;
}

export const PersonalInfoSection = ({ personalInfo, personId, onEditPartialDetailsModel }: Props) => {
  const { open, close } = useContext(SideSheetContext);

  const onOpenEdit = () => open(<EditPersonalInfoSection {...{ personId, onEditPartialDetailsModel, close }} />, 'side-sheet--custom-width');

  return (
    <>
      <div className="details-table-header">
        <Typography use="subtitle2" className="color-blue">
          Személyes
        </Typography>

        <div className="icon-group">
          <Icon className="edit" icon="edit" onClick={onOpenEdit} />
          {/* <Icon icon="arrow_drop_down" /> */}
        </div>
      </div>

      <div className="details-table-row">
        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Adóazonosító jel
          </Typography>

          <Typography use="body2" className="secondary-text">
            {personalInfo.taxId || '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            TAJ szám
          </Typography>

          <Typography use="body2" className="secondary-text">
            {personalInfo.socialSecurityNumber || '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Lakcímkártya száma
          </Typography>

          <Typography use="body2" className="secondary-text">
            {personalInfo.addressCardNumber || '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Személyigazolvány száma / Lejárata
          </Typography>

          <Typography use="body2" className="secondary-text">
            {personalInfo.idCardNumber || '-'}
            {personalInfo.idCardNumber || personalInfo.idCardExpiryDate ? <Icon className="event-icon" icon="event" /> : ''}
            {personalInfo.idCardExpiryDate ? personalInfo.idCardExpiryDate.setLocale('hu').toFormat('D') : '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Jogosítvány száma / Lejárata
          </Typography>

          <Typography use="body2" className="secondary-text">
            {personalInfo.driverLicenseNumber || '-'}
            {personalInfo.driverLicenseNumber || personalInfo.driverLicenseExpiryDate ? <Icon className="event-icon" icon="event" /> : ''}
            {personalInfo.driverLicenseExpiryDate ? personalInfo.driverLicenseExpiryDate.setLocale('hu').toFormat('D') : '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Útlevél száma / Lejárata
          </Typography>

          <Typography use="body2" className="secondary-text">
            {personalInfo.passportNumber || '-'}
            {personalInfo.passportNumber || personalInfo.passportExpiryDate ? <Icon className="event-icon" icon="event" /> : ''}
            {personalInfo.passportExpiryDate ? personalInfo.passportExpiryDate.setLocale('hu').toFormat('D') : '-'}
          </Typography>
        </div>
      </div>
    </>
  );
};

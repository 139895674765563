import React, { useCallback, useContext, useEffect, useState } from 'react';

/* Data Things */
import { DayStatusRow, transformDayStatusRowDTO } from './helper';
import { RouteComponentProps } from '@reach/router';
import { SideSheetContext } from '../../../components/Contexts/SideSheet';
import { getDayStatuses } from '../../../store/lib/MasterData/dailyStatuses';
import { useIsMounted } from '../../../hooks/useIsMounted';

/* Routes  */
import { DailyStatusAddOrEdit } from './AddOrEdit';
import { DailyStatusesList } from './List';

/* Presentation Things */
import { Fab } from '@rmwc/fab';

// Styles
import '../MasterData.scss';

interface IProps extends RouteComponentProps {}

export function DailyStatuses(props: IProps) {
  /* States */
  const [dailyStatuses, setDailyStatuses] = useState<{ [id: number]: DayStatusRow }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  /* Refs */
  const _isMounted = useIsMounted();

  /* Context */
  const { open } = useContext(SideSheetContext);

  const getDailyStatusesFromRemote = useCallback(async () => {
    try {
      const statuses = await getDayStatuses();
      if (!statuses.error && _isMounted.current) {
        setDailyStatuses(statuses.reduce((acc, curr) => ({ ...acc, [curr.dayStatusId]: transformDayStatusRowDTO(curr) }), {}));
      } else throw new Error(statuses.error);
    } catch (error) {
      _isMounted.current && setError(String(error.message || 'Sajnáljuk, valami hiba történt a munkakörök betöltés során. Kérlek próbáld újra!'));
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  }, [_isMounted]);

  const onSetStatus = (status: DayStatusRow) => setDailyStatuses(prev => ({ ...prev, [status.id]: status }));

  const onOpenAddSheet = () => open(<DailyStatusAddOrEdit onSetStatus={onSetStatus} />, 'MasterDataAddOrEditSheet');

  useEffect(() => void getDailyStatusesFromRemote(), [getDailyStatusesFromRemote]);

  return (
    <>
      <div className="fab-menu-container">
        <Fab mini icon="add" onClick={onOpenAddSheet} />
      </div>

      <DailyStatusesList {...{ dailyStatuses, isLoading, error, onSetStatus }} />
    </>
  );
}

import React, { useContext } from 'react';

/* Presentation Things */
import { EditContactInfoSection } from './Edit/ContactSection';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';

/* Data Things */
import { PersonDetails, PersonDetailsContactInfo } from './helper';
import { SideSheetContext } from '../../../Contexts/SideSheet';

interface Props {
  contactInfo: PersonDetailsContactInfo;
  onEditPartialDetailsModel(p: Partial<PersonDetails>): void;
  personId: number;
}

export const ContactSection = ({ contactInfo, personId, onEditPartialDetailsModel }: Props) => {
  const { open, close } = useContext(SideSheetContext);

  const onOpenEdit = () => open(<EditContactInfoSection {...{ personId, onEditPartialDetailsModel, close }} />, 'side-sheet--custom-width');

  return (
    <>
      <div className="details-table-header">
        <Typography use="subtitle2" className="color-blue">
          Kapcsolat
        </Typography>

        <div className="icon-group">
          <Icon className="edit" icon="edit" onClick={onOpenEdit} />
          {/* <Icon icon="arrow_drop_down" /> */}
        </div>
      </div>

      <div className="details-table-row">
        <Typography use="caption" className="details-table-row__sub-header color-blue">
          I. Publikus
        </Typography>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Mobilszám
          </Typography>

          <Typography use="body2" className="secondary-text">
            {contactInfo.publicMobilePhone ? <a href={`tel:${contactInfo.publicMobilePhone}`}>{contactInfo.publicMobilePhone}</a> : '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            E-mail
          </Typography>

          <Typography use="body2" className="secondary-text">
            {contactInfo.publicEmail ? <a href={`mailto:${contactInfo.publicEmail}`}>{contactInfo.publicEmail}</a> : '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Vezetékes (Mellék)
          </Typography>

          <Typography use="body2" className="secondary-text">
            {contactInfo.publicLinePhoneExtension || '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Linkedin Profil URL
          </Typography>

          <Typography use="body2" className="secondary-text">
            {contactInfo.linkedin ? (
              <a href={contactInfo.linkedin} target="_blank" rel="noopener noreferrer">
                {contactInfo.linkedin}
              </a>
            ) : (
              '-'
            )}
          </Typography>
        </div>

        <Typography use="caption" className="details-table-row__sub-header color-blue">
          II. Privát
        </Typography>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Lakcím
          </Typography>

          <Typography use="body2" className="secondary-text">
            {`${contactInfo.privateHomeAddress.country}, ${contactInfo.privateHomeAddress.zip} ${contactInfo.privateHomeAddress.city}, ${contactInfo.privateHomeAddress.addressLine1} ${contactInfo.privateHomeAddress.addressLine2}`}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Tartózkodási hely
          </Typography>

          <Typography use="body2" className="secondary-text">
            {`${contactInfo.privateResidenceAddress.country}, ${contactInfo.privateResidenceAddress.zip} ${contactInfo.privateResidenceAddress.city}, ${contactInfo.privateResidenceAddress.addressLine1} ${contactInfo.privateResidenceAddress.addressLine2}`}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Mobilszám
          </Typography>

          <Typography use="body2" className="secondary-text">
            {contactInfo.privateMobilePhone ? <a href={`tel:${contactInfo.privateMobilePhone}`}>{contactInfo.privateMobilePhone}</a> : '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            E-mail
          </Typography>

          <Typography use="body2" className="secondary-text">
            {contactInfo.privateEmail ? <a href={`mailto:${contactInfo.privateEmail}`}>{contactInfo.privateEmail}</a> : '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Facebook Profil URL
          </Typography>

          <Typography use="body2" className="secondary-text">
            <a href={contactInfo.facebook} target="_blank" rel="noopener noreferrer">
              {contactInfo.facebook || '-'}
            </a>
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Legközelebbi hozzátartozójának neve
          </Typography>

          <Typography use="body2" className="secondary-text">
            {contactInfo.nextOfKinName || '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Legközelebbi hozzátartozójának száma
          </Typography>

          <Typography use="body2" className="secondary-text">
            {contactInfo.nextOfKinPhone ? <a href={`tel:${contactInfo.nextOfKinPhone}`}>{contactInfo.nextOfKinPhone}</a> : '-'}
          </Typography>
        </div>
      </div>
    </>
  );
};

import { PersonRowDTO, ProjectRowDTO, ActivitySyncRowDTO, RootProjectRowDTO, UpdateStatusDTO } from '../../store/lib/TrackTime/activitiesSync';
import { DateTime } from 'luxon';
import { formatName } from '../../constants/helperFuntions';
export enum RowSyncStatus {
  Never = 0,
  Pending = 1,
  Failed = 2,
  Synced = 3,
  SkipForever = 4
}

export const STATUSES = [
  {
    id: RowSyncStatus.Never,
    name: 'Soha'
  },
  {
    id: RowSyncStatus.Pending,
    name: 'Függő'
  },
  {
    id: RowSyncStatus.Failed,
    name: 'Hiba'
  },
  {
    id: RowSyncStatus.Synced,
    name: 'Szinkronizált'
  },
  {
    id: RowSyncStatus.SkipForever,
    name: 'Kihagyás'
  }
];

export type ActivitySyncRow = {
  activitySyncId: number;
  activitySyncRowVersion: string;
  isDeleted: boolean;
  personRow: PersonRowDTO;
  project?: ProjectRowDTO;
  comment: string;
  appliedDate: DateTime;
  startTime: DateTime;
  endTime?: DateTime;
  lastSyncedAt?: DateTime;
  syncStatusCode: number;
  errorMessage: string;
  isChecked: boolean;
  isCheckAble: boolean;
  isLoading: boolean;
};

export interface UnsyncedActivities {
  activities: ActivitySyncRow[];
  totalFilteredActivities: number;
  totalActivities: number;
}

export interface PersonRow {
  value: string;
  label: string;
}

export interface RootProjectRow {
  value: string;
  label: string;
}

export const transformActivitySyncRow = (p: ActivitySyncRowDTO): ActivitySyncRow => {
  return {
    ...p,
    appliedDate: DateTime.fromISO(p.appliedDate),
    startTime: DateTime.fromISO(p.startTime),
    endTime: p.endTime ? DateTime.fromISO(p.endTime) : undefined,
    lastSyncedAt: p.lastSyncedAt ? DateTime.fromISO(p.lastSyncedAt) : undefined,
    isChecked: false,
    isCheckAble: true,
    isLoading: false
  };
};

export const transformActivitySyncRowtoUpdateStatusDTO = (p: ActivitySyncRow[], status: RowSyncStatus): UpdateStatusDTO => {
  return {
    activitySyncs: p.map(x => {
      return { activitySyncId: x.activitySyncId, activitySyncRowVersion: x.activitySyncRowVersion };
    }),
    status
  };
};

export const transformPersonRowDTO = (p: PersonRowDTO): PersonRow => {
  return {
    label: formatName(p.firstName, p.lastName, p.middleName),
    value: p.id.toString()
  };
};

export const transformRootProjectRowDTO = (p: RootProjectRowDTO): RootProjectRow => {
  return {
    label: p.displayCode + ' - ' + p.displayName,
    value: p.id.toString()
  };
};

export const toUTCDate = (date: DateTime | null) => {
  if (date && date.isValid) {
    return DateTime.utc(date.year, date.month, date.day).toSQLDate();
  }
  return null;
};

import React, { Component } from 'react';
import { RouteComponentProps } from '@reach/router';
// import CircularProgressBar from "../CircularProgress";
import { UserManager } from 'oidc-client';
import CircularProgress from '../../CircularProgress';

// import loading from './loading.svg';

class AuthSilentRenew extends Component<RouteComponentProps, {}> {
  public componentDidMount() {
    // console.log("signin");
    new UserManager({}).signinSilentCallback();
  }

  public render() {
    return (
      <div>
        <CircularProgress className="full-height authcallback-progress" />
      </div>
    );
  }
}

export default AuthSilentRenew;

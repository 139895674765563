import React, { useCallback, useContext, useEffect, useReducer } from 'react';

/* Data Things */
import {
  CompanyForOrganization,
  generateCompanyOptions,
  generateEmployeeOptions,
  generateOragnizationUnitsFromCompany,
  generateOrganizationUnitOptions,
  generateOUTypeOptions,
  getEmployeesFromRemote,
  OrganizationUnit,
  trasformStateToAddOrEditOUDTO
} from '../helper';
import { createOrganizationUnit, editOrganizationUnit, OUEditedDTO } from '../../../../store/lib/Organization/Structure';
import { organiztaionStructureAddOrEditReducer, init } from '../../../../store/reducers/Organization/Structure/addOrEdit';
import { getOUTypesFromRemote } from '../../../Settings/OUTypes/helper';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { useIsMounted } from '../../../../hooks/useIsMounted';

/* Presentation Things */
import ResponseSnackbar from '../../../../components/Portals/ResponseSnackbar';
import { LinearProgress } from '@rmwc/linear-progress';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import { Select } from '@rmwc/select';

interface Props {
  editableOrganization?: OrganizationUnit;
  onUpdateOrganization(newOrganizationUnit: OUEditedDTO, isEdit: boolean): void;
  companies: CompanyForOrganization[];
}

export const OrganizationStrucutreAddOrEdit = (props: Props) => {
  /* State */
  const [state, dispatch] = useReducer(organiztaionStructureAddOrEditReducer, init(props.editableOrganization));

  /* Variables */
  const selectedCompany = props.companies.find(x => x.id === state.parentCompanyId);
  const isDisabled = !!(state.isLoading || state.generalError.length);
  const isEdit = Boolean(props.editableOrganization);
  const organizationUnits = selectedCompany ? generateOragnizationUnitsFromCompany(selectedCompany, props.editableOrganization ? props.editableOrganization.id : undefined) : [];
  const organizationUnitId = isEdit ? props.editableOrganization!.id : null;

  const organizationUnitOptions = generateOrganizationUnitOptions(organizationUnits);
  const employeeOptions = generateEmployeeOptions(state.employees);
  const companyOptions = generateCompanyOptions(props.companies);
  const ouTypeOptions = generateOUTypeOptions(state.ouTypes);

  /* Context */
  const { close } = useContext(SideSheetContext);

  /* Refs */
  const _isMounted = useIsMounted();

  const getInitialData = useCallback(async () => {
    try {
      const [ouTypes, employees] = await Promise.all([getOUTypesFromRemote(), getEmployeesFromRemote()]);
      if (typeof ouTypes !== 'string' && typeof employees !== 'string') {
        _isMounted.current && dispatch({ type: 'success_get_init', payload: { ouTypes, employees, initCompanyId: props.companies[0].id } });
      }
    } catch (error) {
      _isMounted.current && dispatch({ type: 'error_get_init', payload: { error: String(error) } });
    }
  }, [_isMounted, props.companies]);

  useEffect(() => void getInitialData(), [getInitialData]);

  const onSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();
    dispatch({ type: 'set_partial_state', payload: { isLoading: true } });
    try {
      const model = trasformStateToAddOrEditOUDTO(state, props.editableOrganization);
      if (model) {
        const resp = isEdit ? await editOrganizationUnit(organizationUnitId!, model) : await createOrganizationUnit(model);

        if (!resp.error && _isMounted.current) {
          props.onUpdateOrganization(resp, isEdit);
          dispatch({
            type: 'set_partial_state',
            payload: { apiResponse: { type: 'success', message: `Sikeres szervezeti egység ${isEdit ? 'szerkesztés' : 'hozzáadás'}` } }
          });
          setTimeout(() => _isMounted.current && close(), 500);
        } else throw new Error(resp.error);
      }
    } catch (error) {
      if (_isMounted.current) {
        dispatch({
          type: 'set_partial_state',
          payload: { apiResponse: { type: 'error', message: String(error.message || 'Sajnáljuk, valami hiba történt a címke mentése során.') } }
        });
      }
    } finally {
      _isMounted.current && dispatch({ type: 'set_partial_state', payload: { isLoading: false } });
    }
  };

  const onNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'set_partial_state', payload: { name: ev.currentTarget.value } });
  };

  const onSelectChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({ type: 'set_partial_state', payload: { [ev.target.name]: ev.currentTarget.value === ' ' ? null : Number(ev.currentTarget.value) } });
  };

  const onCloseResponseBar = () => dispatch({ type: 'set_partial_state', payload: { apiResponse: null } });

  return (
    <>
      <ResponseSnackbar response={state.apiResponse} onClose={onCloseResponseBar} />

      <div className="drawer-header">{isEdit ? `Szerkesztése - ${props.editableOrganization!.name}` : 'Új egység'}</div>
      {state.isLoading && <LinearProgress />}

      <div className="form-content">
        <form onSubmit={onSubmit}>
          <div className="form-content-row">
            <TextField placeholder="Név" required value={state.name} onChange={onNameChange} maxLength={100} />
          </div>

          <div className="form-content-row">
            <Select
              name="ouTypeId"
              label="Szervezeti egység típusa"
              options={ouTypeOptions}
              required
              value={state.ouTypeId !== null ? String(state.ouTypeId) : undefined}
              onChange={onSelectChange}
              disabled={isDisabled}
            />
          </div>

          <div className="form-content-row">
            <Select
              name="parentCompanyId"
              label="Cég"
              options={companyOptions}
              required
              value={state.parentCompanyId !== null ? String(state.parentCompanyId) : undefined}
              onChange={onSelectChange}
              disabled={isDisabled}
            />
          </div>

          <div className="form-content-row">
            <Select
              name="parentOUId"
              label="Szülő szervezeti egység"
              options={organizationUnitOptions}
              value={state.parentOUId !== null ? String(state.parentOUId) : ' '}
              onChange={onSelectChange}
              disabled={isDisabled}
            />
          </div>

          <div className="form-content-row">
            <Select name="managerPersonId" label="Vezető" options={employeeOptions} value={state.managerPersonId !== null ? String(state.managerPersonId) : ' '} onChange={onSelectChange} disabled={isDisabled} />
          </div>

          <div className="form-content-row">
            <Select
              name="deputyManagerPersonId"
              label="Vezető helyettes"
              options={employeeOptions}
              value={state.deputyManagerPersonId !== null ? String(state.deputyManagerPersonId) : ' '}
              onChange={onSelectChange}
              disabled={isDisabled}
            />
          </div>

          <Button unelevated label={isEdit ? 'Szerkesztés' : 'Hozzáadás'} type="submit" disabled={isDisabled} />
        </form>
      </div>
    </>
  );
};

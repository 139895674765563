import { api } from '../../../../utils/api';
import { OUMemberTypes, EmploymentTypes } from '../../../../constants/enums';
import { CompanyForOrgHierarchyDTO } from '../Structure';

export type ConcurrentEditViewModel = {
  updated: string | null;
};

export interface PersonDetailsEmploymentDTO extends ConcurrentEditViewModel {
  employments: EmploymentDetailsRowDTO[];
  lastEmployment: EmploymentDetailsDTO;
}

export type EmploymentDetailsRowDTO = {
  employmentId: number;
  employmentType: EmploymentTypes;
  companyName: string;
  weeklyWorkHours: number | null;
  grossSalary: string | null;
  startDate: string;
  endDate: string | null;
  employmentContracts: EmploymentContractDetailsDTO[];
};

export type EmploymentContractDetailsDTO = {
  employmentContractId: number;
  startDate: string;
  endDate: string | null;
  trialEndDate: string | null;
  weeklyWorkHours: number;
  grossSalary: string | null;
  workScheduleName: string | null;
  jobRoles: PersonJobRoleDetailsDTO[];
};

type PersonJobRoleDetailsDTO = {
  jobRoleId: number;
  jobRoleName: string;
};

type EmploymentDetailsDTO = {
  employmentId: number;
  employmentType: EmploymentTypes;
  companyName: string;
  startDate: string;
  endDate: string | null;
  childrenInHousehold: number | null;
  taxReducement: number | null;
  daysOff: EmploymentDaysOffDTO[];
  resourceGroupTypeId: string;
  employmentContracts: EmploymentContractDetailsDTO[];
  oUs: PersonDetailsOUDTO[];
};

export type EmploymentDaysOffDTO = {
  year: number;
  daysOff: number;
};

type PersonDetailsOUDTO = {
  ouId: number;
  ouName: string;
  ouMemberType: OUMemberTypes;
};

export interface EditEmployeeEmploymentDTO extends ConcurrentEditViewModel {
  employmentId: number;
  startDate: string;
  endDate: string | null;
  companyId: number;
  childrenInHousehold: number | null;
  taxReducement: number | null;
  daysOff: EmploymentDaysOffDTO[];
  resourceGroupTypeId: string | null;
  oUs: PersonOUDTO[];
  employmentContracts: EmploymentContractDTO[];
}

export type PersonOUDTO = {
  ouId: number;
  ouMemberType: OUMemberTypes;
};

export type EmploymentContractDTO = {
  employmentContractId: number | null;
  startDate: string;
  weeklyWorkHours: number;
  grossSalary: string | null; //float
  jobRoleIds: number[];
  trialEndDate: string | null;
  workScheduleId: number | null;
};

export type OUDTO = {
  ouId: number;
  name: string;
};

export interface GetWorkScheduleByHourDTO {
  workScheduleId: number;
  workScheduleName: string;
}

export interface AddEmploymentDTO extends Omit<EditEmployeeEmploymentDTO, 'employmentId'> {}

export const getEmploymentHistory = (id: number) => {
  return api<PersonDetailsEmploymentDTO>('GET', `people/${id}/EmploymentHistory`, null);
};

export const getEmployment = (personId: number, employmentId: number) => {
  return api<EditEmployeeEmploymentDTO>('GET', `people/${personId}/EmploymentDetails/${employmentId}`, null);
};

export const putEmployment = (personId: number, employmentId: number | null = null, model: EditEmployeeEmploymentDTO) => {
  return api<PersonDetailsEmploymentDTO>('PUT', `people/${personId}/EmploymentDetails/${employmentId}`, model);
};

export const getCompanies = () => {
  return api<CompanyForOrgHierarchyDTO[]>('GET', `companies`, null);
};

export const GetOrganizationUnitsByCompanyId = (id: number) => {
  return api<OUDTO[]>('GET', `companies/${id}/OrganizationUnits`, null);
};

export const GetWorkSchedulesByWeeklyHour = (weekylSumHours: number) => {
  return api<GetWorkScheduleByHourDTO[]>('GET', `workSchedule/${weekylSumHours}`, null);
};

export const addEmployment = (personId: number, model: AddEmploymentDTO) => {
  return api<PersonDetailsEmploymentDTO>('POST', `people/${personId}/employment/`, model);
};

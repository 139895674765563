import React, { Component } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
// import CircularProgressBar from "../CircularProgress";
import { UserManager, User } from 'oidc-client';
import CircularProgress from '../../CircularProgress';

// import loading from './loading.svg';

class AuthCallback extends Component<RouteComponentProps, {}> {
  public componentDidMount() {
    // console.log("signin");
    new UserManager({ response_mode: 'query' })
      .signinRedirectCallback()
      .then((user: User) => {
        // console.log("navigate", user);
        navigate(user.state && user.state.path ? user.state.path : '/');
      })
      .catch(() => {
        // console.error(e);
      });
  }

  public render() {
    // if (this.props.location && /access_token|id_token|error/.test(this.props.location.hash)) {
    //   Auth.Instance.handleAuthentication();
    // }
    // console.log("render");

    return (
      <div>
        <CircularProgress className="full-height authcallback-progress" />
      </div>
    );
  }
}

export default AuthCallback;

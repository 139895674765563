import React, { useContext, useState } from 'react';

/* Presentation Things */
import { EditEmployment } from './Edit/index';
import { Typography } from '@rmwc/typography';
import { Select } from '@rmwc/select';
import { Icon } from '@rmwc/icon';
import { Fab } from '@rmwc/fab';

/* Data Things */
import { PersonDetailsEmployment, EmploymentDetails, EmploymentContractDetails, EmploymentDetailsRow } from './helper';
import { OU_MEMBER_TYPE_MAP } from '../../../../constants/enums';
import { SideSheetContext } from '../../../Contexts/SideSheet';
interface IProps {
  error: string;
  employmentModel: PersonDetailsEmployment | null;
  onEditEmploymentModel(newModel: PersonDetailsEmployment, updated: string);
  personId: number;
  updated: string | null;
}
interface LastEmploymentProps {
  row: EmploymentDetails;
  onEditEmploymentModel(newModel: PersonDetailsEmployment, updated: string);
  personId: number;
}

const LARGE_SIDSHEET = 'side-sheet--custom-width employment';

export const EmploymentPanel = (props: IProps) => {
  const { employmentModel, personId, updated, onEditEmploymentModel, error } = props;
  const { open, close } = useContext(SideSheetContext);
  const isCanBeAddNew = employmentModel && (!employmentModel.lastEmployment || employmentModel.lastEmployment.endDate);
  const lastEmploymentEndDate = employmentModel && employmentModel.lastEmployment ? employmentModel.lastEmployment.endDate : null;

  const openAdd = () => {
    open(<EditEmployment {...{ personId, updated, close, onSave: onEditEmploymentModel, lastEmploymentEndDate }} />, LARGE_SIDSHEET);
  };

  return (
    <div className="employment-panel">
      {isCanBeAddNew && <Fab className="add-button" mini icon="add" onClick={openAdd} />}

      {employmentModel && <LastEmployment row={employmentModel.lastEmployment} {...{ personId, onEditEmploymentModel }} />}

      {employmentModel && employmentModel.employments.map(row => <Employment {...{ row, key: row.employmentId }} />)}

      {!employmentModel && <div className="basic-error-text">{error || 'Nincs megjeleníthető adat!'}</div>}
    </div>
  );
};

const LastEmployment = ({ row, personId, onEditEmploymentModel }: LastEmploymentProps) => {
  const { open, close } = useContext(SideSheetContext);

  /* Variables */
  const startDate = row.startDate.setLocale('hu').toFormat('D') + ' - ';
  const endDate = row.endDate ? row.endDate.setLocale('hu').toFormat('D') : '';
  const DAYSOFF_SELECT = row.daysOff.sort((a, b) => b.year - a.year).map(x => ({ label: String(x.year), value: String(x.year) }));

  /* State */
  const [selectedYear, setYear] = useState(DAYSOFF_SELECT[0] ? DAYSOFF_SELECT[0].value : undefined);

  const selectedDaysOff = row.daysOff.find(x => x.year === Number(selectedYear));

  const openEdit = () => {
    open(<EditEmployment {...{ personId, close, employmentId: row.employmentId, onSave: onEditEmploymentModel }} />, LARGE_SIDSHEET);
  };

  return (
    <div className="details-table">
      <div className="details-table-header">
        <Typography use="subtitle2" className="color-blue">
          Munkaviszony {`${startDate} ${endDate} `}
        </Typography>

        <div className="icon-group">
          <Icon onClick={openEdit} className="edit" icon="edit" />
        </div>
      </div>

      <div className="details-table-row">
        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Cégnév
          </Typography>

          <Typography use="body2" className="secondary-text">
            {row.companyName}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Erőforráscsoport kódja
          </Typography>

          <Typography use="body2" className="secondary-text">
            {row.resourceGroupTypeId}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Egy háztartásban élő gyermekek száma
          </Typography>

          <Typography use="body2" className="secondary-text">
            {row.childrenInHousehold || '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Családi adókedvezmény összege
          </Typography>

          <Typography use="body2" className="secondary-text">
            {row.taxReducement || '-'}
          </Typography>
        </div>

        <Typography use="caption" className="details-table-row__sub-header color-blue">
          Szabadság napok
        </Typography>
        <div className="details-table-row__cell">
          <Select disabled={!row.daysOff.length} value={selectedYear} label="Tárgy éve" onChange={evt => setYear(evt.currentTarget.value)} options={DAYSOFF_SELECT} />
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Éves szabadnapok száma
          </Typography>

          <Typography use="body2" className="secondary-text">
            {selectedDaysOff ? selectedDaysOff.daysOff : '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell" />

        <div className="details-table-row__cell" />

        {row.employmentContracts.map(contract => (
          <Contract key={contract.employmentContractId} {...{ contract }} />
        ))}

        <Typography use="caption" className="details-table-row__sub-header color-blue">
          Szervezeti egységek
        </Typography>

        {row.oUs.map((x, i) => (
          <div key={i} className="details-table-row__cell">
            <Typography use="caption" className="primary-text">
              Megnevezés, tagság
            </Typography>

            <Typography use="body2" className="secondary-text">
              {x.ouName + ', ' + OU_MEMBER_TYPE_MAP[x.ouMemberType]}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

const Employment = ({ row }: { row: EmploymentDetailsRow }) => {
  const startDate = row.startDate.setLocale('hu').toFormat('D') + ' - ';
  const endDate = row.endDate ? row.endDate.setLocale('hu').toFormat('D') : '';

  return (
    <div className="details-table">
      <div className="details-table-header">
        <Typography use="subtitle2" className="color-blue">
          Munkaviszony {`${startDate} ${endDate} `}
        </Typography>
      </div>

      <div className="details-table-row">
        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Cégnév
          </Typography>

          <Typography use="body2" className="secondary-text">
            {row.companyName}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Munkabér
          </Typography>

          <Typography use="body2" className="secondary-text">
            {row.grossSalary || '-'}
          </Typography>
        </div>

        <div className="details-table-row__cell" />
        <div className="details-table-row__cell" />

        {row.employmentContracts.map(contract => (
          <Contract key={contract.employmentContractId} {...{ contract }} />
        ))}
      </div>
    </div>
  );
};

const Contract = ({ contract }: { contract: EmploymentContractDetails }) => {
  const startDate = contract.startDate.setLocale('hu').toFormat('D') + ' - ';
  const endDate = contract.endDate ? contract.endDate.setLocale('hu').toFormat('D') : '';
  const trialEnd = contract.trialEndDate ? contract.trialEndDate.setLocale('hu').toFormat('D') : '-';

  return (
    <>
      <Typography use="caption" className="details-table-row__sub-header color-blue">
        {`Munkaszerződés ${startDate} ${endDate} `}
      </Typography>
      <div className="details-table-row__cell">
        <Typography use="caption" className="primary-text">
          Próbaidő vége
        </Typography>

        <Typography use="body2" className="secondary-text">
          {trialEnd}
        </Typography>
      </div>

      <div className="details-table-row__cell">
        <Typography use="caption" className="primary-text">
          {`Heti óraszám (${contract.workScheduleName || 'Általános'})`}
        </Typography>

        <Typography use="body2" className="secondary-text">
          {contract.weeklyWorkHours} óra
        </Typography>
      </div>

      <div className="details-table-row__cell">
        <Typography use="caption" className="primary-text">
          Munkakörök
        </Typography>

        <Typography use="body2" className="secondary-text">
          {contract.jobRoles.map(x => x.jobRoleName).join(', ')}
        </Typography>
      </div>

      <div className="details-table-row__cell" />
    </>
  );
};

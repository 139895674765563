import React, { useCallback, useEffect, useReducer, useRef } from 'react';

/* Data Things */
import SignalR, { HubConnectionState } from '../../utils/signalR';
import { getProducts, getProduct, createProduct, editProduct, createTaskAttachment, deleteTaskAttachment, cloneProduct, getClientDevices } from '../../store/lib/Manufacturing/products';
import { productsReducer, initialState, ProductsState } from '../../store/reducers/Manufacturing/Products';
import { ICreateProductDTO, IEditProductDTO, IProductAttachmentWriteDTO, IProductAttachmentsWriteDTO, IScreenFeedbackDTO } from '../../typings/DTOs';
import { useIsMounted } from '../../hooks';

/* Presentation Things */
import ResponseSnackbar, { ApiResponseType } from '../Portals/ResponseSnackbar';

interface IProductsContext extends ProductsState {
  onGetProducts: (text?: string, page?: number) => Promise<string | null>;
  onGetClientDevices: () => Promise<string | null>;
  onCreateProduct: (model: ICreateProductDTO) => Promise<string | number>;
  onEditProduct: (model: IEditProductDTO) => Promise<string | number>;
  onGetProduct: (id: number) => Promise<string | null>;
  onCloneProduct: (id: number) => Promise<string | number>;
  onCreateAttachment: (model: IProductAttachmentsWriteDTO) => Promise<string | number[]>;
  onDeleteAttachment: (model: IProductAttachmentWriteDTO) => Promise<string | number>;
  onModifyAttachment: (attachmentId: number) => Promise<string | number>;
  onCheckTVAttachments: () => void;
  onChangePage(page: number): void;
  onSearch(text: string): void;
}

const ProductsContext = React.createContext<IProductsContext>({
  ...initialState,
  onGetProducts: () => Promise.reject(''),
  onGetClientDevices: () => Promise.reject(''),
  onCreateProduct: () => Promise.reject(''),
  onEditProduct: () => Promise.reject(''),
  onGetProduct: () => Promise.reject(''),
  onCloneProduct: () => Promise.reject(''),
  onCreateAttachment: () => Promise.reject(''),
  onDeleteAttachment: () => Promise.reject(''),
  onModifyAttachment: () => Promise.reject(''),
  onCheckTVAttachments: () => {},
  onChangePage: () => null,
  onSearch: () => null
});

function ProductsProvider(props: React.PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(productsReducer, initialState);

  /* Variables */
  const signal = useRef(new AbortController());

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetProducts = useCallback(async (): Promise<string> => {
    try {
      signal.current.abort();
      signal.current = new AbortController();

      dispatch({ type: 'set_partial_state', payload: { isLoading: true, error: '' } });

      const products = await getProducts(state.search, state.page * 50, 50, signal.current.signal);
      if (products && !products.error && _isMounted.current) {
        dispatch({ type: 'success_get_products', payload: { products } });
        return Promise.resolve('');
      } else {
        throw new Error(products.error || 'Sajnáljuk, valami hiba történt a cikkek betöltés során. Kérlek próbáld újra!');
      }
    } catch (error) {
      const { message } = error as any;
      if (_isMounted.current) {
        dispatch({
          type: 'error_get_products',
          payload: { error: String(message || 'Sajnáljuk, valami hiba történt a cikkek betöltés során. Kérlek próbáld újra!') }
        });
      }
      return Promise.reject(String(message || error));
    }
  }, [_isMounted, state.page, state.search, signal]);

  const onGetProduct = useCallback(
    async (id: number): Promise<string> => {
      try {
        const product = await getProduct(id);
        if (product && !product.error && _isMounted.current) {
          dispatch({ type: 'success_get_product', payload: { product } });
          return Promise.resolve('');
        } else {
          throw new Error(product.error || 'Sajnáljuk, valami hiba történt a cikk betöltés során. Kérlek próbáld újra!');
        }
      } catch (error) {
        const { message } = error as any;
        return Promise.reject(String(message || 'Sajnáljuk, valami hiba történt a cikk betöltés során. Kérlek próbáld újra!'));
      }
    },
    [_isMounted]
  );

  const onGetClientDevices = useCallback(async (): Promise<string> => {
    try {
      const clientDevices = await getClientDevices();
      if (clientDevices && !clientDevices.error && _isMounted.current) {
        dispatch({ type: 'success_get_devices', payload: { clientDevices } });
        return Promise.resolve('');
      } else {
        throw new Error(clientDevices.error || 'Sajnáljuk, valami hiba történt az eszközök betöltés során.');
      }
    } catch (error) {
      const { message } = error as any;
      return Promise.reject(String(message || 'Sajnáljuk, valami hiba történt az eszközök betöltés során.'));
    }
  }, [_isMounted]);

  const onCreateProduct = useCallback(
    async (model: ICreateProductDTO) => {
      try {
        const product = await createProduct(model);
        if (!product.error && _isMounted.current) {
          dispatch({ type: 'success_post_product', payload: { product } });
          return Promise.resolve(product.id);
        } else throw new Error(product.error);
      } catch (error) {
        const { message } = error as any;
        _isMounted.current && dispatch({ type: 'error_post_product', payload: { error: String(message) } });
        return Promise.reject(String(message || error));
      }
    },
    [_isMounted]
  );

  const onEditProduct = useCallback(
    async (model: IEditProductDTO) => {
      try {
        const product = await editProduct(model);
        if (!product.error && _isMounted.current) {
          dispatch({ type: 'success_put_product', payload: { product } });
          return Promise.resolve(product.id);
        } else throw new Error(product.error);
      } catch (error) {
        const { message } = error as any;
        _isMounted.current && dispatch({ type: 'error_put_product', payload: { error: String(message) } });
        return Promise.reject(String(message || error));
      }
    },
    [_isMounted]
  );

  const onCloneProduct = useCallback(
    async (id: number): Promise<string | number> => {
      try {
        dispatch({ type: 'set_partial_state', payload: { isLoading: true, error: '' } });

        const product = await cloneProduct(id);
        if (product && !product.error && _isMounted.current) {
          dispatch({ type: 'success_post_product', payload: { product } });
          return Promise.resolve(product.id);
        } else {
          throw new Error(product.error || 'Sajnáljuk, valami hiba történt a cikk klónozása során. Kérlek próbáld újra!');
        }
      } catch (error) {
        const { message } = error as any;
        _isMounted.current && dispatch({ type: 'error_post_product', payload: { error: String(message) } });
        return Promise.reject(String(message || error));
      }
    },
    [_isMounted]
  );

  const onCreateAttachment = useCallback(
    async (model: IProductAttachmentsWriteDTO) => {
      try {
        await createTaskAttachment(model);
        if (_isMounted.current) {
          dispatch({ type: 'success_post_task_attachment', payload: null });
          return Promise.resolve(model.attachmentTempIds);
        } else throw new Error('error');
      } catch (error) {
        const { message } = error as any;
        _isMounted.current && dispatch({ type: 'error_task_attachment', payload: { error: String(message) } });
        return Promise.reject(String(message || error));
      }
    },
    [_isMounted]
  );

  const onDeleteAttachment = useCallback(
    async (model: IProductAttachmentWriteDTO) => {
      try {
        await deleteTaskAttachment(model);
        if (_isMounted.current) {
          dispatch({ type: 'success_del_task_attachment', payload: null });
          return Promise.resolve(model.attachmentId);
        } else throw new Error('error');
      } catch (error) {
        const { message } = error as any;
        _isMounted.current && dispatch({ type: 'error_task_attachment', payload: { error: String(message) } });
        return Promise.reject(String(message || error));
      }
    },
    [_isMounted]
  );

  const onModifyAttachment = useCallback(
    async (attachmentId: number) => {
      try {
        if (_isMounted.current) {
          dispatch({ type: 'success_mod_task_attachment', payload: null });
          return Promise.resolve(attachmentId);
        } else throw new Error('error');
      } catch (error) {
        const { message } = error as any;
        _isMounted.current && dispatch({ type: 'error_task_attachment', payload: { error: String(message) } });
        return Promise.reject(String(message || error));
      }
    },
    [_isMounted]
  );

  const onCheckTVAttachments = useCallback(() => {
    if (_isMounted.current && SignalR.Instance.connection.state === HubConnectionState.Connected) {
      dispatch({ type: 'success_clear_feedback', payload: null });
      SignalR.Instance.connection
        .invoke('GetOnScreenFeedback', {})
        .then(() => {})
        .catch(() => {});
    }
  }, [_isMounted]);

  const onChangePage = (page: number) => dispatch({ type: 'set_partial_state', payload: { page } });

  const onSearch = (search: string) => dispatch({ type: 'set_partial_state', payload: { page: 0, search } });

  const onSetApiResponse = (apiResponse: ApiResponseType) => dispatch({ type: 'set_partial_state', payload: { apiResponse } });

  useEffect(() => void onGetProducts(), [onGetProducts]);

  useEffect(() => {
    SignalR.Instance.connection.on('SendOnScreenFeedback', (model: IScreenFeedbackDTO) => {
      dispatch({ type: 'success_get_feedback', payload: { feedback: model } });
    });
    return () => {
      SignalR.Instance.connection.stop().then();
    };
  }, []);

  return (
    <div className="Products">
      <ResponseSnackbar response={state.apiResponse} onClose={() => onSetApiResponse(null)} />
      <ProductsContext.Provider
        value={{
          ...state,
          onGetProducts,
          onGetProduct,
          onGetClientDevices,
          onCreateProduct,
          onEditProduct,
          onCloneProduct,
          onCreateAttachment,
          onDeleteAttachment,
          onModifyAttachment,
          onCheckTVAttachments,
          onChangePage,
          onSearch
        }}>
        {props.children}
      </ProductsContext.Provider>
    </div>
  );
}

const ProductsConsumer = ProductsContext.Consumer;
export { ProductsProvider, ProductsContext, ProductsConsumer };

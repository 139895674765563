import { DateTime } from 'luxon';
import { TagDTO } from '../MasterData/activityTags';
import { api } from '../../../utils/api';

export interface LastClosedMonthDTO {
  year: number;
  month: number;
}

export interface PersonRowDTO {
  id: number;
  courtesyTitle: string;
  firstName: string;
  lastName: string;
  middleName: string;
  photoUrl: string;
  realCostPerMonth: number | null;
}

export interface MonthlyCloseDTO {
  isMonthClosed: boolean;
  isCostClosedForMonth: boolean;
  persons: PersonRowDTO[] | null;
}
export interface PersonMonthlyCloseDTO {
  personId: number;
  monthlyCost: number;
}
export interface AddMonthlyCloseDTO {
  year: number;
  month: number;
  monthlyCosts: PersonMonthlyCloseDTO[];
}

export interface PersonCostRowDTO {
  id: number;
  courtesyTitle: string;
  firstName: string;
  lastName: string;
  middleName: string;
  photoUrl: string;
}

export interface ErrorsDTO {
  notClosedActivityId: number | null;
  pendingLeaveRequestRowIds: number[];
  pendingOvertimeRequestRowIds: number[];
}

export interface WarningsDTO {
  daysWithoutStatus: number;
  activityIdsWithoutProject: number[];
  activityIdsMoreThanEightWorkingHours: number[];
  overlappedActivityIds: number[];
}

export interface ValidationCheckMonthRowDTO {
  person: PersonCostRowDTO;
  errors: ErrorsDTO;
  warnings: WarningsDTO;
}

export interface ValidationCheckMonthDTO {
  validationCheckMonthRows: ValidationCheckMonthRowDTO[];
}

export interface IActivityDTO {
  activityTimeEntryId: number;
  personId: number;
  projectId: number | null;
  projectDisplayCode: string | null;
  projectName: string | null;
  projectColorCode: string | null;
  rootProjectDisplayCode: string | null;
  rootProjectName: string | null;
  rootProjectId: number | null;
  rootProjectColorCode: string | null;
  appliedDate: string;
  startTime: string;
  endTime: string | null;
  comment: string;
  tags: TagDTO[] | null;
  updated: number;
}

export interface OvertimeRequestRowDetailsDTO {
  overtimeRequestRowId: number;
  overtimeRequestId: number;
  personRowVM: PersonCostRowDTO;
  createDate: string;
  startDate: string | null;
  endDate: string | null;
  maxMinutes: string | null;
  reason: string;
  isDeleteRequest: boolean;
}

export interface LeaveRequestRowDetailsDTO {
  leaveRequestRowId: number;
  leaveRequestId: number;
  personRowVM: PersonCostRowDTO;
  createDate: string;
  startDate: string | null;
  endDate: string | null;
  reason: string;
  isDeleteRequest: boolean;
}

export interface PreviewCloseCostsDTO {
  personId: number;
  monthlyRequiredWorkHours: number; //float
  workHours: number; //float
  paidIncreaseWorkTimeHour: number; //float
  calculatedHoursResult: number; //float
  currentHourlyCost: number | null; // float
  calculatedHourlyCost: number; //flaot
}

export interface ClosedMonthDatesDTO {
  lastClosedMonth: string | null;
  restrictedBefore: string | null;
}

export const getMonthlyClose = (year: number, month: number, signal) => {
  return api<MonthlyCloseDTO>('GET', `MonthlyClose?year=${year}&month=${month}`, null, signal);
};

export const closeMonth = (year: number, month: number, signal: AbortSignal) => {
  return api<MonthlyCloseDTO>('POST', `MonthlyClose/Close?year=${year}&month=${month}`, null, signal);
};

export const getValidationsForMonth = (year: number, month: number, signal: AbortSignal) => {
  return api<ValidationCheckMonthDTO>('GET', `MonthlyClose/Validations?year=${year}&month=${month}`, null, signal);
};

export const getActivites = (ids: number[]) => {
  return api<IActivityDTO[]>('GET', `activities/?${ids.map(x => 'activityIds=' + x + '&').join('')}`, null);
};

export const getOvertimeRequestRowsDetails = (ids: number[]) => {
  return api<OvertimeRequestRowDetailsDTO[]>('GET', `overtimerequests/rows/details?${ids.map(x => 'overtimeRequestRowIds=' + x + '&').join('')}`, null);
};

export const getLeaveRequestRowsDetails = (ids: number[]) => {
  return api<LeaveRequestRowDetailsDTO[]>('GET', `leaverequests/rows/details?${ids.map(x => 'leaveRequestRowIds=' + x + '&').join('')}`, null);
};

export const closeCostMonth = (addMonthlyClose: AddMonthlyCloseDTO, signal: AbortSignal) => {
  return api<{}>('POST', `MonthlyClose/CloseCost`, addMonthlyClose, signal);
};

export const reOpenMonth = (year: number, month: number, signal: AbortSignal) => {
  return api<string | null>('POST', `MonthlyClose/ReOpen?year=${year}&month=${month}`, null, signal);
};

export const getPreviewCloseCostMonth = (addMonthlyClose: AddMonthlyCloseDTO, signal: AbortSignal) => {
  return api<PreviewCloseCostsDTO[]>('POST', `MonthlyClose/Preview`, addMonthlyClose, signal);
};

export const getClosedInfo = () => api<ClosedMonthDatesDTO>('GET', 'MonthlyClose/Closed');

export const setClosedInfo = (restrictedBefore: DateTime) => api<ClosedMonthDatesDTO>('PUT', 'MonthlyClose/RestrictedWorkTime', restrictedBefore.toISODate());

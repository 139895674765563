import React, { useState, useRef, useEffect } from 'react';
import { formatBytes } from '../../constants/helperFuntions';
import { IconButton } from '@rmwc/icon-button';
import CircularProgress from '../CircularProgress';

interface Props {
  initialFiles: SelectedFile[];
  label: string;
  isMultiple: boolean;
  compactModder: number;
  accept: string | undefined;
  onSelect(files: SelectedFile, id?: number): Promise<{ locationId: number | null; message: string }>;
  onDelete?(id: string): void;
  maxFileSize: number; ////bytes;
  title?: string;
}

export type SelectedFile = { file: File; error: string; id: string | null; locationId: number | null; isLoading: boolean; url?: string };

const FileSelector = ({ initialFiles, maxFileSize, onSelect, onDelete, isMultiple, compactModder, accept, label, title }: Props) => {
  const _isMounted = useRef(false);
  const timeoutRef = useRef<any>();
  const [selectedFiles, setSelectedFiles] = useState(initialFiles);
  const [isDragging, setIsDragging] = useState(false);

  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    _isMounted.current = true;
    //IGNORING THE ERROR => github.com/Microsoft/TypeScript/issues/28357
    //@ts-ignore
    document.body.addEventListener('dragover', handleActiveFileSelection);
    //@ts-ignore
    document.body.addEventListener('dragleave', handleDeactiveFileSelection);
    return () => {
      //@ts-ignore
      document.body.removeEventListener('dragover', handleActiveFileSelection);
      //@ts-ignore
      document.body.removeEventListener('dragleave', handleDeactiveFileSelection);
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => setSelectedFiles(initialFiles), [initialFiles]);

  const handleActiveFileSelection = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const dt = e.dataTransfer;
    if (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') !== -1 : dt.types.some(x => x === 'Files'))) {
      setIsDragging(true);
      clearTimeout(timeoutRef.current);
    }
  };

  const handleDeactiveFileSelection = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    timeoutRef.current = setTimeout(() => {
      setIsDragging(false);
    }, 50);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    handleFilesSelect(e.dataTransfer.files);
    setIsDragging(false);
  };

  const checkFileHasError = (file: File): string => {
    if (file.size > maxFileSize) {
      return `A fájl mérete meghaladja a maximális (${formatBytes(maxFileSize)}) méretet.`;
    }
    return '';
  };

  const handleFilesSelect = (e: React.ChangeEvent<HTMLInputElement> | FileList) => {
    const selectableFiles = e instanceof FileList ? e : e.target.files;

    if (selectableFiles) {
      const files: SelectedFile[] = [];
      //Adding to the view
      for (let i = 0; i < (isMultiple ? selectableFiles.length : 1); i++) {
        const currentFile = selectableFiles.item(i);
        if (currentFile !== null) {
          files.push({
            file: currentFile,
            error: checkFileHasError(currentFile),
            id: !compactModder ? currentFile.name + i + Math.random() : currentFile.name,
            isLoading: true,
            locationId: null
          });
        }
      }

      setSelectedFiles(prev => [...files, ...(isMultiple ? prev : [])]);

      //API call each file
      for (let i = 0; i < files.length; i++) {
        if (!compactModder) {
          onSelect(files[i])
            .then(res => {
              _isMounted.current && setSelectedFiles(prev => prev.map(x => (x.id === files[i].id ? { ...x, isLoading: false, locationId: res.locationId, error: '' } : x)));
            })
            .catch(err => {
              _isMounted.current && setSelectedFiles(prev => prev.map(x => (x.id === files[i].id ? { ...x, isLoading: false, error: String(err.message || err) } : x)));
            });
        } else
          onSelect(files[i], compactModder)
            .then(res => {
              _isMounted.current && setSelectedFiles(prev => prev.map(x => (x.id === files[i].id ? { ...x, isLoading: false, locationId: res.locationId, error: '' } : x)));
            })
            .catch(err => {
              _isMounted.current && setSelectedFiles(prev => prev.map(x => (x.id === files[i].id ? { ...x, isLoading: false, error: String(err.message || err) } : x)));
            });
      }
    }
  };

  const handleDeleteFile = (id: string) => {
    setSelectedFiles(prev => prev.filter(x => id !== x.id));
    if (input.current) input.current.value! = '';
    onDelete && onDelete(id);
  };

  return (
    <>
      <div className="file-selector" onDrop={handleDrop}>
        {title && <div className="file-selector_title">{title}</div>}

        <div className="file-selector-drag-zone">
          <input ref={input} multiple={isMultiple} name="pictureUpload" id="pictureUpload" accept={accept} type="file" onChange={handleFilesSelect} className="file-selector_input" />

          <label className="file-selector_label" htmlFor="pictureUpload">
            {isDragging ? 'Húzzd ide a fájlt' : label}
          </label>
        </div>

        {!compactModder ? (
          <div className="file-uploaded-list">
            {selectedFiles.map(x => (
              <File key={x.file.name} file={x} showDelete={onDelete ? true : false} handleDelete={handleDeleteFile} />
            ))}
          </div>
        ) : (
          <div className="file-uploaded-list compact-modder">
            <input ref={input} multiple={isMultiple} name="pictureUpload" id="pictureUpload" accept={accept} type="file" onChange={handleFilesSelect} className="file-selector_input-compact" />
            {selectedFiles.map(x => (
              <File key={x.file.name} file={x} showDelete={onDelete ? true : false} handleDelete={handleDeleteFile} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

function File({ file, showDelete, handleDelete }: { file: SelectedFile; showDelete: boolean; handleDelete(id: string): void }) {
  const onDelete = () => handleDelete(file.id!);

  /* Variables */
  const extension = file.file.name.split('.').pop();
  const size = formatBytes(file.file.size);

  return (
    <div className="file-uploaded-list-item">
      <div className="left-side-wrap">
        {file.isLoading ? (
          <CircularProgress className="loader file-upload-progress" />
        ) : (
          <div className="file-extension-wrapper">
            <span className="file-extension">{extension}</span>
          </div>
        )}
      </div>

      <div className="file-data">
        <div>
          <div className="file-name" title={file.file.name}>
            {file.file.name}
          </div>

          <div className="file-size">{size}</div>
        </div>

        <div className="error-box">
          <span className="error-text">{file.error}</span>
        </div>
      </div>

      {file.id && showDelete && <IconButton icon="close" className="file-remove" onClick={onDelete} />}
    </div>
  );
}

FileSelector.defaultProps = {
  initialFiles: [],
  isMultiple: false,
  compactModder: 0
};

export default FileSelector;

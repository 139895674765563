import React from 'react';
import Auth from '../../../utils/auth';
import { RouteComponentProps } from '@reach/router';

interface IHOCProps {}

export interface ISecuredProps extends RouteComponentProps {}

const secure = <IWrappedCompProps extends ISecuredProps>(WrappedComponent: React.ComponentType<IWrappedCompProps & ISecuredProps>): React.ComponentClass<IHOCProps & IWrappedCompProps> => {
  class SecuredComponent extends React.Component<IHOCProps & IWrappedCompProps> {
    public state = { isAuthenticated: false };
    public componentDidMount() {
      Auth.Instance.isAuthenticated().then(user => {
        if (user) {
          console.log('Authenticated...');
          this.setState({ isAuthenticated: true });
        } else {
          console.log('Init Login...');
          Auth.Instance.login();
        }
      });
    }
    public render() {
      // const { auth, ...props } = this.props as IHOCProps;
      if (!this.state.isAuthenticated) {
        return null;
      }
      return <WrappedComponent {...this.props} />;
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    (SecuredComponent as any).displayName = `Secured(${getDisplayName(WrappedComponent)})`;
  }
  return SecuredComponent;
};

function getDisplayName(WrappedComponent: React.ComponentType<any>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default secure;

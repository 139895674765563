import React, { useCallback, useEffect, useReducer, useRef } from 'react';

/* Data Things */
import { createOrder, editOrder, getOrder, getOrders } from '../../store/lib/Manufacturing/orders';
import { ordersReducer, initialState, OrdersState } from '../../store/reducers/Manufacturing/Orders';
import { ICreateOrderDTO, IEditOrderDTO } from '../../typings/DTOs';
import { useIsMounted } from '../../hooks/';

/* Presentation Things */
import ResponseSnackbar, { ApiResponseType } from '../Portals/ResponseSnackbar';

interface IOrdersContext extends OrdersState {
  onGetOrders: () => Promise<string | null>;
  onGetOrder: (id: number) => Promise<string | null>;
  onCreateOrder: (model: ICreateOrderDTO) => Promise<string | number>;
  onEditOrder: (model: IEditOrderDTO) => Promise<string | number>;
  onSetApiResponse: (apiResponse: ApiResponseType) => void;
  onChangePage(page: number): void;
  onSearch(text: string): void;
  onSearchOrderNo(text: string): void;
  onSearchCustomerIds(ids: number[]): void;
  onSearchProjectIds(ids: number[]): void;
  onSearchProductItemIds(ids: number[]): void;
  onSort(field: number, desc: boolean): void;
  onIsActive(active: boolean): void;
}

const OrdersContext = React.createContext<IOrdersContext>({
  ...initialState,
  onGetOrders: () => Promise.reject(''),
  onGetOrder: () => Promise.reject(''),
  onCreateOrder: () => Promise.reject(''),
  onEditOrder: () => Promise.reject(''),
  onSetApiResponse: () => null,
  onChangePage: () => null,
  onSearch: () => null,
  onSearchOrderNo: () => null,
  onSearchCustomerIds: () => null,
  onSearchProjectIds: () => null,
  onSearchProductItemIds: () => null,
  onSort: () => null,
  onIsActive: () => null
});

function OrdersProvider(props: React.PropsWithChildren<{}>) {
  /* State */
  const [state, dispatch] = useReducer(ordersReducer, initialState);

  /* Variables */
  const signal = useRef(new AbortController());

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetOrders = useCallback(async (): Promise<string> => {
    try {
      signal.current.abort();
      signal.current = new AbortController();

      dispatch({ type: 'set_partial_state', payload: { isLoading: true, error: '' } });

      const orders = await getOrders(state.search, state.isActive, state.page * 100, 100, state.orderNo, state.customerIds, state.projectIds, state.productItemIds, state.sortBy, state.sortByDesc, signal.current.signal);
      if (orders && !orders.error && _isMounted.current) {
        dispatch({ type: 'success_get_orders', payload: { orders } });
        return Promise.resolve('');
      } else {
        throw new Error(orders.error || 'Sajnáljuk, valami hiba történt a cikkek betöltés során. Kérlek próbáld újra!');
      }
    } catch (error) {
      const { message } = error as any;
      if (_isMounted.current) {
        dispatch({
          type: 'error_get_orders',
          payload: { error: String(message || 'Sajnáljuk, valami hiba történt a cikkek betöltés során. Kérlek próbáld újra!') }
        });
      }
      return Promise.reject(String(message || error));
    }
  }, [_isMounted, state.page, state.search, state.isActive, state.orderNo, state.customerIds, state.projectIds, state.productItemIds, state.sortBy, state.sortByDesc, signal]);

  const onGetOrder = useCallback(
    async (id: number): Promise<string> => {
      try {
        const order = await getOrder(id);
        if (order && !order.error && _isMounted.current) {
          dispatch({ type: 'success_get_order', payload: { order } });
          return Promise.resolve('');
        } else {
          throw new Error(order.error || 'Sajnáljuk, valami hiba történt a rendelés betöltés során. Kérlek próbáld újra!');
        }
      } catch (error) {
        const { message } = error as any;
        return Promise.reject(String(message || 'Sajnáljuk, valami hiba történt a rendelés betöltés során. Kérlek próbáld újra!'));
      }
    },
    [_isMounted]
  );

  const onCreateOrder = useCallback(
    async (model: ICreateOrderDTO) => {
      try {
        const order = await createOrder(model);
        if (!order.error && _isMounted.current) {
          dispatch({ type: 'success_post_order', payload: { order } });
          return Promise.resolve(order.id);
        } else throw new Error(order.error);
      } catch (error) {
        const { message } = error as any;
        return Promise.reject(String(message || error));
      }
    },
    [_isMounted]
  );

  const onEditOrder = useCallback(
    async (model: IEditOrderDTO) => {
      try {
        const order = await editOrder(model);
        if (!order.error && _isMounted.current) {
          dispatch({ type: 'success_put_order', payload: { order } });
          return Promise.resolve(order.id);
        } else throw new Error(order.error);
      } catch (error) {
        const { message } = error as any;
        return Promise.reject(String(message || error));
      }
    },
    [_isMounted]
  );

  const onSetApiResponse = (apiResponse: ApiResponseType) => dispatch({ type: 'set_partial_state', payload: { apiResponse } });

  const onChangePage = (page: number) => dispatch({ type: 'set_partial_state', payload: { page } });

  const onSearch = (search: string) => dispatch({ type: 'set_partial_state', payload: { page: 0, search } });

  const onSearchOrderNo = (search: string) => dispatch({ type: 'set_partial_state', payload: { page: 0, orderNo: search } });

  const onSearchCustomerIds = (ids: number[]) => dispatch({ type: 'set_partial_state', payload: { page: 0, customerIds: ids } });

  const onSearchProjectIds = (ids: number[]) => dispatch({ type: 'set_partial_state', payload: { page: 0, projectIds: ids } });

  const onSearchProductItemIds = (ids: number[]) => dispatch({ type: 'set_partial_state', payload: { page: 0, productItemIds: ids } });

  const onSort = (field: number, desc: boolean) => dispatch({ type: 'set_partial_state', payload: { page: 0, sortBy: field, sortByDesc: desc } });

  const onIsActive = (isActive: boolean) => dispatch({ type: 'set_partial_state', payload: { isActive } });

  useEffect(() => void onGetOrders(), [onGetOrders]);

  return (
    <div className="orders">
      <ResponseSnackbar response={state.apiResponse} onClose={() => onSetApiResponse(null)} />
      <OrdersContext.Provider
        value={{
          ...state,
          onGetOrders,
          onGetOrder,
          onCreateOrder,
          onEditOrder,
          onSetApiResponse,
          onChangePage,
          onSearch,
          onSearchOrderNo,
          onSearchCustomerIds,
          onSearchProjectIds,
          onSearchProductItemIds,
          onSort,
          onIsActive
        }}>
        {props.children}
      </OrdersContext.Provider>
    </div>
  );
}

const OrdersConsumer = OrdersContext.Consumer;
export { OrdersProvider, OrdersContext, OrdersConsumer };

import React, { memo, useEffect, useMemo, useRef, useState } from 'react';

/* Presenation Things */
import { MenuSurface, MenuSurfaceAnchor } from '@rmwc/menu';
import { TextField, TextFieldProps } from '@rmwc/textfield';
import { List, ListItem } from '@rmwc/list';
import { ChipSet, Chip } from '@rmwc/chip';
import { Checkbox } from '@rmwc/checkbox';

//Style
import './MultiSelector.scss';

export type MultiSelectorOption = { id: number; label: string };
type IOption = MultiSelectorOption & { isVisible: boolean };
interface Props {
  placeholder?: string;
  selectedIds: (string | number)[];
  options: MultiSelectorOption[];
  onSelect(id: string | number, selected: boolean): void;
  disabled?: boolean;
}

interface OptionsProps {
  option: IOption;
  isHighLighted: boolean;
  isChecked: boolean;
  onSelect(id: number);
}

export const MultiSelector = memo(
  (props: Props) => {
    /* States */
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [highlightIndex, setHighlightIndex] = useState(0);
    const [options, setOptions] = useState<IOption[]>([]);

    const selectedIds = useMemo(() => props.selectedIds.reduce((acc, curr) => ({ ...acc, [curr]: true }), {}), [props.selectedIds]);

    /* Refs */
    const _listRef = useRef<HTMLDivElement>(null);

    const visibleOptions = options.filter(x => x.isVisible);

    const onSearch = (ev: React.KeyboardEvent<TextFieldProps>) => setSearchValue(String(ev.currentTarget.value));

    const onArrowEvent = (ev: React.KeyboardEvent<TextFieldProps | HTMLDivElement>) => {
      ev.persist();
      switch (ev.keyCode) {
        case 9:
        case 16:
        case 17:
        case 18:
        case 19:
        case 20:
          break;

        // Enter
        case 13:
          if (isOpen) {
            onSelect(visibleOptions[highlightIndex].id);
            ev.preventDefault();
          } else {
            onOpen();
            ev.preventDefault();
          }
          break;

        // ESC
        case 27:
          ev.preventDefault();
          onClose();
          break;

        // ARROW DOWN
        case 40:
          if (isOpen) {
            setHighlightIndex(index => Math.min(index + 1, visibleOptions.length - 1));
            ev.preventDefault();
          }
          break;

        //ARROW UP
        case 38:
          if (isOpen) {
            setHighlightIndex(index => Math.max(index - 1, 0));
            ev.preventDefault();
          }
          break;

        default:
          if (!isOpen) {
            onOpen();
          }
      }
    };

    const onSelect = (id: number) => props.onSelect(id, !Boolean(selectedIds[id]));

    const onOpen = () => setIsOpen(true);

    const onClose = () => setIsOpen(false);

    useEffect(() => {
      if (_listRef.current) {
        const highlightedElement = _listRef.current.querySelector('.highlighted');
        if (highlightedElement) {
          highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
      }
    }, [highlightIndex]);

    useEffect(() => {
      setHighlightIndex(0);
      setOptions(prev =>
        prev.map(x => ({
          ...x,
          isVisible: x.label.toLowerCase().includes(searchValue.toLowerCase())
        }))
      );
    }, [searchValue]);

    useEffect(
      () => {
        setOptions(
          props.options.map(x => ({
            ...x,
            selected: props.selectedIds.includes(Number(x.id)),
            isVisible: x.label.toLowerCase().includes(searchValue.toLowerCase())
          }))
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.options, props.selectedIds]
    );

    return (
      <>
        <MenuSurfaceAnchor className="multi-selector">
          <MenuSurface anchorCorner="bottomStart" open={isOpen} onClose={onClose}>
            <div className="multi-selector-list-container" ref={_listRef}>
              <List className="multi-selector-list">
                {visibleOptions.map((option, i) => (
                  <Option {...{ option, key: option.id, isHighLighted: i === highlightIndex, isChecked: Boolean(selectedIds[option.id]), onSelect }} />
                ))}
              </List>
            </div>
          </MenuSurface>

          <div className="multi-selector-container">
            <TextField className="multi-selector-search-input" disabled={props.disabled} onClick={onOpen} onKeyDown={onArrowEvent} value={searchValue} placeholder={props.placeholder} onChange={onSearch} />
          </div>
        </MenuSurfaceAnchor>

        <ChipSet className="secure-group-chips" choice>
          {options.map(x =>
            props.selectedIds.includes(x.id) ? <Chip key={x.label} label={x.label} onClick={ev => ev.preventDefault()} trailingIcon="close" onTrailingIconInteraction={() => onSelect(x.id)} /> : undefined
          )}
        </ChipSet>
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.disabled === nextProps.disabled && prevProps.placeholder === nextProps.placeholder && prevProps.options === nextProps.options && prevProps.selectedIds === nextProps.selectedIds;
  }
);

const Option = memo(
  ({ option, isHighLighted, isChecked, onSelect }: OptionsProps) => {
    const handleSlect = () => onSelect(option.id);

    return (
      <ListItem onClick={handleSlect} className={`multi-selector-list-item ${isHighLighted ? 'highlighted' : ''}`}>
        <Checkbox checked={isChecked} />
        {option.label}
      </ListItem>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.option.isVisible === nextProps.option.isVisible && prevProps.isChecked === nextProps.isChecked && prevProps.isHighLighted === nextProps.isHighLighted;
  }
);

import { ApiResponseType } from '../../../../components/Portals/ResponseSnackbar';
import { ICustomerDTO } from '../../../../typings/DTOs';
import { Customer } from '../../../../routes/Manufacturing/Customers/helper';

export const initialState: CustomersState = {
  customers: {},
  apiResponse: null,
  isLoading: true,
  error: ''
};

export interface CustomersState {
  customers: { [id: number]: Customer };
  apiResponse: ApiResponseType;
  error: string;
  isLoading: boolean;
}

type CustomersAction =
  | { type: 'success_get_customers'; payload: { customers: ICustomerDTO[] } }
  | { type: 'error_get_customers'; payload: { error: string } }
  | { type: 'success_get_customer'; payload: { customer: ICustomerDTO } }
  | { type: 'success_post_customer'; payload: { customer: ICustomerDTO } }
  | { type: 'error_post_customer'; payload: { error: string } }
  | { type: 'success_put_customer'; payload: { customer: ICustomerDTO } }
  | { type: 'error_put_customer'; payload: { error: string } }
  | { type: 'set_partial_state'; payload: Partial<CustomersState> };

export function customersReducer(state: CustomersState = initialState, action: CustomersAction): CustomersState {
  switch (action.type) {
    case 'success_get_customers': {
      return {
        ...state,
        isLoading: false,
        customers: action.payload.customers.reduce((acc, curr) => {
          const newCustomer = new Customer(curr);

          return { ...acc, [curr.id]: newCustomer };
        }, {}),
        error: ''
      };
    }

    case 'error_get_customers': {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }

    case 'success_get_customer': {
      return {
        ...state,
        customers: { ...state.customers, [action.payload.customer.id]: new Customer(action.payload.customer) }
      };
    }

    case 'success_post_customer': {
      return {
        ...state,
        customers: { ...state.customers, [action.payload.customer.id]: new Customer(action.payload.customer) },
        apiResponse: { type: 'success', message: 'Sikeres vevő létrehozás' }
      };
    }

    case 'error_post_customer': {
      return {
        ...state,
        apiResponse: { type: 'error', message: action.payload.error }
      };
    }

    case 'success_put_customer': {
      return {
        ...state,
        customers: { ...state.customers, [action.payload.customer.id]: new Customer(action.payload.customer) },
        apiResponse: { type: 'success', message: 'Sikeres vevő szerkesztés' }
      };
    }

    case 'error_put_customer': {
      return {
        ...state,
        apiResponse: { type: 'error', message: action.payload.error }
      };
    }

    case 'set_partial_state': {
      return {
        ...state,
        ...action.payload
      };
    }

    default:
      return state;
  }
}

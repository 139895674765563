import React, { useContext, useState } from 'react';

/* Data Things */
import { ProjectsContext } from '../../../../components/Contexts/Projects';
import { useIsMounted } from '../../../../hooks/useIsMounted';
import { getSync } from '../../../../store/lib/TrackTime/projects';

/* Presentation Things */
import CircularProgress from '../../../../components/CircularProgress';
import { Dialog, DialogActions, DialogButton, DialogContent, DialogTitle } from '@rmwc/dialog';

interface Props {
  open: boolean;
  onClose(): void;
  onListUpdate(): Promise<string>;
}

export const ProjectSync = ({ open, onClose, onListUpdate }: Props) => {
  const { onSetApiResponse } = useContext(ProjectsContext);
  const _isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState<null | 'sync' | 'update'>(null);

  const onSync = async (): Promise<string> => {
    setProgress('sync');
    try {
      const { error } = await getSync();
      if (error) {
        throw new Error(error);
      } else {
        return Promise.resolve('');
      }
    } catch (error) {
      return Promise.reject(error.message || 'Nem sikerült szinkronizálni a projekteket.');
    }
  };

  const onUpdate = async (): Promise<string> => {
    setProgress('update');
    try {
      const error = await onListUpdate();
      if (error.length > 0) {
        throw new Error(error);
      } else {
        return Promise.resolve('');
      }
    } catch (error) {
      return Promise.reject(error.message || 'Nem sikerült frissíteni a listát.');
    }
  };

  const onAction = async () => {
    setIsLoading(true);
    try {
      if (progress === 'sync' || progress === null) {
        const error = await onSync();
        if (error.length > 0) throw new Error(error);
      }
      const error = await onUpdate();
      if (error.length > 0) throw new Error(error);
      else {
        _isMounted.current && setProgress(null);
        _isMounted.current && onSetApiResponse({ type: 'success', message: 'Sikeres szinkronizáció és frissítés' });
        _isMounted.current && onClose();
      }
    } catch (error) {
      _isMounted.current && onSetApiResponse({ type: 'error', message: String(error.message || error) });
    } finally {
      _isMounted.current && setIsLoading(false);
      _isMounted.current && setProgress(null);
    }
  };

  return (
    <Dialog className="project-sync-dialog" {...{ open, onClose }}>
      <DialogTitle>Projekt szinkronizáció</DialogTitle>
      <DialogContent>
        {isLoading && <CircularProgress />}
        {progress === 'sync' ? 'Projektek szinkronizálása...' : progress === 'update' ? 'Projektek frissítése...' : ''}
      </DialogContent>
      <DialogActions>
        <DialogButton action="close">Bezárás</DialogButton>
        <DialogButton isDefaultAction disabled={isLoading} onClick={onAction}>
          {progress === 'update' ? 'Frissítés' : 'Elindítom'}
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

import React from 'react';
import { Menu as MenuItems } from './config';
import { getSelectedAndActiveMenuFromUrl } from './helper';
import { Link, RouteComponentProps } from '@reach/router';

const Header = ({ router }: { router: RouteComponentProps }) => {
  const { sub, main } = getSelectedAndActiveMenuFromUrl(router['*']);
  const selectedMenu = main !== null ? MenuItems.items[main] : null;
  const selectedSubMenu = selectedMenu !== null && sub !== null ? selectedMenu.subMenus[sub] : null;
  return (
    <header className="dashboard-header">
      <div className="dashboard-header__row">
        <section className="dashboard-header__section">
          <span className="material-icons dashboard-header__menu-icon tablet-840-show">menu</span>

          <span className="dashboard-header__title tablet-840-hide">ProHR 365</span>

          {selectedMenu && (
            <div className="breadcrumb-wrapper">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={`${selectedMenu.baseUrl}`}>
                    <span className="mdc-typography--caption">{selectedMenu.name}</span>
                  </Link>
                </li>
                {selectedSubMenu && (
                  <li className="breadcrumb-item">
                    <span className="mdc-typography--caption">{selectedSubMenu.name}</span>
                  </li>
                )}
              </ol>
              {/* <!-- /.breadcrumb --> */}
            </div>
          )}
          {/* <!-- /.breadcrumb-wrapper --> */}
        </section>
      </div>
    </header>
  );
};

export default Header;

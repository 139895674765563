import { AddMonthlyCloseDTO, MonthlyCloseDTO, PersonRowDTO, PreviewCloseCostsDTO } from '../../../store/lib/TrackTime/monthlyClose';
import { WarningsDTO, ErrorsDTO } from '../../../store/lib/TrackTime/monthlyClose';
import { DateTime } from 'luxon';

export const sortPersonsByName = (p: PersonRowDTO[]): PersonRowDTO[] => {
  return p.sort((a, b) => (a.lastName + a.firstName + a.middleName).localeCompare(b.lastName + b.firstName + b.middleName, 'hu'));
};

export interface IPreviewCloseCosts {
  personId: number;
  monthlyRequiredWorkHours: number; //float
  workHours: number; //float
  paidIncreaseWorkTimeHour: number; //float
  calculatedHoursResult: number; //float
  currentHourlyCost: number; // float
  calculatedHourlyCost: number; //flaot
}

export const transformPreviewCloseCostsDTO = (p: PreviewCloseCostsDTO): IPreviewCloseCosts => {
  return {
    ...p,
    currentHourlyCost: p.currentHourlyCost || 0
  };
};

export const calculateCloseCostMonth = (actual: DateTime, model: MonthlyCloseDTO | null): AddMonthlyCloseDTO => {
  return {
    year: actual.year,
    month: actual.month,
    monthlyCosts: model && model.persons ? model.persons.map(x => ({ personId: x.id, monthlyCost: Number(x.realCostPerMonth) })) : []
  };
};

export type CheckMonthFilters = { [key in keyof (WarningsDTO & ErrorsDTO)]: boolean };

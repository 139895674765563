import React, { useCallback, useState } from 'react';

/* Presentation Things */
import { DrawerAppContent } from '@rmwc/drawer';
import SideSheetAppContent from '../SideSheet/AppContent';
import SideSheet from '../SideSheet';

export interface ContextProps {
  isOpen: boolean;
  component: JSX.Element | undefined;
  className?: string;
  open: (component: JSX.Element, className?: string, title?: string) => void;
  close: () => void;
}

const SideSheetContext = React.createContext<ContextProps>({
  isOpen: false,
  component: undefined,
  className: undefined,
  open: () => null,
  close: () => null
});

const SideSheetProvider = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [component, setComponent] = useState<JSX.Element | undefined>(undefined);
  const [className, setClassName] = useState<string | undefined>(undefined);

  const handleOpen = useCallback((component: JSX.Element, className?: string) => {
    setIsOpen(true);
    setComponent(component);
    setClassName(className);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setComponent(undefined);
    setClassName(undefined);
  }, []);

  return (
    <SideSheetContext.Provider value={{ isOpen, component, className, open: handleOpen, close: handleClose }}>
      <SideSheet className={`side-sheet ${className || ''}`} modal onClose={handleClose} open={isOpen}>
        {component}
      </SideSheet>
      <SideSheetAppContent>
        <DrawerAppContent className="drawer-app-content">{props.children}</DrawerAppContent>
      </SideSheetAppContent>
    </SideSheetContext.Provider>
  );
};

const SideSheetConsumer = SideSheetContext.Consumer;
export { SideSheetProvider, SideSheetContext, SideSheetConsumer };

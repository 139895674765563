/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';

/* Data Things */
import { RouteComponentProps, Link } from '@reach/router';
import { PeopleContext } from '../../../../components/Contexts/People';
import { ActiveTab } from './helper';

/* Presentation Things */
import { TabBar, Tab, TabBarOnActivateEventT } from '@rmwc/tabs';
import { Employees } from '../../../../components/People/Employees';
import { Fab } from '@rmwc/fab';

/* Style */
import './People.scss';

interface IProps extends RouteComponentProps {}

export const PeopleList = (_: IProps) => {
  const { people, error, isLoading, isUserCanEditUsers, onFilterByName } = useContext(PeopleContext);

  /* States */
  const [activeTab, setActiveTab] = useState(ActiveTab.Active);
  const [searchText, setSearchText] = useState('');

  const onActiveTabChange = (evt: TabBarOnActivateEventT) => setActiveTab(evt.detail.index);

  const onClearSearch = () => setSearchText('');

  const onSearch = (ev: React.ChangeEvent<HTMLInputElement>) => setSearchText(String(ev.currentTarget.value));

  useEffect(() => void onFilterByName(searchText), [searchText, activeTab]);

  return (
    <>
      <TabBar activeTabIndex={activeTab} onActivate={onActiveTabChange}>
        <Tab>Állományban</Tab>
        {isUserCanEditUsers && <Tab>Mindenki</Tab>}
      </TabBar>

      <div className="fab-menu-container--top-right">
        {!isLoading && !error.length && (
          <div className="fab-menu-container people-create-button--temp">
            <Link to="/Organization/People/Add">
              <Fab mini icon="add" />
            </Link>
          </div>
        )}

        <div className={`search-box ${(isLoading || error.length > 0) && 'disabled'}`}>
          <span className="search-box--start-detail">
            <span className="material-icons">search</span>
          </span>
          <input className="search-box--input" type="text" placeholder="Keresés" onChange={onSearch} value={searchText} />

          <span className="search-box--start-detail" onClick={onClearSearch}>
            <span className="material-icons">close</span>
          </span>
        </div>

        <Employees people={people} error={error} isLoading={isLoading} activeTab={activeTab} />
      </div>
    </>
  );
};

import React, { useContext, useMemo } from 'react';

/* Presentation Things */
import CircularProgress from '../../../../components/CircularProgress';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';

/* Data Things */
import { ActivityTag, sortActivityTags } from '../helper';
import { ActivityTagAddOrEdit } from '../AddOrEdit';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';

interface Props {
  isLoading: boolean;
  error: string;
  tags: ActivityTag[];
  onSetActivityTag(tag: ActivityTag): void;
  onAddActivityTag(tag: ActivityTag): void;
}

export const ActivityTagsList = ({ isLoading, tags, error, onSetActivityTag, onAddActivityTag }: Props) => {
  /* Variable */
  const activityTags = useMemo(() => sortActivityTags(tags.filter(x => x.isVisible)), [tags]);

  /* Context */
  const { open } = useContext(SideSheetContext);

  const onOpenEditSheet = (editableTag: ActivityTag) => {
    open(<ActivityTagAddOrEdit {...{ onSetActivityTag, editableTag, onAddActivityTag }} />, 'MasterDataAddOrEditSheet');
  };

  return (
    <div className="data-table">
      <div className="data-table__row data-table__row--title">
        <div className="data-table__cell">
          <Typography use="caption">Név</Typography>
        </div>

        <div className="row-spacer-header__end" />
      </div>

      {isLoading ? (
        <CircularProgress />
      ) : error.length > 0 || activityTags.length < 1 ? (
        <div className="basic-error-text">{error || 'Nincsenek megjeleníthető címkék'}</div>
      ) : (
        <div className="data-table-body">
          {activityTags.map(tag => (
            <Row key={tag.id} tag={tag} onEdit={onOpenEditSheet} />
          ))}
        </div>
      )}
    </div>
  );
};

function Row({ tag, onEdit }: { tag: ActivityTag; onEdit(tag: ActivityTag) }) {
  const handleEdit = () => onEdit(tag);

  return (
    <div className="data-table__row">
      <div className="data-table__cell">{tag.name}</div>

      <div className="data-table__cell--end">
        <Icon icon="keyboard_arrow_right" onClick={handleEdit} />
      </div>
    </div>
  );
}

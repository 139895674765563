import React, { useContext, useEffect, useMemo, useState } from 'react';

/* Data Things */
import { Product, Task, transformProductToICreateProductDTO, transformProductToIEditProductDTO } from '../helper';
import { IClientDevicesDTO, ITaskAttachmentDTO } from '../../../../typings/DTOs';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { PRODUCT_ITEM_QUANTITY_UNIT_MAP } from '../../../../constants/constants';
import { DEFAULT_PRODUCT } from '../AddOrEdit/helper';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { ProductsContext } from '../../../../components/Contexts/Products';
import { useIsMounted } from '../../../../hooks';

/* Presentation Things */
import { DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableHeadCell, DataTableRow } from '@rmwc/data-table';
import { Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, SimpleDialog } from '@rmwc/dialog';
import { SelectedFile } from '../../../../components/Selectors/FileSelector';
import { Typography } from '@rmwc/typography';
import { Checkbox } from '@rmwc/checkbox';
import { ShowImage } from '../../../../components/Images/ShowImage';
import { Button } from '@rmwc/button';
import { Icon } from '@rmwc/icon';
import { ImageList, ImageListItem } from '@rmwc/image-list';
import { toHHMMSS } from '../../../../utils/hhmmss';
import { formatBytes } from '../../../../constants/helperFuntions';

/* Screens */
import { AddOrEditProduct, AddOrEditTask, AddOrEditAttachment } from '../AddOrEdit';
import { getExtension } from '../../../../utils/ext';

interface IProps extends RouteComponentProps<{ id: string }> {}

export function ProductDetails(props: IProps) {
  /* Contexts */
  const { onGetProduct, onGetClientDevices, onEditProduct, onCloneProduct, products, clientDevices, onCreateAttachment, onDeleteAttachment, onModifyAttachment, onCheckTVAttachments } = useContext(ProductsContext);
  const { open } = useContext(SideSheetContext);

  /* Hooks */
  const _isMounted = useIsMounted();
  const navigate = useNavigate();

  /* Variables */
  const parentId = props.location && props.location.state ? (props.location.state as any).parentId : null;
  const id = props.id !== undefined ? Number(props.id) : null;
  const otherPictures: SelectedFile[] = [];

  /* States */
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [product, setProduct] = useState(products[id!] || DEFAULT_PRODUCT(parentId));
  const [primaryPicture, setPrimaryPicture] = useState<SelectedFile | null>(null);
  const [moddedAttachment, setModdedAttachment] = useState<number | null>(null);
  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

  const onSubmitEdit = async (product: Product, primaryPicture: SelectedFile, otherPictures: SelectedFile[], id: number | null): Promise<Boolean> => {
    try {
      if (primaryPicture !== null) {
        const resp = await onEditProduct(transformProductToIEditProductDTO(product, primaryPicture, otherPictures, id!));
        if (typeof resp === 'number') {
          await onGetProduct(resp);
          return Promise.resolve(true);
        } else return Promise.resolve(false);
      } else throw new Error('Kérlek válassz ki elsődleges képet');
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  };

  const onOpenEditSheet = (product: Product | undefined) => open(<AddOrEditProduct product={product} onSubmit={onSubmitEdit} />, 'side-sheet-600');

  const onActivateSubProducts = () => {
    setActiveTab(0);
    setProduct(prev => ({ ...prev, tasks: [] }));
  };

  const onAddOrEditTask = async (task: Task): Promise<Boolean> => {
    let save = product;
    if (task.id === null) {
      save.tasks = [...save.tasks, task];
    } else {
      save.tasks = save.tasks.map(x => (x.id === task.id ? task : x)).sort((x, y) => x.orderOfTask - y.orderOfTask);
    }

    try {
      const resp = await onEditProduct(transformProductToIEditProductDTO(save, primaryPicture!, otherPictures, save.id));
      if (typeof resp === 'number') {
        setProduct(prev => ({ ...prev, tasks: save.tasks }));
        await onGetProduct(id!);
        return Promise.resolve(true);
      } else throw new Error('Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
    } catch (error) {
      console.log(error);
      return Promise.reject(false);
    }
  };

  const onAddTaskAttachment = async (attachment: SelectedFile[]): Promise<Boolean> => {
    try {
      await onCreateAttachment({ productId: id!, attachmentTempIds: attachment.map(a => (a.locationId ? a.locationId : 0)) });
      await onGetProduct(id!);
      return Promise.resolve(true);
    } catch (error) {
      console.log(error);
      return Promise.reject(false);
    }
  };

  const onDelTaskAttachment = async (taskId: number, attachmentId: number): Promise<Boolean> => {
    try {
      await onDeleteAttachment({ productId: id!, attachmentId: attachmentId });
      await onGetProduct(id!);
      return Promise.resolve(true);
    } catch (error) {
      console.log(error);
      return Promise.reject(false);
    }
  };

  const onModTaskAttachment = async (attachmentId: number): Promise<Boolean> => {
    try {
      await onModifyAttachment(attachmentId);
      await onGetProduct(id!);
      setModdedAttachment(attachmentId);
      clientDevices && onOpenFeedbackDialog();
      return Promise.resolve(true);
    } catch (error) {
      console.log(error);
      return Promise.reject(false);
    }
  };

  const onActivateTasks = () => {
    setActiveTab(1);
    setProduct(prev => ({ ...prev, subProducts: [] }));
  };

  const onClose = () => navigate(-1);

  useEffect(
    () => {
      if (id !== null && products[id]) {
        setProduct(products[id]);
        setPrimaryPicture(products[id].primaryPicture);
        setActiveTab(products[id].tasks.length ? 1 : products[id].subProducts.length ? 0 : null);
      }
    },
    [products, id]
  );

  useEffect(() => {
    (async () => {
      try {
        await onGetClientDevices();
        if (id !== null) await onGetProduct(id);
      } catch (error) {}
    })();
  }, [onGetProduct, onGetClientDevices, id, _isMounted]);

  const onOpenDialog = () => {
    setShowCopyDialog(true);
  };

  const onCloseDialog = async evt => {
    setShowCopyDialog(false);
    if (id && evt.detail.action === 'accept') {
      try {
        const newId = await onCloneProduct(id);
        await navigate(`/Manufacturing/Products/${newId}`);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onOpenFeedbackDialog = () => {
    setShowFeedbackDialog(true);
    onCheckTVAttachments();
  };

  const onCloseFeedbackDialog = evt => {
    if (evt.detail.action === 'accept') {
      onCheckTVAttachments();
    } else setShowFeedbackDialog(false);
  };

  return (
    <div className="details-table products-details">
      <div className="details-table-header">
        <Typography use="subtitle2" className="color-blue">
          Részletes adatok
        </Typography>

        <div className="icon-group gap">
          <Button label="Klónozás" icon="content_copy" onClick={onOpenDialog} outlined />
          <Icon
            onClick={() => {
              onOpenEditSheet(product);
            }}
            className="edit"
            icon="edit"
          />
        </div>
      </div>

      <div className="details-table-row">
        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Megnevezés
          </Typography>

          <Typography use="body2" className="secondary-text">
            {product ? product.title : ''}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Festetlen cikkszám
          </Typography>

          <Typography use="body2" className="secondary-text">
            {product ? product.itemNoRaw : ''}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Festett cikkszám
          </Typography>

          <Typography use="body2" className="secondary-text">
            {product ? product.itemNoReady : ''}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Mennyiségi egység
          </Typography>

          <Typography use="body2" className="secondary-text">
            1 {product ? PRODUCT_ITEM_QUANTITY_UNIT_MAP[product.quantityUnit] : ''}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Váltó egység
          </Typography>

          {product && product.conversionRates && product.conversionRates.length > 0 ? (
            <Typography use="body2" className="secondary-text">
              {product.conversionRates.map((quantityUnit, i, arr) => (
                <span key={i} className="mr-05">
                  {quantityUnit.rate} {PRODUCT_ITEM_QUANTITY_UNIT_MAP[quantityUnit.unit]}
                  {arr.length > 1 && i < arr.length - 1 && ','}
                </span>
              ))}
            </Typography>
          ) : (
            <div className="text-placeholder">-</div>
          )}
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Gyári szám
          </Typography>

          <Checkbox checked={product ? product.hasSerialNumber : false} readOnly disabled />
        </div>
      </div>

      <div className="product-details-images">
        <Typography use="caption" className="primary-text">
          Képek
        </Typography>

        {product.primaryPicture && (
          <ImageList className="image-list-product">
            <ImageListItem key={0}>
              <ShowImage imageClassName="product-images" wrapperClassName="product-image-wrap" pictureURL={product.primaryPicture.url} />
            </ImageListItem>
            {product &&
              product.otherPictures.map((picture: SelectedFile, key) => (
                <ImageListItem key={key}>
                  <ShowImage imageClassName="product-images" wrapperClassName="product-image-wrap" pictureURL={picture.url} />
                </ImageListItem>
              ))}
          </ImageList>
        )}
      </div>

      {id && !product.subProducts.length && (
        <>
          <div className="data-table-title">
            <Typography className="title" use="body2">
              Kapcsolódó dokumentumok
            </Typography>
          </div>
          <div className="routes-content-container add-user">
            <AttachmentList product={product} onAddProductAttachment={onAddTaskAttachment} onDelProductAttachment={onDelTaskAttachment} onModProductAttachment={onModTaskAttachment} />
          </div>
        </>
      )}

      {!product.tasks.length && !product.subProducts.length ? (
        <div className="button-container">
          {!parentId && <Button type="button" label="Kapcsolódó cikkek" onClick={onActivateSubProducts} />}

          <Button type="button" label="Munkafolyamatok" onClick={onActivateTasks} />
        </div>
      ) : (
        <div className="data-table-title">
          <Typography className="title" use="body2">
            {product.tasks.length ? 'Munkafolyamatok' : 'Kapcsolódó cikkek'}
          </Typography>
        </div>
      )}

      <div className="routes-content-container add-user">
        {activeTab === 0 ? <SubProductsList parentName={product.title} parentId={id!} products={product.subProducts} /> : activeTab === 1 ? <TasksList product={product} onAddOrEditTask={onAddOrEditTask} /> : null}
      </div>

      <div className="routes-content-container-footer">
        <Button type="button" label="Vissza" onClick={onClose} />
      </div>

      <SimpleDialog title="Biztosan másolatot készít?" body={'Cikk: #' + id + ' ' + product.title} acceptLabel="Igen" cancelLabel="Mégsem" open={showCopyDialog} onClose={onCloseDialog} />

      <Dialog open={showFeedbackDialog} onClose={onCloseFeedbackDialog} preventOutsideDismiss={true}>
        <DialogTitle>Csatolmány szinkronizáció</DialogTitle>
        <DialogContent>
          <DataTable>
            <DataTableContent>
              <DataTableHead>
                <DataTableRow>
                  <DataTableHeadCell>Név</DataTableHeadCell>
                  <DataTableHeadCell>IP</DataTableHeadCell>
                  <DataTableHeadCell>csID</DataTableHeadCell>
                  <DataTableHeadCell>Státusz</DataTableHeadCell>
                </DataTableRow>
              </DataTableHead>
              <DataTableBody>{clientDevices && clientDevices.map((c, i) => <FeedbackRow client={c} moddedId={moddedAttachment} key={i} />)}</DataTableBody>
            </DataTableContent>
          </DataTable>
        </DialogContent>
        <DialogActions>
          <DialogButton action="dismiss">OK</DialogButton>
          <DialogButton action="accept" isDefaultAction>
            Frissít
          </DialogButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const FeedbackRow = ({ client, moddedId, key }: { client: IClientDevicesDTO; moddedId: number | null; key: number }) => {
  const { onScreenFeedback } = useContext(ProductsContext);

  const feedback = useMemo(() => (onScreenFeedback ? onScreenFeedback.find(f => f.screenId === client.id) : null), [onScreenFeedback, client.id]);

  return (
    <DataTableRow key={key}>
      <DataTableCell>{client.name}</DataTableCell>
      <DataTableCell>{client.id}</DataTableCell>
      <DataTableCell>{feedback && feedback.attachmentId}</DataTableCell>
      <DataTableCell>{feedback ? (feedback.attachmentId ? (feedback.attachmentId === moddedId ? 'ROSSZ!' : 'OK') : 'Online') : 'Offline'}</DataTableCell>
    </DataTableRow>
  );
};

const AttachmentList = ({
  product,
  onAddProductAttachment,
  onDelProductAttachment,
  onModProductAttachment
}: {
  product: Product;
  onAddProductAttachment(attachment: SelectedFile[], taskId: number | null): Promise<Boolean>;
  onDelProductAttachment(taskId: number, attachmentId: number): Promise<Boolean>;
  onModProductAttachment(attachmentId: number): Promise<Boolean>;
}) => {
  /* Contexts */
  const { open } = useContext(SideSheetContext);

  const [attachments, setAttachments] = useState(product.attachments ? product.attachments : []);

  useEffect(
    () => {
      product.attachments && setAttachments(product.attachments);
    },
    [product.attachments]
  );

  const onOpenAttachmentSheet = (attachment?: ITaskAttachmentDTO | null) =>
    open(<AddOrEditAttachment productId={product.id} attachment={attachment} onSubmitAdd={onAddProductAttachment} onSubmitDel={onDelProductAttachment} onSubmitMod={onModProductAttachment} />, 'TaskAddOrEdit');

  return (
    <DataTable>
      <DataTableContent>
        <DataTableHead>
          <DataTableRow>
            <DataTableHeadCell>Kép</DataTableHeadCell>
            <DataTableHeadCell>Megnevezés</DataTableHeadCell>
            <DataTableHeadCell alignMiddle>Méret</DataTableHeadCell>
            <DataTableHeadCell alignMiddle>Hozzárendelve</DataTableHeadCell>
            <DataTableHeadCell alignEnd>
              <Icon icon="add" title="Hozzáadás" onClick={() => onOpenAttachmentSheet()} className="add" />
            </DataTableHeadCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {!attachments.length ? (
            <DataTableRow>
              <DataTableCell colSpan={5} className="basic-error-cell">
                Nincs kapcsolódó dokumentum feltöltve <Button type="button" className="m-1" trailingIcon="add" label="Hozzáadás" onClick={() => onOpenAttachmentSheet()} />
              </DataTableCell>
            </DataTableRow>
          ) : (
            attachments.map(attachment => (
              <DataTableRow key={attachment.id}>
                <DataTableCell>
                  {attachment.fileName && getExtension(attachment.fileName) === 'pdf' ? (
                    <Icon icon="picture_as_pdf" className="pdf" />
                  ) : (
                    <ShowImage imageClassName="image-square" wrapperClassName="product-image-wrap" pictureURL={attachment.fileUrl} />
                  )}
                </DataTableCell>
                <DataTableCell>{attachment.fileName}</DataTableCell>
                <DataTableCell alignMiddle>{formatBytes(attachment.fileLength)}</DataTableCell>
                <DataTableCell alignMiddle>{product.tasks.filter(a => a.assignedAttachmentIds!.includes(attachment.id!)).length}</DataTableCell>
                <DataTableCell alignEnd className="arrow more-icons">
                  <Icon icon="keyboard_arrow_right" onClick={() => onOpenAttachmentSheet(attachment)} />
                </DataTableCell>
              </DataTableRow>
            ))
          )}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  );
};

const SubProductsList = ({ parentId, parentName, products }: { parentId: number; parentName: string; products: Product[] }) => {
  /* Contexts */
  const { onCreateProduct } = useContext(ProductsContext);
  const { open } = useContext(SideSheetContext);
  const navigate = useNavigate();

  const onNavigateToSubProduct = (id: number) => navigate(`/Manufacturing/Products/${id}`, { state: { parentId, parentName } });

  const onSubmitAdd = async (product: Product, primaryPicture: SelectedFile, otherPictures: SelectedFile[]): Promise<Boolean> => {
    try {
      const resp = await onCreateProduct(transformProductToICreateProductDTO(product, primaryPicture, otherPictures));
      if (typeof resp === 'number') {
        navigate(`/Manufacturing/Products/${resp}`, { state: { parentId, parentName } }).then();
        return Promise.resolve(true);
      } else return Promise.resolve(false);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  };

  const onAddSubProduct = () => open(<AddOrEditProduct onSubmit={onSubmitAdd} parentId={parentId} parentName={parentName} />, 'side-sheet-840');

  return (
    <DataTable>
      <DataTableContent>
        <DataTableHead>
          <DataTableRow>
            <DataTableHeadCell>Kép</DataTableHeadCell>
            <DataTableHeadCell>Megnevezés</DataTableHeadCell>
            <DataTableHeadCell>Egység</DataTableHeadCell>
            <DataTableHeadCell>Festetlen cikkszám</DataTableHeadCell>
            <DataTableHeadCell>Festett cikkszám</DataTableHeadCell>
            <DataTableHeadCell>Gyári szám</DataTableHeadCell>
            <DataTableHeadCell alignEnd>
              <Icon icon="add" title="Hozzáadás" onClick={onAddSubProduct} className="add" />
            </DataTableHeadCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {!products.length ? (
            <DataTableRow>
              <DataTableCell colSpan={7} className="basic-error-cell">
                Nincs kapcsolódó cikk hozzárendelve <Button type="button" className="m-1" trailingIcon="add" label="Hozzáadás" onClick={onAddSubProduct} />
              </DataTableCell>
            </DataTableRow>
          ) : (
            products.map(row => (
              <DataTableRow key={row.id}>
                <DataTableCell>
                  <ShowImage pictureURL={row.primaryPicture ? row.primaryPicture.url : null} />
                </DataTableCell>
                <DataTableCell>{row.title}</DataTableCell>
                <DataTableCell>{PRODUCT_ITEM_QUANTITY_UNIT_MAP[row.quantityUnit]}</DataTableCell>
                <DataTableCell>{row.itemNoRaw}</DataTableCell>
                <DataTableCell>{row.itemNoReady}</DataTableCell>
                <DataTableCell>
                  <Checkbox checked={row.hasSerialNumber} readOnly disabled className="checkbox" />
                </DataTableCell>
                <DataTableCell alignEnd className="arrow">
                  <Icon icon="keyboard_arrow_right" onClick={() => onNavigateToSubProduct(row.id)} />
                </DataTableCell>
              </DataTableRow>
            ))
          )}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  );
};

const TasksList = ({ product, onAddOrEditTask }: { product: Product; onAddOrEditTask(task: Task): Promise<Boolean> }) => {
  /* Contexts */
  const { open } = useContext(SideSheetContext);

  const maxTaskNo = useMemo(() => Math.max(...product.tasks.map(o => o.orderOfTask)) | 0, [product.tasks]);

  const onOpenAddSheet = () => open(<AddOrEditTask product={product} orderNo={maxTaskNo + 1} onSubmit={onAddOrEditTask} />, 'TaskAddOrEdit');

  const onOpenEditSheet = (task: Task) => open(<AddOrEditTask product={product} task={task} onSubmit={onAddOrEditTask} />, 'TaskAddOrEdit');

  return (
    <DataTable>
      <DataTableContent>
        <DataTableHead>
          <DataTableRow>
            <DataTableHeadCell>Megnevezés</DataTableHeadCell>
            <DataTableHeadCell>Sorrend</DataTableHeadCell>
            <DataTableHeadCell>MEO</DataTableHeadCell>
            <DataTableHeadCell alignMiddle>Normaidő</DataTableHeadCell>
            <DataTableHeadCell alignMiddle>Csatolmány</DataTableHeadCell>
            <DataTableHeadCell alignEnd>
              <Icon icon="add" title="Hozzáadás" onClick={onOpenAddSheet} className="add" />
            </DataTableHeadCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {!product.tasks.length ? (
            <DataTableRow>
              <DataTableCell colSpan={5} className="basic-error-cell">
                Nincs kapcsolódó munkafolyamat hozzárendelve <Button type="button" className="m-1" trailingIcon="add" label="Hozzáadás" onClick={onOpenAddSheet} />
              </DataTableCell>
            </DataTableRow>
          ) : (
            product.tasks.map(task => (
              <DataTableRow key={task.id}>
                <DataTableCell>{task.name}</DataTableCell>
                <DataTableCell>{task.orderOfTask}</DataTableCell>
                <DataTableCell>
                  <Checkbox checked={task.isQARequired} readOnly disabled className="checkbox" />
                </DataTableCell>
                <DataTableCell alignMiddle>{toHHMMSS(task.standardTimeSec, 'full')}</DataTableCell>
                <DataTableHeadCell alignMiddle>{task.assignedAttachmentIds ? task.assignedAttachmentIds.length : 0}</DataTableHeadCell>
                <DataTableCell alignEnd className="arrow more-icons">
                  {task.id ? <Icon icon="keyboard_arrow_right" onClick={() => onOpenEditSheet(task)} /> : <Icon icon="warning" title="Mentetlen" />}
                </DataTableCell>
              </DataTableRow>
            ))
          )}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  );
};

import { ICustomerDTO } from '../../../typings/DTOs';

export class Customer {
  public id: number;
  public companyName: string;

  constructor(p: ICustomerDTO) {
    this.id = p.id;
    this.companyName = p.companyName || '';
  }
}

import React from 'react';

/* Data Things */
import Auth from '../../utils/auth';
import { instanceOfSubMenuItem, Icon, ActiveMenuStruct, getSelectedAndActiveMenuFromUrl, generateWindowTitleFromCurrentRoute } from './helper';
import { Link, RouteComponentProps } from '@reach/router';
import { MOBILE_SCREEN_SIZE } from '../../constants/constants';
import { Menu as MenuItems } from './config';
import { WithProfileProps } from '../HOCs/withProfile';
import { User } from 'oidc-client';

/* Presentation Things */
import { Drawer, DrawerContent } from '@rmwc/drawer';
import { ShowImage } from '../Images/ShowImage';

interface IMenu extends WithProfileProps {
  router: RouteComponentProps;
  windowWidth: number;
}

type MainMenuProps = { icon: Icon; title: string; isActive: boolean; isSelected: boolean };
type ProfileProps = { profile: User | null; isActive: boolean; isSelected: boolean };
type MainMenuItemProps = { selectedMenu: number; activeMenu: ActiveMenuStruct; onActiveMenu(index: number) };

export const Menu = ({ router, profile, windowWidth }: IMenu) => {
  /* [TODO Jácint] rewrite, this code can not scale up.  */
  const { main, sub } = getSelectedAndActiveMenuFromUrl(router['*']);
  const isMobile = windowWidth <= MOBILE_SCREEN_SIZE;
  const [activeMenu, setActiveMenu] = React.useState<ActiveMenuStruct>({ main, sub });
  const [selectedMenu, setSelectedMenu] = React.useState(main); //blue highlight
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const { main, sub } = getSelectedAndActiveMenuFromUrl(router['*']);
    setActiveMenu({ main, sub });
    setSelectedMenu(main);
    document.title = generateWindowTitleFromCurrentRoute(router['*'], MenuItems.items);
  }, [router]);

  const handleActiveMenu = (index: number) => {
    if (selectedMenu !== null) {
      if (isMobile) {
        setOpen(prevOpen => (activeMenu.main !== null && activeMenu.sub !== null ? (selectedMenu === activeMenu.main && index === activeMenu.sub && prevOpen ? false : true) : true));
      }
      setActiveMenu({ main: selectedMenu, sub: index });
    }
  };

  const handleSelectedMenu = (index: number) => {
    if (isMobile) {
      setOpen(prevOpen => (selectedMenu !== null ? (index === selectedMenu && prevOpen ? false : true) : true));
    }
    setSelectedMenu(index);
  };

  const handleClose = () => setOpen(false);

  console.log("User logged in:", profile);

  return (
    <>
      <div className="dashboard-menu">
        <ul className="dashboard-menu--main">
          {MenuItems.items.map((item, i) => (
            <span onClick={() => handleSelectedMenu(i)} key={i}>
              {i === MenuItems.items.length - 1 ? (
                <Profile profile={profile} isSelected={i === selectedMenu} isActive={activeMenu.sub !== null && activeMenu.main !== null ? i === activeMenu.main : false} />
              ) : (
                (item.baseUrl !== 'Manufacturing' || (profile && profile.profile["http://schemas.prohr365.com/tenantId"] === 'd2cca439-4709-4df7-9cda-daf0d84ec30b')) && <MainMenu isSelected={i === selectedMenu} isActive={activeMenu.sub !== null && activeMenu.main !== null ? i === activeMenu.main : false} icon={item.icon} title={item.name} />
              )}
            </span>
          ))}
        </ul>
      </div>

      <Drawer modal={isMobile} open={!isMobile ? true : open} onClose={handleClose} className="dashboard-menu--sub">
        <DrawerContent>
          <nav className="mdc-drawer__content dashboard-menu--sub-content">
            {selectedMenu !== null ? (
              selectedMenu !== MenuItems.items.length - 1 ? (
                <MainMenuItem selectedMenu={selectedMenu} activeMenu={activeMenu} onActiveMenu={handleActiveMenu} />
              ) : (
                <ProfileMenuItem profile={profile} />
              )
            ) : null}
          </nav>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const Profile = ({ profile, isActive, isSelected }: ProfileProps) => {
  return (
    profile && (
      <li id="user-menu" className={`menu-item ${isActive ? 'menu-item--active' : ''} ${isSelected ? 'menu-item--selected' : ''} menu-item menu-item--last`}>
        <span className="menu-item__inner-wrap">
          <ShowImage pictureURL={profile.profile.picture} initials={profile.profile.family_name.charAt(0) + profile.profile.given_name.charAt(0)} />

          <span className="menu-item--text">
            <span>{profile.profile.given_name}</span>
          </span>
        </span>
      </li>
    )
  );
};

const MainMenu = ({ icon, title, isActive, isSelected }: MainMenuProps) => {
  return (
    <li className={`menu-item ${isActive ? 'menu-item--active' : ''} ${isSelected ? 'menu-item--selected' : ''}`}>
      <span className="menu-item__inner-wrap">
        <i className={icon.className}>{icon.name || ''}</i>
        <span className="menu-item--text">{title}</span>
      </span>
    </li>
  );
};

const MainMenuItem = ({ selectedMenu, activeMenu, onActiveMenu }: MainMenuItemProps) => {
  return (
    <>
      {MenuItems.items[selectedMenu].subMenus.map((subMenu, i) =>
        instanceOfSubMenuItem(subMenu) ? (
          <li className={['mdc-list-item ', activeMenu.main !== null && activeMenu.sub !== null && activeMenu.main === selectedMenu && activeMenu.sub === i && 'active'].filter(Boolean).join(' ')} key={subMenu.url}>
            <Link to={`${MenuItems.items[selectedMenu].baseUrl}/${subMenu.url}`} onClick={() => onActiveMenu(i)}>
              {subMenu.name}
            </Link>
          </li>
        ) : (
          <h3 key={subMenu.name} className="mdc-list-group__subheader mdc-typography--title group-header">
            {subMenu.name}
          </h3>
        )
      )}
    </>
  );
};

const ProfileMenuItem = ({ profile }: { profile: User | null }) => {
  return profile ? (
    <div className="mdc-menu user-menu-sizes mdc-menu--open-from-bottom-left user-menu mdc-menu--open">
      <ul className="mdc-menu__items mdc-list">
        <h3 className="mdc-list-group__subheader mdc-typography--title group-header mdc-form-field user">
          <i className="material-icons user-image">account_circle</i>
          <span className="user-name">{`${profile.profile.family_name} ${profile.profile.given_name}`}</span>
        </h3>

        <li className="mdc-list-item" role="menuitem" tabIndex={-1} onClick={() => Auth.Instance.logout()}>
          <Link to="Account/Logout" className="mdc-menu--link">
            Kijelentkezés
          </Link>
        </li>
      </ul>
    </div>
  ) : null;
};

import React, { Component } from 'react';
export class StaticCheckbox extends Component<
  {
    checked?: boolean;
  },
  {}
> {
  public shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.checked !== this.props.checked) {
      return true;
    }
    return false;
  }
  public render() {
    return (
      <div className="mdc-checkbox mdc-checkbox--upgraded mdc-ripple-upgraded mdc-ripple-upgraded--unbounded">
        <input type="checkbox" className="mdc-checkbox__native-control" id="only-checked-items" aria-checked="false" checked={this.props.checked} readOnly={true} />
        <div className="mdc-checkbox__background">
          <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24" focusable="false">
            <path className="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59" />
          </svg>
          <div className="mdc-checkbox__mixedmark" />
        </div>
      </div>
    );
  }
}

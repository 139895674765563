import { AccessTypes } from '../../../constants/enums';
import { api } from '../../../utils/api';

//=============================[GET] USERS==================================
export const getApplicationUsers = () => api<UserRowDTO[]>('GET', 'applicationUsers');
export interface UserRowDTO {
  userId: number;
  userName: string | null;
  userEmail: string | null;
  personId: number | null;
  isActive: boolean;
  personFirstName: string | null;
  personLastName: string | null;
  personMiddleName: string | null;
  personCourtesyTitle: string | null;
}

//=============================[GET & PUT] EDIT USER==================================
export const getApplicationUser = (userId: number) => api<EditUserDTO>('GET', `applicationUsers/${userId}`);

export const putApplicationUser = (model: EditUserDTO) => api<UserRowDTO>('PUT', `applicationUsers/${model.userId}`, model);
export interface EditUserDTO {
  aadId: string;
  userId: number;
  isActive: boolean;
  userName: string | null;
  userEmail: string | null;
  personId: number | null;
  securityGroupIds: number[];
  claimAssigns: ClaimAssignRowDTO[];
}

export type ClaimAssignRowDTO = {
  claimId: number;
  claimName: string | null;
  accessTypeId: AccessTypes | null;
};

//=============================[GET] CLAIMS==================================
export const getClaims = () => api<ClaimRowDTO[]>('GET', 'claims');
export interface ClaimRowDTO {
  claimId: number;
  claimName: string | null;
  displayName: string | null;
  description: string | null;
  hasAccessTypes: boolean;
}

//=============================[GET] PEOPLE FOR USER==================================
export interface PersonRowDTO {
  personId: number;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  courtesyTitle: string | null;
}
export const getPeopleForUserEdit = (userId: number) => api<PersonRowDTO[]>('GET', `users/${userId}/people`);

export const getPeopleForUserCreate = () => api<PersonRowDTO[]>('GET', `users/people`);

//=============================[POST] ADD USER==================================
export interface AddUserDTO {
  aadId: string | null;
  userName: string | null;
  userEmail: string | null;
  personId: number | null;
  securityGroupIds: number[];
  claimAssigns: ClaimAssignRowDTO[];
}
export const addApplicationUser = (model: AddUserDTO) => api<UserRowDTO>('POST', `applicationUsers`, model);

import React, { useContext, useMemo } from 'react';

/* Data Things */
import { Inventory, transformInventoryToICreateInventoryDTO, transformInventoryToIEditInventoryDTO } from '../helper';
import { RouteComponentProps } from '@reach/router';
import { InventoriesContext } from '../../../../components/Contexts/Inventory';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { useIsMounted } from '../../../../hooks';

/* Presentation Things */
import CircularProgress from '../../../../components/CircularProgress';
import { DataTable, DataTableContent, DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody, DataTableCell } from '@rmwc/data-table';
import { TablePager } from '../../../../components/Pagers';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';

/* Routes */
import { AddOrEdit } from '../AddOrEdit';

/* Style */
import '../../../../styles/common.scss';

interface IProps extends RouteComponentProps {}

export function List(_: IProps) {
  //Context
  const context = useContext(InventoriesContext);
  const { open } = useContext(SideSheetContext);

  //Hooks
  const _isMounted = useIsMounted();

  //Variables
  const invetories = useMemo(() => Object.values(context.inventories), [context.inventories]);

  const onSubmit = async (model: Inventory) => {
    try {
      await context.onCreateInventory(transformInventoryToICreateInventoryDTO(model));
      return Promise.resolve('Ok');
    } catch (error) {
      const { message } = error as Error;
      _isMounted.current && context.onSetApiResponse({ type: 'error', message: String(message || error) });
      return Promise.reject(error);
    }
  };

  const onEdit = async (model: Inventory) => {
    try {
      await context.onEditInventory(transformInventoryToIEditInventoryDTO(model, model.id));

      return Promise.resolve('Ok');
    } catch (error) {
      const { message } = error as Error;
      _isMounted.current && context.onSetApiResponse({ type: 'error', message: String(message || error) });
      return Promise.reject(error);
    }
  };

  const onOpenAddSheet = () => open(<AddOrEdit onSubmit={onSubmit} />);

  const onSearch = (ev: React.ChangeEvent<HTMLInputElement>) => context.onSearch(String(ev.currentTarget.value));
  const onClickCopy = (search: string) => context.onSearch(search);
  const onClearSearch = () => context.onSearch('');

  return (
    <>
      <div className="data-table-title">
        <Typography className="title" use="body2">
          Készlet kezelés
        </Typography>
      </div>

      <div className="fab-menu-container">
        <div className="mdc-sheet-fab--container">
          <div className="mdc-sheet-fab">
            <div className="mdc-fab mdc-fab--mini" aria-label="hozzáadás" onClick={onOpenAddSheet}>
              <i className="material-icons">add</i>
            </div>
          </div>
        </div>
      </div>

      <div className="search-box">
        <span className="search-box--start-detail">
          <span className="material-icons">search</span>
        </span>

        <input className="search-box--input" type="text" placeholder="Keresés" onChange={onSearch} value={context.search} />
        <Icon icon="close" onClick={onClearSearch} />
      </div>

      <DataTable>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>
              <DataTableHeadCell>Megrendelés</DataTableHeadCell>
              <DataTableHeadCell>Cikk</DataTableHeadCell>
              <DataTableHeadCell alignEnd>Mennyiség</DataTableHeadCell>
              <DataTableHeadCell>Utoljára befejezett feladat</DataTableHeadCell>
              <DataTableHeadCell />
            </DataTableRow>
          </DataTableHead>

          <DataTableBody>
            {context.isLoading ? (
              <DataTableRow>
                <DataTableCell colSpan={7}>
                  <CircularProgress />
                </DataTableCell>
              </DataTableRow>
            ) : context.error.length > 0 || invetories.length < 1 ? (
              <DataTableRow>
                <DataTableCell colSpan={7} className="basic-error-cell">
                  {context.error || 'Nincs megjeleníthető készlet'}
                </DataTableCell>
              </DataTableRow>
            ) : (
              invetories.map(row => <Row onSearchOrder={onClickCopy} onSearchProduct={onClickCopy} key={row.id} row={row} onEdit={onEdit} />)
            )}
          </DataTableBody>
        </DataTableContent>

        <TablePager page={context.page} total={context.totalLength} displayedCount={100} onChange={context.onChangePage} />
      </DataTable>
    </>
  );
}

const Row = ({ row, onEdit, onSearchOrder, onSearchProduct }: { row: Inventory; onSearchOrder(search: string); onSearchProduct(search: string); onEdit(model: Inventory) }) => {
  //Context
  const { open } = useContext(SideSheetContext);

  const onOpenEditSheet = () => open(<AddOrEdit inventory={row} onSubmit={onEdit} />);

  const copyOrderSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onSearchOrder(row.order ? row.order.label : '');
  };

  const copyProductSearch = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    onSearchProduct(row.productItem ? row.productItem.label : '');
  };

  return (
    <DataTableRow>
      <DataTableCell>
        <span className="with-copy">
          <Icon icon="file_copy" title="Keresés" onClick={copyOrderSearch} />
          <span>{row.order ? row.order.label : ''}</span>
        </span>
      </DataTableCell>
      <DataTableCell>
        <span className="with-copy">
          <Icon icon="file_copy" title="Keresés" onClick={copyProductSearch} />
          <span>{row.productItem ? row.productItem.label : ''}</span>
        </span>
      </DataTableCell>
      <DataTableCell alignEnd>
        {row.quantity}
        {row.productItem ? row.productItem.itemType : ''}
      </DataTableCell>
      <DataTableCell>{row.task ? row.task.label : ''}</DataTableCell>

      <DataTableCell className="arrow rmwc-data-table__cell">
        <Icon icon="keyboard_arrow_right" onClick={onOpenEditSheet} />
      </DataTableCell>
    </DataTableRow>
  );
};

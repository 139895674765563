import React, { useEffect, useMemo, useRef, useState } from 'react';

/* Data Things */
import { CalendarDay, GlobalDayCss, IPersonCalendar, isLimitedDayStatusSetting } from './helper';
import { useDimensions, useIsMounted } from '../../../hooks/';
import { MonthlyAttendenceListState } from '../../../store/reducers/TrackTime/MonthlyAttendence/monthlyAttendence';
import { DateTime } from 'luxon';

/* Presentation Things */
import CircularProgress from '../../../components/CircularProgress';
import { MultiGrid, AutoSizer } from 'react-virtualized';
import { Icon } from '@rmwc/icon';
import { Cell } from './Cell';

type ListProps = {
  state: MonthlyAttendenceListState;
  isCurrentMonth: boolean;
  onStatusChange(personId: number, statusId: number | null, day: CalendarDay): void;
  restrictedBefore: DateTime | null;
  isMonthRestrictedButEditable: boolean;
};

const NOW = DateTime.local();
const NAME_COLUMN_WIDTH = 233;

export function List(props: ListProps) {
  const { state, isCurrentMonth, onStatusChange } = props;
  const { isLoading, rows, selectedView, selectedMonth, dayStatuses, error, isFullScreenMode } = state;

  /* Refs */
  const _gridRef = useRef<MultiGrid>(null);
  const [ref, dimension, , reMeasure] = useDimensions();
  const _isMounted = useIsMounted();

  /* States */
  const [editablePerson, setEditablePerson] = useState<number | null>(null);
  const [isCanSetLimitedStatus, setIsCanSetLimitedStatus] = useState(false);

  /* Variables */
  const visibleRows: IPersonCalendar[] = useMemo(() => rows.filter(x => x.isVisible), [rows]);

  const cellWidth = ({ index }) => {
    const width = window.innerWidth < 1500 ? 42 : (dimension.width - dimension.scrollWidth - NAME_COLUMN_WIDTH) / selectedMonth.daysInMonth;
    return index % (selectedMonth.daysInMonth + 1) === 0 ? NAME_COLUMN_WIDTH : width;
  };

  const _cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    const row = visibleRows[rowIndex - 1]; //-1 cuz the first row is the header
    const day = row ? row.calendar.days[columnIndex - 1] : null;
    const isEditable = row ? row.personId === editablePerson : false;
    const isToday = isCurrentMonth && NOW.day === columnIndex;

    const onChangeEditablePerson = () => setEditablePerson(isEditable ? null : row.personId);

    return (
      <div style={style} key={key}>
        {rowIndex === 0 ? (
          <div className="attendence-table__row attendence-table--headline ">
            {columnIndex === 0 ? (
              <span className="attendence-table__row-cell person mdc-typography--caption">Név</span>
            ) : (
              <span className={`attendence-table__row-cell attendence-table--headline__cell${isToday ? ' calendar--day--today' : ''} ${GlobalDayCss[day ? day.globalDayStatus : '']}`}>
                <span className="mdc-typography--caption">{columnIndex}</span>
              </span>
            )}
          </div>
        ) : columnIndex === 0 ? (
          <span className="attendence-table__row-cell data-table__row-cell--overflow person">
            <span className="mdc-typography--caption overflow" title={row.name}>
              {row.name}
            </span>

            {selectedView === 'DayStatus' && <Icon className={`${isEditable ? 'close-icon' : 'action-icon'}`} icon={isEditable ? 'close' : 'edit'} onClick={onChangeEditablePerson} />}
          </span>
        ) : day ? (
          <Cell
            isCanSetLimitedStatus={isCanSetLimitedStatus}
            day={day}
            selectedView={selectedView}
            isEditable={isEditable}
            dayStatuses={dayStatuses}
            onStatusChange={onStatusChange}
            personId={row.personId}
            isMonthRestrictedButEditable={state.isMonthRestrictedButEditable}
            restrictedBefore={state.restrictedBefore}
          />
        ) : null}
      </div>
    );
  };

  useEffect(reMeasure, [reMeasure, isFullScreenMode]);

  useEffect(() => {
    isLimitedDayStatusSetting().then(resp => _isMounted.current && setIsCanSetLimitedStatus(resp));
  }, [_isMounted]);

  useEffect(() => {
    if (_gridRef.current) {
      _gridRef.current.recomputeGridSize();
    }
  }, [dimension.width, _gridRef, selectedView]);

  return (
    <div className="scroll-content" style={{ marginRight: -dimension.scrollWidth }} ref={ref}>
      {isLoading ? (
        <CircularProgress />
      ) : error.length > 0 || !visibleRows.length ? (
        <div className="basic-error-text">{error || 'Nincsenek megjeleníthető alkalmazottak'}</div>
      ) : (
        <div className="BottomRightGrid_ScrollWrapperParent">
          <AutoSizer>
            {({ width, height }) => (
              <MultiGrid
                ref={_gridRef}
                className="attandence-table__row"
                fixedRowCount={1}
                fixedColumnCount={1}
                cellRenderer={_cellRenderer}
                columnWidth={cellWidth}
                columnCount={selectedMonth.daysInMonth + 1}
                enableFixedRowScroll
                enableFixedColumnScroll
                hideBottomLeftGridScrollbar
                hideTopRightGridScrollbar
                classNameBottomRightGrid="BottomRightGrid_ScrollWrapper"
                height={height}
                rowHeight={props.state.selectedView === 'PayRoll' ? 42 : 21}
                rowCount={visibleRows.length + 1} //+1 cuz the first row is the header
                width={width}
                editablePerson={editablePerson} //for rerender
                selectedView={selectedView} //for rerender
                visibleRows={visibleRows} //for rerender
              />
            )}
          </AutoSizer>
        </div>
      )}
    </div>
  );
}

import React from 'react';

/* Navigation Things */
import { RouteComponentProps, Router } from '@reach/router';

/* Data Things */
import { SecurityGroupsProvider } from '../../../components/Contexts/SecurityGroups';

/* Routes  */
import { SecurityGroupCreateAndEdit } from './Create&Edit';
import { SecurityGroupsList } from './List';

// Styles

interface IProps extends RouteComponentProps {}

export function SecurityGroups(props: IProps) {
  return (
    <SecurityGroupsProvider>
      <Router basepath="Settings/SecurityGroups">
        <SecurityGroupCreateAndEdit path="/Details/:id" />
        <SecurityGroupCreateAndEdit path="/Create" />
        <SecurityGroupsList default />
      </Router>
    </SecurityGroupsProvider>
  );
}

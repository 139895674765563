import { api } from '../../../utils/api';
import { ConcurrentEditViewModel } from '../Organization/Peoples/employment';
import { ClaimAssignRowDTO } from './applicationUsers';

//=============================GET SECURITY GROUPS==================================
export const getSecurityGroups = () => api<SecurityGroupRowDTO[]>('GET', 'securityGroups');

export interface SecurityGroupRowDTO extends ConcurrentEditViewModel {
  id: number;
  groupName: string | null;
}

//=============================POST SECURITY GROUP==================================
export interface AddSecurityGroupDTO extends ConcurrentEditViewModel {
  name: string;
  memberUserIds: number[];
  claimAssigns: ClaimAssignRowDTO[];
}

export const createSecurityGroup = (model: EditSecurityGroupDTO) => api<EditSecurityGroupDTO>('POST', 'securityGroups', model);

//=============================PUT SECURITY GROUP==================================
export interface EditSecurityGroupDTO extends ConcurrentEditViewModel {
  id: number;
  name: string | null;
  memberIds: number[];
  claimAssigns: ClaimAssignRowDTO[];
}
export const editSecurityGroup = (model: EditSecurityGroupDTO) => api<EditSecurityGroupDTO>('PUT', 'securityGroups', model);

//=============================GET FOR EDIT SECURITY GROUP==================================
export const getSecurityGroup = (id: number) => api<EditSecurityGroupDTO>('GET', `securityGroups/${id}`);

import { DATETIME_INPUT_FORMAT } from './constants';
import { DateTime } from 'luxon';
import React from 'react';
import Auth from '../utils/auth';

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const generateWindowTitle = (title: string, isForce: boolean = false): string => {
  return isForce ? title : title + ' - ProHR365';
};

export const orderByName = (a, b): number => {
  return a.lastName < b.lastName ? -1 : a.lastName > b.lastName ? 1 : a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : a.middleName < b.middleName ? -1 : a.middleName > b.middleName ? 1 : 0;
};

export const formatName = (firstName?: string, lastName?: string, middleName?: string, courtesyTitle?: string) => {
  return [courtesyTitle, lastName, firstName, middleName].filter(Boolean).join(' ');
};

/**
 * generateIndentsForCollapseable
 * @param {Number} level The current level in the tree strucutre
 * @param {Number} currentLevelIndex Inside of the level, the current index. (I'm using this property to determine the level is begin or last or both).
 * @param {Number} levelLenght Elements count in the current level
 * @param {Boolean} isOpened The current Element is opened. (If it's false it means the current Element does not have 'last' type indent)
 * @param {Boolean} nextElementLevel The next element level in the tree structure. If the current Element have sibling it should be the 'level' otherwise it should be the parent 'nextElementLevel'
 */
export const generateIndentsForCollapseable = (level: number, currentLevelIndex: number, levelLenght: number, isOpened: boolean, nextElementLevel: number) => {
  const length = level > 0 ? level - 1 : 0;

  return [...new Array(length)].map((_, currentIndent) => {
    const isLastIndentInTheArray = currentIndent === length - 1;

    const isBegin = currentLevelIndex === 0 && isLastIndentInTheArray;
    const isLast = !isOpened && currentLevelIndex + 1 === levelLenght && currentIndent + 1 >= nextElementLevel;

    return React.createElement('span', { key: `${level} - ${currentLevelIndex} - ${currentIndent}`, className: ['indent', isBegin && 'indent--begin', isLast && 'indent--end'].filter(Boolean).join(' ') });
  });
};

export const getCurrentUserClaims = async () => {
  try {
    const { profile } = await Auth.Instance.isAuthenticated();
    return profile;
  } catch (error) {
    return null;
  }
};

export const isDayClosed = (day: DateTime, restrictedBefore: DateTime | null, isMonthRestrictedButEditable: boolean) => {
  return restrictedBefore && day < restrictedBefore && !isMonthRestrictedButEditable;
};

export const toDateString = (date?: DateTime | null) => (date ? date.setLocale('hu').toFormat('D') : '-');

export const toDateInputString = (date: DateTime | null) => (date ? date.toFormat(DATETIME_INPUT_FORMAT) : '');

import React from 'react';
import ReactDOM from 'react-dom';
import { RowSyncStatus, ActivitySyncRow } from '../ActivitySync/helper';
import { Button } from '@rmwc/button';
import ConfirmSkip from './ConfirmSkip';

export default class MassStatusChange extends React.PureComponent<
  {
    selectedActivities: ActivitySyncRow[];
    statusCode: number;
    onSync(ids: number[], isAuto: boolean);
    onStatusChange(rows: ActivitySyncRow[], newStatusCode: RowSyncStatus): void;
    isAutoSyncEnabled: boolean;
  },
  {
    isSkipOpen: boolean;
  }
> {
  private modalRoot = document.getElementById('MassStatusChange');
  private el;
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.state = {
      isSkipOpen: false
    };
  }

  public componentDidMount() {
    if (this.modalRoot) {
      this.modalRoot.appendChild(this.el);
    }
  }

  public componentWillUnmount() {
    if (this.modalRoot) {
      this.modalRoot.removeChild(this.el);
    }
  }

  private handleConfirmClose = () => {
    this.setState({ isSkipOpen: false });
  };

  public render() {
    const isLoading = this.props.selectedActivities.some(x => x.isLoading);
    return ReactDOM.createPortal(
      <div className={`bottom-bar open`}>
        <span className="text">{this.props.selectedActivities.length + ' db aktivitás van kiválasztva'}</span>
        {RowSyncStatus.Failed === this.props.statusCode || RowSyncStatus.Never === this.props.statusCode ? (
          <>
            <Button
              disabled={isLoading}
              onClick={() =>
                this.props.onSync(
                  this.props.selectedActivities.map(x => x.activitySyncId),
                  false
                )
              }
            >
              Szinkronizálás
            </Button>
            <Button disabled={isLoading} onClick={() => this.setState({ isSkipOpen: true })}>
              Kihagyás
            </Button>
          </>
        ) : RowSyncStatus.Pending === this.props.statusCode ? (
          <>
            <Button disabled={isLoading} onClick={() => this.props.onStatusChange(this.props.selectedActivities, RowSyncStatus.Synced)}>
              Sikeres szinkronizálás
            </Button>
            <Button disabled={isLoading} onClick={() => this.props.onStatusChange(this.props.selectedActivities, RowSyncStatus.Failed)}>
              Sikertelen szinkronizálás
            </Button>
            {this.props.isAutoSyncEnabled && (
              <Button
                disabled={isLoading}
                onClick={() =>
                  this.props.onSync(
                    this.props.selectedActivities.map(x => x.activitySyncId),
                    true
                  )
                }
              >
                Automatikus ellenőrzés
              </Button>
            )}
          </>
        ) : (
          ''
        )}

        {this.state.isSkipOpen && <ConfirmSkip isOpen={this.state.isSkipOpen} onClose={this.handleConfirmClose} onSkip={() => this.props.onStatusChange(this.props.selectedActivities, RowSyncStatus.SkipForever)} />}
      </div>,
      this.el
    );
  }
}

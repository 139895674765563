import { DateTime } from 'luxon';
import { ApiResponseType } from '../../../../components/Portals/ResponseSnackbar';
import {
  calculateOvertimeHours,
  CalendarDay,
  IPersonCalendar,
  isDaystatusRowIncomplete,
  isRegistredTimeRowIncomplete,
  transformCalendarDayDTO,
  transformPersonCalendarDTO,
  ViewTypes
} from '../../../../routes/TrackTime/MonthlyAttendence/helper';
import { DayStatusRow, transformDayStatusRowDTO } from '../../../../routes/MasterData/DailyStatuses/helper';
import { CalendarDayDTO, MonthlyWorkSheetPeopleCalendarsDTO } from '../../../lib/TrackTime/monthlyAttendence';
import { DayStatusRowDTO } from '../../../lib/MasterData/dailyStatuses';

export const initialState: MonthlyAttendenceListState = {
  rows: [],
  dayStatuses: [],
  selectedMonth: DateTime.local().set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }),
  isLoading: true,
  isShowIncomplete: false,
  isFullScreenMode: false,
  selectedView: 'DayStatus',
  apiResponse: null,
  error: '',
  restrictedBefore: null,
  isMonthRestrictedButEditable: false
};

export interface MonthlyAttendenceListState {
  rows: IPersonCalendar[];
  dayStatuses: DayStatusRow[];
  selectedMonth: DateTime;
  isLoading: boolean;
  isShowIncomplete: boolean;
  isFullScreenMode: boolean;
  selectedView: ViewTypes;
  apiResponse: ApiResponseType;
  error: string;
  restrictedBefore: DateTime | null;
  isMonthRestrictedButEditable: boolean;
}

export type MonthlyAttendenceAction =
  | { type: 'success_get_daystatuses'; payload: { dayStatuses: DayStatusRowDTO[] } }
  | { type: 'error_get_daystatuses'; payload: { error: string } }
  | { type: 'error_get_model'; payload: { error: string } }
  | { type: 'success_get_model'; payload: { model: MonthlyWorkSheetPeopleCalendarsDTO; selectedMonth: DateTime; dayStatuses: DayStatusRow[] } }
  | { type: 'error_get_rows'; payload: { error: string } }
  | { type: 'set_partial_state'; payload: Partial<MonthlyAttendenceListState> }
  | { type: 'success_day_status_change'; payload: { personId: number; day: CalendarDayDTO } }
  | { type: 'set_isShowIncomplete'; payload: { isShowIncomplete: boolean } }
  | { type: 'set_selectedView'; payload: { selectedView: ViewTypes } }
  | { type: 'set_day'; payload: { personId: number; day: CalendarDay } }
  | { type: 'prevMonth' }
  | { type: 'nextMonth' }
  | { type: 'toogleFullScreenMode' };

const calculateIsVisibleByShowInCompleteSwitch = (state: MonthlyAttendenceListState, isShowIncomplete: boolean): IPersonCalendar[] => {
  if (state.selectedView === 'DayStatus') {
    return state.rows.map(x => (isShowIncomplete && !isDaystatusRowIncomplete(x) ? { ...x, isVisible: false } : { ...x, isVisible: true }));
  } else if (state.selectedView === 'RegisteredTime') {
    return state.rows.map(x => (isShowIncomplete && !isRegistredTimeRowIncomplete(x, state.dayStatuses) ? { ...x, isVisible: false } : { ...x, isVisible: true }));
  } else {
    return state.rows.map(x => ({ ...x, isVisible: true }));
  }
};

export function monthlyAttendenceListReducer(state: MonthlyAttendenceListState = initialState, action: MonthlyAttendenceAction): MonthlyAttendenceListState {
  switch (action.type) {
    case 'success_get_daystatuses':
      return { ...state, dayStatuses: action.payload.dayStatuses.map(x => transformDayStatusRowDTO(x)) };

    case 'error_get_daystatuses':
      return { ...state, error: action.payload.error, isLoading: false };

    case 'prevMonth':
      return { ...state, selectedMonth: state.selectedMonth.minus({ month: 1 }) };

    case 'nextMonth':
      return { ...state, selectedMonth: state.selectedMonth.plus({ month: 1 }) };

    case 'set_partial_state':
      return { ...state, ...action.payload };

    case 'set_isShowIncomplete': {
      return {
        ...state,
        isShowIncomplete: action.payload.isShowIncomplete,
        rows: calculateIsVisibleByShowInCompleteSwitch(state, action.payload.isShowIncomplete)
      };
    }

    case 'set_selectedView': {
      return {
        ...state,
        selectedView: action.payload.selectedView,
        rows: calculateIsVisibleByShowInCompleteSwitch({ ...state, selectedView: action.payload.selectedView }, state.isShowIncomplete)
      };
    }

    case 'success_get_model': {
      const rows = action.payload.model.peopleCalendars
        .map(x => calculateOvertimeHours(transformPersonCalendarDTO(x), action.payload.selectedMonth, action.payload.dayStatuses))
        .sort((x, y) => x.name.localeCompare(y.name));
      return {
        ...state,
        rows,
        isLoading: false,
        isMonthRestrictedButEditable: action.payload.model.isMonthRestrictedButEditable,
        restrictedBefore: action.payload.model.restrictedBefore ? DateTime.fromISO(action.payload.model.restrictedBefore) : null,
        apiResponse: null
      };
    }

    case 'error_get_model': {
      return { ...state, error: action.payload.error, isLoading: false };
    }

    case 'set_day': {
      return {
        ...state,
        rows: state.rows.map(row =>
          row.personId === action.payload.personId
            ? {
                ...row,
                calendar: {
                  ...row.calendar,
                  days: row.calendar.days.map(day => (day.date.day === action.payload.day.date.day ? action.payload.day : day))
                }
              }
            : row
        )
      };
    }

    case 'success_day_status_change': {
      const comingDay: CalendarDay = { ...transformCalendarDayDTO(action.payload.day) };
      return {
        ...state,
        rows: state.rows.map(row =>
          row.personId === action.payload.personId
            ? {
                ...row,
                calendar: {
                  ...row.calendar,
                  days: row.calendar.days.map(day => (day.date.day === comingDay.date.day ? comingDay : day))
                }
              }
            : row
        )
      };
    }

    case 'toogleFullScreenMode': {
      return {
        ...state,
        isFullScreenMode: !state.isFullScreenMode
      };
    }

    default:
      return state;
  }
}

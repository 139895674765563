import { DateTime } from 'luxon';
import { EmploymentDaysOff } from '../helper';
import { OUMemberTypes } from '../../../../../constants/enums';
import { EditEmployeeEmploymentDTO, ConcurrentEditViewModel, OUDTO, EmploymentContractDTO, PersonOUDTO, AddEmploymentDTO } from '../../../../../store/lib/Organization/Peoples/employment';

export const LOCAL_DATE = DateTime.local();
export interface EditEmployeeEmployment extends ConcurrentEditViewModel {
  employmentId: number;
  startDate: DateTime;
  endDate: DateTime | null;
  companyId: number | null;
  childrenInHousehold: number | null;
  taxReducement: number | null;
  daysOff: EmploymentDaysOff[];
  resourceGroupTypeId: string;
  OUs: PersonOU[];
  employmentContracts: EmploymentContract[];
}

export type PersonOU = {
  OUId: number;
  OUMemberType: OUMemberTypes;
};

export type EmploymentContract = {
  key: number; //using key property cuz the `employmentContractId` can be null and the user can add multiple new contracts
  employmentContractId: number | null;
  startDate: DateTime;
  weeklyWorkHours: number;
  grossSalary: number | null; //float
  jobRoleIds: number[];
  trialEndDate: DateTime | null;
  workScheduleId: number | null;
};

export type OU = {
  ouId: number;
  name: string;
};

export interface GetWorkScheduleByHour {
  workScheduleId: number;
  workScheduleName: string;
}

export interface AddEmployeeEmployment extends Omit<EditEmployeeEmployment, 'employmentId'> {}

export const transformPersonOUDTO = (p: PersonOUDTO): PersonOU => {
  return {
    OUId: p.ouId,
    OUMemberType: p.ouMemberType
  };
};

export const transformPersonOU = (p: PersonOU): PersonOUDTO => {
  return {
    ouId: p.OUId,
    ouMemberType: p.OUMemberType
  };
};

export const tansformEditEmployeeEmploymentDTO = (p: EditEmployeeEmploymentDTO): EditEmployeeEmployment => {
  return {
    companyId: p.companyId,
    daysOff: p.daysOff,
    employmentId: p.employmentId,
    resourceGroupTypeId: p.resourceGroupTypeId || '',
    updated: p.updated,
    OUs: p.oUs.map(x => transformPersonOUDTO(x)),
    childrenInHousehold: p.childrenInHousehold,
    taxReducement: p.taxReducement,
    startDate: DateTime.fromISO(p.startDate),
    endDate: p.endDate ? DateTime.fromISO(p.endDate) : null,
    employmentContracts: p.employmentContracts.map(x => transformEmploymentContractsDTO(x)).sort((x, y) => y.startDate.valueOf() - x.startDate.valueOf())
  };
};

export const transformEmploymentContractsDTO = (p: EmploymentContractDTO): EmploymentContract => {
  return {
    ...p,
    key: p.employmentContractId!,
    grossSalary: p.grossSalary ? parseFloat(p.grossSalary) : null,
    startDate: DateTime.fromISO(p.startDate),
    trialEndDate: p.trialEndDate ? DateTime.fromISO(p.trialEndDate) : null
  };
};

export const transformEmploymentContract = (x: EmploymentContract): EmploymentContractDTO => {
  return {
    jobRoleIds: x.jobRoleIds,
    weeklyWorkHours: x.weeklyWorkHours,
    workScheduleId: x.workScheduleId,
    employmentContractId: x.employmentContractId,
    grossSalary: x.grossSalary ? String(x.grossSalary) : null,
    startDate: x.startDate.toISODate() || '',
    trialEndDate: x.trialEndDate ? x.trialEndDate.toISODate() || '' : null
  };
};

export const tansformEditEmployeeEmployment = (p: EditEmployeeEmployment): EditEmployeeEmploymentDTO | null => {
  if (p.companyId !== null) {
    return {
      companyId: p.companyId,
      daysOff: p.daysOff,
      employmentId: p.employmentId,
      resourceGroupTypeId: p.resourceGroupTypeId || null,
      updated: p.updated,
      oUs: p.OUs.map(x => transformPersonOU(x)),
      childrenInHousehold: p.childrenInHousehold,
      taxReducement: p.taxReducement,
      startDate: p.startDate.toISODate() || '',
      endDate: p.endDate ? p.endDate.toISODate() || '' : null,
      employmentContracts: p.employmentContracts.map(x => transformEmploymentContract(x))
    };
  }
  return null;
};

export const transformOU = (p: OUDTO): OU => {
  return p;
};

export const generateDaysOff = (prevDaysOff: EmploymentDaysOff[], newYear: number) => {
  const oldDaysOff = prevDaysOff.filter(x => x.daysOff > 0);
  const newDaysOff: EmploymentDaysOff[] = [];

  for (let i = newYear; i <= LOCAL_DATE.year + 1; i++) newDaysOff.push({ year: i, daysOff: 0 });

  return Array.from(new Map([...newDaysOff, ...oldDaysOff].map(item => [item.year, item])).values()).sort((a, b) => b.year - a.year);
};

export const transformAddEmployeeEmployment = (p: AddEmployeeEmployment): AddEmploymentDTO | null => {
  if (p.companyId !== null) {
    return {
      companyId: p.companyId,
      daysOff: p.daysOff,
      resourceGroupTypeId: p.resourceGroupTypeId || null,
      updated: p.updated,
      oUs: p.OUs.map(x => transformPersonOU(x)),
      childrenInHousehold: p.childrenInHousehold || null,
      taxReducement: p.taxReducement || null,
      startDate: p.startDate.toISODate() || '',
      endDate: p.endDate ? p.endDate.toISODate() || '' : null,
      employmentContracts: p.employmentContracts.map(x => transformEmploymentContract(x))
    };
  }
  return null;
};

export const initAddEmployeeEmployment: Omit<AddEmployeeEmployment, 'updated'> = {
  OUs: [],
  childrenInHousehold: null,
  companyId: null,
  daysOff: [{ year: LOCAL_DATE.year, daysOff: 0 }],
  employmentContracts: [
    {
      key: LOCAL_DATE.valueOf(),
      employmentContractId: null,
      grossSalary: null,
      jobRoleIds: [],
      startDate: LOCAL_DATE,
      trialEndDate: null,
      weeklyWorkHours: 40,
      workScheduleId: null
    }
  ],
  endDate: null,
  resourceGroupTypeId: '',
  startDate: LOCAL_DATE,
  taxReducement: null
};

export const isEditEmployeeEmployment = (p: EditEmployeeEmployment | AddEmployeeEmployment): p is EditEmployeeEmployment => {
  if ((p as EditEmployeeEmployment).employmentId) {
    return true;
  }
  return false;
};

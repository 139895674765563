export const toHHMMSS = (secs: number, what: 'hours' | 'minutes' | 'seconds' | 'full'): string => {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  switch (what) {
    case 'hours':
      return hours.toString();
    case 'minutes':
      return minutes.toString();
    case 'seconds':
      return seconds.toString();
    case 'full':
      return [hours, minutes, seconds]
        .map(val => (val < 10 ? `0${val}` : val))
        .filter((val, index) => val !== '00' || index > 0)
        .join(':')
        .replace(/^0/, '');
  }
};

import React, { useContext } from 'react';

/* Data Things */
import { SideSheetContext } from '../../Contexts/SideSheet';
import { DateTime } from 'luxon';

/* Presentation Things */
import { Typography } from '@rmwc/typography';
import { Checkbox } from '@rmwc/checkbox';
import { Filter } from '../Filter/index';

type TableheaderProps = {
  onFilter(personId: number | null, projectId: number | null, start: DateTime | null, end: DateTime | null, statusId: number | null): void;
  onSelectAll(checked: boolean): void;
  selectedSyncStatusCodeModel: number | null;
  selectAbleActivitiesLength: number;
  selectedActivitiesLength: number;
  loading: boolean;
  /* Filter values */
  personId: number | null;
  projectId: number | null;
  statusId: number | null;
  start: DateTime | null;
  end: DateTime | null;
};

export const TableHeader = ({ onFilter, selectedActivitiesLength, selectAbleActivitiesLength, selectedSyncStatusCodeModel, onSelectAll, personId, start, end, projectId, statusId, loading }: TableheaderProps) => {
  const context = useContext(SideSheetContext);

  return (
    <div className="data-table__row data-table__row--title">
      <div className="data-table__cell--graphic">
        <div className="row-spacer" />
        {selectedSyncStatusCodeModel !== null && !loading && selectAbleActivitiesLength > 0 && (
          <Checkbox
            onChange={evt => onSelectAll(evt.currentTarget.checked)}
            checked={selectedActivitiesLength > 0 && selectedActivitiesLength === selectAbleActivitiesLength}
            indeterminate={selectedActivitiesLength > 0 && selectedActivitiesLength !== selectAbleActivitiesLength}
          />
        )}
      </div>

      <div className="flex-box-item data-table__cell person">
        <Typography use="caption">Dolgozó</Typography>
      </div>

      <div className="flex-box-group flex-box-group--responsive__1480">
        <div className="flex-box-group__item data-table__cell project-cell">
          <Typography use="caption">Projekt</Typography>
        </div>

        <div className="flex-box-group__item data-table__cell comment">
          <Typography use="caption">Mit csináltam</Typography>
        </div>
      </div>

      <div className="flex-box-item data-table__cell applied">
        <Typography use="caption">Dátum</Typography>
      </div>

      <div className="flex-box-group flex-box-group--column">
        <div className="flex-box-group__item data-table__cell">
          <Typography use="caption">Kezdés</Typography>
        </div>

        <div className="flex-box-group__item data-table__cell">
          <Typography use="caption">Befejezés</Typography>
        </div>
      </div>

      <div className="flex-box-item data-table__cell">
        <Typography use="caption">Összes</Typography>
      </div>

      <div className="flex-box-item data-table__cell">
        <Typography use="caption">Szinkronizálva</Typography>
      </div>

      <div className="flex-box-item data-table__cell">
        <Typography use="caption">Státusz</Typography>
      </div>

      {!loading && (
        <span
          className={`material-icons ${personId !== null || start !== null || end !== null || projectId !== null || statusId !== null ? 'active' : ''}`}
          onClick={() => context.open(<Filter personId={personId} projectId={projectId} start={start} end={end} statusId={statusId} context={context} onFilter={onFilter} />)}
        >
          filter_list
        </span>
      )}
    </div>
  );
};

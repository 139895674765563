import React, { useEffect, useState } from 'react';

//Data Things
import { generateDaysOff, LOCAL_DATE } from './helper';
import { EmploymentDaysOff } from '../helper';

//Presentation Things
import { TextField, TextFieldProps } from '@rmwc/textfield';
import { Select, SelectProps } from '@rmwc/select';

type IProps = {
  employmentStartYear: number;
  daysOff: EmploymentDaysOff[];
  onEditModel(key: 'daysOff', value: EmploymentDaysOff[]): void;
};

export function DaysOffSection({ employmentStartYear, daysOff, onEditModel }: IProps) {
  const SELECT_YEARS = daysOff.map(x => ({ label: String(x.year), value: String(x.year) }));
  const [selectedYear, setSelectedYear] = useState(LOCAL_DATE.year);

  useEffect(
    () => {
      onEditModel('daysOff', generateDaysOff(daysOff, employmentStartYear));
    },
    // eslint-disable-next-line
    [employmentStartYear]
  );

  const onSelectYear = (event: React.FormEvent<SelectProps>) => {
    if (event.currentTarget.value !== undefined) {
      setSelectedYear(parseInt(event.currentTarget.value));
    }
  };

  const onChangeDayOff = (event: React.FormEvent<TextFieldProps>) => {
    const dayOff = parseInt(event.currentTarget.value as string) || 0;
    onEditModel(
      'daysOff',
      daysOff.map(x => (x.year === selectedYear ? { ...x, daysOff: dayOff } : x))
    );
  };

  const dayOffByTheSelectedYear = daysOff.find(x => x.year === selectedYear);

  return (
    <div className="form-content-container days inputs-without-helptext-layout">
      <div className="title margin-bottom-12">Szabadság napok</div>

      <div className="form-content-row">
        <Select disabled={daysOff.length < 2} label="Tárgy év" value={String(selectedYear)} onChange={onSelectYear} options={SELECT_YEARS} />

        <TextField label="Éves szabadnapok száma" type="number" min={0} max={365} value={dayOffByTheSelectedYear !== undefined ? dayOffByTheSelectedYear.daysOff : ''} onChange={onChangeDayOff} />
      </div>
    </div>
  );
}

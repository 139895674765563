import { CompanyForOrganization, OrganizationUnit, transformCompanyForOrgHierarchyDTO, transformOUForOrgHierarchyDTO } from '../../../../routes/Organization/Structure/helper';
import { CompanyForOrgHierarchyDTO, OUEditedDTO } from '../../../lib/Organization/Structure';

export const initialState: OrganizationStructureState = {
  isLoading: true,
  error: '',
  companies: []
};

type OrganizationStructureState = {
  isLoading: boolean;
  error: string;
  companies: CompanyForOrganization[];
};

type OrganizationStructureAction =
  | { type: 'success_get_companies'; payload: { companies: CompanyForOrgHierarchyDTO[] } }
  | { type: 'error_get_companies'; payload: { error: string } }
  | { type: 'set_partial_state'; payload: Partial<OrganizationStructureState> }
  | { type: 'edit_organizationUnit'; payload: { organizationUnit: OUEditedDTO } }
  | { type: 'add_organizationUnit'; payload: { organizationUnit: OUEditedDTO } };

export function organiztaionStructureReducer(state: OrganizationStructureState = initialState, action: OrganizationStructureAction): OrganizationStructureState {
  switch (action.type) {
    case 'success_get_companies': {
      return {
        ...state,
        isLoading: false,
        error: '',
        companies: action.payload.companies.map(x => transformCompanyForOrgHierarchyDTO(x))
      };
    }

    case 'edit_organizationUnit': {
      const isTopLevelOU = action.payload.organizationUnit.parentOUId === null;
      const transformed = transformOUForOrgHierarchyDTO({ ...action.payload.organizationUnit, childUnits: [] });
      let moveAbleOU: OrganizationUnit | null = null;

      const moveChildUnit = (units: OrganizationUnit[], unitId: number, level = 1): OrganizationUnit[] => {
        units.forEach((unit, i) => {
          if (unit.id === unitId) {
            moveAbleOU = { ...transformed, childUnits: unit.childUnits, level };
            //Delete form the old place
            units.splice(i, 1);
            return;
          } else {
            moveChildUnit(unit.childUnits, unitId, level + 1);
          }
        });
        return units;
      };

      const mapChildUnits = (units: OrganizationUnit[]): OrganizationUnit[] => {
        if (moveAbleOU !== null) {
          units.forEach(unit => {
            if (unit.id === moveAbleOU!.parent.id) {
              unit.childUnits = [moveAbleOU!, ...unit.childUnits];
              return;
            } else {
              mapChildUnits(unit.childUnits);
            }
          });
        } else {
          units.forEach((unit, i) => {
            if (unit.id === action.payload.organizationUnit.ouId) {
              units[i] = { ...transformed, childUnits: unit.childUnits };
              return;
            } else {
              mapChildUnits(unit.childUnits);
            }
          });
        }
        return units;
      };

      const companies = state.companies.map(comp => {
        if (comp.id === action.payload.organizationUnit.parentCompanyId) {
          comp.childUnits = moveChildUnit(comp.childUnits, action.payload.organizationUnit.ouId);
          return { ...comp, childUnits: isTopLevelOU ? [transformed, ...comp.childUnits] : mapChildUnits(comp.childUnits) };
        } else return comp;
      });

      return {
        ...state,
        companies
      };
    }

    case 'add_organizationUnit': {
      const isTopLevelOU = action.payload.organizationUnit.parentOUId === null;
      const transformed = transformOUForOrgHierarchyDTO({ ...action.payload.organizationUnit, childUnits: [] });

      const mapChildUnits = (units: OrganizationUnit[], level = 1): OrganizationUnit[] => {
        units.forEach(unit => {
          if (unit.id === action.payload.organizationUnit.parentOUId) {
            //level + 1 cuz the level is parent
            unit.childUnits = [{ ...transformed, level: level + 1 }, ...unit.childUnits];
            return;
          } else {
            mapChildUnits(unit.childUnits, level + 1);
          }
        });
        return units;
      };

      const companies = state.companies.map(comp => {
        if (comp.id === action.payload.organizationUnit.parentCompanyId) {
          return { ...comp, childUnits: isTopLevelOU ? [transformed, ...comp.childUnits] : mapChildUnits(comp.childUnits) };
        } else return comp;
      });

      return {
        ...state,
        companies
      };
    }

    case 'error_get_companies': {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }

    case 'set_partial_state': {
      return {
        ...state,
        ...action.payload
      };
    }

    default:
      return state;
  }
}

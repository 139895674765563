import React, { useContext } from 'react';

/* Data Things */
import { PersonAttachmentDTO } from '../../../../store/lib/Organization/Peoples/peopleDetails';
import { SideSheetContext } from '../../../Contexts/SideSheet';
import { PersonDetails } from './helper';
import { formatBytes } from '../../../../constants/helperFuntions';

/* Presentation Things */
import { EditAtatchmentsSection } from './Edit/AttachmentsSection';
import { Typography } from '@rmwc/typography';
import { Download } from '../../../../components/Download';
import { Icon } from '@rmwc/icon';

interface Props {
  attachments: PersonAttachmentDTO[];
  onEditPartialDetailsModel(p: Partial<PersonDetails>): void;
  personId: number;
}

export const AttachmentsSection = ({ attachments, personId, onEditPartialDetailsModel }: Props) => {
  const { open, close } = useContext(SideSheetContext);

  const onOpenEdit = () => open(<EditAtatchmentsSection {...{ personId, onEditPartialDetailsModel, close }} />, 'side-sheet--custom-width');

  return (
    <>
      <div className="details-table-header">
        <Typography use="subtitle2" className="color-blue">
          Csatolmányok
        </Typography>

        <div className="icon-group">
          <Icon className="edit" icon="publish" onClick={onOpenEdit} />
        </div>
      </div>

      <div className="details-table-row attachments-row">
        {attachments!.map(
          attachment =>
            attachment.fileName && (
              <div key={attachment.attachmentId} className="details-table-row__cell">
                <Typography use="body2" className="secondary-text">
                  <Download className="text-overflow" href={attachment.fileUrl} fileNameWithExtension={attachment.fileName}>
                    {attachment.fileName}
                  </Download>
                </Typography>

                <Typography use="caption" className="primary-text">
                  {formatBytes(attachment.fileLength)}
                </Typography>
              </div>
            )
        )}
      </div>
    </>
  );
};

import { IClientDevicesDTO, IProductDTO, IProductListDTO, IScreenFeedbackDTO } from '../../../../typings/DTOs';
import { ApiResponseType } from '../../../../components/Portals/ResponseSnackbar';
import { Product } from '../../../../routes/Manufacturing/Products/helper';

export const initialState: ProductsState = {
  products: {},
  clientDevices: null,
  onScreenFeedback: null,
  apiResponse: null,
  error: '',
  search: '',
  isLoading: true,
  page: 0,
  totalLength: 0,
  offset: 0
};

export interface ProductsState {
  products: { [id: number]: Product };
  clientDevices: IClientDevicesDTO[] | null;
  onScreenFeedback: IScreenFeedbackDTO[] | null;
  totalLength: number;
  offset: number;
  page: number;
  apiResponse: ApiResponseType;
  error: string;
  search: string;
  isLoading: boolean;
}

type ProductsAction =
  | { type: 'success_get_products'; payload: { products: IProductListDTO } }
  | { type: 'error_get_products'; payload: { error: string } }
  | { type: 'success_get_product'; payload: { product: IProductDTO } }
  | { type: 'success_get_devices'; payload: { clientDevices: IClientDevicesDTO[] } }
  | { type: 'success_get_feedback'; payload: { feedback: IScreenFeedbackDTO } }
  | { type: 'success_clear_feedback'; payload: {} | null }
  | { type: 'success_post_product'; payload: { product: IProductDTO } }
  | { type: 'error_post_product'; payload: { error: string } }
  | { type: 'success_put_product'; payload: { product: IProductDTO } }
  | { type: 'error_put_product'; payload: { error: string } }
  | { type: 'success_post_task_attachment'; payload: {} | null }
  | { type: 'success_del_task_attachment'; payload: {} | null }
  | { type: 'success_mod_task_attachment'; payload: {} | null }
  | { type: 'error_task_attachment'; payload: { error: string } }
  | { type: 'set_partial_state'; payload: Partial<ProductsState> };

export function productsReducer(state: ProductsState = initialState, action: ProductsAction): ProductsState {
  switch (action.type) {
    case 'success_get_products': {
      return {
        ...state,
        isLoading: false,
        totalLength: action.payload.products.totalLength,
        offset: action.payload.products.offset,
        products: action.payload.products.items.reduce((acc, curr) => {
          const newProduct = new Product(curr);

          return { ...acc, [curr.id]: newProduct };
        }, {}),
        error: ''
      };
    }

    case 'error_get_products': {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }

    case 'success_get_product': {
      const isExist = Boolean(state.products[action.payload.product.id]);
      return {
        ...state,
        products: { ...state.products, [action.payload.product.id]: new Product(action.payload.product) },
        totalLength: isExist ? state.totalLength + 1 : state.totalLength
      };
    }

    case 'success_get_devices': {
      return {
        ...state,
        clientDevices: action.payload.clientDevices
      };
    }

    case 'success_get_feedback': {
      return {
        ...state,
        onScreenFeedback: state.onScreenFeedback ? [...state.onScreenFeedback, action.payload.feedback] : [action.payload.feedback]
      };
    }

    case 'success_clear_feedback': {
      return {
        ...state,
        onScreenFeedback: null
      };
    }

    case 'success_post_product': {
      if (action.payload.product.parentId !== null) {
        const editableProduct = state.products[action.payload.product.id];
        return {
          ...state,
          isLoading: false,
          products: editableProduct ? { ...state.products, [action.payload.product.id]: { ...editableProduct, subProducts: [...editableProduct.subProducts, new Product(action.payload.product)] } } : state.products,
          apiResponse: { type: 'success', message: 'Sikeres cikk hozzárendelés' }
        };
      } else {
        return {
          ...state,
          isLoading: false,
          products: { ...state.products, [action.payload.product.id]: new Product(action.payload.product) },
          totalLength: state.totalLength + 1,
          apiResponse: { type: 'success', message: 'Sikeres cikk létrehozás' }
        };
      }
    }

    case 'error_post_product': {
      return {
        ...state,
        isLoading: false,
        apiResponse: { type: 'error', message: action.payload.error }
      };
    }

    case 'success_put_product': {
      if (action.payload.product.parentId !== null) {
        const editableProduct = state.products[action.payload.product.id];

        return {
          ...state,
          products: editableProduct
            ? { ...state.products, [action.payload.product.id]: { ...editableProduct, subProducts: editableProduct.subProducts.map(x => (x.id === action.payload.product.id ? new Product(action.payload.product) : x)) } }
            : state.products,
          apiResponse: { type: 'success', message: 'Sikeres hozzárendelt cikk szerkesztés' }
        };
      }
      return {
        ...state,
        products: { ...state.products, [action.payload.product.id]: new Product(action.payload.product) },
        apiResponse: { type: 'success', message: 'Sikeres cikk szerkesztés' }
      };
    }

    case 'error_put_product': {
      return {
        ...state,
        apiResponse: { type: 'error', message: action.payload.error }
      };
    }

    case 'success_post_task_attachment': {
      return {
        ...state,
        apiResponse: { type: 'success', message: 'Sikeres dokumentum hozzáadás' }
      };
    }

    case 'success_del_task_attachment': {
      return {
        ...state,
        apiResponse: { type: 'success', message: 'Sikeres dokumentum törlés' }
      };
    }

    case 'success_mod_task_attachment': {
      return {
        ...state,
        apiResponse: { type: 'success', message: 'Sikeres dokumentum módosítás' }
      };
    }

    case 'error_task_attachment': {
      return {
        ...state,
        apiResponse: { type: 'error', message: action.payload.error }
      };
    }

    case 'set_partial_state': {
      return {
        ...state,
        ...action.payload
      };
    }

    default:
      return state;
  }
}

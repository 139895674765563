import React, { useContext, useState } from 'react';

/* Data Things */
import { createActivityTag, editActivityTag } from '../../../../store/lib/MasterData/activityTags';
import { ActivityTag, transformTagUpdatedVM } from '../helper';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { useIsMounted } from '../../../../hooks/useIsMounted';

/* Presentation Things */
import ResponseSnackbar, { ApiResponseType } from '../../../../components/Portals/ResponseSnackbar';
import { TextField, TextFieldProps } from '@rmwc/textfield';
import { LinearProgress } from '@rmwc/linear-progress';
import { Button } from '@rmwc/button';

interface Props {
  editableTag?: ActivityTag;
  onSetActivityTag(tag: ActivityTag): void;
  onAddActivityTag(tag: ActivityTag): void;
}

export const ActivityTagAddOrEdit = (props: Props) => {
  /* Variables */
  const isEdit = Boolean(props.editableTag);
  const tagId = isEdit ? props.editableTag!.id : null;

  /* States */
  const [apiResponse, setApiResponse] = useState<ApiResponseType>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(isEdit ? props.editableTag!.name : '');

  /* Context */
  const { close } = useContext(SideSheetContext);

  /* Refs */
  const _isMounted = useIsMounted();

  const handleNameChange = (ev: React.ChangeEvent<TextFieldProps>) => setName(String(ev.currentTarget.value));

  const onSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();
    setIsLoading(true);
    try {
      const resp = isEdit ? await editActivityTag({ tagId: tagId!, tagName: name, updated: props.editableTag!.updated }) : await createActivityTag(name);

      if (!resp.error && _isMounted.current) {
        const newActivity = transformTagUpdatedVM(resp);
        if (isEdit) {
          props.onSetActivityTag(newActivity);
        } else {
          props.onAddActivityTag(newActivity);
        }

        setApiResponse({ type: 'success', message: `Sikeres címke ${isEdit ? 'szerkesztés' : 'hozzáadás'}` });
        setTimeout(() => _isMounted.current && close(), 500);
      } else throw new Error(resp.error);
    } catch (error) {
      if (_isMounted.current) {
        setApiResponse({ type: 'error', message: String(error.message || 'Sajnáljuk, valami hiba történt a címke mentése során.') });
      }
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  return (
    <>
      <ResponseSnackbar response={apiResponse} onClose={() => setApiResponse(null)} />

      <div className="drawer-header">{isEdit ? 'Címke szerkesztése' : 'Új címke'}</div>
      {isLoading && <LinearProgress />}

      <div className="form-content">
        <form onSubmit={onSubmit}>
          <div className="form-content-row">
            <TextField required maxLength={50} type="text" label="Név" value={name} onChange={handleNameChange} autoFocus />
          </div>

          <Button unelevated label={isEdit ? 'Szerkesztés' : 'Hozzáadás'} type="submit" />
        </form>
      </div>
    </>
  );
};

ActivityTagAddOrEdit.defaultProps = {
  editableTag: undefined
};

import React from 'react';
import Auth from '../../utils/auth';
import { User } from 'oidc-client';

export interface WithProfileProps {
  profile: User;
}

interface IState {
  profile: User | null;
}

export function withProfile<T extends WithProfileProps>(Component: React.ComponentType<T>) {
  return class extends React.Component<Pick<T, Exclude<keyof T, keyof WithProfileProps>>, IState> {
    state = { profile: null };

    async componentDidMount() {
      const profile: User = await Auth.Instance.isAuthenticated();
      this.setState({ profile });
    }

    render() {
      return <Component {...(this.props as T)} profile={this.state.profile} />;
    }
  };
}

import Auth from './auth';

const API_BASE = process.env.REACT_APP_API_BASE;

export const api = async <T>(method: string, path: string, body: any = null, signal: AbortSignal | null = null, file: FormData | null = null, absoluteURL: boolean = false): Promise<T & ApiErrorResult> => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const user = await Auth.Instance.isAuthenticated();
  console.log('api call', user);

  if (user) headers.append('Authorization', `Bearer ${user.access_token}`);
  if (!file) headers.append('Content-Type', 'application/json');

  const requestInitParams: RequestInit = { method, headers, signal, body: body !== null ? file || JSON.stringify(body) : undefined };

  return fetch(absoluteURL ? path : API_BASE + path, requestInitParams)
    .then(
      response =>
        new Promise<T & ApiErrorResult>((resolve, reject) => {
          console.log(response.status, path);

          if (response.status < 400) {
            response
              .json()
              .then(resp => resolve(resp))
              .catch(e => resolve(e));
          } else {
            response.text().then(errorMessage => {
              const pureMessage = errorMessage ? JSON.parse(errorMessage) : '';
              const message = pureMessage && pureMessage.error ? pureMessage.error : '';
              if (response.status === 401 || response.status === 403) {
                reject({ error: message || 'Sajnáljuk valami hiba történt, kérjük jelentkezz be újra', code: response.status });
              } else if (response.status === 400 || response.status === 500 || response.status === 404) {
                reject({ error: message || 'Sajnáljuk valami hiba történt, kérjük próbáld újra később', code: response.status });
              } else if (response.status === 504 || response.status === 502) {
                reject({ error: message || 'A szerver nem elérhető, kérjük próbáld újra később', code: response.status });
              } else {
                reject({ error: message || 'Sajnáljuk valami hiba történt, kérjük próbáld újra később', code: response.status });
              }
            });
          }
        })
    )
    .catch(
      error =>
        new Promise<T & ApiErrorResult>((_, reject) => {
          if (error.name === 'AbortError') {
            console.log('Aborted request');
          } else {
            reject({ message: error.error || 'Sajnáljuk valami hiba történt, kérjük próbáld újra később', code: error.code || -1 });
          }
        })
    );
};

export interface ApiErrorResult {
  error: string;
}

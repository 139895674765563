import React, { useContext, useEffect, useMemo, useState } from 'react';

// Navigation things
import { RouteComponentProps } from '@reach/router';

// Presentational things
import { LinearProgress } from '@rmwc/linear-progress';
import { DataTable, DataTableContent, DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody, DataTableCell } from '@rmwc/data-table';
import { OrderAddOrEdit } from '../AddOrEdit';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';

// Data things
import { ORDER_STATUS_MAP, PAINT_STATUS_MAP, PRODUCTION_SITE_MAP } from '../../../../constants/enums';
import { Order, transformOrderToIEditOrderDTO, TaskJob } from '../helper';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { ProjectDisplay } from '../../../../components/Common/ProjectDisplay';
import { OrdersContext } from '../../../../components/Contexts/Orders';
import { toDateString } from '../../../../constants/helperFuntions';
import { useIsMounted } from '../../../../hooks';
import { ShowImage } from '../../../../components/Images/ShowImage';
import { SimpleMenuSurface } from '@rmwc/menu';
import { Button } from '@rmwc/button';
import { generateRowCalculatedFields, formatSeconds } from './helper';

interface IProps extends RouteComponentProps<{ id: string }> {}

export function OrderDetails(props: IProps) {
  //Context
  const { orders, isLoading, onGetOrder, onEditOrder, onSetApiResponse } = useContext(OrdersContext);
  const { open } = useContext(SideSheetContext);

  //States
  const [search, setSearch] = useState('');
  const [filteredTaskJobs, setFilteredTaskJobs] = useState<TaskJob[]>([]);

  //Hooks
  const _isMounted = useIsMounted();

  //Variables
  const id = props.id !== undefined ? Number(props.id) : null;
  const order = useMemo(() => orders.find(o => o.id === id) || null, [id, orders]);

  useEffect(() => void (id && onGetOrder(id)), [id, onGetOrder]);

  const onSubmit = async (model: Order) => {
    try {
      if (model.project && id) {
        await onEditOrder(transformOrderToIEditOrderDTO(model, id));

        return Promise.resolve('Ok');
      } else throw new Error('Kérlek válassz ki projektet');
    } catch (error) {
      _isMounted.current && onSetApiResponse({ type: 'error', message: String(error.message || error) });
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    if (search !== '') {
      let ts: TaskJob[] = [];
      order &&
        order.taskJobs &&
        order.taskJobs.forEach(tj => {
          if (tj.serialNumbers && tj.serialNumbers.length > 0) {
            tj.serialNumbers.forEach(s => {
              if (search !== '' && s.search(search) > -1) {
                ts.push(new TaskJob(tj));
              }
            });
          }
        });
      setFilteredTaskJobs(ts);
    } else setFilteredTaskJobs([]);
  }, [order, search]);

  const onOpenEditSheet = () => order && open(<OrderAddOrEdit onSubmit={onSubmit} order={order} />, 'side-sheet-600');

  const onChangeSearch = e => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const onClearSearch = () => setSearch('');

  return (
    <div className="details-table order-details">
      <div className="details-table-header">
        {isLoading && <LinearProgress className="linear-progress" />}

        <Typography use="subtitle2" className="color-blue">
          Részletes adatok
        </Typography>

        {order && (
          <div className="icon-group">
            <Icon onClick={onOpenEditSheet} className="edit" icon="edit" />
          </div>
        )}
      </div>

      <div className="details-table-row">
        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Vevő
          </Typography>

          <Typography use="body2" className="secondary-text">
            {order ? order.customer.companyName : ''}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Projekt
          </Typography>

          <Typography use="body2" className="secondary-text">
            {order && order.project ? <ProjectDisplay project={order.project} /> : ''}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Telephely
          </Typography>

          <Typography use="body2" className="secondary-text">
            {order ? PRODUCTION_SITE_MAP[order.productionSite] : ''}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Cikk
          </Typography>

          <Typography use="body2" className="secondary-text">
            {order ? order.productItem.title : ''}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Megrendelés szám
          </Typography>

          <Typography use="body2" className="secondary-text">
            {order ? order.orderNo : ''}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Mennyiség
          </Typography>

          <Typography use="body2" className="secondary-text">
            {order ? order.quantity : ''}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Státusz
          </Typography>

          <Typography use="body2" className="secondary-text">
            {order ? ORDER_STATUS_MAP[order.orderStatus] : ''}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Festék státusz
          </Typography>

          <Typography use="body2" className="secondary-text">
            {order ? (order.paintStatus ? PAINT_STATUS_MAP[order.paintStatus] : '-') : ''}
          </Typography>
        </div>

        <Typography use="caption" className="details-table-row__sub-header color-blue">
          Dátumok / Határidők
        </Typography>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Megrendelés dátuma
          </Typography>

          <Typography use="body2" className="secondary-text">
            {order ? (order.orderDate ? toDateString(order.orderDate) : '-') : ''}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Beérkezés dátuma
          </Typography>

          <Typography use="body2" className="secondary-text">
            {order ? (order.supplyDate ? toDateString(order.supplyDate) : '-') : ''}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Igényelt kiadási dátum
          </Typography>

          <Typography use="body2" className="secondary-text">
            {order ? (order.requestedShippingDate ? toDateString(order.requestedShippingDate) : '-') : ''}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Visszaigazolás dátuma
          </Typography>

          <Typography use="body2" className="secondary-text">
            {order ? (order.confirmationDate ? toDateString(order.confirmationDate) : '-') : ''}
          </Typography>
        </div>

        <div className="details-table-row__cell">
          <Typography use="caption" className="primary-text">
            Kiadás dátuma
          </Typography>

          <Typography use="body2" className="secondary-text">
            {order ? (order.shippingDate ? toDateString(order.shippingDate) : '-') : ''}
          </Typography>
        </div>

        <Typography use="caption" className="details-table-row__sub-header color-blue">
          Megjegyzés
        </Typography>

        <div className="details-table-row__cell">
          <Typography use="body2" className="secondary-text">
            {order ? order.notes : ''}
          </Typography>
        </div>
      </div>

      {order && order.taskJobs && order.taskJobs.length > 0 ? (
        <>
          <div className="details-table-header">
            <Typography use="subtitle2" className="color-blue">
              Munkafolyamatok
            </Typography>
          </div>
          <div className="search-box order-details">
            <span className="search-box--start-detail">
              <span className="material-icons">search</span>
            </span>

            <input className="search-box--input" type="text" placeholder="Keresés gyári számra" onChange={onChangeSearch} value={search} />

            <span className="search-box--start-detail" onClick={onClearSearch}>
              <span className="material-icons">close</span>
            </span>
          </div>
          <DataTable stickyRows={0} stickyColumns={0} className="order-taskjob-list">
            <DataTableContent>
              <DataTableHead>
                <DataTableRow>
                  <DataTableHeadCell>Tervezett kezdés</DataTableHeadCell>
                  <DataTableHeadCell>Cikk</DataTableHeadCell>
                  <DataTableHeadCell>Feladat</DataTableHeadCell>
                  <DataTableHeadCell>Résztvevők</DataTableHeadCell>
                  <DataTableHeadCell>Elvárt</DataTableHeadCell>
                  <DataTableHeadCell>Elkészült</DataTableHeadCell>
                  <DataTableHeadCell>MEO</DataTableHeadCell>
                  <DataTableHeadCell>Kezdés</DataTableHeadCell>
                  <DataTableHeadCell>Befejezés</DataTableHeadCell>
                  <DataTableHeadCell>Gyári számok</DataTableHeadCell>
                  <DataTableHeadCell>Hátravan</DataTableHeadCell>
                  <DataTableHeadCell>Tervezett</DataTableHeadCell>
                  <DataTableHeadCell>Teljesítmény</DataTableHeadCell>
                  <DataTableHeadCell>Minőség</DataTableHeadCell>
                </DataTableRow>
              </DataTableHead>
              <DataTableBody>
                {search === '' ? (
                  order.taskJobs.map(taskJob => <Row key={taskJob.id} taskJob={new TaskJob(taskJob)} productName={order.productItem.title} />)
                ) : filteredTaskJobs.length > 0 ? (
                  filteredTaskJobs.map(taskJob => <Row key={taskJob.id} taskJob={taskJob} productName={order.productItem.title} />)
                ) : (
                  <DataTableRow>
                    <DataTableCell colSpan={14} className="basic-error-cell">
                      {'Nincs megjeleníthető munkafolyamat'}
                    </DataTableCell>
                  </DataTableRow>
                )}
              </DataTableBody>
            </DataTableContent>
          </DataTable>
        </>
      ) : null}
    </div>
  );
}

const Row = ({ taskJob, productName }: { taskJob: TaskJob; productName: string }) => {
  const { performance, quality, deltaTimeInSeconds, plannedEndTime } = generateRowCalculatedFields(taskJob);
  return (
    <DataTableRow className="row">
      <DataTableCell>{taskJob ? (taskJob.plannedStartTime ? toDateString(taskJob.plannedStartTime) : '-') : '-'}</DataTableCell>
      <DataTableCell>{productName}</DataTableCell>
      <DataTableCell title={taskJob && taskJob.taskName ? taskJob.taskName : ''}>{taskJob.taskName && taskJob.taskName.length > 24 ? taskJob.taskName.substr(0, 22) + '...' : taskJob.taskName}</DataTableCell>
      <DataTableCell>
        {taskJob.persons.map(p => (
          <ShowImage key={p.id} src={p.photoUrl ? p.photoUrl : ''} initials={(p.lastName ? p.lastName.substring(0, 1) : '') + (p.firstName ? p.firstName.substring(0, 1) : '')} />
        ))}
      </DataTableCell>
      <DataTableCell>{taskJob.quantityExpected}</DataTableCell>
      <DataTableCell>{taskJob.quantityDone}</DataTableCell>
      <DataTableCell>{taskJob.quantityApproved}</DataTableCell>
      <DataTableCell>{taskJob.startTime ? 'T: ' + taskJob.startTime.toFormat('TT') : ''}</DataTableCell>
      <DataTableCell>{taskJob.endTime ? 'T: ' + taskJob.endTime.toFormat('TT') : ''}</DataTableCell>
      <DataTableCell>
        {taskJob.serialNumbers && taskJob.serialNumbers.length > 0 ? (
          <SimpleMenuSurface handle={<Button trailingIcon="expand_more">Gyári számok</Button>}>
            <div className="serials-popup">
              {taskJob.serialNumbers.map(s => (
                <span key={s}>{s}</span>
              ))}
            </div>
          </SimpleMenuSurface>
        ) : null}
      </DataTableCell>
      <DataTableCell>{formatSeconds(deltaTimeInSeconds)}</DataTableCell>
      <DataTableCell>{plannedEndTime ? 'T: ' + plannedEndTime.toFormat('TT') : ''}</DataTableCell>
      <DataTableCell>{performance}</DataTableCell>
      <DataTableCell>{quality}</DataTableCell>
    </DataTableRow>
  );
};

import React from 'react';
import { SelectedCell } from '../helper';
import { Typography } from '@rmwc/typography';
import { Duration } from 'luxon';

const InnerTableHeader = React.memo(
  ({ selectedCell, summedTimes }: { selectedCell: SelectedCell; summedTimes: Duration }) => {
    const title =
      selectedCell === SelectedCell.PendingLeaveRequest
        ? 'Függő távollét'
        : selectedCell === SelectedCell.PendingOvertimeRequest
        ? 'Függő túlóra'
        : selectedCell === SelectedCell.ActivityWithoutProject
        ? 'Projekt nélküli aktivitások'
        : selectedCell === SelectedCell.ActivityMoreThanEightWorkingHours
        ? '8 órát meghaladó aktivitások'
        : selectedCell === SelectedCell.OverlappedActivity
        ? 'Átfedések'
        : '';

    if (selectedCell === SelectedCell.ActivityWithoutProject || selectedCell === SelectedCell.ActivityMoreThanEightWorkingHours || selectedCell === SelectedCell.OverlappedActivity) {
      return (
        <>
          <div className="data-table-header">
            <div className="data-table__row data-table__row--header">
              <div className="flex-box-item data-table__cell--title">
                <h2 className="sub-title mdc-list-group__subheader mdc-typography--title">{title}</h2>
              </div>

              <div className="data-table__cell--end">
                <Typography use="body1">{summedTimes.toFormat('hh:mm:ss')}</Typography>
              </div>
            </div>
          </div>

          <div className="data-table__row data-table__row--title">
            <div className="flex-box-group flex-box-group--responsive__720">
              <div className="flex-box-group__item data-table__cell project">
                <Typography use="caption">Projekt</Typography>
              </div>

              <div className="flex-box-group__item data-table__cell comment">
                <Typography use="caption">Mit csináltam</Typography>
              </div>
            </div>

            <div className="flex-box-item data-table__cell tag-selector">
              <Typography use="caption">Címke</Typography>
            </div>

            {(selectedCell === SelectedCell.ActivityWithoutProject || selectedCell === SelectedCell.ActivityMoreThanEightWorkingHours || selectedCell === SelectedCell.OverlappedActivity) && (
              <div className="flex-box-item">
                <Typography use="caption">Dátum</Typography>
              </div>
            )}

            <div className="flex-box-item data-table__cell data-table__cell--align-right time-begin">
              <Typography use="caption">Kezdés</Typography>
            </div>

            <div className="flex-box-item data-table__cell data-table__cell--align-right time-end">
              <Typography use="caption">Befejezés</Typography>
            </div>

            <div className="flex-box-item data-table__cell--end data-table__cell--align-right">
              <Typography use="caption">Összes</Typography>
            </div>
          </div>
        </>
      );
    } else if (selectedCell === SelectedCell.PendingLeaveRequest || selectedCell === SelectedCell.PendingOvertimeRequest) {
      return (
        <>
          <div className="data-table-header">
            <div className="data-table__row">
              <div className="flex-box-item data-table__cell--title">
                <h2 className="sub-title mdc-list-group__subheader mdc-typography--title">{title}</h2>
              </div>
            </div>
          </div>

          <div className="data-table">
            <div className="data-table__row data-table__row--title">
              <div className="flex-box-group flex-box-group--responsive__1024">
                <div className="flex-box-group__item data-table__cell">
                  <Typography use="caption">Kérelem</Typography>
                </div>

                <div className="flex-box-group__item data-table__cell">
                  <span className="mdc-typography mdc-typography--caption" />
                </div>
              </div>

              {selectedCell === SelectedCell.PendingOvertimeRequest && (
                <div className="flex-box-group__item data-table__cell maximum">
                  <Typography use="caption">Maximum elszámolt óra</Typography>
                </div>
              )}
            </div>
          </div>
        </>
      );
    }
    return null;
  },
  (prevProps, nextProps) => {
    return prevProps.summedTimes.milliseconds === nextProps.summedTimes.milliseconds && prevProps.selectedCell === nextProps.selectedCell;
  }
);

export default InnerTableHeader;

import { api } from '../../../utils/api';
import { RowSyncStatus } from '../../../components/ActivitySync/helper';

export type PersonRowDTO = {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  photoUrl?: string;
};

export type ProjectRowDTO = {
  projectId?: number;
  projectDisplayCode: string;
  projectDisplayName: string;
  projectColorCode: string;
  rootProjectId?: number;
  rootProjectDisplayCode: string;
  rootProjectDisplayName: string;
  rootProjectColorCode: string;
};

export type ActivitySyncRowDTO = {
  activitySyncId: number;
  activitySyncRowVersion: string;
  isDeleted: boolean;
  personRow: PersonRowDTO;
  project?: ProjectRowDTO;
  comment: string;
  appliedDate: string;
  startTime: string;
  endTime?: string;
  lastSyncedAt?: string;
  syncStatusCode: number;
  errorMessage: string;
};

export interface UnsyncedActivitiesDTO {
  activities: ActivitySyncRowDTO[];
  totalFilteredActivities: number;
  totalActivities: number;
}

export interface RootProjectRowDTO {
  id: number;
  displayName: string;
  displayCode: string;
}

export interface UpdateStatusRowDTO {
  activitySyncId: number;
  activitySyncRowVersion: string;
}
export interface UpdateStatusDTO {
  activitySyncs: UpdateStatusRowDTO[];
  status: number;
}

export const getUnsyncedActivities = (rootProjectId: number | null = null, personId: number | null = null, status: RowSyncStatus | null = null, start: string | null = null, end: string | null = null) => {
  return api<UnsyncedActivitiesDTO>(
    'GET',
    `activitysyncs/unsynced-activities?${rootProjectId ? 'rootProjectId=' + rootProjectId + (personId || status !== null || start || end ? '&' : '') : ''}${
      personId ? 'personId=' + personId + (status !== null || start || end ? '&' : '') : ''
    }${status !== null ? 'status=' + status + (start || end ? '&' : '') : ''}${start ? 'start=' + start + (end ? '&' : '') : ''}${end ? 'end=' + end : ''}`,
    null
  );
};

export const getPersonsWhereActivityUnsynced = () => {
  return api<PersonRowDTO[]>('GET', `activitysyncs/unsynced-activities/persons`, null);
};

export const getAllRootProjectWhereActivityUnsynced = () => {
  return api<RootProjectRowDTO[]>('GET', `activitysyncs/unsynced-activities/root-projects`, null);
};

export const appSyncAutoCheckStatus = () => {
  return api<{ enabled: boolean }>('GET', `activitysyncs/app-sync-auto`, null);
};

export const updateActivityTimeSyncStatus = (model: UpdateStatusDTO) => {
  return api<ActivitySyncRowDTO[]>('PUT', `activitysyncs/status`, model);
};

export const syncActivityTime = (model: number[]) => {
  return api<{}>('POST', `activitysyncs/unsynced-activities/sync`, { ActivitySyncIds: model });
};

export const syncAutoCheck = (model: number[]) => {
  return api<{}>('POST', `activitysyncs/sync-auto-check`, { ActivityTimeSyncIds: model });
};

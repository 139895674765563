import {
  PersonDetailsDTO,
  PersonAttachmentDTO,
  EditPersonBasicInfoDTO,
  AddressDTO,
  EditPersonContactInfoDTO,
  EditPersonalInfoDTO,
  PersonDetailsPersonalInfoDTO,
  PersonDetailsBasicInfoDTO,
  PersonDetailsContactInfoDTO
} from '../../../../store/lib/Organization/Peoples/peopleDetails';
import { DateTime } from 'luxon';
import { PersonTypes, MaritalStatuses, SexTypes } from '../../../../constants/enums';
import { ConcurrentEditViewModel } from '../../../../store/lib/Organization/Peoples/employment';

//=========================================EDIT-PERSONALINFO====================================
export interface EditPersonalInfo extends ConcurrentEditViewModel {
  socialSecurityNumber: string;
  addressCardNumber: string;
  idCardNumber: string;
  idCardExpiryDate: DateTime | null;
  passportNumber: string;
  passportExpiryDate: DateTime | null;
  driverLicenseNumber: string;
  driverLicenseExpiryDate: DateTime | null;
  taxNumber: string;
  bankAccountNumber: string;
}

export const transformEditPersonalInfoDTO = (p: EditPersonalInfoDTO): EditPersonalInfo => {
  return {
    ...p,
    addressCardNumber: p.addressCardNumber || '',
    bankAccountNumber: p.bankAccountNumber || '',
    driverLicenseNumber: p.driverLicenseNumber || '',
    idCardNumber: p.idCardNumber || '',
    passportNumber: p.passportNumber || '',
    socialSecurityNumber: p.socialSecurityNumber || '',
    taxNumber: p.taxNumber || '',
    idCardExpiryDate: p.idCardExpiryDate ? DateTime.fromISO(p.idCardExpiryDate) : null,
    passportExpiryDate: p.passportExpiryDate ? DateTime.fromISO(p.passportExpiryDate) : null,
    driverLicenseExpiryDate: p.driverLicenseExpiryDate ? DateTime.fromISO(p.driverLicenseExpiryDate) : null
  };
};

export const transformEditPersonalInfo = (p: EditPersonalInfo): EditPersonalInfoDTO => {
  return {
    ...p,
    addressCardNumber: p.addressCardNumber || null,
    bankAccountNumber: p.bankAccountNumber || null,
    driverLicenseNumber: p.driverLicenseNumber || null,
    idCardNumber: p.idCardNumber || null,
    passportNumber: p.passportNumber || null,
    socialSecurityNumber: p.socialSecurityNumber || null,
    taxNumber: p.taxNumber || null,
    idCardExpiryDate: p.idCardExpiryDate ? p.idCardExpiryDate.toISODate() : null,
    passportExpiryDate: p.passportExpiryDate ? p.passportExpiryDate.toISODate() : null,
    driverLicenseExpiryDate: p.driverLicenseExpiryDate ? p.driverLicenseExpiryDate.toISODate() : null
  };
};

export const transformPersonDetailsPersonalInfoDTO = (p: PersonDetailsPersonalInfoDTO): PersonDetailsPersonalInfo => {
  return {
    ...p,
    addressCardNumber: p.addressCardNumber || '',
    bankAccountNumber: p.bankAccountNumber || '',
    driverLicenseNumber: p.driverLicenseNumber || '',
    idCardNumber: p.idCardNumber || '',
    passportNumber: p.passportNumber || '',
    socialSecurityNumber: p.socialSecurityNumber || '',
    taxId: p.taxId || '',
    idCardExpiryDate: p.idCardExpiryDate ? DateTime.fromISO(p.idCardExpiryDate) : null,
    passportExpiryDate: p.passportExpiryDate ? DateTime.fromISO(p.passportExpiryDate) : null,
    driverLicenseExpiryDate: p.driverLicenseExpiryDate ? DateTime.fromISO(p.driverLicenseExpiryDate) : null
  };
};

//==========================================EDIT-CONTACTINFO====================================
export interface EditPersonContactInfo extends ConcurrentEditViewModel {
  publicMobilePhone: string;
  publicLinePhone: string;
  publicLinePhoneExtension: string;
  publicEmail: string;
  linkedin: string;
  privateHomeAddress: AddressDTO;
  privateResidenceAddress: AddressDTO;
  privateMobilePhone: string;
  privateEmail: string;
  facebook: string;
  nextOfKinName: string;
  nextOfKinPhone: string;
}

export const transformEditPersonContactInfoDTO = (p: EditPersonContactInfoDTO): EditPersonContactInfo => {
  return { ...p, ...transformPersonDetailsContactInfoDTO(p) };
};

export const transformEditPersonContactInfo = (p: EditPersonContactInfo): EditPersonContactInfoDTO => {
  return {
    ...p,
    publicMobilePhone: p.publicMobilePhone || null,
    publicLinePhone: p.publicLinePhone || null,
    publicLinePhoneExtension: p.publicLinePhoneExtension || null,
    publicEmail: p.publicEmail || null,
    linkedin: p.linkedin || null,
    privateMobilePhone: p.privateMobilePhone || null,
    privateEmail: p.privateEmail || null,
    facebook: p.facebook || null,
    nextOfKinName: p.nextOfKinName || null,
    nextOfKinPhone: p.nextOfKinPhone || null
  };
};

//==========================================EDIT-BASICINFO====================================
export interface EditPersonBasicInfo extends ConcurrentEditViewModel {
  syncId: string;
  pictureTempFileId: number | null;
  pictureUrl: string;
  firstName: string;
  lastName: string;
  middleName: string;
  courtesyTitle: string;
  sexType: SexTypes;
  birthDate: DateTime;
  birthPlace: string;
  birthName: string;
  motherName: string;
  maritalStatus: MaritalStatuses | null;
  isPrivate: boolean;
}

export const transformEditPersonBasicInfoDTO = (p: EditPersonBasicInfoDTO): EditPersonBasicInfo => {
  return {
    ...p,
    syncId: p.syncId || '',
    courtesyTitle: p.courtesyTitle || '',
    lastName: p.lastName || '',
    firstName: p.firstName || '',
    middleName: p.middleName || '',
    birthName: p.birthName || '',
    birthPlace: p.birthPlace || '',
    motherName: p.motherName || '',
    birthDate: DateTime.fromISO(p.birthDate),
    pictureUrl: p.pictureUrl || ''
  };
};

export const transformEditPersonBasicInfo = (p: EditPersonBasicInfo): EditPersonBasicInfoDTO => {
  return {
    ...p,
    syncId: p.syncId || null,
    courtesyTitle: p.courtesyTitle || null,
    middleName: p.middleName || null,
    pictureTempFileId: p.pictureTempFileId || null,
    maritalStatus: p.maritalStatus || null,
    birthDate: p.birthDate.toISODate() || '',
    pictureUrl: p.pictureUrl || null
  };
};

//==========================================GET-DETAILS=======================================
export type PersonDetailsBasicInfo = {
  syncId: string;
  name: string;
  birthName: string;
  sex: SexTypes;
  birthDate: DateTime;
  birthPlace: string;
  motherName: string;
  maritalStatus: MaritalStatuses | null;
  isPrivate: boolean;
};

export type PersonDetailsPersonalInfo = {
  taxId: string;
  socialSecurityNumber: string;
  addressCardNumber: string;
  idCardNumber: string;
  idCardExpiryDate: DateTime | null;
  passportNumber: string;
  passportExpiryDate: DateTime | null;
  driverLicenseNumber: string;
  driverLicenseExpiryDate: DateTime | null;
  bankAccountNumber: string;
};

export type PersonDetailsContactInfo = {
  publicMobilePhone: string;
  publicLinePhone: string;
  publicLinePhoneExtension: string;
  publicEmail: string;
  linkedin: string;
  privateHomeAddress: AddressDTO;
  privateResidenceAddress: AddressDTO;
  privateMobilePhone: string;
  privateEmail: string;
  facebook: string;
  nextOfKinName: string;
  nextOfKinPhone: string;
};

export interface PersonDetails extends ConcurrentEditViewModel {
  personId: number;
  pictureUrl: string;
  courtesyTitle: string;
  lastName: string;
  firstName: string;
  middleName: string;
  phoneNumber: string;
  email: string;
  jobRoles: string[] | null;
  ous: string[] | null;
  status: string;
  currentAvailability: string;
  personType: PersonTypes;
  basicInfo: PersonDetailsBasicInfo | null;
  contactInfo: PersonDetailsContactInfo | null;
  personalInfo: PersonDetailsPersonalInfo | null;
  attachments: PersonAttachmentDTO[] | null;
}

export const transformPersonDetails = (p: PersonDetailsDTO): PersonDetails => {
  return {
    pictureUrl: p.pictureUrl || '',
    courtesyTitle: p.courtesyTitle || '',
    lastName: p.lastName || '',
    firstName: p.firstName || '',
    middleName: p.middleName || '',
    phoneNumber: p.phoneNumber || '',
    email: p.email || '',
    status: p.status || '',
    ous: p.oUs,
    attachments: p.attachments,
    jobRoles: p.jobRoles,
    personId: p.personId,
    personType: p.personType,
    currentAvailability: p.currentAvailability || '',
    basicInfo: p.basicInfo ? transformPersonDetailsBasicInfoDTO(p.basicInfo) : null,
    contactInfo: p.contactInfo ? transformPersonDetailsContactInfoDTO(p.contactInfo) : null,
    personalInfo: p.personalInfo ? transformPersonDetailsPersonalInfoDTO(p.personalInfo) : null,
    updated: p.updated
  };
};

export const transformPersonDetailsBasicInfoDTO = (p: PersonDetailsBasicInfoDTO): PersonDetailsBasicInfo => {
  return {
    ...p,
    syncId: p.syncId || '',
    name: p.name || '',
    birthName: p.birthName || '',
    birthPlace: p.birthPlace || '',
    motherName: p.motherName || '',
    birthDate: DateTime.fromISO(p.birthDate)
  };
};

export const transformPersonDetailsContactInfoDTO = (p: PersonDetailsContactInfoDTO): PersonDetailsContactInfo => {
  return {
    ...p,
    privateHomeAddress: {
      addressLine1: p.privateHomeAddress ? p.privateHomeAddress.addressLine1 || '' : '',
      addressLine2: p.privateHomeAddress ? p.privateHomeAddress.addressLine2 || '' : '',
      city: p.privateHomeAddress ? p.privateHomeAddress.city || '' : '',
      country: p.privateHomeAddress ? p.privateHomeAddress.country || '' : '',
      zip: p.privateHomeAddress ? p.privateHomeAddress.zip || '' : ''
    },
    privateResidenceAddress: {
      addressLine1: p.privateResidenceAddress ? p.privateResidenceAddress.addressLine1 || '' : '',
      addressLine2: p.privateResidenceAddress ? p.privateResidenceAddress.addressLine2 || '' : '',
      city: p.privateResidenceAddress ? p.privateResidenceAddress.city || '' : '',
      country: p.privateResidenceAddress ? p.privateResidenceAddress.country || '' : '',
      zip: p.privateResidenceAddress ? p.privateResidenceAddress.zip || '' : ''
    },
    publicMobilePhone: p.publicMobilePhone || '',
    publicLinePhone: p.publicLinePhone || '',
    publicLinePhoneExtension: p.publicLinePhoneExtension || '',
    publicEmail: p.publicEmail || '',
    linkedin: p.linkedin || '',
    privateMobilePhone: p.privateMobilePhone || '',
    privateEmail: p.privateEmail || '',
    facebook: p.facebook || '',
    nextOfKinName: p.nextOfKinName || '',
    nextOfKinPhone: p.nextOfKinPhone || ''
  };
};

import { IActivityDTO, LeaveRequestRowDetailsDTO, OvertimeRequestRowDetailsDTO, PersonCostRowDTO } from '../../../../../store/lib/TrackTime/monthlyClose';
import { DateTime } from 'luxon';
import { TagDTO } from '../../../../../store/lib/MasterData/activityTags';

export interface IActivity {
  activityTimeEntryId: number;
  personId: number;
  projectId: number | null;
  projectDisplayCode: string | null;
  projectName: string | null;
  projectColorCode: string | null;
  rootProjectDisplayCode: string | null;
  rootProjectName: string | null;
  rootProjectColorCode: string | null;
  rootProjectId: number | null;
  appliedDate: DateTime;
  startTime: DateTime;
  endTime: DateTime | null;
  comment: string;
  tags: TagDTO[];
  updated: number;
}

export interface IOvertimeRequestRow {
  overtimeRequestRowId: number;
  overtimeRequestId: number;
  personRow: PersonCostRowDTO;
  createDate: DateTime;
  startDate: DateTime | null;
  endDate: DateTime | null;
  maxMinutes: number | null;
  reason: string;
  isDeleteRequest: boolean;
}

export interface ILeaveRequestRow {
  leaveRequestRowId: number;
  leaveRequestId: number;
  personRow: PersonCostRowDTO;
  createDate: DateTime;
  startDate: DateTime | null;
  endDate: DateTime | null;
  reason: string;
  isDeleteRequest: boolean;
}

export const activitysTransform = (p: IActivityDTO): IActivity => {
  return {
    ...p,
    appliedDate: DateTime.fromISO(p.appliedDate),
    startTime: DateTime.fromISO(p.startTime),
    endTime: p.endTime ? DateTime.fromISO(p.endTime) : null,
    comment: p.comment,
    tags: p.tags ? p.tags : [],
    updated: p.updated
  };
};

export const overtimeRequestRowTransform = (p: OvertimeRequestRowDetailsDTO): IOvertimeRequestRow => {
  return {
    ...p,
    createDate: DateTime.fromISO(p.createDate),
    personRow: p.personRowVM,
    startDate: p.startDate ? DateTime.fromISO(p.startDate) : null,
    endDate: p.endDate ? DateTime.fromISO(p.endDate) : null,
    maxMinutes: p.maxMinutes ? parseFloat(p.maxMinutes) : null
  };
};

export const leaveRequestRowTransform = (p: LeaveRequestRowDetailsDTO): ILeaveRequestRow => {
  return {
    ...p,
    createDate: DateTime.fromISO(p.createDate),
    personRow: p.personRowVM,
    startDate: p.startDate ? DateTime.fromISO(p.startDate) : null,
    endDate: p.endDate ? DateTime.fromISO(p.endDate) : null
  };
};

export function instanceOfLRRow(object: any): object is ILeaveRequestRow {
  return 'leaveRequestId' in object;
}

import React, { useCallback, useEffect } from 'react';
import { Select } from '@rmwc/select';
import { TextField } from '@rmwc/textfield';
import { DateTime } from 'luxon';
import { PersonRow, transformPersonRowDTO, RootProjectRow, transformRootProjectRowDTO, RowSyncStatus } from '../helper';
import { getPersonsWhereActivityUnsynced, getAllRootProjectWhereActivityUnsynced } from '../../../store/lib/TrackTime/activitiesSync';
import { ContextProps } from '../../Contexts/SideSheet';
import { Button } from '@rmwc/button';
import { DATEFORMAT } from '../../../constants/constants';
import { useIsMounted } from '../../../hooks';
import { LinearProgress } from '@rmwc/linear-progress';

type StatusRow = { label: string; value: string };

type FilterState = {
  persons: PersonRow[];
  selectedPersonId: number | null;
  selectedProjectId: number | null;
  selectedStatusId: number | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  projects: RootProjectRow[];
  statuses: StatusRow[];
  isLoading: boolean;
  error: string;
};

type FilterProps = {
  context: ContextProps;
  onFilter(personId: number | null, projectId: number | null, start: DateTime | null, end: DateTime | null, statusId: number | null): void;
  /* Filter values */
  personId: number | null;
  projectId: number | null;
  start: DateTime | null;
  end: DateTime | null;
  statusId: number | null;
};

type ActionType =
  | { type: 'success_on_init'; payload: { persons: PersonRow[]; projects: RootProjectRow[] } }
  | { type: 'error_on_init'; error: string }
  | { type: 'setSelectedPerons'; id: string }
  | { type: 'setSelectedProject'; id: string }
  | { type: 'setStartDate'; startDate: DateTime | null }
  | { type: 'setEndDate'; endDate: DateTime | null }
  | { type: 'setStatuses'; statuses: StatusRow[] }
  | { type: 'setPersonLoading'; loading: boolean }
  | { type: 'setProjectLoading'; loading: boolean }
  | { type: 'setSelectedStatus'; id: string };

const STATUSES = [
  {
    id: RowSyncStatus.Never,
    name: 'Soha'
  },
  {
    id: RowSyncStatus.Pending,
    name: 'Függő'
  },
  {
    id: RowSyncStatus.Failed,
    name: 'Hiba'
  }
];

const initStatuses: StatusRow[] = STATUSES.map(status => ({ label: status.name, value: status.id.toString() }));

export const Filter = ({ context, onFilter, personId, start, end, projectId, statusId }: FilterProps) => {
  const isMounted = useIsMounted();

  const [state, dispatch] = React.useReducer(
    (state: FilterState, action: ActionType) => {
      switch (action.type) {
        case 'success_on_init':
          return {
            ...state,
            persons: action.payload.persons.sort((a, b) => a.label.localeCompare(b.label, 'hu')),
            projects: action.payload.projects.sort((a, b) => a.label.localeCompare(b.label, 'hu')),
            isLoading: false,
            selectedProjectId: projectId,
            selectedPersonId: personId
          };

        case 'error_on_init': {
          return {
            ...state,
            error: action.error,
            isLoading: false
          };
        }
        case 'setSelectedPerons':
          return { ...state, selectedPersonId: action.id ? parseInt(action.id) : null };
        case 'setStartDate':
          return { ...state, startDate: action.startDate };
        case 'setEndDate':
          return { ...state, endDate: action.endDate };
        case 'setSelectedProject':
          return { ...state, selectedProjectId: action.id ? parseInt(action.id) : null };
        case 'setSelectedStatus':
          return { ...state, selectedStatusId: action.id ? parseInt(action.id) : null };
        default:
          return state;
      }
    },
    {
      persons: [],
      selectedPersonId: personId,
      startDate: start,
      endDate: end,
      projects: [],
      selectedProjectId: projectId,
      statuses: [{ label: '', value: '' }, ...initStatuses],
      selectedStatusId: statusId,
      isLoading: true,
      error: ''
    }
  );

  const getInitialDataFromRemote = useCallback(async () => {
    try {
      const [projects, persons] = await Promise.all([getAllRootProjectWhereActivityUnsynced(), getPersonsWhereActivityUnsynced()]);

      if (!projects.error && !persons.error && isMounted.current) {
        dispatch({
          type: 'success_on_init',
          payload: {
            persons: [{ label: '', value: '' }, ...persons.map(x => transformPersonRowDTO(x))],
            projects: [{ label: '', value: '' }, ...projects.map(x => transformRootProjectRowDTO(x))]
          }
        });
      } else throw new Error(projects.error || persons.error || 'Valami hiba történt');
    } catch (error) {
      isMounted.current && dispatch({ type: 'error_on_init', error: String(error.message || 'Valami hiba történt') });
    }
  }, [isMounted]);

  const handleStartTime = evt => {
    if (evt.currentTarget.value) {
      const target = evt.currentTarget.value.split('-');
      dispatch({ type: 'setStartDate', startDate: DateTime.fromObject({ year: target[0], month: target[1], day: target[2] }) });
    } else {
      dispatch({ type: 'setStartDate', startDate: null });
    }
  };

  const handleEndTime = evt => {
    if (evt.currentTarget.value) {
      const target = evt.currentTarget.value.split('-');
      dispatch({ type: 'setEndDate', endDate: DateTime.fromObject({ year: target[0], month: target[1], day: target[2] }) });
    } else {
      dispatch({ type: 'setEndDate', endDate: null });
    }
  };

  const handleFilter = () => {
    context.close();
    onFilter(state.selectedPersonId, state.selectedProjectId, state.startDate, state.endDate, state.selectedStatusId);
  };

  useEffect(() => void getInitialDataFromRemote(), [getInitialDataFromRemote]);

  return (
    <form onSubmit={handleFilter}>
      <div tabIndex={0} className="drawer-header">
        Szűrési feltételek
      </div>

      <div className="side-sheet-body">
        {state.isLoading && <LinearProgress className="linear-progress" />}
        {state.error && !state.isLoading && <div className="basic-error-text">{state.error}</div>}

        <div className="form-field">
          <Select
            label="Dolgozó"
            value={state.selectedPersonId !== null ? state.selectedPersonId.toString() : ''}
            onChange={e => dispatch({ type: 'setSelectedPerons', id: e.currentTarget.value })}
            options={state.persons}
          />
        </div>

        <div className="form-field">
          <Select
            label="Projektek"
            value={state.selectedProjectId !== null ? state.selectedProjectId.toString() : ''}
            onChange={e => dispatch({ type: 'setSelectedProject', id: e.currentTarget.value })}
            options={state.projects}
          />
        </div>

        <Select
          label="Státuszok"
          value={state.selectedStatusId !== null ? state.selectedStatusId.toString() : ''}
          onChange={e => dispatch({ type: 'setSelectedStatus', id: e.currentTarget.value })}
          options={state.statuses}
        />

        <div className="time-group">
          <TextField label="Kezdés" max={state.endDate ? state.endDate.toFormat(DATEFORMAT) : undefined} type="date" onChange={handleStartTime} value={state.startDate ? state.startDate.toFormat(DATEFORMAT) : ' '} />

          <TextField label="Befejezés" type="date" onChange={handleEndTime} min={state.startDate ? state.startDate.toFormat(DATEFORMAT) : undefined} value={state.endDate ? state.endDate.toFormat(DATEFORMAT) : ' '} />
        </div>

        <Button type="submit" unelevated disabled={state.isLoading}>
          szűrés
        </Button>
      </div>
    </form>
  );
};

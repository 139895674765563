import React, { useContext, useMemo } from 'react';

/* Navigation Things */
import { RouteComponentProps } from '@reach/router';

/* Presentation Things */
import CircularProgress from '../../../../components/CircularProgress';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';

/* Data Things */
import { CustomersContext } from '../../../../components/Contexts/Customers';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { useIsMounted } from '../../../../hooks';
import { Customer } from '../helper';

/* Routes */
import { AddOrEdit } from '../AddOrEdit';

interface IProps extends RouteComponentProps {}

export const List = (_: IProps) => {
  // Context
  const context = useContext(CustomersContext);
  const { open } = useContext(SideSheetContext);

  //Hooks
  const _isMounted = useIsMounted();

  //Variables
  const customers = useMemo(() => Object.values(context.customers).sort((a, b) => a.companyName.localeCompare(b.companyName)), [context.customers]);

  const onSubmit = async (model: Customer) => {
    try {
      await context.onCreateCustomer({ companyName: model.companyName });
      return Promise.resolve('Ok');
    } catch (error) {
      _isMounted.current && context.onSetApiResponse({ type: 'error', message: String(error.message || error) });
      return Promise.reject(error);
    }
  };

  const onEdit = async (model: Customer) => {
    try {
      await context.onEditCustomer(model);

      return Promise.resolve('Ok');
    } catch (error) {
      _isMounted.current && context.onSetApiResponse({ type: 'error', message: String(error.message || error) });
      return Promise.reject(error);
    }
  };

  const onOpenAddSheet = () => open(<AddOrEdit onSubmit={onSubmit} />);

  return (
    <>
      {!context.isLoading && !context.error.length && (
        <div className="fab-menu-container">
          <div className="mdc-sheet-fab--container">
            <div className="mdc-sheet-fab">
              <div className="mdc-fab mdc-fab--mini" aria-label="Hozzáadás" onClick={onOpenAddSheet}>
                <i className="material-icons">add</i>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="data-table-title">
        <Typography className="title" use="body2">
          Vevők
        </Typography>
      </div>
      <div className="data-table">
        <div className="data-table__row data-table__row--title">
          <div className="data-table__cell">
            <Typography use="caption">Cégnév</Typography>
          </div>

          <div className="row-spacer-header__end" />
        </div>

        {context.isLoading ? (
          <CircularProgress />
        ) : context.error.length > 0 || customers.length < 1 ? (
          <div className="basic-error-text">{context.error || 'Nincsenek megjeleníthető vevők'}</div>
        ) : (
          <div className="data-table-body">
            {customers.map(customer => (
              <Row row={customer} key={customer.id} onEdit={onEdit} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

function Row({ row, onEdit }: { row: Customer; onEdit(model: Customer) }) {
  //Contexts
  const { open } = useContext(SideSheetContext);

  const onOpenEditSheet = () => open(<AddOrEdit customer={row} onSubmit={model => onEdit(model)} />);

  return (
    <div className="data-table__row">
      <div className="data-table__cell">{row.companyName}</div>

      <div className="data-table__cell--end">
        <Icon icon="keyboard_arrow_right" onClick={onOpenEditSheet} />
      </div>
    </div>
  );
}

import React, { useState } from 'react';

// Styles
import './PaletteSelector.scss';

interface Props {
  colors: string[];
  onSelectColor(color: string): void;
}

export const PaletteSelector = ({ colors, onSelectColor }: Props) => {
  const [selectedColorIndex, setSelectedColorIndex] = useState(0);

  const handleKeyPress = (ev: React.KeyboardEvent<HTMLTableDataCellElement>) => {
    switch (ev.keyCode) {
      //Right
      case 39:
        setSelectedColorIndex(index => Math.min(index + 1, colors.length - 1));
        break;

      //Left
      case 37:
        setSelectedColorIndex(index => Math.max(0, index - 1));
        break;

      //Enter
      case 13:
        handleColorSelect(ev);
        break;
    }
  };

  const handleColorSelect = ev => {
    const color = ev.target.attributes['aria-label'] ? ev.target.attributes['aria-label'].value : colors[selectedColorIndex];
    onSelectColor(color);
  };

  return (
    <div className="k-widget k-colorpalette" role="grid" aria-readonly="true" tabIndex={0} onKeyDown={handleKeyPress}>
      <table className="k-palette k-reset" role="presentation">
        <tbody>
          <tr role="row">
            {colors.map((color, i) => (
              <td
                onClick={handleColorSelect}
                key={color}
                role="gridcell"
                style={{ height: 10, width: 10, backgroundColor: color, border: selectedColorIndex === i ? '1px solid black' : '' }}
                className="k-item"
                aria-label={color}
              />
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

PaletteSelector.defaultProps = {
  colors: [
    '#000000',
    '#7f7f7f',
    '#880015',
    '#ed1c24',
    '#ff7f27',
    '#fff200',
    '#22b14c',
    '#00a2e8',
    '#3f48cc',
    '#a349a4',
    '#ffffff',
    '#c3c3c3',
    '#b97a57',
    '#ffaec9',
    '#ffc90e',
    '#efe4b0',
    '#b5e61d',
    '#99d9ea',
    '#7092be',
    '#c8bfe7'
  ]
};

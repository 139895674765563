import { useState, useCallback, useLayoutEffect } from 'react';

interface DimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
  scrollWidth: number;
}

type UseDimensionsHook = [(node: HTMLDivElement) => void, DimensionObject, HTMLDivElement, () => void];

interface UseDimensionsArgs {
  liveMeasure?: boolean;
}

function getDimensionObject(node: HTMLDivElement): DimensionObject {
  const rect: ClientRect | DOMRect = node.getBoundingClientRect();
  return {
    width: rect.width,
    height: rect.height,
    top: 'x' in rect ? rect.x : rect.top,
    left: 'y' in rect ? rect.y : rect.left,
    x: 'x' in rect ? rect.x : rect.left,
    y: 'y' in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom,
    scrollWidth: node.offsetWidth - node.clientWidth
  };
}

export function useDimensions({ liveMeasure = true }: UseDimensionsArgs = {}): UseDimensionsHook {
  const [dimensions, setDimensions] = useState<DimensionObject>({
    bottom: 0,
    left: 0,
    height: window.innerHeight,
    right: 0,
    top: 0,
    width: window.innerWidth,
    x: 0,
    y: 0,
    scrollWidth: 0
  });
  const [node, setNode] = useState(null);

  const ref = useCallback(node => setNode(node), []);

  const measure = useCallback(() => {
    window.requestAnimationFrame(() => {
      if (node) {
        setDimensions(getDimensionObject(node!));
      }
    });
  }, [node]);

  useLayoutEffect(() => {
    if (node) {
      measure();

      if (liveMeasure) {
        window.addEventListener('resize', measure);
        window.addEventListener('scroll', measure);

        return () => {
          window.removeEventListener('resize', measure);
          window.removeEventListener('scroll', measure);
        };
      }
    }
  }, [node, liveMeasure, measure]);

  return [ref, dimensions, node!, measure];
}

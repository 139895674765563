import { OUType, transformOUTypeDTO } from '../../../../routes/Settings/OUTypes/helper';
import { OrganizationUnit } from '../../../../routes/Organization/Structure/helper';
import { ApiResponseType } from '../../../../components/Portals/ResponseSnackbar';
import { OUTypeDTO } from '../../../lib/Settings/ouTypes';

export type OrganizationStructureAddOrEditState = {
  isLoading: boolean;
  generalError: string;
  employees: { name: string; id: number }[];
  ouTypes: OUType[];
  apiResponse: ApiResponseType;
  name: string;
  ouTypeId: number | null; //REQUIRED
  parentOUId: number | null;
  parentCompanyId: number | null; //REQUIRED
  managerPersonId: number | null;
  deputyManagerPersonId: number | null;
};

type OrganizationStructureAddOrEditAction =
  | { type: 'success_get_init'; payload: { ouTypes: OUTypeDTO[]; employees: { name: string | null; personId: number }[]; initCompanyId: number } }
  | { type: 'error_get_init'; payload: { error: string } }
  | { type: 'set_partial_state'; payload: Partial<OrganizationStructureAddOrEditState> };

export function init(editableOrganization?: OrganizationUnit): OrganizationStructureAddOrEditState {
  return {
    isLoading: true,
    generalError: '',
    ouTypes: [],
    employees: [],
    apiResponse: null,
    parentOUId: editableOrganization ? editableOrganization.parent.id : null,
    parentCompanyId: editableOrganization ? editableOrganization.parent.companyId : null,
    name: editableOrganization ? editableOrganization.name : '',
    ouTypeId: editableOrganization ? editableOrganization.typeId : null,
    managerPersonId: editableOrganization && editableOrganization.manager ? editableOrganization.manager.id : null,
    deputyManagerPersonId: editableOrganization && editableOrganization.deputyManager ? editableOrganization.deputyManager.id : null
  };
}

export function organiztaionStructureAddOrEditReducer(state: OrganizationStructureAddOrEditState, action: OrganizationStructureAddOrEditAction): OrganizationStructureAddOrEditState {
  switch (action.type) {
    case 'success_get_init': {
      return {
        ...state,
        ouTypes: action.payload.ouTypes.map(x => transformOUTypeDTO(x)),
        employees: action.payload.employees.map(x => ({ id: x.personId, name: x.name || '' })),
        isLoading: false,
        parentCompanyId: state.parentCompanyId === null ? action.payload.initCompanyId : state.parentCompanyId,
        generalError: ''
      };
    }

    case 'error_get_init': {
      return {
        ...state,
        isLoading: false,
        generalError: action.payload.error
      };
    }

    case 'set_partial_state': {
      return {
        ...state,
        ...action.payload
      };
    }

    default:
      return state;
  }
}

import React, { useContext, useState } from 'react';

/* Data Things */
import { AddOrEditJobRoleDTO, createJobRole, editJobRole } from '../../../../store/lib/MasterData/jobRoles';
import { JobRole, transformJobRoleDTO } from '../helper';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { useIsMounted } from '../../../../hooks/useIsMounted';

/* Presentation Things */
import ResponseSnackbar, { ApiResponseType } from '../../../../components/Portals/ResponseSnackbar';
import { TextField, TextFieldProps } from '@rmwc/textfield';
import { LinearProgress } from '@rmwc/linear-progress';
import { Button } from '@rmwc/button';

interface Props {
  editableJobRole?: JobRole;
  onSetJobRole(jobRole: JobRole): void;
}

export const JobRoleAddOrEdit = (props: Props) => {
  /* Variables */
  const isEdit = Boolean(props.editableJobRole);
  const updated = isEdit ? props.editableJobRole!.updated : null;
  const jobRoleId = isEdit ? props.editableJobRole!.jobRoleId : null;

  /* States */
  const [apiResponse, setApiResponse] = useState<ApiResponseType>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [feorCode, setFeorCode] = useState(isEdit ? props.editableJobRole!.feorCode : '');
  const [name, setName] = useState(isEdit ? props.editableJobRole!.name : '');

  /* Context */
  const { close } = useContext(SideSheetContext);

  /* Refs */
  const _isMounted = useIsMounted();

  const handleFeorCodeChange = (ev: React.ChangeEvent<TextFieldProps>) => setFeorCode(String(ev.currentTarget.value));

  const handleNameChange = (ev: React.ChangeEvent<TextFieldProps>) => setName(String(ev.currentTarget.value));

  const onSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();
    setIsLoading(true);
    try {
      const model: AddOrEditJobRoleDTO = { jobRoleId, feorCode, name, updated };
      const resp = isEdit ? await editJobRole(model) : await createJobRole(model);
      if (!resp.error && _isMounted.current) {
        props.onSetJobRole(transformJobRoleDTO(resp));
        setApiResponse({ type: 'success', message: `Sikeres új munkakör ${isEdit ? 'szerkesztés' : 'hozzáadás'}` });
        setTimeout(() => _isMounted.current && close(), 500);
      } else throw new Error(resp.error);
    } catch (error) {
      if (_isMounted.current) {
        setApiResponse({ type: 'error', message: String(error.message || 'Sajnáljuk, valami hiba történt a munkakör mentése során.') });
      }
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  return (
    <>
      <ResponseSnackbar response={apiResponse} onClose={() => setApiResponse(null)} />

      <div className="drawer-header">{isEdit ? 'Munkakör szerkesztése' : 'Új munkakör'}</div>
      {isLoading && <LinearProgress />}

      <div className="form-content">
        <form onSubmit={onSubmit}>
          <div className="form-content-row">
            <TextField required maxLength={4} minLength={4} type="number" label="FEOR kód" value={feorCode} onChange={handleFeorCodeChange} autoFocus />
          </div>

          <div className="form-content-row">
            <TextField required maxLength={100} type="text" label="Megnevezés" value={name} onChange={handleNameChange} />
          </div>

          <Button unelevated label={isEdit ? 'Szerkesztés' : 'Hozzáadás'} type="submit" />
        </form>
      </div>
    </>
  );
};

JobRoleAddOrEdit.defaultProps = {
  editableJobRole: undefined
};

import React from 'react';

/* Navigation Things */
import { RouteComponentProps, Router } from '@reach/router';

/* Data Things */
import { ProjectsProvider } from '../../../components/Contexts/Projects';

/*Routes  */
import { ProjectDetails } from './ProjectDetails';
import { ProjectList } from './ProjectList';

// Styles
import './Projects.scss';

interface IProps extends RouteComponentProps {}

export function Projects(props: IProps) {
  return (
    <ProjectsProvider>
      <Router basepath="TrackTime/Projects" primary={false}>
        <ProjectDetails path="/Edit/:id" />
        <ProjectDetails path="/Create" />
        <ProjectList default />
      </Router>
    </ProjectsProvider>
  );
}

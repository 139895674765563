import { ICreateOrderDTO, IEditOrderDTO, IOrderDTO, IOrderListDTO, ISelectableOrdersFilterDTO, ISelectableOrderDTO, ISelectableRootProductDTO, ISelectableTaskItemDTO } from '../../../typings/DTOs';
import { api } from '../../../utils/api';
import { stringify } from 'qs';

export const getOrders = (
  search: string,
  isActive: boolean,
  offset: number,
  limit: number,
  orderNo: string,
  customerIds: number[],
  projectIds: number[],
  productItemIds: number[],
  sortBy: number,
  sortByDesc: boolean,
  signal: AbortSignal
) => {
  const params = {
    search: search,
    isActive: String(isActive),
    offset: offset.toString(),
    limit: limit.toString(),
    orderNo: orderNo,
    customerIds: customerIds,
    projectIds: projectIds,
    productItemIds: productItemIds,
    sortBy: sortBy,
    sortByDesc: sortByDesc
  };
  const stringifyQuery = stringify(params, { arrayFormat: '', encode: false });
  return api<IOrderListDTO>('GET', `orders?${stringifyQuery}`, undefined, signal);
};

export const getOrder = (id: number) => api<IOrderDTO>('GET', `orders/${id}`);

export const createOrder = (model: ICreateOrderDTO) => api<IOrderDTO>('POST', 'orders', model);

export const editOrder = (model: IEditOrderDTO) => api<IOrderDTO>('PUT', `orders/${model.id}`, model);

export const getOrderNumbers = () => api<ISelectableOrderDTO[]>('GET', 'orders/numbers');

export const getSubProducts = (id: string) => api<ISelectableRootProductDTO[]>('GET', `orders/${id}/products`);

export const getTaskItem = (id: string) => api<ISelectableTaskItemDTO[]>('GET', `products/${id}/tasks`);

export const getOrdersCustomers = () => api<ISelectableOrdersFilterDTO[]>('GET', 'orders/customers');

export const getOrdersProducts = () => api<ISelectableOrdersFilterDTO[]>('GET', 'orders/products');

export const getOrdersProjects = () => api<ISelectableOrdersFilterDTO[]>('GET', 'orders/projects');

export enum OUMemberTypes {
  Member,
  Manager,
  DeputyManager
}

export enum EmploymentTypes {
  Employee = 41,
  PersonContractor = 42
}

export enum PersonTypes {
  Employee = 11,
  PersonContractor,
  Candidate
}

export enum ProHR365FileTypes {
  Image = 71,
  Document
}

export enum MaritalStatuses {
  Unmarried = 61,
  Married,
  Divorced,
  Widow,
  Relationship
}

export enum SexTypes {
  Male = 2,
  Female
}

export const SEX_TYPES_MAP = {
  [SexTypes.Male]: 'Férfi',
  [SexTypes.Female]: 'Nő'
};

export const MARITAL_STATUSES_MAP = {
  [MaritalStatuses.Unmarried]: 'Egyedülálló',
  [MaritalStatuses.Married]: 'Házas',
  [MaritalStatuses.Divorced]: 'Elvált',
  [MaritalStatuses.Widow]: 'Özvegy',
  [MaritalStatuses.Relationship]: 'Kapcsolatban'
};

export const OU_MEMBER_TYPE_MAP = {
  [OUMemberTypes.Member]: 'Tag',
  [OUMemberTypes.DeputyManager]: 'Vezető helyettes',
  [OUMemberTypes.Manager]: 'Vezető'
};

export enum GlobalDayStatusTypes {
  Workday = 0,
  NonWorkday,
  Restday
}

export enum AccessTypes {
  Self = 21,
  OU = 22,
  All = 23
}

export enum ProductItemQuantityUnit {
  Amount = 1,
  Nm2 = 2,
  Set = 3
}

export enum ProductionSiteTypes {
  BC = 1,
  Duna = 2
}

export const PRODUCTION_SITE_MAP: Record<ProductionSiteTypes, string> = {
  [ProductionSiteTypes.BC]: 'BC',
  [ProductionSiteTypes.Duna]: 'Duna'
};

export enum PaintStatusTypes {
  Factory = 1,
  InStock = 2,
  OnTheWay = 3
}

export const PAINT_STATUS_MAP: Record<PaintStatusTypes, string> = {
  [PaintStatusTypes.Factory]: 'Üzemben',
  [PaintStatusTypes.InStock]: 'Raktáron',
  [PaintStatusTypes.OnTheWay]: 'Hozza'
};

export enum OrderStatusTypes {
  Customer = 1,
  Factory = 2,
  Invoiced = 3,
  Deleted = 4,
  Done = 5,
  PartiallyInvoiced = 6,
  PartiallyDone = 7,
  Reported = 8,
  Returned = 9
}

export const ORDER_STATUS_MAP: Record<OrderStatusTypes, string> = {
  [OrderStatusTypes.Customer]: 'Megrendelő',
  [OrderStatusTypes.Factory]: 'Üzemben',
  [OrderStatusTypes.Invoiced]: 'Számlázva',
  [OrderStatusTypes.Deleted]: 'Törölve',
  [OrderStatusTypes.Done]: 'Elkészült',
  [OrderStatusTypes.PartiallyInvoiced]: 'Részben számlázva',
  [OrderStatusTypes.PartiallyDone]: 'Részben kész',
  [OrderStatusTypes.Reported]: 'Kijelentve',
  [OrderStatusTypes.Returned]: 'Visszaküldve'
};

export enum InventoryStatusTypes {
  Raw = 1,
  InProgress = 2,
  Finished = 3
}

export const INVENTORY_STATUS_MAP: Record<InventoryStatusTypes, string> = {
  [InventoryStatusTypes.Finished]: 'Befejezve',
  [InventoryStatusTypes.InProgress]: 'Folyamatban',
  [InventoryStatusTypes.Raw]: 'Kezeletlen'
};

export const QUANTITY_UNIT_MAP: Record<ProductItemQuantityUnit, string> = {
  [ProductItemQuantityUnit.Amount]: 'db',
  [ProductItemQuantityUnit.Nm2]: 'nm2',
  [ProductItemQuantityUnit.Set]: 'szett'
};

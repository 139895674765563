import { api } from '../../../utils/api';
import { ConcurrentEditViewModel } from '../Organization/Peoples/employment';

//=============================GET JOB ROLES==================================
export interface JobRoleDTO extends ConcurrentEditViewModel {
  jobRoleId: number;
  name: string | null;
  feorCode: string | null;
}

export const getJobRoles = () => api<JobRoleDTO[]>('GET', 'jobRoles');

//=============================ADD OR EDIT JOB ROLE==================================
export interface AddOrEditJobRoleDTO extends Omit<JobRoleDTO, 'jobRoleId'> {
  jobRoleId: number | null;
}

export const createJobRole = (model: AddOrEditJobRoleDTO) =>
  api<JobRoleDTO>('POST', 'jobRoles', {
    ...model,
    requiredCompetences: null,
    nonRequiredCompetences: null
  });

export const editJobRole = (model: AddOrEditJobRoleDTO) =>
  api<JobRoleDTO>('PUT', 'jobRoles', {
    ...model,
    requiredCompetences: null,
    nonRequiredCompetences: null
  });

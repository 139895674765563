export interface PaginatedList<T> {
  items: T[];
  totalLength: number;
  offset: number;
  limit: number;
}
export class Project {
  public id: number;
  public code: string;
  public name: string;
  public colorCode: string;
  public rootProject: Project | null;

  constructor(id: number, code: string | null, name: string | null, colorCode: string | null, rootProject: Project | null) {
    this.id = id;
    this.code = code || '';
    this.name = name || '';
    this.colorCode = colorCode || '';
    this.rootProject = rootProject;
  }
}

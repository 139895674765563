import React, { useState, useCallback, useEffect, memo } from 'react';

/* Data Things */
import { ValidationCheckMonth, transofrmValidationCheckMonthDTO, isVisibleCheckRow, SelectedCell } from './helper';
import { getValidationsForMonth, ValidationCheckMonthRowDTO } from '../../../../store/lib/TrackTime/monthlyClose';
import { useIsMounted } from '../../../../hooks';
import { formatName } from '../../../../constants/helperFuntions';

/* Presentation Things */
import CircularProgress from '../../../../components/CircularProgress';
import Collapsible from 'react-collapsible';
import { Typography } from '@rmwc/typography';
import { InnerTable } from './InnerTable';
import { ShowImage } from '../../../../components/Images/ShowImage';
import { Switch } from '@rmwc/switch';
import { Icon } from '@rmwc/icon';

export const CheckTable = memo(
  ({ actual, onClose, abortSignal }: any) => {
    /* States */
    const [rows, setRows] = useState<ValidationCheckMonth['validationCheckMonthRows'] | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [filters, setFilters] = useState({
      notClosedActivityId: false,
      pendingLeaveRequestRowIds: false,
      pendingOvertimeRequestRowIds: false,
      daysWithoutStatus: false,
      activityIdsWithoutProject: false,
      activityIdsMoreThanEightWorkingHours: false,
      overlappedActivityIds: false
    });

    /* Variables */
    const visibleRows = rows ? rows.filter(x => x.isVisible) : [];

    /* Ref */
    const _isMounted = useIsMounted();

    const getRows = useCallback(async () => {
      setRows([]);
      setIsLoading(true);
      setError('');
      try {
        const model = await getValidationsForMonth(actual.year, actual.month, abortSignal);
        if (!model.error && _isMounted.current) {
          setRows(transofrmValidationCheckMonthDTO(model));
        } else throw new Error(model.error);
      } catch (error) {
        _isMounted.current && setError(String(error.error || error.message));
      } finally {
        _isMounted.current && setIsLoading(false);
      }
    }, [_isMounted, abortSignal, actual]);

    const onFilter = (ev: React.ChangeEvent<HTMLInputElement>) => {
      ev.persist();
      setFilters(prev => {
        if (ev && ev.target && ev.target.name) {
          return { ...prev, [ev.target.name]: !ev.target.checked };
        }
        return prev;
      });
    };

    useEffect(() => setRows(row => (row ? row.map(x => ({ ...x, isVisible: isVisibleCheckRow(x, filters) })) : null)), [filters]);

    useEffect(() => void getRows(), [getRows]);

    return (
      <div className="check-table">
        {isLoading ? (
          <CircularProgress />
        ) : error.length ? (
          <p>{error}</p>
        ) : (
          rows && (
            <>
              <h2 className="sub-title mdc-list-group__subheader mdc-typography--title header-with-icon sticky">
                Ellenőrzés eredménye
                <Icon onClick={onClose} icon="close" />
              </h2>

              <div className="data-table">
                {rows.length > 0 && (
                  <div className="data-table__row data-table__row--title">
                    <div className="flex-box-item data-table__cell person">
                      <Typography use="caption">Dolgozó</Typography>
                    </div>

                    <div className="flex-box-item data-table__cell not-closed-activity">
                      <Typography use="caption">Lezáratlan aktivitás</Typography>
                      <Switch name="notClosedActivityId" checked={!filters.notClosedActivityId} onChange={onFilter} />
                    </div>

                    <div className="flex-box-item data-table__cell pending-leave-request">
                      <Typography use="caption">Függő távollét</Typography>
                      <Switch name="pendingLeaveRequestRowIds" checked={!filters.pendingLeaveRequestRowIds} onChange={onFilter} />
                    </div>

                    <div className="flex-box-item data-table__cell pending-overtime-request">
                      <Typography use="caption">Függő túlóra</Typography>
                      <Switch name="pendingOvertimeRequestRowIds" checked={!filters.pendingOvertimeRequestRowIds} onChange={onFilter} />
                    </div>

                    <div className="flex-box-item data-table__cell days-without-status">
                      <Typography use="caption">Státusz nélküli napok</Typography>

                      <Switch name="daysWithoutStatus" checked={!filters.daysWithoutStatus} onChange={onFilter} />
                    </div>

                    <div className="flex-box-item data-table__cell activity-without-project">
                      <Typography use="caption">Projekt nélküli</Typography>

                      <Switch name="activityIdsWithoutProject" checked={!filters.activityIdsWithoutProject} onChange={onFilter} />
                    </div>

                    <div className="flex-box-item data-table__cell activity-more-than-eight-working-hours">
                      <Typography use="caption">8 órát meghaladó</Typography>

                      <Switch name="activityIdsMoreThanEightWorkingHours" checked={!filters.activityIdsMoreThanEightWorkingHours} onChange={onFilter} />
                    </div>

                    <div className="flex-box-item data-table__cell overlapped-activity">
                      <Typography use="caption">Átfedések</Typography>

                      <Switch name="overlappedActivityIds" checked={!filters.overlappedActivityIds} onChange={onFilter} />
                    </div>
                  </div>
                )}

                <div className="data-table-body">
                  {visibleRows.map(row => (
                    <Row key={row.person.id} row={row} />
                  ))}

                  {!rows.length ? (
                    <div className="data-table__row not-found-data">Nincs megjeleníthető adat</div>
                  ) : (
                    !visibleRows.length && <div className="data-table__row not-found-data">Nincs a szűrési feltételnek megfelelő adat.</div>
                  )}

                  {rows.length > 0 && <span className="data-table__row not-found-data align-left">{`${visibleRows.length} megjelenítve a ${rows.length} dolgozóból`}</span>}
                </div>
              </div>
            </>
          )
        )}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.actual === nextProps.actual
);

const Row = memo(({ row }: { row: ValidationCheckMonthRowDTO }) => {
  /* States */
  const [selectedCell, setSelectedCell] = useState(0);
  const [isOpening, setIsOpening] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  /* Variables */
  const fullName = formatName(row.person.firstName, row.person.lastName, row.person.middleName, row.person.courtesyTitle);

  const onSelect = (cell: SelectedCell) => {
    if (!isOpening) {
      setSelectedCell(cell);
      setIsOpen(selectedCell !== cell);
    }
  };

  const onOpening = () => setIsOpening(true);

  const onClosing = () => setIsOpen(false);

  const onClose = () => setSelectedCell(0);

  const onOpen = () => setIsOpening(false);

  return (
    <>
      <div className="data-table__row">
        <div className="flex-box-item data-table__cell person">
          <ShowImage fullName={fullName} pictureURL={row.person.photoUrl}>
            <Typography use="body1">{fullName}</Typography>
          </ShowImage>
        </div>

        <div className={`flex-box-item data-table__cell not-closed-activity ${row.errors.notClosedActivityId ? 'error' : 'disabled'} `}>
          <Typography use="body1">{row.errors.notClosedActivityId ? 1 : 0}</Typography>
        </div>

        <div
          className={`flex-box-item data-table__cell pending-leave-request ${row.errors.pendingLeaveRequestRowIds.length >= 1 ? 'error' : 'disabled'} ${
            selectedCell === SelectedCell.PendingLeaveRequest ? 'active' : ''
          }`}>
          <Typography use="body1">{row.errors.pendingLeaveRequestRowIds.length}</Typography>

          {row.errors.pendingLeaveRequestRowIds.length > 0 && <Icon icon="keyboard_arrow_down" onClick={() => onSelect(SelectedCell.PendingLeaveRequest)} />}
        </div>

        <div
          className={`flex-box-item data-table__cell pending-overtime-request ${row.errors.pendingOvertimeRequestRowIds.length >= 1 ? 'error' : 'disabled'} ${
            selectedCell === SelectedCell.PendingOvertimeRequest ? 'active' : ''
          }`}>
          <Typography use="body1">{row.errors.pendingOvertimeRequestRowIds.length}</Typography>
          {row.errors.pendingOvertimeRequestRowIds.length > 0 && <Icon icon="keyboard_arrow_down" onClick={() => onSelect(SelectedCell.PendingOvertimeRequest)} />}
        </div>

        <div className={`flex-box-item data-table__cell days-without-status ${row.warnings.daysWithoutStatus ? 'warning' : 'disabled'}`}>
          <Typography use="body1">{row.warnings.daysWithoutStatus}</Typography>
        </div>

        <div
          className={`flex-box-item data-table__cell activity-without-project ${row.warnings.activityIdsWithoutProject.length >= 1 ? 'warning' : 'disabled'} ${
            selectedCell === SelectedCell.ActivityWithoutProject ? ' active' : ''
          }`}>
          <Typography use="body1">{row.warnings.activityIdsWithoutProject.length}</Typography>
          {row.warnings.activityIdsWithoutProject.length > 0 && <Icon icon="keyboard_arrow_down" onClick={() => onSelect(SelectedCell.ActivityWithoutProject)} />}
        </div>

        <div
          className={`flex-box-item data-table__cell activity-more-than-eight-working-hours ${row.warnings.activityIdsMoreThanEightWorkingHours.length >= 1 ? 'warning' : 'disabled'} ${
            selectedCell === SelectedCell.ActivityMoreThanEightWorkingHours ? ' active' : ''
          } `}>
          <Typography use="body1">{row.warnings.activityIdsMoreThanEightWorkingHours.length}</Typography>
          {row.warnings.activityIdsMoreThanEightWorkingHours.length > 0 && <Icon icon="keyboard_arrow_down" onClick={() => onSelect(SelectedCell.ActivityMoreThanEightWorkingHours)} />}
        </div>

        <div
          className={`flex-box-item data-table__cell overlapped-activity ${row.warnings.overlappedActivityIds.length >= 1 ? 'warning' : 'disabled'} ${selectedCell === SelectedCell.OverlappedActivity ? 'active' : ''}`}>
          <Typography use="body1">{row.warnings.overlappedActivityIds.length}</Typography>
          {row.warnings.overlappedActivityIds.length > 0 && <Icon icon="keyboard_arrow_down" onClick={() => onSelect(SelectedCell.OverlappedActivity)} />}
        </div>
      </div>

      <Collapsible
        open={isOpen}
        triggerDisabled={selectedCell === 0 || isOpening}
        onOpening={onOpening}
        onClosing={onClosing}
        onClose={onClose}
        onOpen={onOpen}
        trigger=""
        contentInnerClassName="data-table InnerTable ActivityList">
        <InnerTable selectedCell={selectedCell} row={row} />
      </Collapsible>
    </>
  );
});

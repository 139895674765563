/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';

/* Presentation Things */
import { TabBar, Tab, TabBarOnActivateEventT } from '@rmwc/tabs';
import { EmploymentPanel } from '../../../../components/People/ProfileDetails/Employment';
import { LinearProgress } from '@rmwc/linear-progress';
import { ProfilePanel } from '../../../../components/People/ProfileDetails/PeopleDetails';
import { Typography } from '@rmwc/typography';
import { ShowImage } from '../../../../components/Images/ShowImage';
import { Icon } from '@rmwc/icon';

/* Data Things */
import { PersonDetailsEmployment, transformEmploymentDetails } from '../../../../components/People/ProfileDetails/Employment/helper';
import { transformPersonDetails, PersonDetails } from '../../../../components/People/ProfileDetails/PeopleDetails/helper';
import { Link, RouteComponentProps } from '@reach/router';
import { getEmploymentHistory } from '../../../../store/lib/Organization/Peoples/employment';
import { generateWindowTitle } from '../../../../constants/helperFuntions';
import { PeopleContext } from '../../../../components/Contexts/People';
import { useIsMounted } from '../../../../hooks/useIsMounted';
import { getDetails } from '../../../../store/lib/Organization/Peoples/peopleDetails';
import { IEmployee } from '../PeopleList/helper';

/* Style */
import './PeopleDetails.scss';

enum ActiveDetailsTab {
  ProfilePanel = 0,
  EmploymentPanel,
  CompetencePanel,
  PersonTimePanel
}

interface IProps extends RouteComponentProps<{ id: string }> {}

export const ProfileDetails = (props: IProps) => {
  const { onSetPartialPeople, people } = useContext(PeopleContext);
  const _isMounted = useIsMounted();
  const personId = Number(props.id);
  const selectedPeople: IEmployee | undefined = people[personId];

  /* States */
  const [activeTab, setActiveTab] = useState(ActiveDetailsTab.ProfilePanel);
  const [isLoading, setIsLoading] = useState(!(selectedPeople && selectedPeople.employment));
  const [error, setError] = useState('');

  useEffect(() => {
    if (personId !== undefined) {
      getDetailsAndEmploymentFromRemote(personId);
    }
  }, [personId]);

  const getDetailsAndEmploymentFromRemote = async (personId: number) => {
    try {
      //[TODO Jácint] after typescript update, delete the ts-ignore.
      //@ts-ignore
      const [details, employment] = await Promise.allSettled([getDetails(personId), getEmploymentHistory(personId)]);

      if (details.status === 'rejected') throw new Error(details.reason ? details.reason.message : '');
      if (employment.status === 'rejected') throw new Error(employment.reason ? employment.reason.message : '');

      if (_isMounted.current) {
        onSetPartialPeople({
          personId,
          employment: employment.status === 'fulfilled' ? transformEmploymentDetails(employment.value) : null,
          details: details.status === 'fulfilled' ? transformPersonDetails(details.value) : undefined
        });
      }
    } catch (error) {
      if (!selectedPeople || (selectedPeople && (!selectedPeople.employment || !selectedPeople.details))) {
        _isMounted.current && setError(error.message || 'Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
      }
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const onEditEmploymentModel = (p: PersonDetailsEmployment, updated: string) => {
    onSetPartialPeople({ personId, details: { ...selectedPeople.details, updated }, employment: p });
  };

  const onActiveTabChange = (evt: TabBarOnActivateEventT) => setActiveTab(evt.detail.index);

  const onEditPartialDetailsModel = (p: Partial<PersonDetails>) => onSetPartialPeople({ personId, details: { ...selectedPeople.details, ...p } });

  const renderPanel = () => {
    if (personId !== undefined && selectedPeople && !isLoading) {
      const { details, employment } = selectedPeople;

      switch (activeTab) {
        case ActiveDetailsTab.ProfilePanel:
          return <ProfilePanel {...{ error, personId, onEditPartialDetailsModel, detailsModel: details }} />;
        case ActiveDetailsTab.EmploymentPanel:
          return <EmploymentPanel {...{ error, personId, onEditEmploymentModel, updated: details.updated || null, employmentModel: employment }} />;
      }
    }
    return null;
  };

  return (
    <div className="People">
      <div className="employment-short-details-panel">{selectedPeople && selectedPeople.details && <LeftSideSheet people={selectedPeople} />}</div>

      <div className="employment-content">
        <TabBar activeTabIndex={activeTab} onActivate={onActiveTabChange}>
          <Tab>Profiladatok</Tab>
          <Tab>Foglalkoztatás</Tab>
        </TabBar>
        {isLoading && <LinearProgress />}

        <div className="panels">{renderPanel()}</div>
      </div>
    </div>
  );
};

function LeftSideSheet({ people }: { people: IEmployee }) {
  const fullName = [people.details.lastName, people.details.firstName, people.details.middleName].filter(Boolean).join(' ');
  const lastContract = people.employment ? people.employment.lastEmployment.employmentContracts[0] : undefined;
  const jobRoles = lastContract ? lastContract.jobRoles.map(y => y.jobRoleName).join(' ') : '';
  const ous = people.employment ? people.employment.lastEmployment.oUs : [];

  useEffect(() => void (document.title = generateWindowTitle(fullName)), [fullName]);

  return (
    <>
      <div className="employe-wrap">
        <Link to="../../">
          <Icon className="back-icon" icon="keyboard_arrow_left" />
        </Link>

        <ShowImage wrapperClassName="avatar-wrap big-avatar" pictureURL={people.details.pictureUrl} initials={(people.details.lastName || '').charAt(0) + (people.details.firstName || '').charAt(0)}>
          {people.details.basicInfo && people.details.basicInfo.isPrivate && <Icon className="private-icon" icon="lock" />}
        </ShowImage>

        <Typography use="headline6" className="color-blue">
          {fullName}
        </Typography>

        <Typography className="job-role" use="subtitle1">
          {jobRoles}
        </Typography>
      </div>

      <div className="employe-details">
        {ous.length > 0 && (
          <div className="details-group">
            <div className="details-group-header">
              <Typography use="subtitle2" className="color-blue">
                Szervezeti egység
              </Typography>

              <Icon icon="arrow_drop_down" />
            </div>

            {ous.map(ou => (
              <div key={ou.ouId} className="details-group-row">
                <span className="details-group-row__text">{ou.ouName}</span>
              </div>
            ))}
          </div>
        )}

        <div className="details-group">
          <div className="details-group-header">
            <Typography use="subtitle2" className="color-blue">
              Elérhetőségek
            </Typography>

            <Icon icon="arrow_drop_down" />
          </div>

          {people.details.email && (
            <div className="details-group-row">
              <span className="details-group-row__text">
                <a href={`mailto:${people.details.email}`}>{people.details.email}</a>
              </span>
            </div>
          )}

          {people.details.phoneNumber && (
            <div className="details-group-row">
              <a href={`tel:${people.details.phoneNumber}`}>
                <span className="details-group-row__text">{people.details.phoneNumber}</span>
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

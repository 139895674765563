import React, { useCallback, useEffect, useMemo, useState } from 'react';

/* Data Things */
import { getRootProjects, getSubProjects, getProject } from '../../../store/lib/TrackTime/projects';
import { flatSubprojects, ProjectWithSubproject } from './helper';
import { useIsMounted } from '../../../hooks';
import { Project } from '../../../typings/common';

/* Presentation Thins */
import CircularProgress from '../../CircularProgress';
import { MenuSurface, MenuSurfaceAnchor } from '@rmwc/menu';
import { ProjectDisplay } from '../../Common/ProjectDisplay';
import { TextField } from '@rmwc/textfield';
import { Icon } from '@rmwc/icon';

/* Styles */
import './styles.scss';

type Props = {
  onSelect(project: Project): void;
  selectedProject?: number;
  required?: boolean;
};

type RootProjectsListProps = {
  projects: ProjectWithSubproject[];
  onSelect(project: ProjectWithSubproject): void;
};

type SubProjectsListProps = {
  selectedProject: ProjectWithSubproject;
  projects: ProjectWithSubproject[];
  onSelect(project: ProjectWithSubproject): void;
  onBack(): void;
};

export const ProjectSelector = (props: Props) => {
  /* States */
  const [selectedProject, setSelectedProject] = useState<ProjectWithSubproject | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState<ProjectWithSubproject[]>([]);
  const [isOpened, setIsOpened] = useState(false);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetRootProjects = useCallback(async () => {
    try {
      const resp = await getRootProjects();

      if (!resp.error && _isMounted.current) {
        setProjects(resp.map(x => new ProjectWithSubproject(x)).sort((a, b) => a.name.localeCompare(b.name)));
      } else throw new Error(resp.error);
    } catch (error) {
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  }, [_isMounted]);

  const onGetSubProjects = useCallback(async () => {
    if (selectedProject && selectedProject.subprojects !== null) {
      setIsLoading(true);

      try {
        const resp = await getSubProjects(selectedProject.id);

        if (!resp.error && _isMounted.current) {
          setProjects(prev =>
            prev.map(x => {
              if (x.id === selectedProject.id) {
                x.subprojects = flatSubprojects(x, resp, []);
              }
              return x;
            })
          );
        } else throw new Error(resp.error);
      } catch (error) {
      } finally {
        _isMounted.current && setIsLoading(false);
      }
    }
  }, [_isMounted, selectedProject]);

  const onProjectSelect = (project: ProjectWithSubproject) => {
    setSelectedProject(project);

    if (!project.subprojects) {
      props.onSelect(project);
      onClose();
    }
  };

  const onClose = () => setIsOpened(false);

  const onBack = () => setSelectedProject(null);

  const onOpen = () => setIsOpened(true);

  useEffect(() => {
    (async () => {
      if (props.selectedProject) {
        try {
          const selected = await getProject(props.selectedProject);
          if (!selected.error) {
            setSelectedProject(new ProjectWithSubproject(selected, null));
          } else throw new Error(selected.error);
        } catch (error) {}
      }
    })();
  }, [props.selectedProject]);

  useEffect(() => void onGetRootProjects(), [onGetRootProjects]);

  useEffect(() => void onGetSubProjects(), [onGetSubProjects]);

  return (
    <MenuSurfaceAnchor className="project-selector">
      <MenuSurface open={isOpened} onClose={onClose} hoistToBody anchorCorner="bottomLeft" className="order-menu-surface">
        {isLoading ? (
          <CircularProgress />
        ) : selectedProject && selectedProject.subprojects ? (
          <SubProjectsList onBack={onBack} onSelect={onProjectSelect} projects={selectedProject.subprojects} selectedProject={selectedProject} />
        ) : (
          <RootProjectsList onSelect={onProjectSelect} projects={projects} />
        )}
      </MenuSurface>

      <TextField
        label="Projekt"
        required={props.required}
        onClick={onOpen}
        onChange={onOpen}
        value={selectedProject && !selectedProject.subprojects ? selectedProject.name : undefined}
        trailingIcon={{ icon: 'expand_more', tabIndex: 0, onClick: onOpen }}
      />
    </MenuSurfaceAnchor>
  );
};

const RootProjectsList = ({ projects, onSelect }: RootProjectsListProps) => {
  return (
    <ul className="project">
      {projects.map(project => (
        <Row key={project.id} project={project} onSelect={onSelect} disabled={(!project.subprojects && !project.isTimeRegisterable) || !project.isActive} />
      ))}
    </ul>
  );
};

const SubProjectsList = ({ projects, selectedProject, onSelect, onBack }: SubProjectsListProps) => {
  const rootProject = useMemo(() => ({ ...selectedProject, subprojects: null }), [selectedProject]);

  return (
    <ul className="project">
      <li onClick={onBack} role="button" className="row back">
        <Icon icon="chevron_left" /> <span>Vissza</span>
      </li>

      <Row project={rootProject} onSelect={onSelect} disabled={!rootProject.isTimeRegisterable || !rootProject.isActive} />

      {projects.map(project => (
        <Row key={project.id} project={project} onSelect={onSelect} disabled={!project.isTimeRegisterable || !project.isActive} />
      ))}
    </ul>
  );
};

const Row = ({ project, onSelect, disabled }: { project: ProjectWithSubproject; disabled?: boolean; onSelect(project: ProjectWithSubproject): void }) => {
  const handleSelect = () => !disabled && onSelect(project);

  return (
    <li onClick={handleSelect} className={`row${disabled ? ' disabled' : ''}`}>
      <ProjectDisplay project={project} showRootProject={false} />
      {project.subprojects && <Icon icon="arrow_right" />}
    </li>
  );
};

import React, { useState, useContext, useMemo, useEffect } from 'react';

/* Data Things */
import { APPLICATION_USERS_ROW_HEIGHT } from '../../../../constants/constants';
import { Link, RouteComponentProps } from '@reach/router';
import { ApplicationUsersContext } from '../../../../components/Contexts/ApplicationUsers';
import { useOuterClick } from '../../../../hooks/useOuterClick';
import { formatName } from '../../../../constants/helperFuntions';
import { IUserRow } from '../helper';

/* Presentation Things */
import CircularProgress from '../../../../components/CircularProgress';
import { WindowScroller, List, ListRowProps, AutoSizer } from 'react-virtualized';
import { Typography } from '@rmwc/typography';
import { Checkbox } from '@rmwc/checkbox';
import { Icon } from '@rmwc/icon';

interface Props extends RouteComponentProps {}

export const ApplicationUsersList = (_: Props) => {
  const { onSearchInUsers, ...context } = useContext(ApplicationUsersContext);
  const users = useMemo(() => Object.values(context.users).filter(x => x.isVisible), [context.users]);

  //States
  const [isOpenAddSheet, setIsOpenAddSheet] = useState(false);
  const [searchText, setSearchText] = useState('');

  //Refs
  const _fabRef = useOuterClick(() => setIsOpenAddSheet(false));

  const onOpenAddSheet = () => setIsOpenAddSheet(true);

  const onClearSearch = () => setSearchText('');

  const onSearch = (ev: React.ChangeEvent<HTMLInputElement>) => setSearchText(String(ev.currentTarget.value));

  useEffect(() => void onSearchInUsers(searchText), [searchText, onSearchInUsers]);

  const rowRenderer = ({ index, style, isScrolling, isVisible, key }: ListRowProps) => {
    const row = users[index];
    const isPlaceholder = isScrolling && !isVisible;

    return (
      <div style={style} key={key}>
        <Row row={row} isPlaceholder={isPlaceholder} />
      </div>
    );
  };

  return (
    <>
      <div className="data-table-title">
        <Typography className="title" use="body2">
          Felhasználók
        </Typography>
      </div>

      {!context.isLoading && !context.error.length && (
        <div className="fab-menu-container">
          <div className={`mdc-sheet-fab--container ${isOpenAddSheet ? 'mdc-sheet-fab--container-animate' : ''}`} ref={_fabRef}>
            <div className="mdc-sheet-fab">
              <div className="mdc-fab mdc-fab--mini" aria-label="Add" onClick={onOpenAddSheet}>
                <i className="material-icons">add</i>
              </div>

              <ul className="mdc-list" role="menu" aria-hidden="true">
                <li className="mdc-list-item" role="menuitem">
                  <Link className="mdc-menu--link" to="/Settings/ApplicationUsers/Create">
                    Új létrehozása
                  </Link>
                </li>

                <li className="mdc-list-item" role="menuitem">
                  AD Import
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <div className={`search-box ${(context.isLoading || context.error.length > 0) && 'disabled'}`}>
        <span className="search-box--start-detail">
          <span className="material-icons">search</span>
        </span>

        <input className="search-box--input" type="text" placeholder="Keresés" onChange={onSearch} value={searchText} />

        <span className="search-box--start-detail" onClick={onClearSearch}>
          <span className="material-icons">close</span>
        </span>
      </div>

      <div className="data-table users-list">
        <div className="data-table__row data-table__row--title">
          <div className="data-table__cell person">
            <Typography use="caption">Felhasználó név</Typography>
          </div>

          <div className="data-table__cell">
            <Typography use="caption">Email</Typography>
          </div>

          <div className="data-table__cell">
            <Typography use="caption">Profil</Typography>
          </div>

          <div className="data-table__cell active">
            <Typography use="caption">Aktív</Typography>
          </div>

          <div className="row-spacer-header__end" />
        </div>

        <div className="data-table-body">
          {context.isLoading ? (
            <CircularProgress />
          ) : context.error.length > 0 || users.length < 1 ? (
            <div className="basic-error-text">{context.error || 'Nincs megjeleníthető felhasználó'}</div>
          ) : (
            <WindowScroller>
              {({ height, isScrolling, registerChild, scrollTop }) => (
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <div ref={el => registerChild(el)}>
                      <List
                        autoHeight
                        height={height}
                        width={width}
                        scrollTop={scrollTop}
                        rowHeight={APPLICATION_USERS_ROW_HEIGHT}
                        rowRenderer={rowRenderer}
                        rowCount={users.length}
                        isScrolling={isScrolling}
                        overscanRowCount={20}
                      />
                    </div>
                  )}
                </AutoSizer>
              )}
            </WindowScroller>
          )}
        </div>
      </div>
    </>
  );
};

const Row = ({ row, isPlaceholder }: { row: IUserRow; isPlaceholder: boolean }) => {
  const { user, person, isActive } = row;

  return (
    <div className={`data-table__row ${isPlaceholder ? 'wireframe-design' : ''}`}>
      <div className="data-table__cell">{user.name}</div>

      <div className="data-table__cell">{user.email}</div>

      <div className="data-table__cell">{formatName(person.firstName, person.lastName, person.middleName, person.courtesyTitle)}</div>

      <div className="data-table__cell active checkbox">{!isPlaceholder ? <Checkbox readOnly disabled checked={isActive} /> : <div className="wireframe-item" />}</div>

      <div className="data-table__cell--end">
        <Link to={`/Settings/ApplicationUsers/Details/${user.id}`}>
          <Icon icon="keyboard_arrow_right" />
        </Link>
      </div>
    </div>
  );
};

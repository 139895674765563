import { transformUserRowDTO, IUserRow } from '../../../../routes/Settings/ApplicationUsers/helper';
import { ApiResponseType } from '../../../../components/Portals/ResponseSnackbar';
import { UserRowDTO } from '../../../lib/Settings/applicationUsers';

export const initialState: UsersState = {
  users: {},
  isLoading: true,
  error: '',
  apiResponse: null
};

export interface UsersState {
  users: { [userId: number]: IUserRow };
  isLoading: boolean;
  error: string;
  apiResponse: ApiResponseType;
}

type UsersAction =
  | { type: 'success_get_users'; payload: { users: UserRowDTO[] } }
  | { type: 'error_get_users'; payload: { error: string } }
  | { type: 'on_set_user'; payload: { user: IUserRow; isAPIResponse: boolean } }
  | { type: 'on_set_apiResponse'; payload: { apiResponse: ApiResponseType } }
  | { type: 'on_search_users'; payload: { searchText: string } };

export function usersReducer(state: UsersState = initialState, action: UsersAction): UsersState {
  switch (action.type) {
    case 'success_get_users': {
      let users: UsersState['users'] = { ...state.users };
      action.payload.users.forEach(x => {
        const user = transformUserRowDTO(x, users[x.userId]);
        users[x.userId] = user;
      });
      return {
        ...state,
        isLoading: false,
        error: '',
        users
      };
    }

    case 'error_get_users': {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }

    case 'on_set_user': {
      const isEdit = Boolean(state.users[action.payload.user.user.id]);
      return {
        ...state,
        users: { ...state.users, [action.payload.user.user.id]: action.payload.user },
        apiResponse: action.payload.isAPIResponse ? { type: 'success', message: 'Sikeres ' + (isEdit ? 'módosítás' : 'létrehozás') } : state.apiResponse
      };
    }

    case 'on_set_apiResponse': {
      return {
        ...state,
        apiResponse: action.payload.apiResponse
      };
    }

    case 'on_search_users': {
      const users = { ...state.users };

      Object.values(users).forEach(user => {
        users[user.user.id] = { ...user, isVisible: user.user.name.toLocaleLowerCase().includes(action.payload.searchText.toLocaleLowerCase()) };
      });

      return {
        ...state,
        users
      };
    }

    default:
      return state;
  }
}

import * as signalRClient from '@microsoft/signalr';
import Auth from './auth';

export default class SignalR {
  private static INSTANCE: SignalR;

  public connection: signalRClient.HubConnection;

  private constructor() {
    if (process.env.REACT_APP_API_BASE) {
      this.connection = new signalRClient.HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_API_BASE + 'signalr/manufacturing', { accessTokenFactory: () => Auth.Instance.getAccessToken() })
        .withAutomaticReconnect()
        .build();

      this.connection.start().catch(err => console.error(err));
    } else {
      throw Error('Signalr Error');
    }
  }

  public static get Instance() {
    return this.INSTANCE || (this.INSTANCE = new this());
  }
}

export const HubConnectionState = signalRClient.HubConnectionState;

import { PersonRowDTO } from '../../../../store/lib/TrackTime/monthlyClose';
import { formatName } from '../../../../constants/helperFuntions';

export const generateMonthlyCostCSV = (persons: PersonRowDTO[], exportedFilename: string) => {
  const header = 'id,name,cost\n';

  const csv = header + persons.map(person => '"' + [person.id, formatName(person.firstName, person.lastName, person.middleName, person.courtesyTitle), person.realCostPerMonth].join('","') + '"').join('\r\n');

  const blob = new Blob(['\ufeff' + csv], { type: 'text/csv' });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const parseMonthlyCostCSVLine = (line: string): { id: number; cost: number | null } | null => {
  const [id, , cost] = line.replace(/"/g, '').split(',');
  if (id !== undefined && cost !== undefined) {
    const parsedCost = parseInt(cost);
    return { id: Number(id), cost: isNaN(parsedCost) ? null : parsedCost };
  }

  return null;
};

import { ISelectableOrderDTO, ISelectableRootProductDTO, ISelectableTaskItemDTO } from '../../../../typings/DTOs';
import { INVENTORY_STATUS_MAP, QUANTITY_UNIT_MAP } from '../../../../constants/enums';
import { FormattedOption } from '@rmwc/select';
import { Inventory } from '../helper';

export const DEFAULT_INVENTORY: Inventory = {
  id: -1,
  order: null,
  productItem: null,
  task: null,
  quantity: 1
};

export const INVENTORY_STATUS_SELECT: FormattedOption[] = Object.keys(INVENTORY_STATUS_MAP).map(x => ({ label: INVENTORY_STATUS_MAP[+x], value: x }));

export const transformISelectableSubProductDTO = (p: ISelectableRootProductDTO): FormattedOption => {
  return {
    label: p.title || '',
    value: String(p.id),
    itemType: QUANTITY_UNIT_MAP[p.quantityUnit]
  };
};

export const transformIOrderDTO = (p: ISelectableOrderDTO): FormattedOption => {
  return {
    label: p.no || '',
    value: String(p.id)
  };
};

export const transformISelectableTaskItemDTO = (p: ISelectableTaskItemDTO): FormattedOption => {
  return {
    label: p.name || '',
    value: String(p.id),
    size: p.standardTimeSeconds
  };
};

export const addProductLabelToOrderList = (orders: ISelectableOrderDTO[]) => {
  return orders.map(o => {
    if (orders.filter(f => f.no === o.no).length > 1) {
      // let ot = { ...o };
      // ot.no = ot.no + ' (' + ot.productItemTitle + ')';
      // return ot;
      return { ...o, no: o.no + ' (' + o.productItemTitle + ')' };
    } else return o;
  });
};

import React from 'react';
import { IconButton } from '@rmwc/icon-button';
import { DateTime } from 'luxon';
import { Icon } from '@rmwc/icon';

interface IProps {
  prevMonth(): void;
  nextMonth(): void;
  date: DateTime;
  isLoading?: boolean;
  format?: string;
  restrictedBefore?: DateTime | null;
  isMonthRestrictedButEditable?: boolean;
}

const calculateLockIcon = (date: DateTime, restrictedBefore?: DateTime | null, isMonthRestrictedButEditable?: boolean) => {
  if (restrictedBefore && isMonthRestrictedButEditable !== undefined && date.set({ day: 1 }) < restrictedBefore) {
    const icon = isMonthRestrictedButEditable ? 'lock_open' : 'lock';
    if (date.set({ day: date.daysInMonth }) < restrictedBefore) {
      return { icon, label: 'Lezárt hónap' };
    } else {
      return { icon, label: `Lezárva ${restrictedBefore.day}-e előtt` };
    }
  }
  return null;
};

export function MonthPager({ prevMonth, nextMonth, date, restrictedBefore, isMonthRestrictedButEditable, isLoading = false, format = "yyyy'.'LL" }: IProps) {
  const restriction = calculateLockIcon(date, restrictedBefore, isMonthRestrictedButEditable);

  return (
    <div className="calendar-pager mdc-form-field">
      {restriction && !isLoading && (
        <span className="lock-section">
          <span className="lock-section__inner-wrap">
            <Icon icon={restriction.icon} />
            <span className="title">{restriction.label}</span>
          </span>
        </span>
      )}
      <IconButton onClick={prevMonth} icon="keyboard_arrow_left" />
      <span className="date">{date.setLocale('hun').toFormat(format)}</span>
      <IconButton onClick={nextMonth} icon="keyboard_arrow_right" />
    </div>
  );
}

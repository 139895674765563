import React, { useCallback, useEffect, useReducer } from 'react';

/* Data Things */
import ResponseSnackbar, { ApiResponseType } from '../Portals/ResponseSnackbar';
import { projectsReducer, initialState, ProjectsState } from '../../store/reducers/TrackTime/Projects';
import { useIsMounted } from '../../hooks/useIsMounted';
import { getProjects } from '../../store/lib/TrackTime/projects';
import { IProject } from '../../routes/TrackTime/Projects/helper';

interface IProjectsContext extends ProjectsState {
  onSetApiResponse: (apiResponse: ApiResponseType) => void;
  onSetProject: (newProject: IProject) => void;
  onRefresh: () => Promise<string>;
}

const ProjectsContext = React.createContext<IProjectsContext>({
  ...initialState,
  onSetApiResponse: () => null,
  onSetProject: () => null,
  onRefresh: () => Promise.reject('')
});

function ProjectsProvider(props: React.PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(projectsReducer, initialState);
  const _isMounted = useIsMounted();

  const onSetApiResponse = (apiResponse: ApiResponseType) => dispatch({ type: 'on_set_apiResponse', payload: { apiResponse } });

  const onSetProject = useCallback((newProject: IProject) => dispatch({ type: 'on_set_project', payload: { newProject } }), []);

  const onGetRootProjectFromRemote = useCallback(async (): Promise<string> => {
    try {
      const projects = await getProjects();
      if (projects && !projects.error && _isMounted.current) {
        dispatch({ type: 'success_get_projects', payload: { projects } });
        return Promise.resolve('');
      } else {
        throw new Error(projects.error || 'Sajnáljuk, valami hiba történt a projektek betöltés során. Kérlek próbáld újra!');
      }
    } catch (error) {
      _isMounted.current &&
        dispatch({
          type: 'error_get_projects',
          payload: { error: String(error.message || 'Sajnáljuk, valami hiba történt a projektek betöltés során. Kérlek próbáld újra!') }
        });
      return Promise.reject(String(error.message | error));
    }
  }, [_isMounted]);

  useEffect(() => void onGetRootProjectFromRemote(), [onGetRootProjectFromRemote]);

  return (
    <div className="Projects">
      <ResponseSnackbar response={state.apiResponse} onClose={() => onSetApiResponse(null)} />
      <ProjectsContext.Provider value={{ ...{ ...state, onSetProject, onSetApiResponse, onRefresh: onGetRootProjectFromRemote } }}>{props.children}</ProjectsContext.Provider>
    </div>
  );
}

const ProjectsConsumer = ProjectsContext.Consumer;
export { ProjectsProvider, ProjectsContext, ProjectsConsumer };

import React, { useContext, useEffect, useState } from 'react';

/* Data Things */
import { addProductLabelToOrderList, DEFAULT_INVENTORY, transformIOrderDTO, transformISelectableSubProductDTO, transformISelectableTaskItemDTO } from './helper';
import { getOrderNumbers, getSubProducts, getTaskItem } from '../../../../store/lib/Manufacturing/orders';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { useIsMounted } from '../../../../hooks';
import { Inventory } from '../helper';

/* Presentation Things */
import { FormattedOption, Select } from '@rmwc/select';
import { LinearProgress } from '@rmwc/linear-progress';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import { useKeyPress } from '../../../../hooks/useKeypress';

interface IProps {
  inventory?: Inventory;
  onSubmit(model: Inventory): Promise<string>;
}

export function AddOrEdit(props: IProps) {
  /* Contexts */
  const { close } = useContext(SideSheetContext);

  /* States */
  const [editableInventory, setEditableInventory] = useState(props.inventory || DEFAULT_INVENTORY);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<FormattedOption[]>([]);
  const [orders, setOrders] = useState<FormattedOption[]>([]);
  const [tasks, setTasks] = useState<FormattedOption[]>([]);
  const [numberStep, setNumberStep] = useState(1);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();

    try {
      setIsLoading(true);
      await props.onSubmit(editableInventory);
      close();
    } catch (error) {
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    switch (e.target.type) {
      case 'select-one':
      case 'number':
        setEditableInventory(prev => ({ ...prev, [e.target.name]: Number(e.target.value) }));
        break;

      default:
        setEditableInventory(prev => ({ ...prev, [e.target.name]: String(e.target.value) }));
        break;
    }
  };

  const onSelectOrder = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOrder = orders.find(x => x.value === e.target.value);

    if (selectedOrder) {
      setProducts([]);
      setTasks([]);
      setEditableInventory(prev => ({ ...prev, productItem: null, task: null, order: selectedOrder }));
    }
  };

  const onSelectProduct = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedProduct = products.find(x => x.value === e.target.value);

    if (selectedProduct) {
      setTasks([]);
      setEditableInventory(prev => ({ ...prev, productItem: selectedProduct, task: null }));
    }
  };

  const onSelectTask = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedTask = tasks.find(x => x.value === e.target.value);

    setEditableInventory(prev => ({ ...prev, task: selectedTask || null }));
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const orders = await getOrderNumbers();

        if (!orders.error && _isMounted.current) {
          setOrders(addProductLabelToOrderList(orders).map(x => transformIOrderDTO(x)));
        } else throw new Error(orders.error);
      } catch (error) {
      } finally {
        _isMounted.current && setIsLoading(false);
      }
    })();
  }, [_isMounted]);

  useEffect(() => {
    (async () => {
      if (editableInventory.order) {
        try {
          setIsLoading(true);
          const products = await getSubProducts(editableInventory.order.value!);

          if (!products.error && _isMounted.current) {
            setProducts(products.map(x => transformISelectableSubProductDTO(x)));
          } else throw new Error(products.error);
        } catch (error) {
        } finally {
          _isMounted.current && setIsLoading(false);
        }
      }
    })();
  }, [_isMounted, editableInventory.order]);

  useEffect(() => {
    (async () => {
      if (editableInventory.productItem) {
        try {
          setIsLoading(true);
          const tasks = await getTaskItem(editableInventory.productItem.value!);

          if (!tasks.error && _isMounted.current) {
            setTasks([{ label: '' }, ...tasks.map(x => transformISelectableTaskItemDTO(x))]);
          } else throw new Error(tasks.error);
        } catch (error) {
        } finally {
          _isMounted.current && setIsLoading(false);
        }
      }
    })();
  }, [_isMounted, editableInventory.productItem]);

  const onKeyPress = event => {
    if (document.activeElement!.tagName !== 'INPUT' && _isMounted) {
      if (numberStep === 0.1) setNumberStep(1);
      else setNumberStep(0.1);
      event.preventDefault();
    }
  };

  useKeyPress(['.', ','], onKeyPress);

  return (
    <>
      <div className="drawer-header">{props.inventory ? 'Készlet szerkesztése' : 'Új készlet'}</div>

      {isLoading && <LinearProgress />}

      <form onSubmit={onSubmit} className="form-content">
        {!props.inventory && (
          <>
            <div className="form-content-row">
              <Select required label="Megrendelés" options={orders} onChange={onSelectOrder} value={editableInventory.order ? editableInventory.order.value : undefined} />
            </div>

            <div className="form-content-row">
              <Select required label="Cikk" disabled={!editableInventory.order} options={products} onChange={onSelectProduct} value={editableInventory.productItem ? editableInventory.productItem.value : undefined} />
            </div>

            <div className="form-content-row">
              <Select label="Feladat" disabled={!editableInventory.productItem} options={tasks} onChange={onSelectTask} value={editableInventory.task ? editableInventory.task.value : undefined} />
            </div>
          </>
        )}

        <div className="form-content-row">
          <TextField
            required
            type="number"
            label={`Mennyiség${editableInventory.productItem ? '(' + editableInventory.productItem.itemType + ')' : ''} (${numberStep})`}
            name="quantity"
            step={numberStep}
            onChange={onInputChange}
            value={editableInventory.quantity}
          />
        </div>

        <Button unelevated label={props.inventory ? 'Szerkesztés' : 'Hozzáadás'} type="submit" disabled={isLoading} />
      </form>
    </>
  );
}

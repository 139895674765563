import React from 'react';

/* Navigation Things */
import { RouteComponentProps, Router } from '@reach/router';

/* Data Things */
import { InventoriesProvider } from '../../../components/Contexts/Inventory';

/* Routes */
import { List } from './List';

interface IProps extends RouteComponentProps {}

export function Inventory(_: IProps) {
  return (
    <InventoriesProvider>
      <Router basepath="Manufacturing/Inventory">
        <List default />
      </Router>
    </InventoriesProvider>
  );
}

import { TaskJob } from '../helper';
import { DateTime } from 'luxon';

export const generateRowCalculatedFields = ({ standardTimeSeconds, totalActivityTimeSeconds, quantityDone, quantityExpected, quantityApproved, plannedStartTime }: TaskJob) => {
  const deltaTimeInSeconds = quantityExpected * standardTimeSeconds - (totalActivityTimeSeconds || 0);

  const quality = quantityDone !== null && quantityApproved !== null ? ((quantityApproved / quantityDone) * 100).toFixed(2) + '%' : '-';

  const performance = quantityDone !== null && totalActivityTimeSeconds ? (((quantityDone * standardTimeSeconds) / totalActivityTimeSeconds) * 100).toFixed(2) + '%' : '-';

  const plannedEndTime = plannedStartTime ? plannedStartTime.plus({ seconds: quantityExpected * standardTimeSeconds }) : null;

  return {
    deltaTimeInSeconds,
    quality,
    performance,
    plannedEndTime
  };
};

export const formatSeconds = (input: number) => {
  const seconds = Math.abs(input);

  const hh = Math.floor(seconds / 3600),
    mm = Math.floor(seconds / 60) % 60,
    ss = Math.floor(seconds) % 60;
  return (input < 0 ? '-' : '') + ((hh < 10 ? '0' : '') + hh + ':') + (mm < 10 ? '0' : '') + mm + ':' + (ss < 10 ? '0' : '') + ss;
};
export const isDayClosed = (day: DateTime, restrictedBefore: DateTime | null, isMonthRestrictedButEditable: boolean): boolean => {
  return Boolean(restrictedBefore && day < restrictedBefore && !isMonthRestrictedButEditable);
};

import { ApiResponseType } from '../../../../components/Portals/ResponseSnackbar';
import { IProject, transformProjectDTO } from '../../../../routes/TrackTime/Projects/helper';
import { ProjectDTO } from '../../../lib/TrackTime/projects';

export const initialState: ProjectsState = {
  projects: {},
  apiResponse: null,
  error: '',
  isLoading: true
};

export interface ProjectsState {
  projects: { [projectId: number]: IProject };
  apiResponse: ApiResponseType;
  error: string;
  isLoading: boolean;
}

type ProjectsAction =
  | { type: 'success_get_projects'; payload: { projects: Exclude<ProjectDTO, 'subProjects'>[] } }
  | { type: 'error_get_projects'; payload: { error: string } }
  | { type: 'on_set_project'; payload: { newProject: IProject } }
  | { type: 'on_set_apiResponse'; payload: { apiResponse: ApiResponseType } };

export function projectsReducer(state: ProjectsState = initialState, action: ProjectsAction): ProjectsState {
  switch (action.type) {
    case 'success_get_projects': {
      return {
        ...state,
        isLoading: false,
        projects: action.payload.projects.reduce((acc, curr) => {
          const isExist = state.projects[curr.projectId];
          const newProject = transformProjectDTO(curr);
          return { ...acc, [curr.projectId]: isExist ? { ...newProject, subProjects: state.projects[curr.projectId].subProjects } : newProject };
        }, {}),
        error: ''
      };
    }

    case 'error_get_projects': {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }

    case 'on_set_project': {
      return {
        ...state,
        projects: { ...state.projects, [action.payload.newProject.projectId]: action.payload.newProject }
      };
    }

    case 'on_set_apiResponse': {
      return {
        ...state,
        apiResponse: action.payload.apiResponse
      };
    }

    default:
      return state;
  }
}

import { GlobalDayStatusTypes } from '../../../constants/enums';
import { DateTime } from 'luxon';
import { api } from '../../../utils/api';

//=====================================[GET]ForMonth================================
export interface PersonCalendarDTO {
  calendar: CalendarDTO;
  personId: number;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  courtesyTitle: string | null;
  ouName: string | null;
}

type CalendarDTO = {
  days: CalendarDayDTO[];
  leaveRequests: CalendarLeaveRequestDTO[];
  overtimeRequests: CalendarOvertimeRequestDTO[];
};

export type CalendarDayDTO = {
  globalDayStatus: GlobalDayStatusTypes;
  date: string;
  statusId: number | null;
  statusName: string | null;
  statusCode: string | null;
  statusColorCode: string | null;
  increaseWorkTime: boolean;
  isPaid: boolean;
  isPendingStatus: boolean;
  defaultBasicHour: number; //float
  basicHours: number | null; //float
  registeredSeconds: number | null;
};

export type CalendarLeaveRequestDTO = {
  leaveRequestId: number;
  requestFromDate: string | null;
  requestToDate: string | null;
  requestIsDelete: boolean | null;
  responseFromDate: string | null;
  responseToDate: string | null;
  responseIsDelete: boolean | null;
};

export type CalendarOvertimeRequestDTO = {
  overtimeRequestId: number;
  requestStartDate: string | null;
  requestEndDate: string | null;
  requestMaxMinutes: number | null;
  requestIsDelete: boolean | null;
  responseStartDate: string | null;
  responseEndDate: string | null;
  responseMaxMinutes: number | null;
  responseIsDelete: boolean | null;
};

export interface MonthlyWorkSheetPeopleCalendarsDTO {
  peopleCalendars: PersonCalendarDTO[];
  restrictedBefore: string | null;
  isMonthRestrictedButEditable: boolean;
}

export const getForMonth = (selectedMonth: DateTime, signal: AbortSignal) => {
  return api<MonthlyWorkSheetPeopleCalendarsDTO>('GET', `MonthlyWorkSheets/${selectedMonth.year}/${selectedMonth.month}`, undefined, signal);
};

//=====================================[PUT]DayStatus================================
interface AddOrEditPersonDayStatusDTO {
  personId: number;
  statusId: null | number;
  date: DateTime;
}

export const setPersonDayStatus = (model: AddOrEditPersonDayStatusDTO) => {
  return api<CalendarDayDTO>('PUT', `people/${model.personId}/daystatuses/${model.date.year}/${model.date.month}/${model.date.day}`, model);
};

//=====================================[GET]ProjectHoursReport================================
export interface PersonActivityTimeProjectSumDTO {
  personId: number;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  courtesyTitle: string | null;
  days: ActivitySumsByProjectDTO[];
}

export type ActivitySumsByProjectDTO = {
  projectId: number | null;
  projectName: string | null;
  projectCode: string | null;
  projectColorCode: string | null;
  rootProjectName: string | null;
  rootProjectCode: string | null;
  rootProjectColorCode: string | null;
  days: ActivitySumsByDayDTO[];
};

export type ActivitySumsByDayDTO = {
  date: string;
  sumActivityTimeMinutes: number;
};

export const getProjectHoursReport = (selectedMonth: DateTime) => {
  return api<PersonActivityTimeProjectSumDTO[]>('GET', `reports/projecthours/${selectedMonth.year}/${selectedMonth.month}`);
};

//=====================================[GET]GetActivitiesReport================================
export interface PersonActivityTimesDTO {
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  courtesyTitle: string | null;
  activities: ActivityDTO[];
}

export type ActivityDTO = {
  appliedDate: string;
  startTime: string;
  endTime: string;
  activityDurationMinutes: number;
  projectName: string | null;
  projectCode: string | null;
  rootProjectName: string | null;
  rootProjectCode: string | null;
  comment: string | null;
  tags: string[] | null;
};

export const getActivitiesReport = (selectedMonth: DateTime) => {
  return api<PersonActivityTimesDTO[]>('GET', `reports/activities/${selectedMonth.year}/${selectedMonth.month}`);
};

import { api } from '../../../../utils/api';
import { ConcurrentEditViewModel } from './employment';
import { ProHR365FileTypes, PersonTypes, MaritalStatuses, SexTypes } from '../../../../constants/enums';

//======================================EDIT-ATTACHMENTS====================================

export const putAttachments = (id: number, model: { tempFileIds: number[] }) => {
  return api<PersonAttachmentDTO[]>('PUT', `people/${id}/attachments`, model);
};

//======================================EDIT-PERSONALINFO====================================
export interface EditPersonalInfoDTO extends ConcurrentEditViewModel {
  socialSecurityNumber: string | null;
  addressCardNumber: string | null;
  idCardNumber: string | null;
  idCardExpiryDate: string | null;
  passportNumber: string | null;
  passportExpiryDate: string | null;
  driverLicenseNumber: string | null;
  driverLicenseExpiryDate: string | null;
  taxNumber: string | null;
  bankAccountNumber: string | null;
}

export const getPersonalInfoForEdit = (id: number) => {
  return api<EditPersonalInfoDTO>('GET', `people/${id}/PersonalInfo`, null);
};

export const putPersonalInfoForEdit = (id: number, model: EditPersonalInfoDTO) => {
  return api<PersonDetailsPersonalInfoDTO>('PUT', `people/${id}/PersonalInfo`, model);
};

//====================================EDIT-CONTACTINFO======================================
export interface EditPersonContactInfoDTO extends ConcurrentEditViewModel {
  publicMobilePhone: string | null;
  publicLinePhone: string | null;
  publicLinePhoneExtension: string | null;
  publicEmail: string | null;
  linkedin: string | null;
  privateHomeAddress: AddressDTO;
  privateResidenceAddress: AddressDTO;
  privateMobilePhone: string | null;
  privateEmail: string | null;
  facebook: string | null;
  nextOfKinName: string | null;
  nextOfKinPhone: string | null;
}

export const getContactInfoForEdit = (id: number) => {
  return api<EditPersonContactInfoDTO>('GET', `people/${id}/ContactInfo`, null);
};

export const putContactInfoForEdit = (id: number, model: EditPersonContactInfoDTO) => {
  return api<PersonDetailsContactInfoDTO>('PUT', `people/${id}/ContactInfo`, model);
};

//====================================EDIT-BASICIFO=========================================
export interface EditPersonBasicInfoDTO extends ConcurrentEditViewModel {
  syncId: string | null;
  pictureTempFileId: number | null;
  pictureUrl: string | null;
  firstName: string;
  lastName: string;
  middleName: string | null;
  courtesyTitle: string | null;
  sexType: SexTypes;
  birthDate: string;
  birthPlace: string;
  birthName: string;
  motherName: string;
  maritalStatus: MaritalStatuses | null;
  isPrivate: boolean;
}

export const getBasicInfoForEdit = (id: number) => {
  return api<EditPersonBasicInfoDTO>('GET', `people/${id}/BasicInfo`, null);
};

export const putBasicInfoForEdit = (id: number, model: EditPersonBasicInfoDTO) => {
  return api<PersonDetailsBasicInfoDTO>('PUT', `people/${id}/BasicInfo`, model);
};

//==========================================GET-DETAILS=======================================

export type PersonDetailsBasicInfoDTO = {
  syncId: string | null;
  name: string | null;
  birthName: string | null;
  sex: SexTypes;
  birthDate: string;
  birthPlace: string | null;
  motherName: string | null;
  maritalStatus: MaritalStatuses | null;
  isPrivate: boolean;
};

export type AddressDTO = {
  zip: string;
  city: string;
  addressLine1: string;
  addressLine2: string;
  country: string;
};

export type PersonDetailsContactInfoDTO = {
  publicMobilePhone: string | null;
  publicLinePhone: string | null;
  publicLinePhoneExtension: string | null;
  publicEmail: string | null;
  linkedin: string | null;
  privateHomeAddress: AddressDTO | null;
  privateResidenceAddress: AddressDTO | null;
  privateMobilePhone: string | null;
  privateEmail: string | null;
  facebook: string | null;
  nextOfKinName: string | null;
  nextOfKinPhone: string | null;
};

export type PersonDetailsPersonalInfoDTO = {
  taxId: string | null;
  socialSecurityNumber: string | null;
  addressCardNumber: string | null;
  idCardNumber: string | null;
  idCardExpiryDate: string | null;
  passportNumber: string | null;
  passportExpiryDate: string | null;
  driverLicenseNumber: string | null;
  driverLicenseExpiryDate: string | null;
  bankAccountNumber: string | null;
};

export type PersonAttachmentDTO = {
  attachmentId: number;
  fileName: string | null;
  extension: string;
  fileType: ProHR365FileTypes;
  fileUrl: string;
  fileLength: number;
};

export interface PersonDetailsDTO extends ConcurrentEditViewModel {
  personId: number;
  pictureUrl: string | null;
  courtesyTitle: string | null;
  lastName: string | null;
  firstName: string | null;
  middleName: string | null;
  phoneNumber: string | null;
  email: string | null;
  jobRoles: string[] | null;
  oUs: string[] | null;
  status: string | null;
  currentAvailability: string | null;
  personType: PersonTypes;
  basicInfo: PersonDetailsBasicInfoDTO | null;
  contactInfo: PersonDetailsContactInfoDTO | null;
  personalInfo: PersonDetailsPersonalInfoDTO | null;
  attachments: PersonAttachmentDTO[] | null;
}

export const getDetails = (id: number) => {
  return api<PersonDetailsDTO>('GET', `people/${id}`, null);
};

import './styles/common.scss';

import React from 'react';
import { Redirect, Router } from '@reach/router';
import { RMWCProvider } from '@rmwc/provider';

/* Data Things */
import secure from './components/Auth/SecuredComponent';

/* Routes  */
import AuthSilentRenew from './components/Auth/AuthSilentRenew';
import AuthCallback from './components/Auth/AuthCallback';
import MasterLayout from './routes/MasterLayout';
import { MonthlyAttendence } from './routes/TrackTime/MonthlyAttendence';
import { OrganizationStructure } from './routes/Organization/Structure';
import { ApplicationUsers } from './routes/Settings/ApplicationUsers';
import { SecurityGroups } from './routes/Settings/SecurityGroups';
import { DailyStatuses } from './routes/MasterData/DailyStatuses';
import { ActivitySync } from './components/ActivitySync/ActivitySync';
import { MonthlyClose } from './routes/TrackTime/MonthlyClose';
import { ActivityTags } from './routes/MasterData/ActivityTags';
import { Inventory } from './routes/Manufacturing/Inventories';
import { JobRoles } from './routes/MasterData/JobRoles';
import { Products } from './routes/Manufacturing/Products';
import { Customers } from './routes/Manufacturing/Customers';
import { Projects } from './routes/TrackTime/Projects';
import { OUTypes } from './routes/Settings/OUTypes';
import { People } from './routes/Organization/People';
import { Orders } from './routes/Manufacturing/Orders';

// Styles
import '@rmwc/tooltip/tooltip.css';

const SecuredMasterLayout = secure(MasterLayout);

class App extends React.Component {
  componentDidMount() {
    if (process.env.NODE_ENV === 'production') console.log = function no_console() {};
  }

  public render() {
    return (
      <div id="app">
        <RMWCProvider ripple={false}>
          <Router className="container" primary={false}>
            <Redirect from="/" to="/Organization/Chart" noThrow />

            <SecuredMasterLayout path="/">
              <People path="Organization/People/*" />
              <OrganizationStructure path="Organization/Chart/*" />

              <MonthlyAttendence path="/TrackTime/MonthlyAttendence" />
              <MonthlyClose path="/TrackTime/MonthlyClose/:year/:month" />
              <MonthlyClose path="/TrackTime/MonthlyClose" />
              <Projects path="/TrackTime/Projects/*" />
              <ActivitySync path="/TrackTime/SyncStatus" />

              <ApplicationUsers path="/Settings/ApplicationUsers/*" />
              <SecurityGroups path="/Settings/SecurityGroups/*" />
              <OUTypes path="/Settings/OUTypes/*" />

              <DailyStatuses path="/MasterData/DayStatuses/*" />
              <ActivityTags path="/MasterData/ActivityTags/*" />
              <JobRoles path="/MasterData/JobRoles/*" />

              <Products path="/Manufacturing/Products/*" />
              <Inventory path="/Manufacturing/Inventories/*" />
              <Orders path="/Manufacturing/Orders/*" />
              <Customers path="/Manufacturing/Customers/*" />
            </SecuredMasterLayout>
            <AuthCallback path="/authcallback" />
            <AuthSilentRenew path="/silent" />
          </Router>
        </RMWCProvider>
      </div>
    );
  }
}

export default App;

import React, { useState, useEffect, useCallback } from 'react';

/* Presentation Things */
import { Radio } from '@rmwc/radio';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { Checkbox } from '@rmwc/checkbox';
import { TextField } from '@rmwc/textfield';
import { LinearProgress } from '@rmwc/linear-progress';
import FileSelector, { SelectedFile } from '../../.././../Selectors/FileSelector';

/* Data Things */
import { DateTime } from 'luxon';
import { DATEFORMAT } from '../../../../../constants/constants';
import { formatBytes } from '../../../../../constants/helperFuntions';
import { useIsMounted } from '../../../../../hooks';
import { uploadProfilePicture } from '../../../../../store/lib/upload';
import { SexTypes, MARITAL_STATUSES_MAP } from '../../../../../constants/enums';
import { getBasicInfoForEdit, putBasicInfoForEdit } from '../../../../../store/lib/Organization/Peoples/peopleDetails';
import { EditPersonBasicInfo, transformEditPersonBasicInfoDTO, transformEditPersonBasicInfo, PersonDetails, transformPersonDetailsBasicInfoDTO } from '../helper';

/* Style */
import './PeopleEdit.scss';

const MARITALSTATUSES = [{ label: 'Nincs megadva', value: 'null' }, ...Object.keys(MARITAL_STATUSES_MAP).map(key => ({ label: String(MARITAL_STATUSES_MAP[key]), value: String(key) }))];

const zeroAll = { hour: 0, minute: 0, second: 0, millisecond: 0 };
const today = DateTime.local();
const MAX_PICTURE_SIZE = 6000000; //bytes

type IProps = {
  close(): void;
  onEditPartialDetailsModel(p: Partial<PersonDetails>): void;
  personId: number;
};

export function EditBasicInfoSection({ personId, onEditPartialDetailsModel, close }: IProps) {
  const _isMounted = useIsMounted();
  const [model, setModel] = useState<null | EditPersonBasicInfo>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const isSaveAble = model && !isLoading && error.length === 0;

  const getModelFromRemote = useCallback(() => {
    getBasicInfoForEdit(personId)
      .then(resp => {
        _isMounted.current && setModel(transformEditPersonBasicInfoDTO(resp));
        _isMounted.current && setIsLoading(false);
        _isMounted.current && setError('');
      })
      .catch(() => {
        _isMounted.current && setIsLoading(false);
        _isMounted.current && setError('Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
      });
  }, [_isMounted, personId]);

  const handleModelChange = <T extends keyof EditPersonBasicInfo, K extends EditPersonBasicInfo[T]>(key: T, value: K) => {
    setModel(prev => (prev ? { ...prev, [key]: value } : prev));
  };

  const handleBirthDate = (date: string) => {
    const birthDate = DateTime.fromFormat(`${date}`, DATEFORMAT).set(zeroAll);
    if (birthDate.isValid) {
      setModel(prev => (prev ? { ...prev, birthDate } : prev));
    }
  };

  const handlePictureSelect = (pictures: SelectedFile) => {
    if (pictures.file.size < MAX_PICTURE_SIZE && !pictures.error) {
      return uploadProfilePictureToTempStorage(pictures.file);
    }
    return Promise.reject(pictures.error || 'Nem megfelelő a file típusa vagy túl nagy a mérete');
  };

  const handlePictureDelete = () => handleModelChange('pictureTempFileId', null);

  const uploadProfilePictureToTempStorage = async (picture: File): Promise<{ locationId: number | null; message: string }> => {
    try {
      const formData = new FormData();
      formData.append('file', picture);
      const [{ error, id }] = await uploadProfilePicture(formData);
      if (!error) {
        _isMounted.current && handleModelChange('pictureTempFileId', id);
        return Promise.resolve({ locationId: id, message: '' });
      } else throw new Error(error);
    } catch (error) {
      return Promise.reject(String(error.message || error));
    }
  };

  const handleSave = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (isSaveAble) {
      setIsLoading(true);
      putBasicInfoForEdit(personId, transformEditPersonBasicInfo(model!))
        .then(resp => {
          _isMounted.current && onEditPartialDetailsModel({ basicInfo: transformPersonDetailsBasicInfoDTO(resp) });
          _isMounted.current && setIsLoading(false);
          close();
        })
        .catch(err => {
          _isMounted.current && setError(err.message || 'Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
          _isMounted.current && setIsLoading(false);
        });
    }
  };

  useEffect(() => getModelFromRemote(), [getModelFromRemote]);

  return (
    <>
      <div tabIndex={0} className="drawer-header">
        Alapadatok szerkesztése
      </div>

      <div className="form-content basic-info">
        {isLoading && <LinearProgress className="linear-progress" />}

        {error.length > 0 && <div className="basic-error-text">{error}</div>}
        <form onSubmit={handleSave}>
          <Checkbox checked={model ? model.isPrivate : false} label="Privát profil" onChange={evt => handleModelChange('isPrivate', !!evt.currentTarget.checked)} />

          <FileSelector label="Válassz képet" maxFileSize={MAX_PICTURE_SIZE} onSelect={handlePictureSelect} onDelete={handlePictureDelete} accept=".png, .jpg" />
          <span className="file-selector-helper-text">Maximális fájlméret: {formatBytes(MAX_PICTURE_SIZE)}. Megengedett fájltípusok: jpg, png.</span>

          <div className="form-content-row">
            <TextField type="text" maxLength={30} label="Titulus" value={model ? model.courtesyTitle : ''} onChange={ev => handleModelChange('courtesyTitle', ev.currentTarget.value)} />

            <TextField type="text" maxLength={80} label="Vezetéknév" required value={model ? model.lastName : ''} onChange={ev => handleModelChange('lastName', ev.currentTarget.value)} />

            <TextField type="text" maxLength={80} label="Keresztnév" required value={model ? model.firstName : ''} onChange={ev => handleModelChange('firstName', ev.currentTarget.value)} />

            <TextField type="text" maxLength={80} label="Második Keresztnév" value={model ? model.middleName : ''} onChange={ev => handleModelChange('middleName', ev.currentTarget.value)} />
          </div>

          <div className="form-content-row">
            <div className="radio-control-wrap">
              <div className="label">Neme*</div>

              <div className="radio-buttons">
                <Radio value={SexTypes.Male} checked={model ? model.sexType === SexTypes.Male : false} onChange={() => handleModelChange('sexType', SexTypes.Male)}>
                  Férfi
                </Radio>

                <Radio value={SexTypes.Female} checked={model ? model.sexType === SexTypes.Female : false} onChange={() => handleModelChange('sexType', SexTypes.Female)}>
                  Nő
                </Radio>
              </div>
            </div>

            <TextField type="text" maxLength={200} label="Születési név" value={model ? model.birthName : ''} onChange={ev => handleModelChange('birthName', ev.currentTarget.value)} />

            <TextField type="text" required maxLength={100} label="Születési hely" value={model ? model.birthPlace : ''} onChange={ev => handleModelChange('birthPlace', ev.currentTarget.value)} />

            <TextField type="date" max={today.toFormat(DATEFORMAT)} required label="Születési idő" value={model ? model.birthDate.toFormat(DATEFORMAT) : ''} onChange={ev => handleBirthDate(ev.currentTarget.value)} />
          </div>

          <div className="form-content-row">
            <TextField type="text" maxLength={200} label="Anyja neve" value={model ? model.motherName : ''} onChange={ev => handleModelChange('motherName', ev.currentTarget.value)} />

            <Select
              label="Családi állapot"
              options={MARITALSTATUSES}
              value={model && model.maritalStatus !== null ? String(model.maritalStatus) : 'null'}
              onChange={ev => handleModelChange('maritalStatus', ev.currentTarget.value === 'null' ? null : Number(ev.currentTarget.value))}
            />

            <TextField type="text" maxLength={80} label="Szinkronicáziós kódja" value={model ? model.syncId : ''} onChange={ev => handleModelChange('syncId', ev.currentTarget.value)} />
          </div>

          <Button unelevated disabled={!isSaveAble}>
            Mentés
          </Button>
        </form>
      </div>
    </>
  );
}

import { ISecurityGroupRow, transformSecurityGroupRowDTO } from '../../../../routes/Settings/SecurityGroups/helper';
import { SecurityGroupRowDTO } from '../../../lib/Settings/securityGroups';
import { ApiResponseType } from '../../../../components/Portals/ResponseSnackbar';

export const initialState: SecurityGroupState = {
  groups: {},
  isLoading: true,
  error: '',
  apiResponse: null
};

export interface SecurityGroupState {
  groups: { [id: number]: ISecurityGroupRow };
  isLoading: boolean;
  error: string;
  apiResponse: ApiResponseType;
}

type UsersAction =
  | { type: 'success_get_groups'; payload: { groups: SecurityGroupRowDTO[] } }
  | { type: 'error_get_groups'; payload: { error: string } }
  | { type: 'on_set_group'; payload: { group: ISecurityGroupRow; isAPIResponse: boolean } }
  | { type: 'on_set_apiResponse'; payload: { apiResponse: ApiResponseType } }
  | { type: 'on_search_groups'; payload: { searchText: string } };

export function securityGroups(state: SecurityGroupState = initialState, action: UsersAction): SecurityGroupState {
  switch (action.type) {
    case 'success_get_groups': {
      let groups: SecurityGroupState['groups'] = { ...state.groups };

      action.payload.groups.forEach(x => {
        const user = transformSecurityGroupRowDTO(x);
        groups[x.id] = user;
      });
      return {
        ...state,
        isLoading: false,
        error: '',
        groups
      };
    }

    case 'error_get_groups': {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }

    case 'on_set_group': {
      const isEdit = Boolean(state.groups[action.payload.group.id]);
      return {
        ...state,
        groups: { ...state.groups, [action.payload.group.id]: action.payload.group },
        apiResponse: action.payload.isAPIResponse ? { type: 'success', message: 'Sikeres ' + (isEdit ? 'módosítás' : 'létrehozás') } : state.apiResponse
      };
    }

    case 'on_set_apiResponse': {
      return {
        ...state,
        apiResponse: action.payload.apiResponse
      };
    }

    case 'on_search_groups': {
      const groups = { ...state.groups };

      Object.values(groups).forEach(group => {
        groups[group.id] = { ...group, isVisible: group.name.toLocaleLowerCase().includes(action.payload.searchText.toLocaleLowerCase()) };
      });

      return {
        ...state,
        groups
      };
    }

    default:
      return state;
  }
}

import React, { useContext, useEffect, useState } from 'react';

/* Data Things */
import { DEFAULT_ORDER, ORDER_STATUS_SELECT, PAINT_STATUS_SELECT, PRODUCTION_SITE_SELECT, transformICustomerDTO, transformISelectableRootProductDTO } from './helper';
import { DATETIME_INPUT_FORMAT } from '../../../../constants/constants';
import { toDateInputString } from '../../../../constants/helperFuntions';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { getRootProducts } from '../../../../store/lib/Manufacturing/products';
import { getCustomers } from '../../../../store/lib/Manufacturing/customers';
import { useIsMounted } from '../../../../hooks';
import { DateTime } from 'luxon';
import { Project } from '../../../../typings/common';
import { Order } from '../helper';

/* Presentation Things */
import { FormattedOption, Select } from '@rmwc/select';
import { ProjectSelector } from '../../../../components/Selectors/Project';
import { LinearProgress } from '@rmwc/linear-progress';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import { useKeyPress } from '../../../../hooks/useKeypress';

interface IProps {
  order?: Order;
  onSubmit(model: Order): Promise<string>;
}

export function OrderAddOrEdit(props: IProps) {
  /* Contexts */
  const { close } = useContext(SideSheetContext);

  /* States */
  const [editableOrder, setEditableOrder] = useState(props.order || DEFAULT_ORDER);
  const [isLoading, setIsLoading] = useState(false);
  const [numberStep, setNumberStep] = useState(1);
  const [customers, setCustomers] = useState<FormattedOption[]>([]);
  const [products, setProducts] = useState<FormattedOption[]>([]);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();

    try {
      setIsLoading(true);
      await props.onSubmit(editableOrder);
      close();
    } catch (error) {
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const onSelectCustomer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedCustomer = customers.find(x => x.value === e.target.value);

    if (selectedCustomer) {
      setEditableOrder(prev => ({ ...prev, customer: { id: Number(selectedCustomer.value), companyName: selectedCustomer.label } }));
    }
  };

  const onSelectProduct = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedProduct = products.find(x => x.value === e.target.value);

    if (selectedProduct) {
      setEditableOrder(prev => ({ ...prev, productItem: { id: Number(selectedProduct.value), title: selectedProduct.label } }));
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    switch (e.target.type) {
      case 'select-one':
      case 'number':
        setEditableOrder(prev => ({ ...prev, [e.target.name]: Number(e.target.value) }));
        break;

      case 'date':
        const formated = DateTime.fromFormat(e.target.value, DATETIME_INPUT_FORMAT);
        setEditableOrder(prev => ({ ...prev, [e.target.name]: formated.isValid ? formated : null }));
        break;

      default:
        setEditableOrder(prev => ({ ...prev, [e.target.name]: String(e.target.value) }));
        break;
    }
  };

  const onSelectProject = (project: Project) => setEditableOrder(prev => ({ ...prev, project }));

  useEffect(() => {
    (async () => {
      try {
        const [products, customers] = await Promise.all([getRootProducts(), getCustomers()]);

        if (!products.error && !customers.error && _isMounted.current) {
          setCustomers(customers.map(x => transformICustomerDTO(x)));
          setProducts(products.map(x => transformISelectableRootProductDTO(x)));
        } else throw new Error(products.error || customers.error);
      } catch (error) {}
    })();
  }, [_isMounted]);

  const onKeyPress = event => {
    if (document.activeElement!.tagName !== 'INPUT' && document.activeElement!.tagName !== 'TEXTAREA' && _isMounted) {
      if (numberStep === 0.1) setNumberStep(1);
      else setNumberStep(0.1);
      event.preventDefault();
    }
  };

  useKeyPress(['.', ','], onKeyPress);

  return (
    <>
      <div className="drawer-header order-edit">{props.order ? 'Megrendelés szerkesztése' : 'Új megrendelés'}</div>

      {isLoading && <LinearProgress />}

      <div className="form-content">
        <form onSubmit={onSubmit}>
          <div className="form-content-row">
            <Select required options={PRODUCTION_SITE_SELECT} label="Telephely" name="productionSite" onChange={onInputChange} value={String(editableOrder.productionSite)} />
          </div>

          <div className="form-content-row">
            <ProjectSelector selectedProject={editableOrder.project ? editableOrder.project.id : undefined} onSelect={onSelectProject} required />
          </div>

          <div className="form-content-row">
            <Select required options={products} label="Cikk" onChange={onSelectProduct} value={editableOrder.productItem.id ? String(editableOrder.productItem.id) : undefined} />
          </div>

          <div className="form-content-row">
            <Select required options={customers} label="Vevő" onChange={onSelectCustomer} value={editableOrder.customer.id ? String(editableOrder.customer.id) : undefined} />
          </div>

          <div className="form-content-row">
            <TextField required type="text" label="Megrendelés szám" name="orderNo" onChange={onInputChange} value={editableOrder.orderNo} />
          </div>

          <div className="form-content-row">
            <TextField required step={numberStep} type="number" label={`Mennyiség (${numberStep})`} name="quantity" onChange={onInputChange} value={editableOrder.quantity} />
          </div>

          <div className="form-content-row">
            <Select required options={PAINT_STATUS_SELECT} label="Festék státusz" name="paintStatus" onChange={onInputChange} value={String(editableOrder.paintStatus)} />
          </div>

          <div className="form-content-row">
            <TextField type="date" label="Megrendelés dátuma" name="orderDate" max="9999-12-31" onChange={onInputChange} value={toDateInputString(editableOrder.orderDate)} />
          </div>

          <div className="form-content-row">
            <TextField type="date" label="Beérkezés dátuma" name="supplyDate" max="9999-12-31" onChange={onInputChange} value={toDateInputString(editableOrder.supplyDate)} />
          </div>

          <div className="form-content-row">
            <TextField type="date" label="Igényelt kiadási dátum" max="9999-12-31" name="requestedShippingDate" onChange={onInputChange} value={toDateInputString(editableOrder.requestedShippingDate)} />
          </div>
          <div className="form-content-row">
            <TextField type="date" label="Visszaigazolás dátuma" max="9999-12-31" name="confirmationDate" onChange={onInputChange} value={toDateInputString(editableOrder.confirmationDate)} />
          </div>
          <div className="form-content-row">
            <TextField type="date" label="Kiadás dátuma" max="9999-12-31" name="shippingDate" onChange={onInputChange} value={toDateInputString(editableOrder.shippingDate)} />
          </div>

          <div className="form-content-row">
            <Select required options={ORDER_STATUS_SELECT} label="Státusz" name="orderStatus" onChange={onInputChange} value={String(editableOrder.orderStatus)} />
          </div>

          <div className="form-content-row full">
            <TextField textarea outlined rows={3} label="Megjegyzés" name="notes" onChange={onInputChange} value={editableOrder.notes} />
          </div>

          <Button unelevated label={props.order ? 'Szerkesztés' : 'Hozzáadás'} type="submit" />
        </form>
      </div>
    </>
  );
}

import React from 'react';

/* Navigation Things */
import { RouteComponentProps, Router } from '@reach/router';
import { PeopleProvider } from '../../../components/Contexts/People';

/*Routes  */
import { ProfileDetails } from './PeopleDetails';
import { PeopleList } from './PeopleList';
import { ProfileAdd } from './PeopleAdd';

interface IProps extends RouteComponentProps {}

export function People(_: IProps) {
  return (
    <PeopleProvider>
      <Router basepath="Organization/People">
        <PeopleList default path="/" />
        <ProfileDetails path="/Details/:id" />
        <ProfileAdd path="/Add" />
      </Router>
    </PeopleProvider>
  );
}

import React, { useCallback, useEffect, useReducer, useRef } from 'react';

/* Data Things */
import { createInventory, editInventory, getInventory, getInventories } from '../../store/lib/Manufacturing/inventories';
import { inventoriesReducer, initialState, InventoriesState } from '../../store/reducers/Manufacturing/Inventories';
import { ICreateInventoryDTO, IEditInventoryDTO } from '../../typings/DTOs';
import { useDebounce, useIsMounted } from '../../hooks';

/* Presentation Things */
import ResponseSnackbar, { ApiResponseType } from '../Portals/ResponseSnackbar';

interface IInventoriesContext extends InventoriesState {
  onGetInventories: (text?: string, page?: number) => Promise<string | null>;
  onGetInventory: (id: number) => Promise<string | null>;
  onCreateInventory: (model: ICreateInventoryDTO) => Promise<string | number>;
  onEditInventory: (model: IEditInventoryDTO) => Promise<string | number>;
  onSetApiResponse: (apiResponse: ApiResponseType) => void;
  onSearch(text: string): void;
  onChangePage(page: number): void;
}

const InventoriesContext = React.createContext<IInventoriesContext>({
  ...initialState,
  onGetInventories: () => Promise.reject(''),
  onGetInventory: () => Promise.reject(''),
  onCreateInventory: () => Promise.reject(''),
  onEditInventory: () => Promise.reject(''),
  onSetApiResponse: () => null,
  onSearch: () => null,
  onChangePage: () => null
});

function InventoriesProvider(props: React.PropsWithChildren<{}>) {
  /* State */
  const [state, dispatch] = useReducer(inventoriesReducer, initialState);

  /* Variables */
  const signal = useRef(new AbortController());
  const debounceSearch = useDebounce(state.search, 500);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetInventories = useCallback(async (): Promise<string> => {
    try {
      signal.current.abort();
      signal.current = new AbortController();

      dispatch({ type: 'set_partial_state', payload: { isLoading: true, error: '' } });

      const inventories = await getInventories(debounceSearch, state.page * 100, 100, signal.current.signal);
      if (inventories && !inventories.error && _isMounted.current) {
        dispatch({ type: 'success_get_inventories', payload: { inventories } });
        return Promise.resolve('');
      } else {
        throw new Error(inventories.error || 'Sajnáljuk, valami hiba történt a készlet betöltés során. Kérlek próbáld újra!');
      }
    } catch (error) {
      const { message } = error as any;
      if (_isMounted.current) {
        dispatch({
          type: 'error_get_inventories',
          payload: { error: String(message || 'Sajnáljuk, valami hiba történt a készlet betöltés során. Kérlek próbáld újra!') }
        });
      }
      return Promise.reject(String(message || error));
    }
  }, [_isMounted, state.page, debounceSearch, signal]);

  const onGetInventory = useCallback(
    async (id: number): Promise<string> => {
      try {
        const inventory = await getInventory(id);
        if (inventory && !inventory.error && _isMounted.current) {
          dispatch({ type: 'success_get_inventory', payload: { inventory } });
          return Promise.resolve('');
        } else {
          throw new Error(inventory.error || 'Sajnáljuk, valami hiba történt a készlet betöltés során. Kérlek próbáld újra!');
        }
      } catch (error) {
        const { message } = error as any;
        return Promise.reject(String(message || 'Sajnáljuk, valami hiba történt a készlet betöltés során. Kérlek próbáld újra!'));
      }
    },
    [_isMounted]
  );

  const onCreateInventory = useCallback(
    async (model: ICreateInventoryDTO) => {
      try {
        const inventory = await createInventory(model);
        if (!inventory.error && _isMounted.current) {
          dispatch({ type: 'success_post_inventory', payload: { inventory } });
          return Promise.resolve(inventory.id);
        } else throw new Error(inventory.error);
      } catch (error) {
        const { message } = error as any;
        return Promise.reject(String(message || error));
      }
    },
    [_isMounted]
  );

  const onEditInventory = useCallback(
    async (model: IEditInventoryDTO) => {
      try {
        const inventory = await editInventory(model);
        if (!inventory.error && _isMounted.current) {
          dispatch({ type: 'success_put_inventory', payload: { inventory } });
          return Promise.resolve(inventory.id);
        } else throw new Error(inventory.error);
      } catch (error) {
        const { message } = error as any;
        return Promise.reject(String(message || error));
      }
    },
    [_isMounted]
  );

  const onChangePage = (page: number) => dispatch({ type: 'set_partial_state', payload: { page } });

  const onSearch = (search: string) => dispatch({ type: 'set_partial_state', payload: { page: 0, search } });

  const onSetApiResponse = (apiResponse: ApiResponseType) => dispatch({ type: 'set_partial_state', payload: { apiResponse } });

  useEffect(() => void onGetInventories(), [onGetInventories]);

  return (
    <div className="Inventories">
      <ResponseSnackbar response={state.apiResponse} onClose={() => onSetApiResponse(null)} />
      <InventoriesContext.Provider value={{ ...state, onGetInventories, onGetInventory, onCreateInventory, onEditInventory, onSetApiResponse, onChangePage, onSearch }}>{props.children}</InventoriesContext.Provider>
    </div>
  );
}

const InventoriesConsumer = InventoriesContext.Consumer;
export { InventoriesProvider, InventoriesContext, InventoriesConsumer };

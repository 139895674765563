import { DateTime } from 'luxon';
import { IS_LIMITED_DAY_STATUS_SETTING_CLAIM } from '../../../constants/constants';
import { GlobalDayStatusTypes } from '../../../constants/enums';
import { formatName, getCurrentUserClaims } from '../../../constants/helperFuntions';
import {
  ActivityDTO,
  CalendarDayDTO,
  CalendarLeaveRequestDTO,
  CalendarOvertimeRequestDTO,
  PersonActivityTimeProjectSumDTO,
  PersonActivityTimesDTO,
  PersonCalendarDTO
} from '../../../store/lib/TrackTime/monthlyAttendence';
import { DayStatusRow } from '../../MasterData/DailyStatuses/helper';
import { FormattedOption } from '@rmwc/select';

export interface IPersonCalendar {
  calendar: Calendar;
  personId: number;
  name: string;
  OUName: string;
  isVisible: boolean;
}

type Calendar = {
  days: CalendarDay[];
  leaveRequests: CalendarLeaveRequest[];
  overtimeRequests: CalendarOvertimeRequest[];
};

export type CalendarDay = {
  globalDayStatus: GlobalDayStatusTypes;
  date: DateTime;
  statusId: number | null;
  statusName: string | null;
  statusCode: string | null;
  statusColorCode: string | null;
  increaseWorkTime: boolean;
  isPaid: boolean;
  isPendingStatus: boolean;
  defaultBasicHour: number; //float
  basicHours: number | null; //float
  registeredSeconds: number | null;
  overtimeHours: number | null; //computed value
  isUpdating: boolean;
};

type CalendarLeaveRequest = {
  leaveRequestId: number;
  requestFromDate: DateTime | null;
  requestToDate: DateTime | null;
  requestIsDelete: boolean | null;
  responseFromDate: DateTime | null;
  responseToDate: DateTime | null;
  responseIsDelete: boolean | null;
};

type CalendarOvertimeRequest = {
  overtimeRequestId: number;
  requestStartDate: DateTime | null;
  requestEndDate: DateTime | null;
  requestMaxMinutes: number | null;
  requestIsDelete: boolean | null;
  responseStartDate: DateTime | null;
  responseEndDate: DateTime | null;
  responseMaxMinutes: number | null;
  responseIsDelete: boolean | null;
};

export const transformCalendarDayDTO = (p: CalendarDayDTO): CalendarDay => {
  return {
    ...p,
    date: DateTime.fromISO(p.date),
    overtimeHours: null,
    isUpdating: false
  };
};

export const transformCalendarOvertimeRequestDTO = (p: CalendarOvertimeRequestDTO): CalendarOvertimeRequest => {
  return {
    ...p,
    requestEndDate: p.requestEndDate ? DateTime.fromISO(p.requestEndDate) : null,
    requestStartDate: p.requestStartDate ? DateTime.fromISO(p.requestStartDate) : null,
    responseStartDate: p.responseStartDate ? DateTime.fromISO(p.responseStartDate) : null,
    responseEndDate: p.responseEndDate ? DateTime.fromISO(p.responseEndDate) : null
  };
};

export const transformCalendarLeaveRequestDTO = (p: CalendarLeaveRequestDTO): CalendarLeaveRequest => {
  return {
    ...p,
    requestFromDate: p.requestFromDate ? DateTime.fromISO(p.requestFromDate) : null,
    requestToDate: p.requestToDate ? DateTime.fromISO(p.requestToDate) : null,
    responseFromDate: p.responseFromDate ? DateTime.fromISO(p.responseFromDate) : null,
    responseToDate: p.responseToDate ? DateTime.fromISO(p.responseToDate) : null
  };
};

export const transformPersonCalendarDTO = (p: PersonCalendarDTO): IPersonCalendar => {
  return {
    personId: p.personId,
    OUName: p.ouName || '',
    name: formatName(p.firstName || '', p.lastName || '', p.middleName || '', p.courtesyTitle || ''),
    calendar: {
      days: p.calendar.days.map(x => transformCalendarDayDTO(x)),
      leaveRequests: p.calendar.leaveRequests.map(x => transformCalendarLeaveRequestDTO(x)),
      overtimeRequests: p.calendar.overtimeRequests.map(x => transformCalendarOvertimeRequestDTO(x))
    },
    isVisible: true
  };
};

export const hoursDisplay = (hour: number) => {
  let h = Math.trunc(hour);
  let m = Math.round(Math.abs(hour - h) * 60);
  if (m === 60) {
    m = 0;
    h++;
  }
  return `${h}:${(m < 10 ? '0' : '') + m}`;
};

export function isLimitedDayStatusSetting(): Promise<boolean> {
  return getCurrentUserClaims()
    .then(claims => Boolean(typeof claims[IS_LIMITED_DAY_STATUS_SETTING_CLAIM] === 'string'))
    .catch(() => false);
}

const STATUS_FILTER_BY_DAY_TYPE: { [keyof in GlobalDayStatusTypes]: keyof DayStatusRow } = {
  [GlobalDayStatusTypes.Workday]: 'canSetOnWorkday',
  [GlobalDayStatusTypes.NonWorkday]: 'canSetOnNonWorkday',
  [GlobalDayStatusTypes.Restday]: 'canSetOnRestday'
};

export const generateDayStatusOptions = (dayStatuses: DayStatusRow[], globalDayStatus: GlobalDayStatusTypes, isCanSetLimitedStatus: boolean): FormattedOption[] => {
  return dayStatuses.reduce(
    (acc, x) => (x[STATUS_FILTER_BY_DAY_TYPE[globalDayStatus]] && (!x.isLimited || isCanSetLimitedStatus) ? [...acc, { label: x.name, value: String(x.id), style: { color: x.colorCode } }] : acc),
    [] as FormattedOption[]
  );
};

//=================================== DANGEROUS ZONE (take a quick brake, i know how u feel bro) (this is not my code) =================================

export const calculateOvertimeHours = (row: IPersonCalendar, selectedMonth: DateTime, dayStatuses: DayStatusRow[]): IPersonCalendar => {
  let m = row.calendar;
  if (m.overtimeRequests) {
    for (let overtime of m.overtimeRequests) {
      if (overtime.responseIsDelete === false) {
        const start = overtime.responseStartDate! < selectedMonth ? 1 : overtime.responseStartDate!.day;
        const end = overtime.responseEndDate! >= selectedMonth.plus({ months: 1 }) ? selectedMonth.daysInMonth : overtime.responseEndDate!.day;

        //TODO include today
        const yesterday = DateTime.local().minus({ days: 1 });
        const thatDays = m.days.slice(start - 1, end).filter(x => x.date <= yesterday);
        const workHoursNeeded = thatDays
          .filter(x => x.globalDayStatus === GlobalDayStatusTypes.Workday)
          .reduce(
            (accumulator, currentValue) => accumulator + (dayStatuses.length && currentValue.statusId && dayStatuses.find(x => x.id === currentValue.statusId)!.isIncreaseWorkTime ? 0 : currentValue.defaultBasicHour),
            0
          );
        const workHoursMeasured = thatDays.reduce((accumulator, currentValue) => accumulator + currentValue.registeredSeconds! / 3600, 0);
        let maxOvertimeLeft = Math.max(0, overtime.responseMaxMinutes !== null ? Math.min(workHoursMeasured - workHoursNeeded, overtime.responseMaxMinutes / 60) : workHoursMeasured - workHoursNeeded);
        let diff = 0;
        for (let i = start; i <= end; i++) {
          diff = m.days[i - 1].registeredSeconds! / 3600 - m.days[i - 1].basicHours! + diff;
          const overtimeHours = Math.min(Math.max(diff, 0), maxOvertimeLeft);
          diff -= overtimeHours;
          maxOvertimeLeft -= overtimeHours;
          m.days[i - 1].overtimeHours = overtimeHours;
        }
      }
    }
  }
  row.calendar = m;
  return row;
};

export const GlobalDayCss = {
  [GlobalDayStatusTypes.Workday]: '',
  [GlobalDayStatusTypes.NonWorkday]: 'calendar--day--holiday',
  [GlobalDayStatusTypes.Restday]: 'calendar--day--weekend'
};

export type ViewTypes = 'DayStatus' | 'PayRoll' | 'OverTime' | 'RegisteredTime';

export const isDaystatusRowIncomplete = (row: IPersonCalendar): boolean => {
  /* [TODO Jácint] Write test */
  return !row.calendar.days || row.calendar.days.some(d => d.globalDayStatus === GlobalDayStatusTypes.Workday && d.statusId === null && d.defaultBasicHour > 0 && d.date <= DateTime.local().minus({ days: 1 }));
};

export const isRegistredTimeRowIncomplete = (row: IPersonCalendar, dayStatuses: DayStatusRow[]): boolean => {
  /* [TODO Jácint] Write test */
  return (
    !row.calendar.days ||
    row.calendar.days.some(
      d =>
        d.globalDayStatus === GlobalDayStatusTypes.Workday &&
        d.statusId !== null &&
        !dayStatuses.find(x => x.id === d.statusId)!.isIncreaseWorkTime &&
        !d.registeredSeconds &&
        d.defaultBasicHour > 0 &&
        d.date <= DateTime.local().minus({ days: 1 })
    )
  );
};

export const exportCSVFile = (headers: Object, items: Array<object>, fileTitle: string) => {
  if (headers) {
    items.unshift(headers);
  }

  const csv = items.map(row => '"' + Object.values(row).join('","') + '"').join('\r\n');

  const exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  const blob = new Blob(['\ufeff' + csv], { type: 'text/csv' });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const generateOverviewCSV = (rows: IPersonCalendar[], dayStatuses: DayStatusRow[]): { headerrow: Object; itemrows: Array<object> } => {
  /* THIS FUNCTION WRITTEN IN PURE JS. WE WILL REPLACE IT IN THE FUTURE, NOW I IGNORE ALL TYPESCRIPT THINGS (Richard's request)   */

  const headerrow = {
    name: 'Név',
    ou: 'Szervezeti egység',
    workhoursAll: 'Havi összes óraszám (óra)',
    leaveHoursAll: 'Távollét (óra)',
    ...Object.assign(
      //@ts-ignore
      ...dayStatuses.filter(x => x.isIncreaseWorkTime).map(daytype => ({ [`statusId${daytype.id}`]: daytype.name + ' (nap)' }))
    ),
    workhoursNeeded: 'Elvárt óraszám (óra)',
    workHoursMeasuredWorkday: 'Dolgozott hétköznap (óra)',
    workHoursMeasuredRestday: 'Dolgozott pihenőnap (óra)',
    workHoursMeasuredNonworkday: 'Dolgozott munkaszünetinap (óra)',
    overtimeWorkday: 'Túlóra hétköznap (óra)',
    overtimeRestday: 'Túlóra pihenőnap (óra)',
    overtimeNonworkday: 'Túlóra munkaszünetinap (óra)',
    ...Object.assign(
      //@ts-ignore
      ...dayStatuses.filter(x => x.isIncreaseWorkTime !== true).map(daytype => ({ [`statusId${daytype.id}`]: daytype.name + ' (nap)' }))
    )
  };

  const itemrows = rows.map(person => {
    const workdays = person.calendar.days.filter(x => x.globalDayStatus === GlobalDayStatusTypes.Workday);
    const restdays = person.calendar.days.filter(x => x.globalDayStatus === GlobalDayStatusTypes.Restday);
    const nonworkdays = person.calendar.days.filter(x => x.globalDayStatus === GlobalDayStatusTypes.NonWorkday);
    const workhoursAll = workdays.reduce((accumulator, currentValue) => accumulator + currentValue.defaultBasicHour, 0);
    const workhoursNeeded = workdays.reduce(
      (accumulator, currentValue) =>
        accumulator +
        //@ts-ignore
        (dayStatuses.length && currentValue.statusId && dayStatuses.find(x => x.id === currentValue.statusId).isIncreaseWorkTime ? 0 : currentValue.defaultBasicHour),
      0
    );
    let row = {
      name: person.name,
      ou: person.OUName,
      workhoursAll,
      leaveHoursAll: workhoursAll - workhoursNeeded,
      ...Object.assign(
        //@ts-ignore
        ...dayStatuses.filter(x => x.isIncreaseWorkTime).map(daytype => ({ [`statusId${daytype.id}`]: person.calendar.days.filter(x => x.statusId === daytype.id).length }))
      ),
      workhoursNeeded,
      workHoursMeasuredWorkday: workdays.reduce((accumulator, currentValue) => accumulator + currentValue.registeredSeconds! / 3600, 0).toFixed(1),
      workHoursMeasuredRestday: restdays.reduce((accumulator, currentValue) => accumulator + currentValue.registeredSeconds! / 3600, 0).toFixed(1),
      workHoursMeasuredNonworkday: nonworkdays.reduce((accumulator, currentValue) => accumulator + currentValue.registeredSeconds! / 3600, 0).toFixed(1),
      overtimeWorkday: workdays.reduce((accumulator, currentValue) => accumulator + (currentValue.overtimeHours || 0), 0).toFixed(1),
      overtimeRestday: restdays.reduce((accumulator, currentValue) => accumulator + (currentValue.overtimeHours || 0), 0).toFixed(1),
      overtimeNonworkday: nonworkdays.reduce((accumulator, currentValue) => accumulator + (currentValue.overtimeHours || 0), 0).toFixed(1),
      ...Object.assign(
        //@ts-ignore
        ...dayStatuses.filter(x => x.isIncreaseWorkTime !== true).map(daytype => ({ [`statusId${daytype.id}`]: person.calendar.days.filter(x => x.statusId === daytype.id).length }))
      )
    };
    return row;
  });

  return { headerrow, itemrows };
};

export const generateWorkhoursNeededCSV = (rows: IPersonCalendar[]): { headerrow: Object; itemrows: Array<object> } => {
  /* THIS FUNCTION WRITTEN IN PURE JS. WE WILL REPLACE IT IN THE FUTURE, NOW I IGNORE ALL TYPESCRIPT THINGS (Richard's request)   */
  const headerrow = {
    name: 'Név',
    type: '',
    //@ts-ignore
    ...Object.assign(...rows[0].calendar.days.map((day, index) => ({ ['day' + (index + 1)]: index + 1 + '.' })))
  };

  const itemrows = rows.reduce(
    (accumulator, person) =>
      //@ts-ignore
      accumulator.push(
        {
          //@ts-ignore
          name: person.name,
          //@ts-ignore
          type: 'óra',
          //@ts-ignore
          ...Object.assign(...person.calendar.days.map((day, index) => ({ ['day' + (index + 1)]: day.basicHours })))
        },
        {
          name: person.name,
          type: 'státusz',
          //@ts-ignore
          ...Object.assign(...person.calendar.days.map((day, index) => ({ ['day' + (index + 1)]: day.statusCode })))
        }
      ) && accumulator,
    []
  );

  //@ts-ignore
  return { headerrow, itemrows };
};

export const generateOvertimesCSV = (rows: IPersonCalendar[]): { headerrow: Object; itemrows: Array<object> } => {
  const headerrow = {
    name: 'Név'
  };
  rows[0].calendar.days.forEach((_, index) => (headerrow['day' + (index + 1)] = index + 1 + '.'));

  const itemrows = rows.reduce(
    (accumulator, person) =>
      //@ts-ignore
      accumulator.push({
        //@ts-ignore
        name: person.name,
        //@ts-ignore
        ...Object.assign(...person.calendar.days.map((day, index) => ({ ['day' + (index + 1)]: day.overtimeHours })))
      }) && accumulator,
    []
  );

  //@ts-ignore
  return { headerrow, itemrows };
};

export const generateLeaveDaysCSV = (rows: IPersonCalendar[], dayStatuses: DayStatusRow[]): { headerrow: Object; itemrows: Array<object> } => {
  /* THIS FUNCTION WRITTEN IN PURE JS. WE WILL REPLACE IT IN THE FUTURE, NOW I IGNORE ALL TYPESCRIPT THINGS (Richard's request)   */
  const headerrow = {
    name: 'Név',
    dateFrom: 'Kezdő dátum',
    dateTo: 'Záró dátum',
    leaveType: 'Távollét típusa',
    leaveWorkDaysCount: 'Távolléten töltött munkanapok száma'
  };

  const itemrows = rows
    .reduce((accumulator, person) => {
      let start, end, workdayCount, leaveType;
      for (let i = 0; i < person.calendar.days.length; i++) {
        const day = person.calendar.days[i];
        if (start) {
          if (day.globalDayStatus !== GlobalDayStatusTypes.Workday && !end) {
            //temp set last day as the end
            end = person.calendar.days[i - 1].date;
          } else if (day.globalDayStatus === GlobalDayStatusTypes.Workday) {
            if (day.statusId !== leaveType) {
              accumulator.push({
                //@ts-ignore
                name: person.name,
                //@ts-ignore
                dateFrom: start,
                //@ts-ignore
                dateTo: end ? end : person.calendar.days[i - 1].date,
                //@ts-ignore
                leaveType: leaveType,
                //@ts-ignore
                leaveWorkDaysCount: workdayCount
              });
              start = null;
              end = null;
              leaveType = null;
              workdayCount = 0;
            } else {
              //increase workdays and delte temp end
              end = null;
              workdayCount++;
            }
          }
        }

        if (!start && day.globalDayStatus === GlobalDayStatusTypes.Workday && day.statusId && dayStatuses.find(x => x.id === day.statusId)!.isIncreaseWorkTime) {
          start = day.date;
          workdayCount = 1;
          leaveType = day.statusId;
        }
      }

      if (start) {
        accumulator.push({
          //@ts-ignore
          name: person.name,
          //@ts-ignore
          dateFrom: start,
          //@ts-ignore
          dateTo: end ? end : person.calendar.days[person.calendar.days.length - 1].date,
          //@ts-ignore
          leaveType: leaveType,
          //@ts-ignore
          leaveWorkDaysCount: workdayCount
        });
      }

      return accumulator;
    }, [])
    .map(row => ({
      //@ts-ignore
      name: row.name,
      //@ts-ignore
      dateFrom: row.dateFrom.toLocaleString(),
      //@ts-ignore
      dateTo: row.dateTo.toLocaleString(),
      //@ts-ignore
      leaveType: dayStatuses.find(x => x.id === row.leaveType)!.name,
      //@ts-ignore
      leaveWorkDaysCount: row.leaveWorkDaysCount
    }));

  return { headerrow, itemrows };
};

export const generateProjectWorkHoursCSV = (projectSums: PersonActivityTimeProjectSumDTO[], selectedMonth: DateTime): { headerrow: Object; itemrows: Array<object> } => {
  /* THIS FUNCTION WRITTEN IN PURE JS. WE WILL REPLACE IT IN THE FUTURE, NOW I IGNORE ALL TYPESCRIPT THINGS (Richard's request)   */

  projectSums.sort((a, b) =>
    (a.lastName || '') < (b.lastName || '')
      ? -1
      : (a.lastName || '') > (b.lastName || '')
      ? 1
      : (a.firstName || '') < (b.firstName || '')
      ? -1
      : (a.firstName || '') > (b.firstName || '')
      ? 1
      : (a.middleName || '') < (b.middleName || '')
      ? -1
      : (a.middleName || '') > (b.middleName || '')
      ? 1
      : 0
  );

  const headerrow = {
    name: 'Név',
    rootProjectCode: 'Projekt kód',
    rootProjectName: 'Projekt név',
    projectCode: 'Alprojekt kód',
    projectName: 'Alprojekt név',
    ...Object.assign(
      //@ts-ignore
      ...Array(selectedMonth.daysInMonth)
        .fill(0)
        .map((x, i) => ({ ['day' + (i + 1)]: i + 1 + '.' }))
    )
  };

  const itemrows = projectSums.reduce(
    //@ts-ignore
    (accumulator, person) =>
      //@ts-ignore
      accumulator.push(
        //@ts-ignore
        ...(person.days.length > 0 ? person.days : [{ days: [] }]).map(project => ({
          name: [person.courtesyTitle, person.lastName, person.firstName, person.middleName].filter(Boolean).join(' '),
          rootProjectCode: project.rootProjectCode,
          rootProjectName: project.rootProjectName,
          projectCode: project.projectCode,
          projectName: project.projectName,
          ...project.days.reduce(
            (a, day) => {
              a['day' + DateTime.fromISO(day.date).day] = (day.sumActivityTimeMinutes / 60).toFixed(4);
              return a;
            },
            Object.assign(
              //@ts-ignore
              ...Array(selectedMonth.daysInMonth)
                .fill(0)
                .map((x, i) => ({ ['day' + (i + 1)]: '' }))
            )
          )
        }))
      ) && accumulator,
    []
  );

  //@ts-ignore
  return { headerrow, itemrows: itemrows as Array<object> };
};

export const generateActivitiesCSV = (personActivities: PersonActivityTimesDTO[], selectedMonth: DateTime): { headerrow: Object; itemrows: Array<object> } => {
  /* THIS FUNCTION WRITTEN IN PURE JS. WE WILL REPLACE IT IN THE FUTURE, NOW I IGNORE ALL TYPESCRIPT THINGS (Richard's request)   */

  personActivities.sort((a, b) =>
    (a.lastName || '') < (b.lastName || '')
      ? -1
      : (a.lastName || '') > (b.lastName || '')
      ? 1
      : (a.firstName || '') < (b.firstName || '')
      ? -1
      : (a.firstName || '') > (b.firstName || '')
      ? 1
      : (a.middleName || '') < (b.middleName || '')
      ? -1
      : (a.middleName || '') > (b.middleName || '')
      ? 1
      : 0
  );

  const headerrow = {
    name: 'Név',
    date: 'Dátum',
    starttime: 'Kezdete',
    endtime: 'Vége',
    activityDurationHours: 'Mért órák',
    rootProjectCode: 'Projekt kód',
    rootProjectName: 'Projekt név',
    projectCode: 'Alprojekt kód',
    projectName: 'Alprojekt név',
    comment: 'Mit csináltam?',
    tags: 'Címkék'
  };

  const itemrows = personActivities.reduce(
    //@ts-ignore
    (accumulator, person: PersonActivityTimesDTO) =>
      //@ts-ignore
      accumulator.push(
        //@ts-ignore
        ...(person.activities.length > 0 ? person.activities : [{ activities: [] }]).map((activity: ActivityDTO) => ({
          name: [person.courtesyTitle, person.lastName, person.firstName, person.middleName].filter(Boolean).join(' '),
          date: activity.appliedDate && DateTime.fromISO(activity.appliedDate).toLocaleString(),
          starttime: activity.startTime && DateTime.fromISO(activity.startTime).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
          endtime: activity.endTime && DateTime.fromISO(activity.endTime).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
          activityDurationHours: activity.activityDurationMinutes && (activity.activityDurationMinutes / 60).toFixed(4),
          rootProjectCode: activity.rootProjectCode,
          rootProjectName: activity.rootProjectName,
          projectCode: activity.projectCode,
          projectName: activity.projectName,
          comment: activity.comment,
          tags: activity.tags
        }))
      ) && accumulator,
    []
  );

  //@ts-ignore
  return { headerrow, itemrows: itemrows as Array<object> };
};

import { ConcurrentAddOrEditViewModel } from '../../../constants/types';
import { ISelectableRootProjectDTO, ISelectableSubProjectDTO } from '../../../typings/DTOs';
import { api } from '../../../utils/api';

//=============================ADD PROJECT==================================
export interface AddOrEditProjectDTO extends ConcurrentAddOrEditViewModel {
  projectId: number | null;
  parentProjectId: number | null;
  projectCode: string | null;
  displayCode: string | null;
  displayName: string | null;
  resourceGroupTypeId: string | null;
  isActive: boolean;
  isTimeRegisterable: boolean;
  colorCode: string | null;
}

export interface ProjectDTO {
  projectId: number;
  parentProjectId: number | null;
  rootProjectId: number | null;
  projectCode: string | null;
  displayCode: string | null;
  displayName: string | null;
  resourceGroupTypeId: string | null;
  isSyncronized: boolean;
  isActive: boolean;
  colorCode: string | null;
  isTimeRegisterable: boolean;
  subProjects: ProjectDTO[];
}

export const addProject = (model: AddOrEditProjectDTO) => api<ProjectDTO>('PUT', `projects`, model);

//=============================GET PROJECTS==================================
export const getProjects = () => api<Exclude<ProjectDTO, 'subProjects'>[]>('GET', `projects`);

//=============================GET PROJECT==================================
export const getProject = (id: number) => api<ProjectDTO>('GET', `projects/${id}`);

//=============================GET PROJECT SYNC==================================
export const getSync = () => api<{}>('GET', `projects/sync`);

export const getRootProjects = () => api<ISelectableRootProjectDTO[]>('GET', 'projects/foractivity');

export const getSubProjects = (id: number) => api<ISelectableSubProjectDTO>('GET', `projects/${id}`);

import React, { useContext, useState } from 'react';

/* Presentation Things */
import { LinearProgress } from '@rmwc/linear-progress';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';

/* Data Things */
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { useIsMounted } from '../../../../hooks';
import { Customer } from '../helper';

interface IProps {
  customer?: Customer;
  onSubmit(model: Customer): Promise<string>;
}

export function AddOrEdit(props: IProps) {
  /* Contexts */
  const { close } = useContext(SideSheetContext);

  /* States */
  const [editableCustomer, setEditableCustomer] = useState(props.customer || { id: 0, companyName: '' });
  const [isLoading, setIsLoading] = useState(false);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();

    try {
      setIsLoading(true);
      await props.onSubmit(editableCustomer);
      close();
    } catch (error) {
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setEditableCustomer(prev => ({ ...prev, [e.target.name]: String(e.target.value) }));
  };

  return (
    <>
      <div className="drawer-header">{props.customer ? 'Vevő szerkesztése' : 'Új vevő'}</div>

      {isLoading && <LinearProgress />}

      <div className="form-content">
        <form onSubmit={onSubmit}>
          <div className="form-content-row">
            <TextField required type="text" label="Cégnév" value={editableCustomer.companyName} name="companyName" onChange={onInputChange} autoFocus />
          </div>

          <Button unelevated label={props.customer ? 'Szerkesztés' : 'Hozzáadás'} type="submit" disabled={isLoading} />
        </form>
      </div>
    </>
  );
}

import { api } from '../../../utils/api';
import { ConcurrentEditViewModel } from '../Organization/Peoples/employment';

//=============================GET ACTIVITY TAGS==================================
export interface TagDTO {
  tagId: number;
  tagName: string | null;
}

export interface TagUpdateDTO extends TagDTO, ConcurrentEditViewModel {}

export const getActivityTags = () => api<TagUpdateDTO[]>('GET', 'activityTag');

//=============================ADD OR EDIT ACTIVITY TAG==================================
export const createActivityTag = (tagName: string) => api<TagUpdateDTO>('POST', `activityTag?tagName=${tagName}`);

export const editActivityTag = (model: TagUpdateDTO) => api<TagUpdateDTO>('PUT', 'activityTag', model);

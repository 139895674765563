import { addApplicationUser, AddUserDTO, ClaimAssignRowDTO, ClaimRowDTO, EditUserDTO, PersonRowDTO, putApplicationUser, UserRowDTO } from '../../../store/lib/Settings/applicationUsers';
import { MultiSelectorOption } from '../../../components/Selectors/MultiSelector';
import { SecurityGroupRowDTO } from '../../../store/lib/Settings/securityGroups';
import { AccessTypes } from '../../../constants/enums';

export const INITIAL_USER: IUserRow = {
  user: {
    id: 0,
    name: '',
    email: ''
  },
  person: {
    id: null,
    courtesyTitle: '',
    firstName: '',
    lastName: '',
    middleName: ''
  },
  isActive: true,
  isVisible: true,
  securityGroupIds: [],
  aadId: '',
  claimAssigns: {}
};
export interface IUserRow {
  user: {
    id: number;
    name: string;
    email: string;
  };
  person: {
    id: number | null;
    firstName: string;
    lastName: string;
    middleName: string;
    courtesyTitle: string;
  };
  isActive: boolean;
  isVisible: boolean;
  securityGroupIds: number[];
  aadId: string;
  claimAssigns: { [claimId: number]: IClaimAssignRow };
}

export type IClaimAssignRow = {
  claimId: number;
  claimName: string;
  accessTypeId: AccessTypes | null;
};
export interface IClaimRow {
  claimId: number;
  claimName: string;
  displayName: string;
  description: string;
  hasAccessTypes: boolean;
}
export interface IPersonRow {
  personId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  courtesyTitle: string;
}

export const transformUserRowDTO = (p: UserRowDTO, base?: IUserRow): IUserRow => {
  return {
    user: {
      id: p.userId,
      name: p.userName || '',
      email: p.userEmail || ''
    },
    person: {
      id: p.personId,
      firstName: p.personFirstName || '',
      lastName: p.personLastName || '',
      middleName: p.personMiddleName || '',
      courtesyTitle: p.personCourtesyTitle || ''
    },
    isVisible: true,
    isActive: p.isActive,
    securityGroupIds: base ? base.securityGroupIds : [],
    claimAssigns: base ? base.claimAssigns : {},
    aadId: base ? base.aadId : ''
  };
};

export const transformClaimAssignRowDTO = (p: ClaimAssignRowDTO): IClaimAssignRow => ({ ...p, claimName: p.claimName || '' });

export const transformEditUserDTO = (p: EditUserDTO, base?: IUserRow): IUserRow => {
  return {
    user: base
      ? base.user
      : {
          email: p.userEmail || '',
          id: p.userId,
          name: p.userName || ''
        },
    person: base
      ? base.person
      : {
          id: p.personId,
          courtesyTitle: '',
          firstName: '',
          lastName: '',
          middleName: ''
        },
    isVisible: true,
    isActive: p.isActive,
    claimAssigns: p.claimAssigns.reduce((acc, curr) => ({ ...acc, [curr.claimId]: transformClaimAssignRowDTO(curr) }), {}),
    securityGroupIds: p.securityGroupIds,
    aadId: p.aadId
  };
};

export const transformSecurityGroupRowDTOToMultiSelectorOptions = (p: SecurityGroupRowDTO): MultiSelectorOption => {
  return { id: p.id, label: p.groupName || '' };
};

export const transformClaimRowDTO = (p: ClaimRowDTO): IClaimRow => {
  return {
    claimId: p.claimId,
    claimName: p.claimName || '',
    displayName: p.displayName || '',
    description: p.description || '',
    hasAccessTypes: p.hasAccessTypes
  };
};

export const transformPersonRowDTO = (p: PersonRowDTO): IPersonRow => {
  return {
    personId: p.personId,
    courtesyTitle: p.courtesyTitle || '',
    firstName: p.firstName || '',
    lastName: p.lastName || '',
    middleName: p.middleName || ''
  };
};

export const transformIClaimAssignRow = (p: IClaimAssignRow): ClaimAssignRowDTO => {
  return {
    accessTypeId: p.accessTypeId,
    claimId: p.claimId,
    claimName: p.claimName || null
  };
};

export const transformIUserRowToAddUserDTO = (p: IUserRow): AddUserDTO => {
  return {
    aadId: p.aadId,
    securityGroupIds: p.securityGroupIds,
    personId: p.person.id,
    userEmail: p.user.email,
    userName: p.user.name,
    claimAssigns: Object.values(p.claimAssigns).map(x => transformIClaimAssignRow(x))
  };
};

export const transformIUserRowToEditUserDTO = (p: IUserRow): EditUserDTO => {
  return {
    aadId: p.aadId,
    userId: p.user.id,
    isActive: p.isActive,
    securityGroupIds: p.securityGroupIds,
    personId: p.person.id,
    userEmail: p.user.email,
    userName: p.user.name,
    claimAssigns: Object.values(p.claimAssigns).map(x => transformIClaimAssignRow(x))
  };
};

export const onCreateUserRemote = async (user: IUserRow): Promise<string | IUserRow> => {
  try {
    const resp = await addApplicationUser(transformIUserRowToAddUserDTO(user));
    if (resp && !resp.error) {
      return Promise.resolve(transformUserRowDTO(resp));
    } else throw new Error(resp.error || 'Sikertelen létrehozás');
  } catch (error) {
    return Promise.reject(String(error.message) || 'Sikertelen létrehozás');
  }
};

export const onEditUserRemote = async (user: IUserRow): Promise<string | IUserRow> => {
  try {
    const resp = await putApplicationUser(transformIUserRowToEditUserDTO(user));
    if (resp && !resp.error) {
      return Promise.resolve(transformUserRowDTO(resp));
    } else throw new Error(resp.error || 'Sikertelen módosítás');
  } catch (error) {
    return Promise.reject(String(error.message) || 'Sikertelen módosítás');
  }
};

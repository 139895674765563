import React, { useCallback, useContext, useEffect, useState } from 'react';

/* Data Things */
import { getOUTypesFromRemote, OUType, transformOUTypeDTO } from './helper';
import { RouteComponentProps } from '@reach/router';
import { SideSheetContext } from '../../../components/Contexts/SideSheet';
import { useIsMounted } from '../../../hooks/useIsMounted';

/* Routes  */
import { OUTypeAddOrEdit } from './AddOrEdit';
import { OUTypesList } from './List';

/* Presentation Things */
import { Typography } from '@rmwc/typography';
import { Fab } from '@rmwc/fab';

// Styles
import './OUTypes.scss';

interface IProps extends RouteComponentProps {}

export function OUTypes(_: IProps) {
  /* States */
  const [isLoading, setIsLoading] = useState(true);
  const [ouTypes, setOUTypes] = useState<OUType[]>([]);
  const [error, setError] = useState('');

  /* Refs */
  const _isMounted = useIsMounted();

  /* Context */
  const { open } = useContext(SideSheetContext);

  const getAllOUTypeFromRemote = useCallback(async () => {
    try {
      const types = await getOUTypesFromRemote();
      if (typeof types !== 'string') {
        _isMounted.current && setOUTypes(types.map(x => transformOUTypeDTO(x)));
      } else throw new Error(types);
    } catch (error) {
      _isMounted.current && setError(String(error.message || error));
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  }, [_isMounted]);

  const onOpenAddSheet = () => open(<OUTypeAddOrEdit {...{ onSetOUType, onAddOUType }} />);

  const onSetOUType = (type: OUType) => setOUTypes(types => types.map(x => (x.id === type.id ? type : x)));

  const onAddOUType = (type: OUType) => setOUTypes(types => [...types, type]);

  useEffect(() => void getAllOUTypeFromRemote(), [getAllOUTypeFromRemote]);

  return (
    <div>
      <div className="data-table-title">
        <div className="data-table-title__inner-content">
          <Typography className="title" use="body2">
            Szervezeti egység típusok
          </Typography>
        </div>
      </div>

      {!isLoading && !error.length && (
        <div className="fab-menu-container">
          <Fab mini icon="add" onClick={onOpenAddSheet} />
        </div>
      )}

      <OUTypesList {...{ ouTypes, isLoading, error, onSetOUType, onAddOUType }} />
    </div>
  );
}

import { api } from '../../../utils/api';
import { ConcurrentEditViewModel } from '../Organization/Peoples/employment';

//=====================================[GET]DayStatuses================================

export interface DayStatusRowDTO extends ConcurrentEditViewModel {
  dayStatusId: number;
  name: string | null;
  code: string | null;
  colorCode: string | null;
  isCodeHandled: boolean;
  isIncreaseWorkTime: boolean;
  isPaid: boolean;
  isWorkDay: boolean;
  canSetOnNonWorkday: boolean;
  canSetOnWorkday: boolean;
  canSetOnRestday: boolean;
  displayOrder: number | null;
  isLimited: boolean;
}
export const getDayStatuses = () => api<DayStatusRowDTO[]>('GET', `daystatuses`);

//=============================ADD OR EDIT DAYSTATUS==================================
export interface AddOrEditDayStatusRowDTO extends Omit<DayStatusRowDTO, 'dayStatusId'> {
  dayStatusId: number | null;
}

export const createOrEditDayStatus = (model: AddOrEditDayStatusRowDTO) => api<DayStatusRowDTO>('POST', `daystatuses`, model);

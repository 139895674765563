import { DayStatusRowDTO } from '../../../store/lib/MasterData/dailyStatuses';
import { ConcurrentEditViewModel } from '../../../store/lib/Organization/Peoples/employment';

export interface DayStatusRow extends ConcurrentEditViewModel {
  id: number;
  name: string;
  code: string;
  colorCode: string;
  isCodeHandled: boolean;
  isIncreaseWorkTime: boolean;
  isPaid: boolean;
  isWorkDay: boolean;
  canSetOnNonWorkday: boolean;
  canSetOnWorkday: boolean;
  canSetOnRestday: boolean;
  displayOrder: number | null;
  isLimited: boolean;
}

export interface AddOrEditDayStatusRow extends Omit<DayStatusRow, 'id'> {
  id: number | null;
}

export const INITIAL_STATE: AddOrEditDayStatusRow = {
  id: null,
  name: '',
  code: '',
  colorCode: '',
  isCodeHandled: false,
  isIncreaseWorkTime: false,
  isPaid: false,
  isWorkDay: false,
  canSetOnNonWorkday: false,
  canSetOnRestday: false,
  canSetOnWorkday: false,
  displayOrder: null,
  isLimited: false,
  updated: null
};

export const transformDayStatusRowDTO = (p: DayStatusRowDTO): DayStatusRow => ({ ...p, name: p.name || '', code: p.code || '', id: p.dayStatusId, colorCode: p.colorCode || 'black' });

import React, { memo } from 'react';

/* Presentationt Things */
import { Typography } from '@rmwc/typography';
import { CostInput } from '../../../../components/Inputs/CostInput';
import { ShowImage } from '../../../../components/Images/ShowImage';
import { Button } from '@rmwc/button';
import { Icon } from '@rmwc/icon';

/* Data Things */
import { generateMonthlyCostCSV, parseMonthlyCostCSVLine } from './helper';
import { IPreviewCloseCosts } from '../helper';
import { MonthlyCloseDTO } from '../../../../store/lib/TrackTime/monthlyClose';
import { formatName } from '../../../../constants/helperFuntions';
import { DateTime } from 'luxon';

type Props = {
  actual: DateTime;
  preview: Record<number, IPreviewCloseCosts>;
  model: MonthlyCloseDTO;
  isLoading: boolean;
  onPersonCostChange(id: number, cost: number | null): void;
  onUploadCSV(persons: MonthlyCloseDTO['persons']): void;
  onCloseCost(ev: React.FormEvent): void;
  onGetPreview(): void;
};

export const CostTable = memo(
  ({ actual, model, preview, isLoading, onPersonCostChange, onUploadCSV, onCloseCost, onGetPreview }: Props) => {
    const isDisabled = !model.persons || isLoading || model.isCostClosedForMonth;

    const summedCost = (): string => {
      return model.persons ? model.persons.reduce((acc, curr) => acc + Number(curr.realCostPerMonth), 0).toLocaleString() : '0';
    };

    const onDownloadCSV = () => {
      if (model.persons) {
        generateMonthlyCostCSV(model.persons, 'ProHR365 - ' + actual.toFormat('yyyy LLL') + ' - Havi zárás.csv');
      }
    };

    const handleUploadCSV = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = progressEvent => {
          if (progressEvent.target && progressEvent.target.result && model && model.persons) {
            const lines = (progressEvent.target.result as string).split('\n');
            lines.shift(); //header

            const result = lines.reduce((acc, curr) => {
              const data = parseMonthlyCostCSVLine(curr);
              return data ? { ...acc, [data.id]: { cost: data.cost } } : acc;
            }, [] as { [id: number]: { cost: number | null } });
            onUploadCSV(model.persons.map(x => (result[x.id] ? { ...x, realCostPerMonth: result[x.id].cost } : x)));
          }
        };

        reader.readAsText(e.target.files[0]);
      }
    };

    return (
      <>
        <h2 className="sub-title mdc-list-group__subheader mdc-typography--title sticky-2">
          Havi költségek
          <div className="button-group">
            <div className={`mdc-button ${isDisabled ? 'disabled' : ''}`} role="button" onClick={!isDisabled ? onDownloadCSV : undefined}>
              <Icon icon="download" disabled={isDisabled} />
            </div>

            <div className="file-selector-wrap">
              <label className={`file-selector__label mdc-button mdc-button--unelevated ${isDisabled ? 'disabled' : ''}`} htmlFor="fileSelect">
                <Icon icon="publish" disabled={isDisabled} />
              </label>

              <input
                disabled={isDisabled}
                id="fileSelect"
                className="file-selector__input"
                type="file"
                onChange={handleUploadCSV}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </div>
          </div>
        </h2>

        <form onSubmit={onCloseCost}>
          <div className="data-table">
            <div className="data-table__row data-table__row--title">
              <div className="flex-box-item data-table__cell person">
                <Typography use="caption">Dolgozó</Typography>
              </div>

              <div className="flex-box-item data-table__cell data-table__cell--align-right cost">
                <Typography use="caption">Költségek</Typography>
              </div>

              <div className="flex-box-item data-table__cell data-table__cell--align-right basic-hours">
                <Typography use="caption">Elvárt óraszám</Typography>
              </div>

              <div className="flex-box-item data-table__cell data-table__cell--align-right">
                <Typography use="caption">Ledolgozott órák</Typography>
              </div>

              <div className="flex-box-item data-table__cell data-table__cell--align-right">
                <Typography use="caption">Fizetett távollét(óra)</Typography>
              </div>

              <div className="flex-box-item data-table__cell data-table__cell--align-right">
                <Typography use="caption">Kalkulált óraszám</Typography>
              </div>

              <div className="flex-box-item data-table__cell data-table__cell--align-right">
                <Typography use="caption">Rögzített óradíj</Typography>
              </div>

              <div className="flex-box-item data-table__cell data-table__cell--align-right">
                <Typography use="caption">Kalkulált óradíj</Typography>
              </div>
            </div>

            <div className="data-table-body">
              {model.persons!.map(row => (
                <Row key={row.id} isClosed={model.isCostClosedForMonth} row={row} onCostChange={onPersonCostChange} preview={preview[row.id]} isDisabled={isDisabled} />
              ))}
            </div>
          </div>

          <div className="sub-title mdc-list-group__subheader mdc-typography--title footer">
            <span>
              Összesen: <span className="number"> {summedCost()} </span> Ft
            </span>

            <div className="button-group">
              <Button type="submit" className="mdc-button mdc-button--unelevated" disabled={isDisabled} label="Beküldöm" />
              <Button type="button" className="mdc-button" disabled={isDisabled} label="Előkalkuláció" onClick={onGetPreview} />
            </div>
          </div>
        </form>
      </>
    );
  },
  (prevProps, nextProps) => prevProps.actual === nextProps.actual && prevProps.isLoading === nextProps.isLoading && prevProps.model === nextProps.model
);

const Row = memo(
  ({ row, isClosed, isDisabled, onCostChange, preview }: any) => {
    const fullName = formatName(row.firstName, row.lastName, row.middleName, row.courtesyTitle);

    const handleCostChange = (value: number | null) => onCostChange(row.id, value);

    return (
      <div className="data-table__row">
        <div className="flex-box-item data-table__cell person">
          <div className="avatar-wrap">
            <ShowImage pictureURL={row.photoUrl} fullName={fullName}>
              <Typography use="body1">{fullName}</Typography>
            </ShowImage>
          </div>
        </div>

        <div className="flex-box-item data-table__cell cost">
          <CostInput disabled={isClosed} onChange={handleCostChange} value={row.realCostPerMonth} />
        </div>
        <div className="flex-box-item data-table__cell data-table__cell--align-right basic-hours">
          <span>{preview && !isDisabled ? preview.monthlyRequiredWorkHours.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) : '-'}</span>
        </div>

        <div className="flex-box-item data-table__cell data-table__cell--align-right">
          <span>{preview && !isDisabled ? preview.workHours.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : '-'}</span>
        </div>

        <div className="flex-box-item data-table__cell data-table__cell--align-right">
          <span>{preview && !isDisabled ? preview.paidIncreaseWorkTimeHour.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) : '-'}</span>
        </div>

        <div className="flex-box-item data-table__cell data-table__cell--align-right">
          <span>{preview && !isDisabled ? (preview.calculatedHoursResult / 3600).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : '-'}</span>
        </div>

        <div className="flex-box-item data-table__cell data-table__cell--align-right">
          <span>{preview && !isDisabled ? preview.currentHourlyCost.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 4 }) : '-'}</span>
        </div>

        <div className="flex-box-item data-table__cell data-table__cell--align-right">
          <span>
            {/* The calculatedHoursResult could be 0, and the js can't devide with 0 */}
            {preview && !isDisabled
              ? preview.calculatedHoursResult === 0
                ? '0'
                : (Number(row.realCostPerMonth) / (preview.calculatedHoursResult / 3600)).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4
                  })
              : '-'}
          </span>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.row.realCostPerMonth === nextProps.row.realCostPerMonth && prevProps.preview === nextProps.preview && prevProps.isDisabled === nextProps.isDisabled && prevProps.isClosed === nextProps.isClosed
);

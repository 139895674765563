import React, { useCallback, useEffect, useState } from 'react';
import { useRequestWithToken } from '../../hooks/useRequestWithToken';
import { useIsMounted } from '../../hooks/useIsMounted';

interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  pictureURL?: string | null;
  initials?: string;
  fullName?: string;
  wrapperClassName: string;
  imageClassName: string;
  initialsClassName: string;
}

export const ShowImage = ({ pictureURL, initials, fullName, children, wrapperClassName, imageClassName, initialsClassName, ...props }: Props) => {
  const [firstName, lastName] = fullName ? fullName.split(' ') : ['', ''];

  /* Variables */
  const localInitials = initials || firstName.charAt(0) + lastName.charAt(0);
  const source = useRequestWithToken(pictureURL || '');

  /* Ref */
  const _isMounted = useIsMounted();

  /* States */
  const [isLoading, setIsLoading] = useState(source ? true : false);
  const [isError, setIsError] = useState(false);

  const onSuccess = useCallback(() => _isMounted.current && setIsLoading(false), [_isMounted]);

  const onError = useCallback(() => {
    _isMounted.current && setIsLoading(false);
    _isMounted.current && setIsError(true);
  }, [_isMounted]);

  useEffect(() => {
    if (source) {
      const testImg = new Image();
      testImg.onerror = onError;
      testImg.onload = onSuccess;
      testImg.src = source;
    }
  }, [source, onError, onSuccess]);

  return (
    <div className={wrapperClassName}>
      {source && !isLoading && !isError ? (
        <div className={imageClassName}>
          <img {...props} decoding="async" src={source} alt={`avatar - ${localInitials}`} role="presentation" />
        </div>
      ) : (
        <div className={initialsClassName}>{localInitials}</div>
      )}
      {children}
    </div>
  );
};

ShowImage.defaultProps = {
  wrapperClassName: 'avatar-wrap',
  imageClassName: 'avatar',
  initialsClassName: 'avatar monogram'
};

import React, { Component } from 'react';

/* Presentation Things */
import { ContractsSection } from './ContractsSection';
import { DetailsSection } from './DetailsSection';
import { DaysOffSection } from './DaysOffSection';
import { LinearProgress } from '@rmwc/linear-progress';
import { OUsSections } from './OUsSections';
import { Button } from '@rmwc/button';

/* Data Things */
import { addEmployment, getEmployment, putEmployment } from '../../../../../store/lib/Organization/Peoples/employment';
import {
  tansformEditEmployeeEmploymentDTO,
  EditEmployeeEmployment,
  EmploymentContract,
  PersonOU,
  tansformEditEmployeeEmployment,
  AddEmployeeEmployment,
  initAddEmployeeEmployment,
  isEditEmployeeEmployment,
  transformAddEmployeeEmployment
} from './helper';
import { PersonDetailsEmployment, transformEmploymentDetails } from '../helper';
import ResponseSnackbar, { ApiResponseType } from '../../../../Portals/ResponseSnackbar';
import { ContextProps } from '../../../../Contexts/SideSheet';
import { DateTime } from 'luxon';

/* Style */
import '../../PeopleDetails/Edit/PeopleEdit.scss';

interface IState {
  editModel: EditEmployeeEmployment | AddEmployeeEmployment | null;
  loading: boolean;
  apiResponse: ApiResponseType;
}

interface IProps {
  personId: number;
  employmentId: number | null;
  lastEmploymentEndDate: DateTime | null;
  updated: string | null;
  onSave(newModel: PersonDetailsEmployment, updated: string | null);
  close: ContextProps['close'];
}
export class EditEmployment extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      editModel: props.employmentId === null ? { ...initAddEmployeeEmployment, updated: props.updated } : null,
      loading: Boolean(props.employmentId),
      apiResponse: null
    };
  }

  static defaultProps = {
    model: null,
    lastEmploymentEndDate: null,
    employmentId: null,
    updated: null
  };

  private _isMounted = false;

  componentDidMount() {
    this._isMounted = true;

    if (this.props.employmentId !== null) {
      getEmployment(this.props.personId, this.props.employmentId)
        .then(resp => this._isMounted && this.setState({ editModel: tansformEditEmployeeEmploymentDTO(resp), loading: false }))
        .catch(() => this._isMounted && this.setState({ loading: false }));
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  private handleEditModel = <T extends keyof EditEmployeeEmployment, K extends EditEmployeeEmployment[T]>(key: T, value: K) => {
    this.setState(state => {
      if (state.editModel) {
        return {
          ...state,
          editModel: { ...state.editModel, [key]: value }
        };
      }
      return state;
    });
  };

  private handleAddContract = (contract: EmploymentContract) => {
    this.setState(state => {
      if (state.editModel) {
        return { ...state, editModel: { ...state.editModel, employmentContracts: [contract, ...state.editModel.employmentContracts] } };
      }
      return state;
    });
  };

  private handleUpdateContract = (contract: EmploymentContract | undefined, index: number) => {
    // if contract is undefined it means DELETE
    this.setState(state => {
      if (state.editModel && contract) {
        return {
          ...state,
          editModel: { ...state.editModel, employmentContracts: state.editModel.employmentContracts.map((_, i) => (i === index ? contract : _)) }
        };
      } else if (state.editModel && !contract) {
        return {
          ...state,
          editModel: { ...state.editModel, employmentContracts: state.editModel.employmentContracts.filter((_, i) => i !== index) }
        };
      }
      return state;
    });
  };

  private handleAddOU = (newOu: PersonOU) => {
    this.setState(state => {
      if (state.editModel) {
        return { ...state, editModel: { ...state.editModel, OUs: [...state.editModel.OUs, newOu] } };
      }
      return state;
    });
  };

  private handleUpdateOU = (ou: PersonOU | undefined, index: number | undefined) => {
    // if ou is undefined it means DELETE
    this.setState(state => {
      if (state.editModel && ou && index !== undefined) {
        return {
          ...state,
          editModel: { ...state.editModel, OUs: state.editModel.OUs.map((_, i) => (i === index ? ou : _)) }
        };
      } else if (state.editModel && !ou && index !== undefined) {
        return {
          ...state,
          editModel: { ...state.editModel, OUs: state.editModel.OUs.filter((_, i) => i !== index) }
        };
      } else if (state.editModel && !ou && !index) {
        return { ...state, editModel: { ...state.editModel, OUs: [] } };
      }
      return state;
    });
  };

  private handleAddEmployment = (model: AddEmployeeEmployment) => {
    const newEmployment = transformAddEmployeeEmployment(model);
    if (newEmployment) {
      this.setState({ loading: true }, () => {
        addEmployment(this.props.personId, newEmployment)
          .then(resp => {
            if (!resp.error) {
              this._isMounted &&
                this.setState({ loading: false, apiResponse: { type: 'success', message: 'Sikeres hozzáadás' } }, () => {
                  this.props.onSave(transformEmploymentDetails(resp), resp.updated);
                  this.props.close();
                });
            } else {
              throw new Error(resp.error);
            }
          })
          .catch(err => {
            this._isMounted && this.setState({ loading: false, apiResponse: { type: 'error', message: `Sikertelen hozzáadás (${err.message})` } });
          });
      });
    }
  };

  private handleEditEmployment = (model: EditEmployeeEmployment) => {
    const newEmployment = tansformEditEmployeeEmployment(model);
    if (newEmployment) {
      this.setState({ loading: true }, () => {
        putEmployment(this.props.personId, newEmployment.employmentId, newEmployment)
          .then(resp => {
            this._isMounted &&
              this.setState({ loading: false, apiResponse: { type: 'success', message: 'Sikeres szerkesztés' } }, () => {
                this.props.onSave(transformEmploymentDetails(resp), resp.updated);
                this.props.close();
              });
          })
          .catch(err => {
            this._isMounted && this.setState({ loading: false, apiResponse: { type: 'error', message: `Sikertelen szerkesztés (${err.message})` } });
          });
      });
    }
  };

  private handleSave = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (this.state.editModel) {
      if (isEditEmployeeEmployment(this.state.editModel)) {
        this.handleEditEmployment(this.state.editModel);
      } else {
        this.handleAddEmployment(this.state.editModel);
      }
    }
  };

  private handleCloseResponseSnackBar = () => this.setState({ apiResponse: null });

  render() {
    const { loading, editModel, apiResponse } = this.state;
    const { lastEmploymentEndDate, employmentId } = this.props;

    return (
      <>
        <div tabIndex={0} className="drawer-header">
          {`Foglalkoztatás ${employmentId ? 'szerkesztése' : 'hozzáadás'}`}
        </div>

        <div className="form-content employment employment-edit">
          <form onSubmit={this.handleSave}>
            {loading && <LinearProgress className="linear-progress" />}

            {!editModel && !loading && <div className="basic-error-text">Sajnáljuk, valami hiba történt. Kérlek próbáld újra!</div>}

            {editModel && (
              <>
                {
                  //@ts-ignore
                  <DetailsSection {...{ lastEmploymentEndDate, editModel, onEditModel: this.handleEditModel }} />
                }

                <DaysOffSection daysOff={editModel.daysOff} onEditModel={this.handleEditModel} employmentStartYear={editModel.startDate.year} />

                <ContractsSection employmentStartDate={editModel.startDate} contracts={editModel.employmentContracts} onAddContract={this.handleAddContract} onUpdateContract={this.handleUpdateContract} />

                <OUsSections onAddOU={this.handleAddOU} onUpdateOU={this.handleUpdateOU} editableOUs={editModel.OUs} companyId={editModel.companyId} />
                <Button disabled={loading} label={employmentId ? 'Mentés' : 'Hozzáadás'} unelevated type="submit" />
              </>
            )}

            <ResponseSnackbar response={apiResponse} onClose={this.handleCloseResponseSnackBar} />
          </form>
        </div>
      </>
    );
  }
}

export default EditEmployment;

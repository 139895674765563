import { IEmployee, transformEmployeeDTO } from '../../../../routes/Organization/People/PeopleList/helper';
import { ApiResponseType } from '../../../../components/Portals/ResponseSnackbar';
import { EmployeeDTO } from '../../../lib/Organization/Peoples/organization';

export const initialState: PeopleState = {
  people: {},
  isLoading: true,
  isUserCanEditUsers: false,
  error: '',
  apiResponse: null
};

export interface PeopleState {
  people: { [id: number]: IEmployee };
  isLoading: boolean;
  isUserCanEditUsers: boolean;
  error: string;
  apiResponse: ApiResponseType;
}

type PeopleAction =
  | { type: 'error_get_people'; payload: { error: string } }
  | { type: 'success_get_people'; payload: { all: EmployeeDTO[]; active: EmployeeDTO[] } }
  | { type: 'set_isLoading'; payload: { isLoading: boolean } }
  | { type: 'filterByName'; payload: { searchText: string } }
  | { type: 'set_apiResponse'; payload: { apiResponse: ApiResponseType } }
  | { type: 'set_partial_profile'; payload: { newProfile: Partial<IEmployee> } }
  | { type: 'on_add_people'; payload: { people: IEmployee } }
  | { type: 'set_isUserCanEditUsers'; payload: { isUserCanEditUsers: boolean } };

export function peopleReducer(state: PeopleState = initialState, action: PeopleAction): PeopleState {
  switch (action.type) {
    case 'error_get_people': {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }

    case 'success_get_people': {
      const peopleMap = state.people;

      //The people partial profile(employment and details) can be exist,
      //because if the user went first the profile page, then in the list the partial data already exist
      action.payload.all.forEach(people => (peopleMap[people.personId] = transformEmployeeDTO(people, false, peopleMap[people.personId])));
      action.payload.active.forEach(people => (peopleMap[people.personId] = transformEmployeeDTO(people, true, peopleMap[people.personId])));

      return {
        ...state,
        people: { ...peopleMap }, //spread cuz new instance
        isLoading: false,
        error: ''
      };
    }

    case 'set_isLoading': {
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    }

    case 'filterByName': {
      const peopleMap = state.people;

      Object.values(state.people).forEach(people =>
        [people.details.courtesyTitle, people.details.lastName, people.details.firstName, people.details.middleName].join(' ').toLowerCase().includes(action.payload.searchText.toLowerCase())
          ? (peopleMap[people.personId] = { ...people, isVisible: true })
          : (peopleMap[people.personId] = { ...people, isVisible: false })
      );

      return {
        ...state,
        people: { ...peopleMap } //spread cuz new instance
      };
    }

    case 'set_apiResponse': {
      return {
        ...state,
        apiResponse: action.payload.apiResponse
      };
    }

    case 'set_partial_profile': {
      const { personId, details, employment } = action.payload.newProfile;

      if (personId !== undefined) {
        //Set the name on the LeftSide component and the isPrivate flag
        if (details && details.basicInfo) {
          const name = details.basicInfo.name.split(' ');
          action.payload.newProfile = {
            ...action.payload.newProfile,
            isPrivate: details.basicInfo.isPrivate,
            details: { ...action.payload.newProfile.details, firstName: name[1], lastName: name[0] }
          };
        }

        //Set the phoneNumber and the email on the LeftSide component
        if (details && details.contactInfo) {
          const phoneNumber = details.contactInfo.publicMobilePhone;
          const email = details.contactInfo.publicEmail;
          action.payload.newProfile = {
            ...action.payload.newProfile,
            details: { ...action.payload.newProfile.details, email, phoneNumber }
          };
        }

        //Set the isActive flag if the employment is changed
        if (employment) {
          const isActive = !employment.lastEmployment.endDate;
          const jobRoles = employment.lastEmployment.employmentContracts[0].jobRoles.map(x => x.jobRoleName);

          action.payload.newProfile = {
            ...action.payload.newProfile,
            details: { ...action.payload.newProfile.details, jobRoles },
            isActive
          };
        }

        const people = state.people;
        people[personId] = { ...people[personId], ...action.payload.newProfile };

        return { ...state, people };
      }
      return state;
    }

    case 'on_add_people': {
      return {
        ...state,
        apiResponse: { type: 'success', message: 'Sikeres ember hozzáadás' },
        people: { ...state.people, [action.payload.people.personId]: action.payload.people }
      };
    }

    case 'set_isUserCanEditUsers': {
      return {
        ...state,
        isUserCanEditUsers: action.payload.isUserCanEditUsers
      };
    }

    default:
      return state;
  }
}

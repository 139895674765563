import { PersonDetailsEmploymentDTO, EmploymentDetailsRowDTO, EmploymentContractDetailsDTO } from '../../../../store/lib/Organization/Peoples/employment';
import { DateTime } from 'luxon';
import { OUMemberTypes, EmploymentTypes } from '../../../../constants/enums';

export interface PersonDetailsEmployment {
  employments: EmploymentDetailsRow[];
  lastEmployment: EmploymentDetails;
}

export type EmploymentDetailsRow = {
  employmentId: number;
  employmentType: EmploymentTypes;
  companyName: string;
  weeklyWorkHours: number | null;
  grossSalary: number | null; //float
  startDate: DateTime;
  endDate: DateTime | null;
  employmentContracts: EmploymentContractDetails[];
};

export type EmploymentContractDetails = {
  employmentContractId: number;
  startDate: DateTime;
  endDate: DateTime | null;
  trialEndDate: DateTime | null;
  weeklyWorkHours: number;
  grossSalary: number | null; //float
  jobRoles: PersonJobRoleDetails[];
  workScheduleName: string | null;
};

type PersonJobRoleDetails = {
  jobRoleId: number;
  jobRoleName: string;
};

export type EmploymentDetails = {
  employmentId: number;
  employmentType: EmploymentTypes;
  companyName: string;
  startDate: DateTime;
  endDate: DateTime | null;
  childrenInHousehold: number | null;
  taxReducement: number | null;
  daysOff: EmploymentDaysOff[];
  resourceGroupTypeId: string;
  employmentContracts: EmploymentContractDetails[];
  oUs: PersonDetailsOU[];
};

export type EmploymentDaysOff = {
  year: number;
  daysOff: number;
};

type PersonDetailsOU = {
  ouId: number;
  ouName: string;
  ouMemberType: OUMemberTypes;
};

export const transformEmploymentDetails = (p: PersonDetailsEmploymentDTO): PersonDetailsEmployment => {
  return {
    employments: p.employments.map(dto => transformEmploymentDetailsRow(dto)).sort((x, y) => y.startDate.valueOf() - x.startDate.valueOf()),
    lastEmployment: {
      ...p.lastEmployment,
      startDate: DateTime.fromISO(p.lastEmployment.startDate),
      endDate: p.lastEmployment.endDate ? DateTime.fromISO(p.lastEmployment.endDate) : null,
      employmentContracts: p.lastEmployment.employmentContracts.map(x => transformEmploymentContractDetails(x)).sort((x, y) => y.startDate.valueOf() - x.startDate.valueOf())
    }
  };
};

const transformEmploymentDetailsRow = (dto: EmploymentDetailsRowDTO): EmploymentDetailsRow => {
  return {
    ...dto,
    grossSalary: dto.grossSalary ? parseFloat(dto.grossSalary) : null,
    startDate: DateTime.fromISO(dto.startDate),
    endDate: dto.endDate ? DateTime.fromISO(dto.endDate) : null,
    employmentContracts: dto.employmentContracts.map(x => transformEmploymentContractDetails(x))
  };
};

const transformEmploymentContractDetails = (dto: EmploymentContractDetailsDTO): EmploymentContractDetails => {
  return {
    ...dto,
    startDate: DateTime.fromISO(dto.startDate),
    endDate: dto.endDate ? DateTime.fromISO(dto.endDate) : null,
    trialEndDate: dto.trialEndDate ? DateTime.fromISO(dto.trialEndDate) : null,
    grossSalary: dto.grossSalary ? parseFloat(dto.grossSalary) : null
  };
};

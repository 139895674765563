/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useReducer } from 'react';

/* Data Things */
import { ActiveTab, AddPersonBasicInfo, AddPersonContactInfo, AddPersonPersonalInfo, IAddEmployeeModel, ACTIVETAB_MAP, TAB_ITEMS, transformIAddEmployeeModel, transformPersonDetailsDTO } from './helper';
import { addReducer, initState } from '../../../../store/reducers/Organization/Peoples/addEmployeeReducer';
import { EmploymentContract } from '../../../../components/People/ProfileDetails/Employment/Edit/helper';
import { RouteComponentProps } from '@reach/router';
import { createEmployee } from '../../../../store/lib/Organization/Peoples/peopleAdd';
import { PeopleContext } from '../../../../components/Contexts/People';
import { useIsMounted } from '../../../../hooks/useIsMounted';
import { AddressDTO } from '../../../../store/lib/Organization/Peoples/peopleDetails';

/* Presentation Things */
import ResponseSnackbar from '../../../../components/Portals/ResponseSnackbar';
import { AddPersonalInfoSection } from '../../../../components/People/Add/PersonalInfoSection';
import { AddAttachmentsSection } from '../../../../components/People/Add/AttachmentsSection';
import { AddContactInfoSection } from '../../../../components/People/Add/ContactSection';
import { AddEmploymentSection } from '../../../../components/People/Add/EmploymentSection';
import { AddBasicInfoSection } from '../../../../components/People/Add/BasicInfoSection';
import { SelectedFile } from '../../../../components/Selectors/FileSelector';
import { Button } from '@rmwc/button';
import { Icon } from '@rmwc/icon';

/* Style */
import './PeopleAdd.scss';

export function ProfileAdd(props: RouteComponentProps) {
  const { onAddPeople, onSetApiResponse, apiResponse } = useContext(PeopleContext);
  const _isMounted = useIsMounted();
  const [state, dispatch] = useReducer(addReducer, initState);
  const { activeTab, tabStates, model, isLoading } = state;
  const isLastTab = activeTab === TAB_ITEMS.length;
  const isDisabled = isLastTab && Object.values(tabStates).some(x => x === 'error');

  const onEditEmployment = <T extends keyof IAddEmployeeModel['employment'], K extends IAddEmployeeModel['employment'][T]>(key: T, value: K) => {
    dispatch({ type: 'set_partial_employment', payload: { partial: { [key]: value } } });
  };

  const onEditBasicInfo = <T extends keyof AddPersonBasicInfo, K extends AddPersonBasicInfo[T]>(key: T, value: K) => {
    dispatch({ type: 'set_partial_basicInfo', payload: { partial: { [key]: value } } });
  };

  const onEditContactInfo = <T extends keyof AddPersonContactInfo, K extends AddPersonContactInfo[T]>(key: T, value: K) => {
    dispatch({ type: 'set_partial_contactInfo', payload: { partial: { [key]: value } } });
  };

  const onEditPersonalInfo = <T extends keyof AddPersonPersonalInfo, K extends AddPersonPersonalInfo[T]>(key: T, value: K) => {
    dispatch({ type: 'set_partial_personalInfo', payload: { partial: { [key]: value } } });
  };

  const onEditAddress = <T extends keyof AddressDTO, K extends AddressDTO[T]>(homeType: 'privateHomeAddress' | 'privateResidenceAddress', key: T, value: K) => {
    dispatch({ type: 'update_address', payload: { homeType, key, value } });
  };

  const onUpdateContract = (contract: EmploymentContract | undefined, index: number) => {
    dispatch({ type: 'update_contract_employment', payload: { contract, index } });
  };

  const onAddContract = (contract: EmploymentContract) => dispatch({ type: 'add_contract_employment', payload: { contract } });

  const onAddAttachment = (attachment: SelectedFile) => dispatch({ type: 'add_attachment', payload: { attachment } });

  const setActiveTab = (activeTab: ActiveTab) => dispatch({ type: 'set_activeTab', payload: { activeTab } });

  const onDeleteAttachment = (id: string) => dispatch({ type: 'delete_attachment', payload: { id } });

  const onNext = (event: React.FormEvent) => {
    event.preventDefault();
    setActiveTab(activeTab + 1);

    if (isLastTab) {
      onSubmit();
    }
  };

  const onBack = () => props.navigate && props.navigate('../', { replace: true });

  const onSubmit = () => {
    const sendAble = transformIAddEmployeeModel(model);
    if (sendAble) {
      dispatch({ type: 'set_isLoading', payload: { isLoading: true } });
      createEmployee(sendAble)
        .then(resp => {
          if (_isMounted.current) {
            onAddPeople(transformPersonDetailsDTO(resp, model.employment.isEmployee));
            props.navigate && props.navigate(`../Details/${resp.personId}`);
          }
        })
        .catch(err => {
          if (_isMounted.current) {
            dispatch({ type: 'error_save' });
            onSetApiResponse({ type: 'error', message: String(err.message) });
          }
        });
    }
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case ActiveTab.Employment:
        return <AddEmploymentSection {...{ onUpdateContract, onAddContract, isLoading }} model={model.employment} onEditModel={onEditEmployment} />;
      case ActiveTab.Attachments:
        return <AddAttachmentsSection {...{ attachments: model.attachments, onAdd: onAddAttachment, onDelete: onDeleteAttachment }} />;
      case ActiveTab.Contact:
        return <AddContactInfoSection {...{ model: model.contactInfo, onEditModel: onEditContactInfo, onEditAddress }} />;
      case ActiveTab.Personal:
        return <AddPersonalInfoSection {...{ model: model.personalInfo, onEditModel: onEditPersonalInfo }} />;
      case ActiveTab.BasicInfo:
        return <AddBasicInfoSection {...{ model: model.basicInfo, onEditModel: onEditBasicInfo }} />;
    }
  };

  return (
    <>
      <div className="wizard-tab-navigation">
        {TAB_ITEMS.map((tab, i) => (
          <Tab {...{ key: i, activeTab, setActiveTab, tabStates, tabNumber: Number(tab) }} />
        ))}
      </div>

      <div className="wizard-tab-content">
        <form onSubmit={onNext}>
          {renderActiveTab()}

          <div className="wizard-tab-content-footer">
            <Button unelevated disabled={isDisabled} label={isLastTab ? 'Mentés' : 'Tovább'} type="submit" />
            <Button label="Mégse" onClick={onBack} />
          </div>
        </form>
      </div>

      <ResponseSnackbar response={apiResponse} onClose={onSetApiResponse} />
    </>
  );
}

function Tab({ tabStates, activeTab, tabNumber, setActiveTab }) {
  const tabState = tabStates[tabNumber];
  const onTabChange = () => setActiveTab(tabNumber);

  return (
    <div className={`wizard-tab ${activeTab === tabNumber ? 'active' : tabState}`} onClick={onTabChange}>
      <div className="tab-leading-content">{activeTab === tabNumber ? <Icon icon="edit" /> : tabState === 'error' ? <Icon icon="error" /> : tabState === 'success' ? <Icon icon="done" /> : tabNumber}</div>

      <div className="tab-title">
        {ACTIVETAB_MAP[tabNumber].title}
        {ACTIVETAB_MAP[tabNumber].isRequired && <sup>*</sup>}
      </div>
    </div>
  );
}

import { useCallback, useEffect, useRef, useState } from 'react';
import { api } from '../utils/api';

export const useRequestWithToken = (src: string): string => {
  const [source, setSource] = useState('');
  const _abort = useRef(new AbortController()).current;

  const getWithToken = useCallback(
    async (source: string) => {
      if (source) {
        try {
          const resp = await api<string>('GET', source, undefined, _abort.signal, undefined);
          if (resp) setSource(resp);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [_abort]
  );

  useEffect(() => {
    getWithToken(src);
    return () => _abort.abort();
  }, [src, getWithToken, _abort]);

  return source;
};

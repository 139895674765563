import React, { useMemo } from 'react';
import { Project } from '../../typings/common';

type ProjectDisplayType = {
  rootProjectId?: number | null;
  rootProjectColorCode?: string | null;
  rootProjectDisplayCode?: string;
  rootProjectDisplayName?: string;
  projectId?: number | null;
  projectColorCode: string | null;
  projectDisplayCode: string | null;
  projectDisplayName: string;
};

type Props = {
  project?: ProjectDisplayType | Project;
  showRootProject?: boolean;
};

export const ProjectDisplay = ({ project: rawProject, showRootProject = true }: Props) => {
  const project = useMemo(() => (rawProject ? (isProjectDisplayType(rawProject) ? rawProject : transformProjectDTO(rawProject)) : null), [rawProject]);

  return project && project.projectId ? (
    <span className="project-selector-wrap">
      {/* Main project */}
      {project.rootProjectId && showRootProject && (
        <span className="project-selector">
          {project.rootProjectColorCode && <span className="project-color" style={{ backgroundColor: `${project.rootProjectColorCode}` }} />}
          <span className={`project-code ${project.rootProjectColorCode ? 'border-radius' : ''}`}>{project.rootProjectDisplayCode}</span>
          <span className="project-name" title={project.rootProjectDisplayName}>
            {project.rootProjectDisplayName}
          </span>
        </span>
      )}

      {/* Sub project */}
      <span className="project-selector indent-wrap">
        {project.rootProjectId && showRootProject && <span className="indent indent--end" />}

        {project.projectColorCode && <span className="project-color" style={{ backgroundColor: `${project.projectColorCode}` }} />}
        <span className={`project-code ${project.rootProjectColorCode || project.projectColorCode ? 'border-radius' : ''}`}>{project.projectDisplayCode}</span>

        <span className="project-name" title={project.projectDisplayName}>
          {project.projectDisplayName}
        </span>
      </span>
    </span>
  ) : (
    <span className="not-found empty-cell">Nincs kiválasztva projekt</span>
  );
};

const isProjectDisplayType = (p: ProjectDisplayType | Project): p is ProjectDisplayType => {
  return (p as ProjectDisplayType).projectColorCode !== undefined;
};

const transformProjectDTO = (p: Project): ProjectDisplayType => {
  return {
    projectColorCode: p.colorCode,
    projectDisplayCode: p.code,
    projectDisplayName: p.name,
    projectId: p.id,
    rootProjectColorCode: p.rootProject ? p.rootProject.colorCode : undefined,
    rootProjectDisplayCode: p.rootProject ? p.rootProject.code : undefined,
    rootProjectDisplayName: p.rootProject ? p.rootProject.name : undefined,
    rootProjectId: p.rootProject ? p.rootProject.id : undefined
  };
};

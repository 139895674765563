import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';

/* Data Things */
import { Product } from '../helper';
import { PRODUCT_ITEM_QUANTITY_UNIT_MAP } from '../../../../constants/constants';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { DEFAULT_PRODUCT } from './helper';
import { IProductConversionRatesDTO } from '../../../../typings/DTOs';
import { useIsMounted } from '../../../../hooks';
import { formatBytes } from '../../../../constants/helperFuntions';
import { uploadFile } from '../../../../store/lib/upload';

/* Presentation Things */
import FileSelector, { SelectedFile } from '../../../../components/Selectors/FileSelector';
import { LinearProgress } from '@rmwc/linear-progress';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { Checkbox } from '@rmwc/checkbox';
import { IconButton } from '@rmwc/icon-button';

interface IProps {
  product?: Product;
  parentId?: number;
  parentName?: string;
  onSubmit(product: Product, primaryPicture: SelectedFile, otherPictures: SelectedFile[], id: number | null): Promise<Boolean>;
}

const PRODUCT_ITEM_QUANTITY_UNIT_SELECT = Object.keys(PRODUCT_ITEM_QUANTITY_UNIT_MAP).map(x => ({ value: x, label: PRODUCT_ITEM_QUANTITY_UNIT_MAP[+x] }));

const MAX_FILE_SIZE = 8000000; //bytes

export function AddOrEditProduct(props: IProps) {
  /* Contexts */
  const { close } = useContext(SideSheetContext);

  /* Hooks */
  const _isMounted = useIsMounted();

  /* Variables */
  const parentName = props.parentName || null;
  const parentId = props.parentId || null;

  /* States */
  const [primaryPicture, setPrimaryPicture] = useState<SelectedFile | null>(null);
  const [otherPictures, setOtherPictures] = useState<SelectedFile[]>([]);
  const [newQuantityUnit, setNewQuantityUnit] = useState('');
  const [newQuantityChange, setNewQuantityChange] = useState('');
  const [isShowNewQuantityUnit, setShowNewQuantityUnit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState(props.product || DEFAULT_PRODUCT(parentId));
  const isDisabled = isLoading || primaryPicture === null || (isShowNewQuantityUnit && (isNaN(+newQuantityChange) || +newQuantityChange === 0));

  const selectableNewQuantityUnit = useMemo(
    () => PRODUCT_ITEM_QUANTITY_UNIT_SELECT.filter(x => !(product.quantityUnit === parseInt(x.value) || (product.conversionRates && product.conversionRates.find(q => q.unit === parseInt(x.value))))),
    [product.quantityUnit, product.conversionRates]
  );

  const onSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();

    try {
      if (primaryPicture !== null) {
        setIsLoading(true);
        let newProduct = { ...product };
        if (isShowNewQuantityUnit) {
          if (product.id === -1) {
            newProduct.conversionRates = [{ unit: parseInt(newQuantityUnit), rate: parseFloat(newQuantityChange) }];
          } else {
            newProduct.conversionRates!.push({ unit: parseInt(newQuantityUnit), rate: parseFloat(newQuantityChange) });
          }
        }
        let resp = await props.onSubmit(newProduct, primaryPicture, otherPictures, product.id);
        if (resp === true) {
          close();
        }
      } else throw new Error('Kérlek válassz ki elsődleges képet');
    } catch (error) {
      console.log(error);
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const onSelectPrimaryPicture = async (selectedFile: SelectedFile): Promise<{ locationId: number | null; message: string }> => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile.file);
      const [{ id, error }] = await uploadFile(formData);

      if (!error && id !== null && _isMounted.current) {
        setPrimaryPicture({ ...selectedFile, locationId: id });
        setProduct({ ...product, primaryPicture: { ...selectedFile, locationId: id, isLoading: false } });
        return Promise.resolve({ locationId: id, message: '' });
      } else throw new Error(error || 'Valami hiba történt, kérlek próbáld újra később.');
    } catch (error) {
      const { message } = error as any;
      return Promise.reject({ locationId: null, message: String(message || selectedFile.error || error) });
    }
  };

  const onSelectOtherPicture = async (selectedFile: SelectedFile): Promise<{ locationId: number | null; message: string }> => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile.file);
      const [{ id, error }] = await uploadFile(formData);

      if (!error && id !== null && _isMounted.current) {
        setOtherPictures(prev => [...prev, { ...selectedFile, locationId: id }]);
        return Promise.resolve({ locationId: id, message: '' });
      } else throw new Error(error || 'Valami hiba történt, kérlek próbáld újra később.');
    } catch (error) {
      const { message } = error as any;
      return Promise.reject({ locationId: null, message: String(message || selectedFile.error || error) });
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    switch (e.target.type) {
      case 'select-one':
        if (e.target.name === 'quantityUnit') {
          setProduct(prev => ({ ...prev, [e.target.name]: Number(e.target.value), conversionRates: [] }));
        } else {
          setProduct(prev => ({ ...prev, [e.target.name]: Number(e.target.value) }));
        }
        break;

      case 'checkbox':
        setProduct(prev => ({ ...prev, [e.target.name]: e.target.checked }));
        break;

      default:
        setProduct(prev => ({ ...prev, [e.target.name]: String(e.target.value) }));
        break;
    }
  };

  const onDeleteOtherPicture = (id: string) => setOtherPictures(prev => prev.filter(x => x.id !== id));

  const onDeletePrimaryPicture = () => setPrimaryPicture(null);

  useEffect(
    () => {
      if (props.product && props.product.id !== -1) {
        setPrimaryPicture(props.product.primaryPicture);
        setProduct(props.product);
      }
    },
    [props.product]
  );

  const onSetNewQuantityUnit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNewQuantityUnit(e.target.value);
  };

  const onSetNewQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewQuantityChange(e.target.value);
  };

  const onShowNewQuantityUnitInput = () => {
    setNewQuantityUnit(selectableNewQuantityUnit[0].value);
    setNewQuantityChange('');
    setShowNewQuantityUnit(true);
  };

  const onDeleteAdditionalQuantityUnit = (unit: number) => {
    setProduct(prev => ({ ...prev, conversionRates: prev.conversionRates!.filter(u => u.unit !== unit) }));
  };

  return (
    <>
      <div className="drawer-header">
        {isLoading && <LinearProgress className="linear-progress" />}
        {parentName ? `${parentName} cikkhez rendelés` : product.id !== -1 ? 'Cikk szerkesztése' : 'Új cikk létrehozása'}
      </div>

      <div className="form-content">
        <form onSubmit={onSubmit} className="grid">
          <div className="form-content-row col-span-2">
            <TextField className="field" name="title" label="Megnevezés" value={product.title} onChange={onInputChange} autoFocus required />
          </div>
          <div className="form-content-row col-span-2">
            <TextField className="field" name="itemNoRaw" label="Festetlen cikkszám" value={product.itemNoRaw} onChange={onInputChange} />
            <TextField className="field" name="itemNoReady" label="Festett cikkszám" value={product.itemNoReady} onChange={onInputChange} />
          </div>

          <Select className="field unset-margin-right" name="quantityUnit" required label="Mennyiségi egység" value={String(product.quantityUnit)} options={PRODUCT_ITEM_QUANTITY_UNIT_SELECT} onChange={onInputChange} />
          {selectableNewQuantityUnit.length === 0 || !isShowNewQuantityUnit ? (
            <Button type="button" icon="add" label="Új mértékegység" onClick={onShowNewQuantityUnitInput} disabled={selectableNewQuantityUnit.length === 0} />
          ) : (
            <>
              <InputWithSelect
                inputName="exchangeNumber"
                selectName="exchangeUnit"
                inputLabel="Váltó egység"
                inputValue={newQuantityChange}
                selectValue={newQuantityUnit}
                options={selectableNewQuantityUnit}
                selectOnChange={onSetNewQuantityUnit}
                inputOnChange={onSetNewQuantityChange}
              />
            </>
          )}
          {product.conversionRates && product.conversionRates.length > 0 ? product.conversionRates.map((u, i) => <AdditionalQuantityUnit key={i} quantityUnit={u} onDelete={onDeleteAdditionalQuantityUnit} />) : null}

          <div className="form-content-row align-start col-span-2">
            <div>
              <FileSelector
                label="Elsődleges kép"
                initialFiles={product.primaryPicture ? [product.primaryPicture] : undefined}
                maxFileSize={MAX_FILE_SIZE}
                accept=".jpeg, .jpg, .png, .tif"
                onSelect={onSelectPrimaryPicture}
                onDelete={onDeletePrimaryPicture}
              />
              <div className="file-selector-helper-text">Maximális fájlméret: {formatBytes(MAX_FILE_SIZE)}.</div>
            </div>

            <div>
              <FileSelector
                isMultiple
                label="Egyéb képek"
                initialFiles={product.otherPictures.length ? product.otherPictures : undefined}
                maxFileSize={MAX_FILE_SIZE}
                accept=".jpeg, .jpg, .png, .tif"
                onSelect={onSelectOtherPicture}
                onDelete={onDeleteOtherPicture}
              />
              <div className="file-selector-helper-text">Maximális fájlméret: {formatBytes(MAX_FILE_SIZE)}.</div>
            </div>
          </div>

          <div className="form-content-row col-span-2">
            <Checkbox label="Elkészült cikknek gyári szám megadás közelező" name="hasSerialNumber" checked={product.hasSerialNumber} onChange={onInputChange} />
          </div>

          <div className="form-content-row col-span-2">
            <TextField textarea rows={3} className="field" label="Megjegyzés" name="notes" onChange={onInputChange} value={product.notes} />
          </div>

          <div className="form-content-row col-span-2">
            <Button type="submit" unelevated label={parentName ? 'Hozzárendelem' : product.id !== -1 ? 'Módosítom' : 'Létrehozom'} disabled={isDisabled} />
            <Button type="button" label="Mégse" onClick={close} disabled={isLoading} />
          </div>
        </form>
      </div>
    </>
  );
}

interface IPropsAdditionalQuantityUnit {
  quantityUnit: IProductConversionRatesDTO;
  onDelete(unit: number): void;
}

const AdditionalQuantityUnit = (props: IPropsAdditionalQuantityUnit) => {
  const onClickDelete = () => {
    props.onDelete(props.quantityUnit.unit);
  };

  return (
    <div className="additional-quantity-box">
      {props.quantityUnit.rate} {PRODUCT_ITEM_QUANTITY_UNIT_MAP[props.quantityUnit.unit]}
      <IconButton icon="close" size={20} onClick={onClickDelete} />
    </div>
  );
};

interface IPropsInputWithSelect {
  inputValue: string;
  selectValue: string;
  inputOnChange(e: ChangeEvent<HTMLInputElement>): void;
  selectOnChange(e: ChangeEvent<HTMLSelectElement>): void;
  inputName: string;
  selectName: string;
  inputLabel: string;
  options: { value: string; label: string }[];
}

const InputWithSelect = ({ inputValue, selectValue, inputOnChange, selectOnChange, inputName, selectName, inputLabel, options }: IPropsInputWithSelect) => {
  return (
    <div className="input-with-select">
      <TextField className="field" name={inputName} value={inputValue} label={inputLabel} onChange={inputOnChange} invalid={isNaN(+inputValue)} />
      <label htmlFor={selectName}>
        <select id={selectName} name={selectName} onChange={selectOnChange} value={selectValue}>
          {options.map(opt => (
            <option key={opt.label} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
        <i className="mdc-select__dropdown-icon" />
      </label>
    </div>
  );
};

import { DateTime } from 'luxon';
import { MaritalStatuses, SexTypes } from '../../../../constants/enums';
import { AddressDTO, PersonDetailsDTO } from '../../../../store/lib/Organization/Peoples/peopleDetails';
import { EmploymentDaysOff } from '../../../../components/People/ProfileDetails/Employment/helper';
import { EmploymentContract, PersonOU, transformEmploymentContract, transformPersonOU } from '../../../../components/People/ProfileDetails/Employment/Edit/helper';
import { SelectedFile } from '../../../../components/Selectors/FileSelector';
import { AddEmployeeDTO } from '../../../../store/lib/Organization/Peoples/peopleAdd';
import { IEmployee } from '../PeopleList/helper';
import { transformPersonDetails } from '../../../../components/People/ProfileDetails/PeopleDetails/helper';

export enum ActiveTab {
  BasicInfo = 1,
  Contact,
  Personal,
  Attachments,
  Employment
}

export const ACTIVETAB_MAP = {
  [ActiveTab.BasicInfo]: { title: 'Alapadatok', isRequired: true },
  [ActiveTab.Contact]: { title: 'Kapcsolat', isRequired: true },
  [ActiveTab.Personal]: { title: 'Személyes', isRequired: false },
  [ActiveTab.Attachments]: { title: 'Csatolmányok', isRequired: false },
  [ActiveTab.Employment]: { title: 'Foglalkoztatás', isRequired: true }
};

export const TAB_ITEMS = Object.keys(ACTIVETAB_MAP);

export type AddPersonBasicInfo = {
  syncId: string;
  pictureTempFileId: number | null;
  firstName: string;
  lastName: string;
  middleName: string;
  courtesyTitle: string;
  sexTypeId: SexTypes;
  birthName: string;
  birthDate: DateTime;
  birthPlace: string;
  motherName: string;
  maritalStatus: MaritalStatuses | null;
  isPrivate: boolean;
};

export type AddPersonContactInfo = {
  publicMobilePhone: string;
  publicLinePhone: string;
  publicLinePhoneExtension: string;
  publicEmail: string;
  linkedin: string;
  privateHomeAddress: AddressDTO;
  privateResidenceAddress: AddressDTO;
  privateMobilePhone: string;
  privateEmail: string;
  facebook: string;
  nextOfKinName: string;
  nextOfKinPhone: string;
};

export type AddPersonPersonalInfo = {
  socialSecurityNumber: string;
  addressCardNumber: string;
  idCardNumber: string;
  idCardExpiryDate: DateTime | null;
  passportNumber: string;
  passportExpiryDate: DateTime | null;
  driverLicenseNumber: string;
  driverLicenseExpiryDate: DateTime | null;
  taxNumber: string;
  bankAccountNumber: string;
};

export type EmployeeEmployement = {
  OUs: PersonOU[];
  startDate: DateTime;
  endDate: DateTime | null;
  companyId: number | null;
  childrenInHousehold: number | null;
  taxReducement: number | null;
  daysOff: EmploymentDaysOff[];
  resourceGroupTypeId: string;
  employmentContracts: EmploymentContract[];
};

export interface AddPerson {
  basicInfo: AddPersonBasicInfo;
  contactInfo: AddPersonContactInfo;
  personalInfo: AddPersonPersonalInfo;
  attachments: SelectedFile[];
}
export interface AddEmployee extends AddPerson {
  employment: EmployeeEmployement;
}

export interface IAddEmployeeModel extends AddEmployee {
  employment: AddEmployee['employment'] & {
    isEmployee: boolean;
    httpError: string;
  };
}

export const isValidBasicInfo = (basicInfo: IAddEmployeeModel['basicInfo']): boolean => {
  if (!basicInfo.birthDate.isValid || !basicInfo.birthPlace || !basicInfo.firstName || !basicInfo.lastName || !basicInfo.motherName) {
    return false;
  }

  return true;
};

export const isValidContactInfo = (contactInfo: IAddEmployeeModel['contactInfo']): boolean => {
  if (
    !contactInfo.privateHomeAddress.country ||
    !contactInfo.privateHomeAddress.city ||
    !contactInfo.privateHomeAddress.zip ||
    !contactInfo.privateHomeAddress.addressLine1 ||
    !contactInfo.privateResidenceAddress.country ||
    !contactInfo.privateResidenceAddress.city ||
    !contactInfo.privateResidenceAddress.zip ||
    !contactInfo.privateResidenceAddress.addressLine1
  ) {
    return false;
  }

  return true;
};

export const isValidPersonalInfo = (personalInfo: IAddEmployeeModel['personalInfo']): boolean => {
  return true;
};

export const isValidAttachments = (attachments: IAddEmployeeModel['attachments']): boolean => {
  return true;
};

export const isValidEmployment = (employment: IAddEmployeeModel['employment']): boolean => {
  if (employment.isEmployee) {
    if (!employment.startDate) {
      return false;
    } else if (employment.employmentContracts.some(contract => !contract.startDate.isValid || contract.weeklyWorkHours < 0)) {
      return false;
    }
  } else {
  }

  return true;
};

export const transformIAddEmployeeModel = (p: IAddEmployeeModel): AddEmployeeDTO | undefined => {
  if (p.employment.companyId !== null && p.employment.OUs[0]) {
    return {
      OUs: p.employment.OUs.map(x => transformPersonOU(x)),
      addressCardNumber: p.personalInfo.addressCardNumber || null,
      bankAccountNumber: p.personalInfo.bankAccountNumber || null,
      birthDate: p.basicInfo.birthDate.toISODate() || '',
      birthName: p.basicInfo.birthName || null,
      birthPlace: p.basicInfo.birthPlace,
      isPrivate: p.basicInfo.isPrivate,
      competences: [],
      courtesyTitle: p.basicInfo.courtesyTitle || null,
      driverLicenseExpiryDate: p.personalInfo.driverLicenseExpiryDate ? p.personalInfo.driverLicenseExpiryDate.toISODate() || '' : null,
      driverLicenseNumber: p.personalInfo.driverLicenseNumber || null,
      facebook: p.contactInfo.facebook || null,
      firstName: p.basicInfo.firstName,
      idCardExpiryDate: p.personalInfo.idCardExpiryDate ? p.personalInfo.idCardExpiryDate.toISODate() || '' : null,
      idCardNumber: p.personalInfo.idCardNumber || null,
      lastName: p.basicInfo.lastName,
      linkedin: p.contactInfo.linkedin || null,
      maritalStatus: p.basicInfo.maritalStatus || null,
      middleName: p.basicInfo.middleName || null,
      motherName: p.basicInfo.motherName,
      nextOfKinName: p.contactInfo.nextOfKinName || null,
      nextOfKinPhone: p.contactInfo.nextOfKinPhone || null,
      passportExpiryDate: p.personalInfo.passportExpiryDate ? p.personalInfo.passportExpiryDate.toISODate() || '' : null,
      passportNumber: p.personalInfo.passportNumber || null,
      pictureTempFileId: p.basicInfo.pictureTempFileId || null,
      privateEmail: p.contactInfo.privateEmail || null,
      privateHomeAddress: p.contactInfo.privateHomeAddress || null,
      privateMobilePhone: p.contactInfo.privateMobilePhone || null,
      privateResidenceAddress: p.contactInfo.privateResidenceAddress || null,
      publicEmail: p.contactInfo.publicEmail || null,
      publicLinePhone: p.contactInfo.publicLinePhone || null,
      publicLinePhoneExtension: p.contactInfo.publicLinePhoneExtension || null,
      publicMobilePhone: p.contactInfo.publicMobilePhone || null,
      sexTypeId: p.basicInfo.sexTypeId,
      socialSecurityNumber: p.personalInfo.socialSecurityNumber || null,
      syncId: p.basicInfo.syncId || null,
      taxNumber: p.personalInfo.taxNumber || null,
      tempFileIds: p.attachments.filter(x => x.locationId !== null).map(x => x.locationId as number),
      employment: {
        startDate: p.employment.startDate.toISODate() || '',
        endDate: p.employment.isEmployee ? (p.employment.endDate ? p.employment.endDate.toISODate() || '' : null) : null,
        companyId: p.employment.companyId,
        childrenInHousehold: p.employment.isEmployee ? p.employment.childrenInHousehold || null : null,
        taxReducement: p.employment.isEmployee ? p.employment.taxReducement || null : null,
        daysOff: p.employment.isEmployee ? p.employment.daysOff : [],
        resourceGroupTypeId: p.employment.isEmployee ? p.employment.resourceGroupTypeId || null : null,
        employmentContracts: p.employment.isEmployee ? p.employment.employmentContracts.map(x => transformEmploymentContract(x)) : []
      }
    };
  }
  return undefined;
};

export const transformPersonDetailsDTO = (p: PersonDetailsDTO, isActive: boolean = false): IEmployee => {
  return {
    personId: p.personId,
    details: transformPersonDetails(p),
    employment: null,
    isPrivate: p.basicInfo ? p.basicInfo.isPrivate : false,
    isVisible: true,
    isActive
  };
};

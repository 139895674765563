import React from 'react';

/* Presentation Things */
import { PersonalInfoSection } from './PersonalInfoSection';
import { AttachmentsSection } from './AttachmentsSection';
import { BasicInfoSection } from './BasicInfoSection';
import { ContactSection } from './ContactSection';
import { PersonDetails } from './helper';

interface IProps {
  error: string;
  personId: number;
  onEditPartialDetailsModel(p: Partial<PersonDetails>): void;
  detailsModel: Partial<PersonDetails>;
}

export const ProfilePanel = ({ personId, onEditPartialDetailsModel, detailsModel, error }: IProps) => {
  const { basicInfo, contactInfo, personalInfo, attachments, courtesyTitle, firstName, lastName, middleName } = detailsModel || {};
  const fullName = [courtesyTitle, lastName, firstName, middleName].filter(Boolean).join(' ');

  return (
    <div className="profile-panel">
      <div className="details-table">
        {basicInfo && <BasicInfoSection {...{ basicInfo, personId, onEditPartialDetailsModel, fullName }} />}

        {contactInfo && <ContactSection {...{ contactInfo, personId, onEditPartialDetailsModel }} />}

        {personalInfo && <PersonalInfoSection {...{ personalInfo, personId, onEditPartialDetailsModel }} />}

        {attachments && <AttachmentsSection {...{ attachments, personId, onEditPartialDetailsModel }} />}

        {!basicInfo && !contactInfo && !personalInfo && !attachments && <div className="basic-error-text">{error || 'Nincs megjeleníthető adat!'}</div>}
      </div>
    </div>
  );
};

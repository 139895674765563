export const COLOR = {
  containerBg: '#3c4044',
  contentBg: '#33373b',
  /* ------------------------------------------------------ */
  primary: '#ffffff',
  secondary: '#14ebf1', // main titles, highlight
  tertiary: '#42464a', // wireframe, placeholder
  /* ------------------------------------------------------ */
  mediumGrey: '#808080', // icons
  grey: '#829090' // title, placeholder text
};

export const SPACEING = {
  sm: 6,
  md: 12,
  lg: 24,
  xl: 48
};

export const FONT = {
  sm: 14,
  md: 16,
  lg: 24,
  xl: 28
};

export const ICON = {
  sm: 20,
  md: 26,
  lg: 29
};

export const SCREEN_SECTION = {
  container: { marginHorizontal: SPACEING.md, marginVertical: SPACEING.md, backgroundColor: COLOR.containerBg },
  title: { color: COLOR.primary, fontWeight: '500', marginTop: SPACEING.sm, marginBottom: SPACEING.md, fontSize: FONT.sm, textTransform: 'uppercase' }
};

export const WIREFRAME_DESIGN = {
  item: {
    backgroundColor: COLOR.tertiary,
    height: 24,
    borderRadius: 4
  }
};

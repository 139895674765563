/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

/* Presentation Things */
import { Select } from '@rmwc/select';
import { Radio } from '@rmwc/radio';

/* Data Things */
import { OU, EditEmployeeEmployment, transformOU, PersonOU } from './helper';
import { GetOrganizationUnitsByCompanyId } from '../../../../../store/lib/Organization/Peoples/employment';
import { OUMemberTypes } from '../../../../../constants/enums';
import { useIsMounted } from '../../../../../hooks/useIsMounted';
import { usePrevious } from '../../../../../hooks/usePrevious';

interface Props {
  editableOUs: EditEmployeeEmployment['OUs'];
  companyId: number | null;
  onUpdateOU(ou: PersonOU | undefined, index: number | undefined): void;
  onAddOU(newOu: PersonOU): void;
}

export function OUsSections({ onUpdateOU, onAddOU, companyId, editableOUs }: Props) {
  const prevCompanyId = usePrevious<number | null>(companyId);
  const [OUsList, setOUsList] = useState<OU[]>([]);
  const _isMounted = useIsMounted();

  const displayableOUsList = OUsList.map(x => ({ label: x.name, value: String(x.ouId) }));

  useEffect(() => {
    if (companyId !== null) {
      getOUsFromRemote(companyId);
    }

    if (prevCompanyId && prevCompanyId !== companyId) {
      handleDeleteAllOus();
    }
  }, [companyId]);

  useEffect(() => {
    if (OUsList.length > 0 && !editableOUs.length) {
      handleAddOU();
    }
  }, [OUsList]);

  const getOUsFromRemote = async (companyId: number) => {
    try {
      setOUsList([]);
      const OUs = await GetOrganizationUnitsByCompanyId(companyId);
      if (OUs) {
        _isMounted.current && setOUsList(OUs.map(x => transformOU(x)).sort((x, y) => (x.name < y.name ? -1 : 1)));
      } else {
        throw new Error('Can not get the Organization units');
      }
    } catch (error) {}
  };

  const handleSelectOUId = (ouId: string, ouIndex: number) => {
    onUpdateOU({ OUId: parseInt(ouId), OUMemberType: editableOUs[ouIndex].OUMemberType }, ouIndex);
  };

  const handleSelectOUMemberType = (memberType: OUMemberTypes, ouIndex: number) => {
    onUpdateOU({ OUId: editableOUs[ouIndex].OUId, OUMemberType: memberType }, ouIndex);
  };

  const handleAddOU = () => onAddOU({ OUId: OUsList[0].ouId, OUMemberType: OUMemberTypes.Member });

  const handleDeleteOU = (index: number) => onUpdateOU(undefined, index);

  const handleDeleteAllOus = () => onUpdateOU(undefined, undefined);

  return (
    <div className="form-content-container organisation inputs-without-helptext-layout">
      <div className="title margin-top-8 margin-bottom-12">
        Szervezeti egységek
        <span className="material-icons edit-icon" aria-label="Add" onClick={handleAddOU}>
          add_circle_outline
        </span>
      </div>

      {editableOUs.map((ou, i) => (
        <div className="form-content-row align-items-center margin-bottom-12--important" key={i}>
          <Select label="Szervezeti egység" value={String(ou.OUId)} onChange={ev => handleSelectOUId(ev.currentTarget.value, i)} options={displayableOUsList} required />

          <div className="radio-control-wrap">
            <div className="radio-buttons">
              <div className="label">Szerepkör</div>

              <Radio value={String(OUMemberTypes.Member)} checked={String(ou.OUMemberType) === String(OUMemberTypes.Member)} onChange={evt => handleSelectOUMemberType(parseInt(evt.currentTarget.value), i)}>
                Tag
              </Radio>

              <Radio value={String(OUMemberTypes.Manager)} checked={String(ou.OUMemberType) === String(OUMemberTypes.Manager)} onChange={evt => handleSelectOUMemberType(parseInt(evt.currentTarget.value), i)}>
                Vezető
              </Radio>

              <Radio value={String(OUMemberTypes.DeputyManager)} checked={String(ou.OUMemberType) === String(OUMemberTypes.DeputyManager)} onChange={evt => handleSelectOUMemberType(parseInt(evt.currentTarget.value), i)}>
                Vezető helyettes
              </Radio>
            </div>
          </div>

          {editableOUs.length > 1 && (
            <span className="material-icons edit-icon" aria-label="Delete" onClick={() => handleDeleteOU(i)}>
              delete
            </span>
          )}
        </div>
      ))}
    </div>
  );
}

import { ICreateProductDTO, IEditProductDTO, IProductDTO, IProductImageDTO, IProductListItemDTO, IProductConversionRatesDTO, ITaskAttachmentDTO, ITaskWriteDTO } from '../../../typings/DTOs';
import { ProductItemQuantityUnit } from '../../../constants/enums';
import { SelectedFile } from '../../../components/Selectors/FileSelector';

export class TaskAttachment {
  public id: number | null;
  public fileName: string;
  public extension: string;
  public fileUrl: string;
  public fileLength: number;
  public clientDevices: Device[] | null;

  constructor(p: ITaskAttachmentDTO) {
    this.id = p.imageId;
    this.fileName = p.fileName || '';
    this.extension = p.extension || '';
    this.fileUrl = p.fileUrl || '';
    this.fileLength = p.fileLength;
  }
}
export class Task {
  public id: number | null;
  public name: string;
  public orderOfTask: number;
  public standardTimeSec: number;
  public isQARequired: boolean;
  public assignedAttachmentIds: number[] | null;

  constructor(p: ITaskWriteDTO) {
    this.id = p.id;
    this.name = p.name || '';
    this.orderOfTask = p.orderOfTask;
    this.standardTimeSec = p.standardTimeSec;
    this.isQARequired = p.isQARequired;
    this.assignedAttachmentIds = p.assignedAttachmentIds;
  }
}

export class Product {
  public id: number;
  public primaryPicture: SelectedFile | null;
  public otherPictures: SelectedFile[] = [];
  public title: string;
  public quantityUnit: ProductItemQuantityUnit;
  public conversionRates: IProductConversionRatesDTO[] | null;
  public itemNoRaw: string;
  public itemNoReady: string;
  public parentId: number | null = null;
  public tasks: Task[] = [];
  public subProducts: Product[] = [];
  public notes: string;
  public hasSerialNumber: boolean;
  public attachments: ITaskAttachmentDTO[] | null;

  constructor(p: IProductListItemDTO | IProductDTO) {
    this.id = p.id;
    this.title = p.title || '';
    this.itemNoRaw = p.itemNoRaw || '';
    this.itemNoReady = p.itemNoReady || '';
    this.quantityUnit = p.quantityUnit;
    this.conversionRates = p.conversionRates ? p.conversionRates : [];
    this.notes = p.notes || '';
    this.hasSerialNumber = p.hasSerialNumber;
    this.attachments = p.attachments;

    if (isIProductDTO(p)) {
      this.parentId = p.parentId;
      this.primaryPicture = transfromIProductImageDTOToSelectedFile(p.primaryPicture);
      this.otherPictures = p.otherPictures ? p.otherPictures.map(x => transfromIProductImageDTOToSelectedFile(x)) : [];
      this.subProducts = p.subProducts ? p.subProducts.map(x => new Product(x)) : [];
      this.tasks = p.tasks ? p.tasks.map(x => new Task(x)).sort((x, y) => x.orderOfTask - y.orderOfTask) : [];
    } else {
      this.primaryPicture = transfromIProductImageDTOToSelectedFile(p.pictureUrl || '');
    }
  }
}

const isIProductDTO = (p: IProductListItemDTO | IProductDTO): p is IProductDTO => {
  return (p as IProductDTO).tasks !== undefined;
};

const transfromIProductImageDTOToSelectedFile = (image: IProductImageDTO | string): SelectedFile => {
  /* It's really a hack, but unfortunately the 'SelectedFile' is a silly implementation. In the future remove the File */
  return {
    error: '',
    isLoading: false,
    locationId: typeof image === 'string' ? null : image.imageId,
    url: typeof image === 'string' ? image : image.fileUrl,
    id: null,
    file: new File(typeof image === 'string' ? [] : [new ArrayBuffer(+image.fileLength)], typeof image === 'string' ? '' : String(image.fileName), typeof image === 'string' ? undefined : { type: image.extension })
  };
};

export const transformITaskAttachmentDTOToTaskAttachment = (doc: ITaskAttachmentDTO | null): TaskAttachment => {
  /* It's really a hack, but unfortunately the 'SelectedFile' is a silly implementation. In the future remove the File */
  return {
    id: doc!.id,
    fileName: doc!.fileName!,
    extension: doc!.extension,
    fileUrl: doc!.fileUrl,
    fileLength: doc!.fileLength
  };
};

export const transformTaskAttachmentToSelectedFile = (doc: ITaskAttachmentDTO | TaskAttachment): SelectedFile => {
  /* It's really a hack, but unfortunately the 'SelectedFile' is a silly implementation. In the future remove the File */
  return {
    error: '',
    isLoading: false,
    locationId: doc!.id,
    url: doc!.fileUrl,
    id: doc!.id!.toString(),
    file: new File(!doc ? [] : [new ArrayBuffer(+doc.fileLength)], !doc ? '' : String(doc.fileName), !doc ? undefined : { type: doc.extension })
  };
};

export const transformProductToICreateProductDTO = (p: Product, tempPrimaryPicture: SelectedFile, tempOtherPictures: SelectedFile[]): ICreateProductDTO => {
  return {
    primaryTempPictureId: tempPrimaryPicture.locationId!,
    otherTempPictureIds: tempOtherPictures.length ? tempOtherPictures.map(x => x.locationId!) : null,
    itemNoRaw: p.itemNoRaw || null,
    itemNoReady: p.itemNoReady || null,
    parentId: p.parentId,
    quantityUnit: p.quantityUnit,
    conversionRates: p.conversionRates ? p.conversionRates : [],
    tasks: p.tasks.length ? p.tasks : null,
    title: p.title,
    notes: p.notes || null,
    hasSerialNumber: p.hasSerialNumber
  };
};

export const transformProductToIEditProductDTO = (p: Product, tempPrimaryPicture: SelectedFile, tempOtherPictures: SelectedFile[], id: number): IEditProductDTO => {
  return {
    id,
    ...transformProductToICreateProductDTO(p, tempPrimaryPicture, tempOtherPictures)
  };
};

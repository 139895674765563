import React, { useContext, useEffect, useState } from 'react';

/* Data Things */
import {
  IClaimRow,
  INITIAL_USER,
  IPersonRow,
  transformClaimRowDTO,
  transformEditUserDTO,
  onEditUserRemote,
  onCreateUserRemote,
  transformPersonRowDTO,
  transformSecurityGroupRowDTOToMultiSelectorOptions
} from '../helper';
import { getApplicationUser, getClaims, getPeopleForUserCreate, getPeopleForUserEdit } from '../../../../store/lib/Settings/applicationUsers';
import { formatName, generateWindowTitle, orderByName } from '../../../../constants/helperFuntions';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { ApplicationUsersContext } from '../../../../components/Contexts/ApplicationUsers';
import { getSecurityGroups } from '../../../../store/lib/Settings/securityGroups';
import { useIsMounted } from '../../../../hooks/useIsMounted';
import { GUID_PATTERN } from '../../../../constants/regex';
import { AccessTypes } from '../../../../constants/enums';

/* Presentation Things */
import { MultiSelector, MultiSelectorOption } from '../../../../components/Selectors/MultiSelector';
import { TextField, TextFieldProps } from '@rmwc/textfield';
import { Switch, SwitchProps } from '@rmwc/switch';
import { Select, SelectProps } from '@rmwc/select';
import { LinearProgress } from '@rmwc/linear-progress';
import { Typography } from '@rmwc/typography';
import { Checkbox } from '@rmwc/checkbox';
import { Button } from '@rmwc/button';
import { Radio } from '@rmwc/radio';

// Styles
import './Create.scss';

interface Props extends RouteComponentProps<{ id: number }> {}

export const ApplicationUserCreateAndEdit = (props: Props) => {
  const navigate = useNavigate();
  const context = useContext(ApplicationUsersContext);

  /* Variables */
  const userId = props.id !== undefined ? Number(props.id) : undefined;
  const selectedUser = userId ? context.users[userId] : undefined;
  const isEdit = userId !== undefined;

  //States
  const [securityGroups, setSecurityGroups] = useState<MultiSelectorOption[]>([]);
  const [isEditableId, setIsEditableId] = useState(!isEdit);
  const [isLoading, setIsLoading] = useState(true);
  const [people, setPeople] = useState<IPersonRow[]>([]);
  const [claims, setClaims] = useState<IClaimRow[]>([]);
  const [user, setUser] = useState(selectedUser || INITIAL_USER);

  const isDisabled = isLoading;
  const fieldDefaultProps = { disabled: isDisabled, className: 'field', required: true };

  const PEOPLE_SELECT = [{ label: '', value: undefined }, ...people.map(x => ({ label: formatName(x.firstName, x.lastName, x.middleName, x.courtesyTitle), value: String(x.personId) }))];

  //Refs
  const _isMounted = useIsMounted();

  const onGoBack = () => navigate('/Settings/ApplicationUsers', { replace: true });

  const onSecurityGroupSelect = (id: string, selected: boolean) => {
    setUser(prev => ({
      ...prev,
      securityGroupIds: selected ? [...prev.securityGroupIds, Number(id)] : prev.securityGroupIds.filter(x => x !== Number(id))
    }));
  };

  const handleToogleClaim = (claim: IClaimRow, accessTypeId?: AccessTypes) => {
    const isDelete = user.claimAssigns[claim.claimId] && !accessTypeId;
    accessTypeId = accessTypeId || AccessTypes.Self;

    if (isDelete) delete user.claimAssigns[claim.claimId];

    const newClaims = isDelete ? user.claimAssigns : { ...user.claimAssigns, [claim.claimId]: { claimId: claim.claimId, claimName: claim.claimName, accessTypeId } };

    setUser({ ...user, claimAssigns: newClaims });
  };

  const getUserFromRemote = async () => {
    const resp = await getApplicationUser(userId!);
    if (resp && _isMounted.current) {
      context.onSetUser(transformEditUserDTO(resp, selectedUser));
    } else throw new Error('Valami hiba történt, nem tudtuk lekérni a felhasználót');
  };

  const getPersonForUserEditFromRemote = async () => {
    const resp = await getPeopleForUserEdit(userId!);
    _isMounted.current && setPeople(resp.map(x => transformPersonRowDTO(x)).sort((a, b) => orderByName(a, b)));
  };

  const getPersonForUserCreateFromRemote = async () => {
    const resp = await getPeopleForUserCreate();
    _isMounted.current && setPeople(resp.map(x => transformPersonRowDTO(x)).sort((a, b) => orderByName(a, b)));
  };

  const getSecurityGroupsFromRemote = async () => {
    const resp = await getSecurityGroups();
    _isMounted.current && setSecurityGroups(resp.map(x => transformSecurityGroupRowDTOToMultiSelectorOptions(x)).sort((a, b) => a.label.localeCompare(b.label)));
  };

  const getClaimsFromRemote = async () => {
    const resp = await getClaims();
    _isMounted.current && setClaims(resp.map(x => transformClaimRowDTO(x)).sort((a, b) => a.displayName.localeCompare(b.displayName)));
  };

  const getInitialDataForEdit = async () => {
    try {
      const resp = await Promise.all([getSecurityGroupsFromRemote(), getClaimsFromRemote()]);
      if (resp) {
        const u = await Promise.all([getUserFromRemote(), getPersonForUserEditFromRemote()]);
        if (u) {
          _isMounted.current && setIsLoading(false);
        }
      }
    } catch (error) {
      if (_isMounted.current) {
        context.onSetApiResponse({ type: 'error', message: String(error.message || 'Sajnáljuk valami hiba történt, próbáld meg később') });
        setIsLoading(false);
      }
    }
  };

  const getInitialDataForCreate = async () => {
    try {
      await Promise.all([getSecurityGroupsFromRemote(), getClaimsFromRemote(), getPersonForUserCreateFromRemote()]);
    } catch (error) {
      if (_isMounted.current) {
        context.onSetApiResponse({ type: 'error', message: String(error.message || 'Sajnáljuk valami hiba történt, próbáld meg később') });
      }
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const onChangeName = (ev: React.ChangeEvent<TextFieldProps>) => {
    ev.persist();
    setUser(prev => ({ ...prev, user: { ...prev.user, name: String(ev.target.value || '') } }));
  };

  const onChangeEmail = (ev: React.ChangeEvent<TextFieldProps>) => {
    ev.persist();
    setUser(prev => ({ ...prev, user: { ...prev.user, email: String(ev.target.value) } }));
  };

  const onChangeIsActive = (ev: React.ChangeEvent<SwitchProps>) => {
    setUser(prev => ({ ...prev, isActive: !!ev.target.checked }));
  };

  const onChangeSelectedProfile = (ev: React.ChangeEvent<SelectProps>) => {
    ev.persist();
    setUser(prev => ({ ...prev, person: { ...prev.person, id: ev.target.value ? Number(ev.target.value) : null } }));
  };

  const onChangeAAD = (ev: React.ChangeEvent<TextFieldProps>) => {
    if (isEditableId) {
      ev.persist();
      setUser(prev => ({ ...prev, aadId: String(ev.target.value) }));
    }
  };

  const onSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();
    setIsLoading(true);

    const query = isEdit ? () => onEditUserRemote(user) : () => onCreateUserRemote(user);

    try {
      const resp = await query();
      if (typeof resp !== 'string' && _isMounted.current) {
        context.onSetUser(resp, true);
        onGoBack();
      }
    } catch (error) {
      _isMounted.current && context.onSetApiResponse({ type: 'error', message: String(error) });
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedUser) {
      setUser(selectedUser);
      document.title = generateWindowTitle(selectedUser.user.name);
    }
  }, [selectedUser]);

  useEffect(
    () => {
      if (userId !== undefined) {
        getInitialDataForEdit();
      } else {
        getInitialDataForCreate();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId]
  );

  return (
    <form className="routes-content-container add-user" onSubmit={onSubmit}>
      {isLoading && <LinearProgress className="linear-progress full-top" />}

      <div className="data-table-title">
        <Typography className="title" use="body2">
          {isEdit ? 'Felhasználó szerkesztése' : 'Új felhasználó'}
        </Typography>
      </div>

      <div className="basic-data-fields">
        <TextField {...fieldDefaultProps} label="Név" maxLength={200} value={user.user.name} onChange={onChangeName} autoFocus />

        <TextField {...fieldDefaultProps} label="Email" maxLength={100} type="email" value={user.user.email} onChange={onChangeEmail} />

        <TextField
          {...fieldDefaultProps}
          label="AAD ID"
          pattern={GUID_PATTERN}
          value={user.aadId}
          onChange={onChangeAAD}
          trailingIcon={
            isEdit && {
              icon: isEditableId ? 'lock_open' : 'lock',
              tabIndex: 0,
              onClick: () => setIsEditableId(p => !p)
            }
          }
        />

        <Select {...fieldDefaultProps} label="Profil" value={user.person.id === null ? undefined : String(user.person.id)} options={PEOPLE_SELECT} required={false} onChange={onChangeSelectedProfile} />

        {isEdit && <Switch {...fieldDefaultProps} label="Aktív" checked={user.isActive} onChange={onChangeIsActive} required={false} />}
      </div>

      <Typography className="sub-title" use="subtitle2">
        Biztonsági csoportok
      </Typography>

      <MultiSelector
        {...{
          options: securityGroups,
          onSelect: onSecurityGroupSelect,
          selectedIds: user.securityGroupIds,
          disabled: isDisabled,
          placeholder: 'Biztonsági csoport hozzáadása'
        }}
      />

      <Typography className="sub-title" use="subtitle2">
        Egyéni jogosultságok
      </Typography>

      <div className="data-table">
        <div className="data-table__row data-table__row--title">
          <div className="data-table__cell">Jogosultság neve</div>

          <div className="data-table__cell">Jogosultság szinje</div>
        </div>

        <div className="data-table-body">
          {claims.map(x => {
            const isActive = Boolean(user.claimAssigns[x.claimId]);
            return (
              <div className="data-table__row" key={x.claimId}>
                <div className="data-table__cell">
                  <Checkbox label={x.displayName} checked={isActive} onChange={() => handleToogleClaim(x)} />
                </div>

                {x.hasAccessTypes && isActive && (
                  <div className="data-table_cell">
                    <Radio label="Csak saját" onChange={() => handleToogleClaim(x, AccessTypes.Self)} checked={AccessTypes.Self === user.claimAssigns[x.claimId].accessTypeId} />

                    <Radio label="Szervezeti egység szinten" onChange={() => handleToogleClaim(x, AccessTypes.OU)} checked={AccessTypes.OU === user.claimAssigns[x.claimId].accessTypeId} />

                    <Radio label="Mindenkinek" onChange={() => handleToogleClaim(x, AccessTypes.All)} checked={AccessTypes.All === user.claimAssigns[x.claimId].accessTypeId} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className="routes-content-container-footer">
        <Button type="submit" unelevated label={isEdit ? 'Módosítom' : 'Létrehozom'} disabled={isDisabled} />
        <Button type="button" label="Mégse" onClick={onGoBack} disabled={isDisabled} />
      </div>
    </form>
  );
};

import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';

/* Route things */
import { RouteComponentProps } from '@reach/router';

/* Data Things */
import { closeCostMonth, closeMonth, getMonthlyClose, getPreviewCloseCostMonth, getClosedInfo, PersonRowDTO, reOpenMonth, setClosedInfo } from '../../../store/lib/TrackTime/monthlyClose';
import { monthlyCloseReducer, initState } from '../../../store/reducers/TrackTime/MonthlyClose';
import { calculateCloseCostMonth } from './helper';
import { DATETIME_INPUT_FORMAT } from '../../../constants/constants';
import { useIsMounted } from '../../../hooks';
import { DateTime } from 'luxon';

/* Presentation Things */
import ResponseSnackbar from '../../../components/Portals/ResponseSnackbar';
import CircularProgress from '../../../components/CircularProgress';
import { MonthPager } from '../../../components/Pagers/';
import { CheckTable } from './CheckTable';
import { TextField } from '@rmwc/textfield';
import { CostTable } from './CostTable';
import { Tooltip } from '@rmwc/tooltip';
import { Button } from '@rmwc/button';
import { Icon } from '@rmwc/icon';

/* Style */
import './MonthlyClose.scss';

interface IProps
  extends RouteComponentProps<{
    year: string;
    month: string;
  }> {}

export const MonthlyClose = ({ navigate, month, year }: IProps) => {
  /* State */
  const [state, dispatch] = useReducer(monthlyCloseReducer, initState(year, month));

  /* Refs */
  const _isMounted = useIsMounted();
  const _abortion = useRef(new AbortController());

  /* Variables */
  const isError = !state.isLoading && (state.error || !state.model || (state.model && state.model.isMonthClosed && !state.model.persons));

  const onGetModel = useCallback(async () => {
    try {
      const model = await getMonthlyClose(state.actual.year, state.actual.month, _abortion.current.signal);
      if (!model.error && _isMounted.current) {
        dispatch({ type: 'success_get_model', payload: { model } });
      } else throw new Error(model.error);
    } catch (error) {
      _isMounted.current && dispatch({ type: 'error_get_model', payload: { error: String(error.error || error.message) } });
    }
  }, [state.actual, _isMounted, _abortion]);

  const onOpenMonth = async () => {
    try {
      dispatch({ type: 'set_partial_state', payload: { isLoading: true } });

      const lastClosedMonth = await reOpenMonth(state.actual.year, state.actual.month, _abortion.current.signal);
      if (!lastClosedMonth.error && _isMounted.current) {
        dispatch({ type: 'success_open_month', payload: { lastClosedMonth } });
      } else throw new Error(lastClosedMonth.error);
    } catch (error) {
      _isMounted.current && dispatch({ type: 'error_open_month', payload: { error: String(error.error || error.message) } });
    }
  };

  const onCloseMonth = async () => {
    try {
      dispatch({ type: 'set_partial_state', payload: { isLoading: true } });

      const model = await closeMonth(state.actual.year, state.actual.month, _abortion.current.signal);
      if (!model.error && _isMounted.current) {
        dispatch({ type: 'success_close_month', payload: { model } });
      } else throw new Error(model.error);
    } catch (error) {
      _isMounted.current && dispatch({ type: 'error_close_month', payload: { error: String(error.error || error.message) } });
    }
  };

  const onCloseCost = async (ev: React.FormEvent) => {
    try {
      ev.preventDefault();
      dispatch({ type: 'set_partial_state', payload: { isLoading: true } });
      const resp = await closeCostMonth(calculateCloseCostMonth(state.actual, state.model), _abortion.current.signal);
      if (!resp.error && _isMounted.current) {
        dispatch({ type: 'success_close_cost' });
      } else throw new Error(resp.error);
    } catch (error) {
      _isMounted.current && dispatch({ type: 'error_close_cost', payload: { error: String(error.message || error.error) } });
    }
  };

  const onGetPreview = async () => {
    dispatch({ type: 'set_partial_state', payload: { isLoading: true } });

    try {
      const previews = await getPreviewCloseCostMonth(calculateCloseCostMonth(state.actual, state.model), _abortion.current.signal);
      if (previews.length > 0 && _isMounted.current) {
        dispatch({ type: 'success_get_preview', payload: { previews } });
      } else throw new Error('Nincs megjeleníthető előkalkuláció');
    } catch (error) {
      _isMounted.current && dispatch({ type: 'error_get_preview', payload: { error: String(error.message || error.error) } });
    }
  };

  const onGetClosedInfo = useCallback(async () => {
    try {
      const closedInfo = await getClosedInfo();
      if (!closedInfo.error && _isMounted.current) {
        dispatch({ type: 'success_get_closedInfo', payload: { closedInfo } });
        onGetModel();
      } else throw new Error(closedInfo.error);
    } catch (error) {
      _isMounted.current && dispatch({ type: 'error_get_closedInfo', payload: { error: String(error.message || error.error) } });
    }
  }, [_isMounted, onGetModel]);

  const onSaveClosedInfo = useCallback(async () => {
    try {
      if (state.closedInfo.restrictedBefore) {
        const closedInfo = await setClosedInfo(state.closedInfo.restrictedBefore);
        if (!closedInfo.error && _isMounted.current) {
          dispatch({ type: 'set_partial_state', payload: { apiResponse: { type: 'success', message: 'Sikeres módosítás' } } });
          return Promise.resolve('');
        } else throw new Error(closedInfo.error);
      } else throw new Error('Kötelező mező');
    } catch (error) {
      dispatch({ type: 'set_partial_state', payload: { apiResponse: { type: 'error', message: String(error.message || error.error) } } });
      return Promise.reject('');
    }
  }, [state.closedInfo, _isMounted]);

  const onRestrictedLastClosedDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const restrictedBefore = DateTime.fromFormat(e.target.value, DATETIME_INPUT_FORMAT);
    if (restrictedBefore.isValid) {
      dispatch({
        type: 'set_partial_state',
        payload: { closedInfo: { ...state.closedInfo, restrictedBefore } }
      });
    }
  };

  const prevMonth = () => {
    const { year, month } = state.actual.minus({ month: 1 });
    navigate!(`/TrackTime/MonthlyClose/${year}/${month}`);
  };

  const nextMonth = () => {
    const { year, month } = state.actual.plus({ month: 1 });
    navigate!(`/TrackTime/MonthlyClose/${year}/${month}`);
  };

  const onPersonCostChange = (id: number, cost: number | null) => dispatch({ type: 'person_cost_change', payload: { id, cost } });

  const onCloseCheckTable = () => dispatch({ type: 'set_partial_state', payload: { isShowCheckTable: false } });

  const onUploadCSV = (persons: PersonRowDTO[]) => dispatch({ type: 'on_upload_csv', payload: { persons } });

  const onCloseResponse = () => dispatch({ type: 'set_partial_state', payload: { apiResponse: null } });

  const onOpenTable = () => dispatch({ type: 'set_partial_state', payload: { isShowCheckTable: true } });

  useEffect(() => {
    _abortion.current.abort();
    _abortion.current = new AbortController();
    dispatch({ type: 'on_month_change', payload: { year, month } });
  }, [year, month]);

  useEffect(() => void onGetClosedInfo(), [onGetClosedInfo]);

  return (
    <div className="MonthlyClose">
      <WoktimeRestiction info={state.closedInfo} onChange={onRestrictedLastClosedDateChange} onSave={onSaveClosedInfo} isDisabled={state.isLoading || state.error} />

      <MonthPager date={state.actual} prevMonth={prevMonth} nextMonth={nextMonth} format="yyyy'.'LL' hónap zárása'" />

      {state.isLoading && <CircularProgress className="full-height monthlyclose-circular-progress" />}

      {state.model && (
        <>
          <Description
            onOpenTable={onOpenTable}
            onOpenMonth={onOpenMonth}
            onCloseMonth={onCloseMonth}
            isCostClosedForMonth={state.model.isCostClosedForMonth}
            isMonthClosed={state.model.isMonthClosed}
            isDisabled={state.isLoading}
          />

          {state.isShowCheckTable && <CheckTable actual={state.actual} onClose={onCloseCheckTable} abortSignal={_abortion.current.signal} />}

          {state.model.isMonthClosed && (
            <CostTable
              actual={state.actual}
              model={state.model}
              preview={state.preview}
              isLoading={state.isLoading}
              onPersonCostChange={onPersonCostChange}
              onUploadCSV={onUploadCSV}
              onCloseCost={onCloseCost}
              onGetPreview={onGetPreview}
            />
          )}
        </>
      )}

      {isError && <div className="basic-error-text">{state.error || 'Nincs megjeleníthető adat'}</div>}

      {state.apiResponse && <ResponseSnackbar response={state.apiResponse} onClose={onCloseResponse} />}
    </div>
  );
};

const Description = ({ onOpenTable, onOpenMonth, onCloseMonth, isCostClosedForMonth, isMonthClosed, isDisabled }) => {
  return (
    <div className="description">
      <span className="text">A hónap lezárása után havi zárás jogosultsággal sincs lehetőség a hónapot érintő aktivitásokat, szabadságokat, túlórákat, napi státuszokat módosítani.</span>
      <span className="text">A lezárást követően lehetőség nyílik a költségek megadására. Amíg a költségek nem kerülnek beküldésre, a hónap újra visszanyitható.</span>

      {!isCostClosedForMonth && (
        <div className="button-group">
          <Button onClick={onOpenTable} disabled={isDisabled} label="Ellenőrzés" />

          {isMonthClosed ? (
            <Button unelevated className="monthly-close_button" onClick={onOpenMonth} disabled={isDisabled} label="Feloldom" />
          ) : (
            <Button unelevated className="monthly-close_button" onClick={onCloseMonth} disabled={isDisabled} label="Lezárom" />
          )}
        </div>
      )}
    </div>
  );
};

const WoktimeRestiction = ({ info, onChange, onSave, isDisabled }) => {
  const defaultValue = useRef<DateTime | null>();

  /* State */
  const [isLoading, setIsLoading] = useState(false);

  /* Variables */
  isDisabled = isDisabled || isLoading;
  const min = info.lastClosedDate ? info.lastClosedDate.plus({ month: 1 }).toFormat(DATETIME_INPUT_FORMAT) : undefined;
  const isShowButtons = isLoading || !defaultValue.current || !info.restrictedBefore || (defaultValue.current && info.restrictedBefore && !defaultValue.current.equals(info.restrictedBefore));

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await onSave();
      defaultValue.current = info.restrictedBefore;
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onClear = () => onChange({ target: { value: defaultValue.current ? defaultValue.current.toFormat(DATETIME_INPUT_FORMAT) : '' } });

  useEffect(() => {
    if (!defaultValue.current) {
      defaultValue.current = info.restrictedBefore;
    }
  }, [defaultValue, info.restrictedBefore]);

  return (
    <form className="worktime-restriction" onSubmit={handleSave}>
      <span className="label-wrap">
        <span className="label">Munkaidő módosítás korlátozása</span>
        <Tooltip
          content={
            <div className="worktime-restriction__tooltip">A beállított dátum előtti időszakra csak havi zárás jogosultsággal lehet aktivitást, napi státuszt, szabadságot és túlórát rögzíteni vagy módosítani.</div>
          }
          showArrow
          align="bottom"
        >
          <Icon icon="info" />
        </Tooltip>
      </span>

      <TextField autoFocus type="date" className="datepicker-input" min={min} onChange={onChange} disabled={isDisabled} value={info.restrictedBefore ? info.restrictedBefore.toFormat(DATETIME_INPUT_FORMAT) : ' '} />

      {isShowButtons && (
        <div className="button-group">
          <Button label="Mégsem" type="button" disabled={isDisabled} onClick={onClear} />
          <Button label="Mentés" type="submit" disabled={isDisabled} unelevated icon={isLoading && <CircularProgress />} />
        </div>
      )}
    </form>
  );
};

import { IVisibility } from '../../../constants/types';
import { TagUpdateDTO } from '../../../store/lib/MasterData/activityTags';
import { ConcurrentEditViewModel } from '../../../store/lib/Organization/Peoples/employment';

export interface ITag {
  id: number;
  name: string;
}

export type ActivityTag = ITag & ConcurrentEditViewModel & IVisibility;

export const transformTagUpdatedVM = (p: TagUpdateDTO): ActivityTag => {
  return {
    updated: p.updated,
    id: p.tagId,
    name: p.tagName || '',
    isVisible: true
  };
};

export const sortActivityTags = (tags: ActivityTag[]) => tags.sort((x, y) => x.name.localeCompare(y.name));

import { ValidationCheckMonthDTO, ValidationCheckMonthRowDTO } from '../../../../store/lib/TrackTime/monthlyClose';
import { CheckMonthFilters } from '../helper';

export interface ValidationCheckMonth {
  validationCheckMonthRows: ValidationCheckMonthRow[];
}

interface ValidationCheckMonthRow extends ValidationCheckMonthRowDTO {
  isVisible: boolean;
}

export enum SelectedCell {
  PendingLeaveRequest = 1,
  PendingOvertimeRequest,
  ActivityWithoutProject,
  ActivityMoreThanEightWorkingHours,
  OverlappedActivity
}

export const transofrmValidationCheckMonthDTO = (p: ValidationCheckMonthDTO, isSort = true): ValidationCheckMonth['validationCheckMonthRows'] => {
  const sortRowssByName = (p: ValidationCheckMonthRow[]): ValidationCheckMonthRow[] => {
    return p.sort((a, b) => (a.person.lastName + a.person.firstName + a.person.middleName).localeCompare(b.person.lastName + b.person.firstName + b.person.middleName, 'hu'));
  };

  const tranformed = p.validationCheckMonthRows.map(x => ({ ...x, isVisible: true }));

  return isSort ? sortRowssByName(tranformed) : tranformed;
};

export const isVisibleCheckRow = (x: ValidationCheckMonthRow, activeFilters: CheckMonthFilters): boolean => {
  const errorValues = Object.values(x.errors).map(x => (Array.isArray(x) ? x.length : Number(x)));
  const warningValues = Object.values(x.warnings).map(x => (Array.isArray(x) ? x.length : Number(x)));
  const errorAndWarningKeys = [...Object.keys(x.errors), ...Object.keys(x.warnings)];
  const errorAndWarningValues = [...errorValues, ...warningValues];

  return errorAndWarningValues.some((x, i) => x && Boolean(!activeFilters[errorAndWarningKeys[i]]));
};

import { IOrderDTO, IOrderListDTO } from '../../../../typings/DTOs';
import { ApiResponseType } from '../../../../components/Portals/ResponseSnackbar';
import { Order } from '../../../../routes/Manufacturing/Orders/helper';

export const initialState: OrdersState = {
  orders: [],
  apiResponse: null,
  error: '',
  search: '',
  isLoading: true,
  totalLength: 0,
  page: 0,
  offset: 0,
  isActive: true,
  orderNo: '',
  customerIds: [],
  projectIds: [],
  productItemIds: [],
  sortBy: 4,
  sortByDesc: true
};

export interface OrdersState {
  orders: Order[];
  totalLength: number;
  offset: number;
  apiResponse: ApiResponseType;
  error: string;
  page: number;
  search: string;
  isLoading: boolean;
  isActive: boolean;
  orderNo: string;
  customerIds: number[];
  projectIds: number[];
  productItemIds: number[];
  sortBy: number;
  sortByDesc: boolean;
}

type OrdersAction =
  | { type: 'success_get_orders'; payload: { orders: IOrderListDTO } }
  | { type: 'error_get_orders'; payload: { error: string } }
  | { type: 'success_get_order'; payload: { order: IOrderDTO } }
  | { type: 'success_post_order'; payload: { order: IOrderDTO } }
  | { type: 'error_post_order'; payload: { error: string } }
  | { type: 'success_put_order'; payload: { order: IOrderDTO } }
  | { type: 'error_put_order'; payload: { error: string } }
  | { type: 'set_partial_state'; payload: Partial<OrdersState> };

export function ordersReducer(state: OrdersState = initialState, action: OrdersAction): OrdersState {
  switch (action.type) {
    case 'success_get_orders': {
      return {
        ...state,
        isLoading: false,
        totalLength: action.payload.orders.totalLength,
        offset: action.payload.orders.offset,
        orders: action.payload.orders.items.map(o => new Order(o)),
        error: ''
      };
    }

    case 'error_get_orders': {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }

    case 'success_get_order': {
      const isExist = Boolean(state.orders.find(o => o.id === action.payload.order.id));
      return {
        ...state,
        orders: state.orders.map(o => {
          if (o.id === action.payload.order.id) {
            return new Order(action.payload.order);
          } else {
            return o;
          }
        }),
        totalLength: !isExist ? state.totalLength + 1 : state.totalLength
      };
    }

    case 'success_post_order': {
      return {
        ...state,
        orders: state.orders.concat([new Order(action.payload.order)]),
        totalLength: state.totalLength + 1,
        apiResponse: { type: 'success', message: 'Sikeres megrendelés létrehozás' }
      };
    }

    case 'error_post_order': {
      return {
        ...state,
        apiResponse: { type: 'error', message: action.payload.error }
      };
    }

    case 'success_put_order': {
      return {
        ...state,
        orders: state.orders.map(o => {
          if (o.id === action.payload.order.id) {
            return new Order(action.payload.order);
          } else {
            return o;
          }
        }),
        apiResponse: { type: 'success', message: 'Sikeres megrendelés szerkesztés' }
      };
    }

    case 'error_put_order': {
      return {
        ...state,
        apiResponse: { type: 'error', message: action.payload.error }
      };
    }

    case 'set_partial_state': {
      return {
        ...state,
        ...action.payload
      };
    }

    default:
      return state;
  }
}

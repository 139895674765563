/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

/* Presentation Things */
import { TextField } from '@rmwc/textfield';

/* Data Things */
import { TAX_NUMBER_REGEX, SOCIAL_SECURITY_NUMBER } from '../../../constants/regex';
import { AddPersonPersonalInfo } from '../../../routes/Organization/People/PeopleAdd/helper';
import { DATEFORMAT } from '../../../constants/constants';
import { DateTime } from 'luxon';

type ExpiryDates = 'idCardExpiryDate' | 'passportExpiryDate' | 'driverLicenseExpiryDate';

const zeroAll = { hour: 0, minute: 0, second: 0, millisecond: 0 };

type IProps = {
  model: AddPersonPersonalInfo;
  onEditModel<T extends keyof AddPersonPersonalInfo, K extends AddPersonPersonalInfo[T]>(key: T, value: K): void;
};

export function AddPersonalInfoSection({ model, onEditModel }: IProps) {
  const handleExpiryDate = (key: ExpiryDates, date: string) => {
    let expiryDate = DateTime.fromFormat(`${date}`, DATEFORMAT).set(zeroAll);
    if (expiryDate.isValid) {
      onEditModel(key, expiryDate);
    } else {
      onEditModel(key, null);
    }
  };

  return (
    <div className="form-content">
      <div className="form-content-row">
        <TextField
          autoFocus
          type="text"
          maxLength={10}
          label="Adóazonosító jel"
          pattern={TAX_NUMBER_REGEX.source}
          title="Helytelen adóazonosító jel"
          value={model ? model.taxNumber : ''}
          onChange={ev => onEditModel('taxNumber', ev.currentTarget.value)}
        />

        <TextField
          type="text"
          maxLength={9}
          label="TAJ szám"
          pattern={SOCIAL_SECURITY_NUMBER.source}
          title="Helytelen TAJ szám"
          value={model ? model.socialSecurityNumber : ''}
          onChange={ev => onEditModel('socialSecurityNumber', ev.currentTarget.value)}
        />

        <TextField type="text" maxLength={8} label="Lakcímkártya száma" value={model ? model.addressCardNumber : ''} onChange={ev => onEditModel('addressCardNumber', ev.currentTarget.value)} />
      </div>

      <div className="form-content-row">
        <TextField type="text" maxLength={8} label="Személyigazolvány száma" value={model ? model.idCardNumber : ''} onChange={ev => onEditModel('idCardNumber', ev.currentTarget.value)} />

        <TextField
          type="date"
          label="Személyigazolvány lejárata"
          value={model && model.idCardExpiryDate ? model.idCardExpiryDate.toFormat(DATEFORMAT) : ' '}
          onChange={ev => handleExpiryDate('idCardExpiryDate', ev.currentTarget.value)}
        />
      </div>

      <div className="form-content-row">
        <TextField type="text" maxLength={9} label="Útlevél száma" value={model ? model.passportNumber : ''} onChange={ev => onEditModel('passportNumber', ev.currentTarget.value)} />

        <TextField
          type="date"
          label="Útlevél lejárata"
          value={model && model.passportExpiryDate ? model.passportExpiryDate.toFormat(DATEFORMAT) : ' '}
          onChange={ev => handleExpiryDate('passportExpiryDate', ev.currentTarget.value)}
        />
      </div>

      <div className="form-content-row">
        <TextField type="text" maxLength={12} label="Jogosítvány száma" value={model ? model.driverLicenseNumber : ''} onChange={ev => onEditModel('driverLicenseNumber', ev.currentTarget.value)} />

        <TextField
          type="date"
          label="Jogosítvány lejárata"
          value={model && model.driverLicenseExpiryDate ? model.driverLicenseExpiryDate.toFormat(DATEFORMAT) : ' '}
          onChange={ev => handleExpiryDate('driverLicenseExpiryDate', ev.currentTarget.value)}
        />
      </div>
    </div>
  );
}

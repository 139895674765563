import React, { useState } from 'react';
import { MenuSurfaceAnchor, MenuSurface } from '@rmwc/menu';
import { Typography } from '@rmwc/typography';
import { IActivity } from './helper';
import { Icon } from '@rmwc/icon';

export const ActivityRow = ({ row }: { row: IActivity }) => {
  const timeFormat = "HH':'mm':'ss";
  const end = row.endTime !== null ? row.endTime.toFormat(timeFormat) : ' ';
  const start = row.startTime.toFormat(timeFormat);
  const difference = row.endTime !== null ? row.endTime.diff(row.startTime).toFormat('hh:mm:ss') : ' ';
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  return (
    <div className="data-table__row">
      <div className="flex-box-group flex-box-group--responsive__720">
        <div className="flex-box-group__item data-table__cell project">
          {row.projectId ? (
            <span className="project-selector-wrap">
              {(row.rootProjectDisplayCode || row.rootProjectName) && (
                <span className="project-selector">
                  {row.rootProjectColorCode && <span className="project-color" style={{ backgroundColor: `${row.rootProjectColorCode}` }} />}

                  <span className={`project-code ${row.rootProjectColorCode ? 'border-radius' : ''}`}>{row.rootProjectDisplayCode}</span>
                  <span className="project-name text-overflow">{row.rootProjectName}</span>
                </span>
              )}

              <span className="project-selector indent-wrap">
                {row.rootProjectName !== null && <span className="indent indent--end" />}

                {row.projectColorCode && <span className="project-color" style={{ backgroundColor: `${row.projectColorCode}` }} />}

                <span className={`project-code ${row.projectColorCode ? 'border-radius' : ''}`}>{row.projectDisplayCode}</span>
                <span className="project-name text-overflow">{row.projectName}</span>
              </span>
            </span>
          ) : (
            <span className="empty-cell project">Nincs projekt hozzárendelve</span>
          )}
        </div>

        <div className="flex-box-group__item data-table__cell comment">{row.comment ? <span className="project-comment text-overflow">{row.comment}</span> : <span className="empty-cell">Nincs kitöltve</span>}</div>
      </div>

      <MenuSurfaceAnchor>
        {row.tags && (
          <MenuSurface hoistToBody open={open} onClose={onClose}>
            <div className="tags-surface">
              <div className="header">
                <div className="title">Kiválasztott címkéim</div>
                <Icon icon="close" onClick={onClose} />
              </div>

              <div className="tags">
                {row.tags.map(elem => (
                  <span className="tag" title={elem.tagName || ''} key={elem.tagId}>
                    {elem.tagName}
                  </span>
                ))}
              </div>
            </div>
          </MenuSurface>
        )}

        <div className="flex-box-item data-table__cell tag-selector">
          <span className="tags">
            {row.tags.length !== 0 ? (
              row.tags.length <= 2 ? (
                row.tags.map(elem => (
                  <span title={elem.tagName || ''} className={`tag ${row.tags.length === 1 ? 'full-width' : ''}`} key={elem.tagId}>
                    {elem.tagName}
                  </span>
                ))
              ) : (
                <span className="more-tag tag full-width" onClick={onOpen} role="button">
                  {row.tags.length} címke van kiválasztva
                </span>
              )
            ) : (
              <span className="empty-cell">Nincs címke kiválasztva</span>
            )}
          </span>
        </div>
      </MenuSurfaceAnchor>

      <div className="flex-box-item">
        <Typography use="body1">{row.appliedDate.toFormat("MM'.'dd")}</Typography>
      </div>

      <div className="flex-box-item data-table__cell data-table__cell--align-right time-begin">
        <Typography use="body1">{start}</Typography>
      </div>

      <div className="flex-box-item data-table__cell data-table__cell--align-right time-end">
        <Typography use="body1">{end}</Typography>
      </div>

      <div className="flex-box-item data-table__cell--end data-table__cell--align-right">
        <Typography use="body1">{difference}</Typography>
      </div>
    </div>
  );
};

import React, { useCallback, useEffect, useReducer } from 'react';

/* Data Things */
import { createCustomer, editCustomer, getCustomer, getCustomers } from '../../store/lib/Manufacturing/customers';
import { customersReducer, initialState, CustomersState } from '../../store/reducers/Manufacturing/Customers';
import { ICreateCustomerDTO, IEditCustomerDTO } from '../../typings/DTOs';
import { useIsMounted } from '../../hooks/';

/* Presentation Things */
import ResponseSnackbar, { ApiResponseType } from '../Portals/ResponseSnackbar';

interface ICustomersContext extends CustomersState {
  onGetCustomers: (text?: string, page?: number) => Promise<string | null>;
  onGetCustomer: (id: number) => Promise<string | null>;
  onCreateCustomer: (model: ICreateCustomerDTO) => Promise<string | number>;
  onEditCustomer: (model: IEditCustomerDTO) => Promise<string | number>;
  onSetApiResponse: (apiResponse: ApiResponseType) => void;
}

const CustomersContext = React.createContext<ICustomersContext>({
  ...initialState,
  onGetCustomers: () => Promise.reject(''),
  onGetCustomer: () => Promise.reject(''),
  onCreateCustomer: () => Promise.reject(''),
  onEditCustomer: () => Promise.reject(''),
  onSetApiResponse: () => null
});

function CustomersProvider(props: React.PropsWithChildren<{}>) {
  /* State */
  const [state, dispatch] = useReducer(customersReducer, initialState);

  /* Hooks */
  const _isMounted = useIsMounted();

  const onGetCustomers = useCallback(async (): Promise<string> => {
    try {
      dispatch({ type: 'set_partial_state', payload: { isLoading: true, error: '' } });

      const customers = await getCustomers();
      if (customers && !customers.error && _isMounted.current) {
        dispatch({ type: 'success_get_customers', payload: { customers } });
        return Promise.resolve('');
      } else {
        throw new Error(customers.error || 'Sajnáljuk, valami hiba történt a vevők betöltés során. Kérlek próbáld újra!');
      }
    } catch (error) {
      const { message } = error as any;
      if (_isMounted.current) {
        dispatch({
          type: 'error_get_customers',
          payload: { error: String(message || 'Sajnáljuk, valami hiba történt a vevők betöltés során. Kérlek próbáld újra!') }
        });
      }
      return Promise.reject(String(message || error));
    }
  }, [_isMounted]);

  const onGetCustomer = useCallback(
    async (id: number): Promise<string> => {
      try {
        const customer = await getCustomer(id);
        if (customer && !customer.error && _isMounted.current) {
          dispatch({ type: 'success_get_customer', payload: { customer } });
          return Promise.resolve('');
        } else {
          throw new Error(customer.error || 'Sajnáljuk, valami hiba történt a vevők betöltés során. Kérlek próbáld újra!');
        }
      } catch (error) {
        const { message } = error as any;
        return Promise.reject(String(message || 'Sajnáljuk, valami hiba történt a vevők betöltés során. Kérlek próbáld újra!'));
      }
    },
    [_isMounted]
  );

  const onCreateCustomer = useCallback(
    async (model: ICreateCustomerDTO) => {
      try {
        const customer = await createCustomer(model);
        if (!customer.error && _isMounted.current) {
          dispatch({ type: 'success_post_customer', payload: { customer } });
          return Promise.resolve(customer.id);
        } else throw new Error(customer.error);
      } catch (error) {
        const { message } = error as any;
        return Promise.reject(String(message || error));
      }
    },
    [_isMounted]
  );

  const onEditCustomer = useCallback(
    async (model: IEditCustomerDTO) => {
      try {
        const customer = await editCustomer(model);
        if (!customer.error && _isMounted.current) {
          dispatch({ type: 'success_put_customer', payload: { customer } });
          return Promise.resolve(customer.id);
        } else throw new Error(customer.error);
      } catch (error) {
        const { message } = error as any;
        return Promise.reject(String(message || error));
      }
    },
    [_isMounted]
  );

  const onSetApiResponse = (apiResponse: ApiResponseType) => dispatch({ type: 'set_partial_state', payload: { apiResponse } });

  useEffect(() => void onGetCustomers(), [onGetCustomers]);

  return (
    <div className="Customers">
      <ResponseSnackbar response={state.apiResponse} onClose={() => onSetApiResponse(null)} />
      <CustomersContext.Provider value={{ ...state, onGetCustomers, onGetCustomer, onCreateCustomer, onEditCustomer, onSetApiResponse }}>{props.children}</CustomersContext.Provider>
    </div>
  );
}

const CustomersConsumer = CustomersContext.Consumer;
export { CustomersProvider, CustomersContext, CustomersConsumer };

import { ClosedMonthDatesDTO, MonthlyCloseDTO, PersonRowDTO, PreviewCloseCostsDTO } from '../../../lib/TrackTime/monthlyClose';
import { IPreviewCloseCosts, sortPersonsByName, transformPreviewCloseCostsDTO } from '../../../../routes/TrackTime/MonthlyClose/helper';
import { ApiResponseType } from '../../../../components/Portals/ResponseSnackbar';
import { DateTime } from 'luxon';

export const initialState: MonthlyCloseState = {
  actual: DateTime.local().minus({ month: 1, day: 1 }),
  model: null,
  apiResponse: null,
  error: '',
  isLoading: true,
  isShowCheckTable: false,
  preview: {},
  closedInfo: {
    lastClosedDate: null,
    restrictedBefore: null
  }
};

export const initState = (year?: string, month?: string, state?: MonthlyCloseState): MonthlyCloseState => {
  const parsedYear = year ? parseInt(year) : NaN;
  const parsedMonth = month ? parseInt(month) : NaN;

  return {
    ...initialState,
    closedInfo: state ? state.closedInfo : initialState.closedInfo,
    actual: !isNaN(parsedYear) && !isNaN(parsedMonth) ? DateTime.local().set({ year: parsedYear, month: parsedMonth, day: 1 }) : initialState.actual
  };
};
export interface MonthlyCloseState {
  actual: DateTime;
  model: MonthlyCloseDTO | null;
  preview: Record<number, IPreviewCloseCosts>;
  apiResponse: ApiResponseType;
  error: string;
  isLoading: boolean;
  isShowCheckTable: boolean;
  closedInfo: {
    lastClosedDate: DateTime | null;
    restrictedBefore: DateTime | null;
  };
}

type MonthlyCloseAction =
  | { type: 'success_get_closedInfo'; payload: { closedInfo: ClosedMonthDatesDTO } }
  | { type: 'error_get_closedInfo'; payload: { error: string } }
  | { type: 'success_get_model'; payload: { model: MonthlyCloseDTO } }
  | { type: 'error_get_model'; payload: { error: string } }
  | { type: 'success_open_month'; payload: { lastClosedMonth: string | null } }
  | { type: 'error_open_month'; payload: { error: string } }
  | { type: 'success_close_month'; payload: { model: MonthlyCloseDTO } }
  | { type: 'error_close_month'; payload: { error: string } }
  | { type: 'success_get_preview'; payload: { previews: PreviewCloseCostsDTO[] } }
  | { type: 'error_get_preview'; payload: { error: string } }
  | { type: 'success_close_cost' }
  | { type: 'error_close_cost'; payload: { error: string } }
  | { type: 'on_upload_csv'; payload: { persons: PersonRowDTO[] } }
  | { type: 'person_cost_change'; payload: { id: number; cost: number | null } }
  | { type: 'set_partial_state'; payload: Partial<MonthlyCloseState> }
  | { type: 'on_month_change'; payload: { year?: string; month?: string } };

export function monthlyCloseReducer(state: MonthlyCloseState = initialState, action: MonthlyCloseAction): MonthlyCloseState {
  switch (action.type) {
    case 'success_get_closedInfo': {
      return {
        ...state,
        closedInfo: {
          lastClosedDate: action.payload.closedInfo.lastClosedMonth ? DateTime.fromISO(action.payload.closedInfo.lastClosedMonth) : null,
          restrictedBefore: action.payload.closedInfo.restrictedBefore ? DateTime.fromISO(action.payload.closedInfo.restrictedBefore) : null
        }
      };
    }

    case 'error_get_closedInfo': {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }

    case 'success_get_model': {
      return {
        ...state,
        isLoading: false,
        model: { ...action.payload.model, persons: action.payload.model.persons !== null ? sortPersonsByName(action.payload.model.persons) : null },
        error: ''
      };
    }

    case 'error_get_model': {
      return {
        ...state,
        isLoading: false,
        model: null,
        error: action.payload.error
      };
    }

    case 'on_upload_csv': {
      return {
        ...state,
        model: state.model ? { ...state.model, persons: action.payload.persons } : null
      };
    }

    case 'set_partial_state': {
      return {
        ...state,
        ...action.payload
      };
    }

    case 'success_open_month': {
      return {
        ...state,
        model: state.model ? { ...state.model, isMonthClosed: false } : null,
        apiResponse: { type: 'success', message: 'Sikeres feloldás' },
        isLoading: false,
        closedInfo: {
          restrictedBefore: state.closedInfo.restrictedBefore,
          lastClosedDate: action.payload.lastClosedMonth ? DateTime.fromISO(action.payload.lastClosedMonth) : null
        }
      };
    }

    case 'error_open_month': {
      return {
        ...state,
        apiResponse: { type: 'error', message: action.payload.error },
        isLoading: false
      };
    }

    case 'success_close_month': {
      const lastDayInMonth = state.actual.set({ day: state.actual.daysInMonth });

      return {
        ...state,
        model: action.payload.model.persons ? { ...action.payload.model, isMonthClosed: true, persons: sortPersonsByName(action.payload.model.persons) } : null,
        isLoading: false,
        closedInfo: {
          lastClosedDate: state.closedInfo.lastClosedDate && state.closedInfo.lastClosedDate <= state.actual ? state.actual : state.closedInfo.lastClosedDate,
          restrictedBefore: state.closedInfo.restrictedBefore && state.closedInfo.restrictedBefore <= lastDayInMonth ? lastDayInMonth.plus({ day: 1 }) : state.closedInfo.restrictedBefore
        },
        apiResponse: { type: 'success', message: 'Sikeres hónap zárás' }
      };
    }

    case 'error_close_month': {
      return {
        ...state,
        apiResponse: { type: 'error', message: action.payload.error },
        isLoading: false
      };
    }

    case 'success_get_preview': {
      return {
        ...state,
        preview: action.payload.previews.reduce((acc, curr) => ({ ...acc, [curr.personId]: transformPreviewCloseCostsDTO(curr) }), {}),
        isLoading: false,
        apiResponse: { type: 'success', message: 'Sikeres előkalkuláció' }
      };
    }

    case 'error_get_preview': {
      return {
        ...state,
        apiResponse: { type: 'error', message: action.payload.error },
        isLoading: false
      };
    }

    case 'success_close_cost': {
      return {
        ...state,
        model: state.model
          ? {
              ...state.model,
              isMonthClosed: true,
              isCostClosedForMonth: true
            }
          : null,
        isLoading: false,
        apiResponse: { type: 'success', message: 'Sikeres költésg felvitel' }
      };
    }

    case 'error_close_cost': {
      return {
        ...state,
        apiResponse: { type: 'error', message: action.payload.error },
        isLoading: false
      };
    }

    case 'person_cost_change': {
      return state.model && state.model.persons
        ? {
            ...state,
            model: {
              ...state.model,
              persons: state.model.persons.map(x => (x.id === action.payload.id ? { ...x, realCostPerMonth: action.payload.cost } : x))
            }
          }
        : state;
    }

    case 'on_month_change': {
      return initState(action.payload.year, action.payload.month, state);
    }

    default:
      return state;
  }
}

import { ConcurrentEditViewModel } from '../../../store/lib/Organization/Peoples/employment';
import { EditSecurityGroupDTO, SecurityGroupRowDTO } from '../../../store/lib/Settings/securityGroups';
import { IClaimAssignRow, transformClaimAssignRowDTO, transformIClaimAssignRow } from '../ApplicationUsers/helper';

export interface ISecurityGroupRow extends ConcurrentEditViewModel {
  id: number;
  name: string;
  memberIds: number[];
  claimAssigns: { [claimId: number]: IClaimAssignRow };
  isVisible: boolean;
}

export const INITIAL_GROUP: ISecurityGroupRow = {
  id: -1,
  name: '',
  isVisible: true,
  memberIds: [],
  claimAssigns: {},
  updated: null
};

export const transformSecurityGroupRowDTO = (p: SecurityGroupRowDTO): ISecurityGroupRow => {
  return {
    id: p.id,
    name: p.groupName || '',
    memberIds: [],
    claimAssigns: {},
    updated: p.updated,
    isVisible: true
  };
};

export const transformEditSecurityGroupDTO = (p: EditSecurityGroupDTO): ISecurityGroupRow => {
  return {
    id: p.id,
    name: p.name || '',
    memberIds: p.memberIds,
    claimAssigns: p.claimAssigns.reduce((acc, curr) => ({ ...acc, [curr.claimId]: transformClaimAssignRowDTO(curr) }), {}),
    updated: p.updated,
    isVisible: true
  };
};

export const generateEditSecurityGroupDTO = (p: ISecurityGroupRow): EditSecurityGroupDTO => {
  return {
    id: p.id,
    name: p.name,
    updated: p.updated,
    memberIds: p.memberIds,
    claimAssigns: Object.values(p.claimAssigns).map(x => transformIClaimAssignRow(x))
  };
};

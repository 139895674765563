import React from 'react';
import { getActivites, ValidationCheckMonthRowDTO, getLeaveRequestRowsDetails } from '../../../../../store/lib/TrackTime/monthlyClose';
import CircularProgress from '../../../../../components/CircularProgress';
import InnerTableHeader from './Header';
import { Duration } from 'luxon';
import { ActivityRow } from './ActivityRow';
import ResponseSnackbar, { ApiResponseType } from '../../../../../components/Portals/ResponseSnackbar';
import { getOvertimeRequestRowsDetails } from '../../../../../store/lib/TrackTime/monthlyClose';
import RequestRow from './RequestRow';
import { activitysTransform, IActivity, ILeaveRequestRow, IOvertimeRequestRow, leaveRequestRowTransform, overtimeRequestRowTransform } from './helper';
import { SelectedCell } from '../helper';

export type IProps = {
  selectedCell: SelectedCell;
  row: ValidationCheckMonthRowDTO;
};

export type IState = {
  model: InnerTableModel;
  loading: boolean;
  apiResponse: ApiResponseType;
};

type InnerTableModel = {
  activitiesWithoutProject: IActivity[];
  activitiesMoreThanEightWorkingHours: IActivity[];
  overlappedActivities: IActivity[];
  pendingOvertimeRequests: IOvertimeRequestRow[];
  leaveOvertimeRequests: ILeaveRequestRow[];
};

export class InnerTable extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      model: {
        activitiesWithoutProject: [],
        activitiesMoreThanEightWorkingHours: [],
        overlappedActivities: [],
        pendingOvertimeRequests: [],
        leaveOvertimeRequests: []
      },
      loading: false,
      apiResponse: null
    };
  }
  private _isMounted;

  private handleApiResponseClose = (apiResponse: ApiResponseType) => this.setState({ apiResponse });

  componentDidMount = () => (this._isMounted = true);

  componentWillUnmount = () => (this._isMounted = false);

  componentDidUpdate(prevProps: IProps) {
    const { activitiesWithoutProject, activitiesMoreThanEightWorkingHours, overlappedActivities, pendingOvertimeRequests, leaveOvertimeRequests } = this.state.model;

    if (!this.state.loading && prevProps.selectedCell !== this.props.selectedCell) {
      if (activitiesWithoutProject.length === 0 && this.props.selectedCell === SelectedCell.ActivityWithoutProject) {
        this.setState({ loading: true, apiResponse: null }, () => {
          getActivites([...this.props.row.warnings.activityIdsWithoutProject])
            .then(resp => {
              if (!resp.error && this._isMounted) {
                this.setState(state => {
                  return { model: { ...state.model, activitiesWithoutProject: resp.map(x => activitysTransform(x)) }, loading: false };
                });
              } else if (resp.error) this.setState({ apiResponse: { message: resp.error, type: 'error' }, loading: false });
            })
            .catch(err => this.setState({ apiResponse: { message: err.toString(), type: 'error' }, loading: false }));
        });
      }

      if (activitiesMoreThanEightWorkingHours.length === 0 && this.props.selectedCell === SelectedCell.ActivityMoreThanEightWorkingHours) {
        this.setState({ loading: true, apiResponse: null }, () => {
          getActivites([...this.props.row.warnings.activityIdsMoreThanEightWorkingHours])
            .then(resp => {
              if (!resp.error && this._isMounted) {
                this.setState(state => {
                  return { model: { ...state.model, activitiesMoreThanEightWorkingHours: resp.map(x => activitysTransform(x)) }, loading: false };
                });
              } else if (resp.error) this.setState({ apiResponse: { message: resp.error, type: 'error' }, loading: false });
            })
            .catch(err => this.setState({ apiResponse: { message: err.toString(), type: 'error' }, loading: false }));
        });
      }

      if (overlappedActivities.length === 0 && this.props.selectedCell === SelectedCell.OverlappedActivity) {
        this.setState({ loading: true, apiResponse: null }, () => {
          getActivites([...this.props.row.warnings.overlappedActivityIds])
            .then(resp => {
              if (!resp.error && this._isMounted) {
                this.setState(state => {
                  return { model: { ...state.model, overlappedActivities: resp.map(x => activitysTransform(x)) }, loading: false };
                });
              } else if (resp.error) this.setState({ apiResponse: { message: resp.error, type: 'error' }, loading: false });
            })
            .catch(err => this.setState({ apiResponse: { message: err.toString(), type: 'error' }, loading: false }));
        });
      }

      if (pendingOvertimeRequests.length === 0 && this.props.selectedCell === SelectedCell.PendingOvertimeRequest) {
        this.setState({ loading: true, apiResponse: null }, () => {
          getOvertimeRequestRowsDetails([...this.props.row.errors.pendingOvertimeRequestRowIds])
            .then(resp => {
              if (!resp.error && this._isMounted) {
                this.setState(state => {
                  return { model: { ...state.model, pendingOvertimeRequests: resp.map(x => overtimeRequestRowTransform(x)) }, loading: false };
                });
              } else if (resp.error) this.setState({ apiResponse: { message: resp.error, type: 'error' }, loading: false });
            })
            .catch(err => this.setState({ apiResponse: { message: err.toString(), type: 'error' }, loading: false }));
        });
      }

      if (leaveOvertimeRequests.length === 0 && this.props.selectedCell === SelectedCell.PendingLeaveRequest) {
        this.setState({ loading: true, apiResponse: null }, () => {
          getLeaveRequestRowsDetails([...this.props.row.errors.pendingLeaveRequestRowIds])
            .then(resp => {
              if (!resp.error && this._isMounted) {
                this.setState(state => {
                  return { model: { ...state.model, leaveOvertimeRequests: resp.map(x => leaveRequestRowTransform(x)) }, loading: false };
                });
              } else if (resp.error) this.setState({ apiResponse: { message: resp.error, type: 'error' }, loading: false });
            })
            .catch(err => this.setState({ apiResponse: { message: err.toString(), type: 'error' }, loading: false }));
        });
      }
    }
  }

  render() {
    const { activitiesWithoutProject, activitiesMoreThanEightWorkingHours, overlappedActivities, pendingOvertimeRequests, leaveOvertimeRequests } = this.state.model;
    const { selectedCell } = this.props;
    const summedTimes = (
      selectedCell === SelectedCell.ActivityWithoutProject
        ? activitiesWithoutProject
        : selectedCell === SelectedCell.ActivityMoreThanEightWorkingHours
        ? activitiesMoreThanEightWorkingHours
        : selectedCell === SelectedCell.OverlappedActivity
        ? overlappedActivities
        : []
    ).reduce((sum, current) => (current.endTime !== null ? sum.plus(current.endTime.diff(current.startTime)) : sum), Duration.fromObject({}));

    return (
      <>
        <InnerTableHeader selectedCell={selectedCell} summedTimes={summedTimes} />
        {this.state.loading ? (
          <CircularProgress />
        ) : selectedCell === SelectedCell.ActivityWithoutProject ? (
          activitiesWithoutProject.map(activitie => <ActivityRow key={activitie.activityTimeEntryId} row={activitie} />)
        ) : selectedCell === SelectedCell.ActivityMoreThanEightWorkingHours ? (
          activitiesMoreThanEightWorkingHours.map(activitie => <ActivityRow key={activitie.activityTimeEntryId} row={activitie} />)
        ) : selectedCell === SelectedCell.OverlappedActivity ? (
          overlappedActivities.map(activitie => <ActivityRow key={activitie.activityTimeEntryId} row={activitie} />)
        ) : selectedCell === SelectedCell.PendingOvertimeRequest ? (
          pendingOvertimeRequests.map(request => <RequestRow key={request.overtimeRequestRowId} row={request} />)
        ) : selectedCell === SelectedCell.PendingLeaveRequest ? (
          leaveOvertimeRequests.map(request => <RequestRow key={request.leaveRequestRowId} row={request} />)
        ) : (
          ''
        )}
        {this.state.apiResponse && <ResponseSnackbar response={this.state.apiResponse} onClose={this.handleApiResponseClose} />}
      </>
    );
  }
}

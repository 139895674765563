import { UserManager, UserManagerSettings, User } from 'oidc-client';

export default class Auth {
  private static INSTANCE: Auth;

  private mgr: UserManager;

  private constructor() {
    if (process.env.REACT_APP_AUTH_DOMAIN && process.env.REACT_APP_AUTH_CLIENT_ID && process.env.REACT_APP_AUTH_REDIRECT_URI && process.env.REACT_APP_AUTH_REDIRECT_LOGOUT_URI) {
      // https://github.com/IdentityModel/oidc-client-js/wiki
      const config: UserManagerSettings = {
        authority: process.env.REACT_APP_AUTH_DOMAIN, // "http://localhost:5000",
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID, // "spa",
        redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI, // "http://localhost:5003/callback.html",
        response_type: 'code', // token, id_token
        scope: 'openid profile email api1 everything',
        post_logout_redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_LOGOUT_URI, // "http://localhost:5003/index.html",
        automaticSilentRenew: true,
        silent_redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_LOGOUT_URI + 'silent' // .TODO silent URL config or app base url to config
        // filterProtocolClaims: true, // default true
        // loadUserInfo: true, // default true
        // monitorSession: true, // default true
      };
      this.mgr = new UserManager(config);
      this.mgr.events.addUserSignedOut(() => {
        console.log('User signed out...');
        this.mgr.removeUser().then(() => this.login());
      });
      this.mgr.events.addSilentRenewError(error => {
        console.error('error while renewing the access token', error);
      });
      this.mgr.events.addAccessTokenExpiring(() => {
        console.log('Access token expiring...');
      });
      this.mgr.events.addAccessTokenExpired(() => {
        console.log('Access token expired...');
        this.logout();
      });
    } else {
      throw Error('Auth is not initialized');
    }
  }

  public static get Instance() {
    return this.INSTANCE || (this.INSTANCE = new this());
  }

  public login() {
    console.log('Sign in, redirect to: ', window.location.pathname);
    this.mgr.signinRedirect({ data: { path: window.location.pathname } });
    // .TODO error handling if Auth Endpoint not available
  }

  public logout() {
    this.mgr.signoutRedirect();
    this.mgr.removeUser(); // TODO: needed?
  }

  public isAuthenticated(): Promise<User> {
    return this.mgr.getUser(); // TODO: question: async await?
  }

  public async getAccessToken(): Promise<string> {
    console.log('Trying to get access token', this);
    const user = await this.mgr.getUser(); // TODO: question: async await?
    console.log('Returning access token');
    return user.access_token;
  }
}

import React, { useCallback, useEffect, useReducer, useRef } from 'react';

/* Route things */
import { RouteComponentProps } from '@reach/router';

/* Data Things */
import { CalendarDay, exportCSVFile, generateActivitiesCSV, generateLeaveDaysCSV, generateOvertimesCSV, generateOverviewCSV, generateProjectWorkHoursCSV, generateWorkhoursNeededCSV, ViewTypes } from './helper';
import { getActivitiesReport, getForMonth, getProjectHoursReport, setPersonDayStatus } from '../../../store/lib/TrackTime/monthlyAttendence';
import { monthlyAttendenceListReducer, initialState } from '../../../store/reducers/TrackTime/MonthlyAttendence/monthlyAttendence';
import { getDayStatuses } from '../../../store/lib/MasterData/dailyStatuses';
import { DayStatusRow } from '../../MasterData/DailyStatuses/helper';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { DateTime } from 'luxon';

/* Presentation Things */
import ResponseSnackbar from '../../../components/Portals/ResponseSnackbar';
import { MenuItem, SimpleMenu } from '@rmwc/menu';
import { Radio, RadioProps } from '@rmwc/radio';
import { MonthPager } from '../../../components/Pagers/';
import { IconButton } from '@rmwc/icon-button';
import { Switch } from '@rmwc/switch';
import { List } from './List';

/* Style */
import './MonthlyAttendence.scss';

const NOW = DateTime.local();

interface IProps extends RouteComponentProps {}

export function MonthlyAttendence(_: IProps) {
  const [state, dispatch] = useReducer(monthlyAttendenceListReducer, initialState);
  const { selectedMonth, selectedView, isShowIncomplete, dayStatuses, rows, apiResponse, isFullScreenMode } = state;

  /* Refs */
  const _fetchSignal = useRef<AbortController>();
  const _isMounted = useIsMounted();

  /* Variable */
  const isCurrentMonth = selectedMonth.year === NOW.year && selectedMonth.month === NOW.month;

  const getDayStatusesFromRemote = useCallback(async () => {
    try {
      const dayStatuses = await getDayStatuses();
      if (dayStatuses && _isMounted.current) {
        dispatch({ type: 'success_get_daystatuses', payload: { dayStatuses } });
      }
    } catch (error) {
      _isMounted.current && dispatch({ type: 'error_get_daystatuses', payload: { error: String(error.message) } });
    }
  }, [_isMounted]);

  const getRowsFromRemote = async (dayStatuses: DayStatusRow[]) => {
    try {
      _fetchSignal.current = new AbortController();
      dispatch({ type: 'set_partial_state', payload: { isLoading: true, rows: [] } });
      const model = await getForMonth(selectedMonth, _fetchSignal.current.signal);
      if (model && !model.error && _isMounted.current) {
        dispatch({ type: 'success_get_model', payload: { model, selectedMonth, dayStatuses } });
      } else throw new Error(model.error);
    } catch (error) {
      _isMounted.current && dispatch({ type: 'error_get_model', payload: { error: String(error.message || error.error) } });
    }
  };

  const getProjectWorkHoursFromRemote = async () => {
    dispatch({ type: 'set_partial_state', payload: { apiResponse: { type: 'success', message: 'Riport generálás folyamatban...' } } });
    try {
      const resp = await getProjectHoursReport(selectedMonth);
      if (resp) {
        return generateProjectWorkHoursCSV(resp, selectedMonth);
      } else throw new Error('Sikertelen riport generálás...');
    } catch (error) {
      if (_isMounted.current) {
        dispatch({ type: 'set_partial_state', payload: { apiResponse: { type: 'error', message: 'Sikertelen riport generálás...' } } });
      }
      return null;
    }
  };

  const getActivitiesFromRemote = async () => {
    dispatch({ type: 'set_partial_state', payload: { apiResponse: { type: 'success', message: 'Riport generálás folyamatban...' } } });
    try {
      const resp = await getActivitiesReport(selectedMonth);
      if (resp) {
        return generateActivitiesCSV(resp, selectedMonth);
      } else throw new Error('Sikertelen riport generálás...');
    } catch (error) {
      if (_isMounted.current) {
        dispatch({ type: 'set_partial_state', payload: { apiResponse: { type: 'error', message: 'Sikertelen riport generálás...' } } });
      }
      return null;
    }
  };

  const handleShowInComplete = (e: React.FormEvent<HTMLInputElement>) => {
    e.persist();
    dispatch({ type: 'set_isShowIncomplete', payload: { isShowIncomplete: !!e.currentTarget.checked } });
  };

  const handleChangeSelectedView = (e: React.FormEvent<RadioProps>) => {
    e.persist();
    dispatch({ type: 'set_selectedView', payload: { selectedView: e.currentTarget.value as ViewTypes } });
  };

  const handleDayStatusChange = async (personId: number, statusId: number | null, day: CalendarDay) => {
    try {
      dispatch({ type: 'set_day', payload: { personId, day: { ...day, isUpdating: true } } });
      const response = await setPersonDayStatus({ personId, statusId, date: day.date });
      if (response && !response.error && _isMounted.current) {
        _isMounted.current && dispatch({ type: 'success_day_status_change', payload: { personId, day: response } });
      } else throw new Error(response.error || '');
    } catch (error) {
      if (_isMounted.current) {
        dispatch({ type: 'set_day', payload: { personId, day: { ...day, isUpdating: false } } });
        dispatch({
          type: 'set_partial_state',
          payload: { apiResponse: { type: 'error', message: error.message || 'Sikertelen státusz beállítás' } }
        });
      }
    }
  };

  const handleGenerateCSVFile = (type: 'OverView' | 'WHNeeded' | 'PHours' | 'LDays' | 'Overtimes' | 'Activities') => {
    let headerrow, itemrows, fileTitle;
    switch (type) {
      case 'OverView': {
        const resp = generateOverviewCSV(rows, dayStatuses);
        headerrow = resp.headerrow;
        itemrows = resp.itemrows;
        fileTitle = ('ProHR ' + selectedMonth.toLocaleString({ year: 'numeric', month: 'long' })).replace(' ', '_');
        break;
      }

      case 'WHNeeded': {
        const resp = generateWorkhoursNeededCSV(rows);
        headerrow = resp.headerrow;
        itemrows = resp.itemrows;
        fileTitle = ('ProHR Elvart_orak ' + selectedMonth.toLocaleString({ year: 'numeric', month: 'long' })).replace(' ', '_');
        break;
      }

      case 'Overtimes': {
        const resp = generateOvertimesCSV(rows);
        headerrow = resp.headerrow;
        itemrows = resp.itemrows;
        fileTitle = ('ProHR Tulorak ' + selectedMonth.toLocaleString({ year: 'numeric', month: 'long' })).replace(' ', '_');
        break;
      }

      case 'LDays': {
        const resp = generateLeaveDaysCSV(rows, dayStatuses);
        headerrow = resp.headerrow;
        itemrows = resp.itemrows;
        fileTitle = ('ProHR Tavollet_napok ' + selectedMonth.toLocaleString({ year: 'numeric', month: 'long' })).replace(' ', '_');
        break;
      }

      case 'PHours': {
        getProjectWorkHoursFromRemote().then(resp => {
          if (resp) {
            exportCSVFile(resp.headerrow, resp.itemrows, ('ProHR Projekt_orak ' + selectedMonth.toLocaleString({ year: 'numeric', month: 'long' })).replace(' ', '_'));
          }
        });
        break;
      }

      case 'Activities': {
        getActivitiesFromRemote().then(resp => {
          if (resp) {
            exportCSVFile(resp.headerrow, resp.itemrows, ('ProHR Aktivitasok ' + selectedMonth.toLocaleString({ year: 'numeric', month: 'long' })).replace(' ', '_'));
          }
        });
        break;
      }
    }
    if (headerrow && itemrows && fileTitle) {
      exportCSVFile(headerrow, itemrows, fileTitle);
    }
  };

  const handleCloseResponseBar = () => dispatch({ type: 'set_partial_state', payload: { apiResponse: null } });

  const onToogleFullScreenMode = () => dispatch({ type: 'toogleFullScreenMode' });

  const prevMonth = () => dispatch({ type: 'prevMonth' });

  const nextMonth = () => dispatch({ type: 'nextMonth' });

  useEffect(() => void getDayStatusesFromRemote(), [getDayStatusesFromRemote]);

  useEffect(
    () => {
      if (dayStatuses.length > 1) {
        _fetchSignal.current && _fetchSignal.current.abort();
        getRowsFromRemote(dayStatuses);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedMonth, dayStatuses]
  );

  return (
    <div className={`MonthlyAttendence attendence ${isFullScreenMode ? 'fixed' : ''}`}>
      <MonthPager
        date={selectedMonth}
        prevMonth={prevMonth}
        nextMonth={nextMonth}
        isLoading={state.isLoading}
        isMonthRestrictedButEditable={state.isMonthRestrictedButEditable}
        restrictedBefore={state.restrictedBefore}
      />

      {/* Table */}
      <div className={`proHr-card custom-card attendence-table' ${selectedView === 'PayRoll' ? 'row--doubleheight' : ''}`}>
        <div className="attendence-table--subheader">
          <h3 className="title">Jelenléti ív</h3>

          <div className="controls">
            <span className="mdc-form-field">
              <Switch disabled={selectedView !== 'DayStatus' && selectedView !== 'RegisteredTime'} id="showIncomplete" label="Hiányos sorok mutatása" checked={isShowIncomplete} onChange={handleShowInComplete} />
            </span>

            <Radio value="DayStatus" checked={selectedView === 'DayStatus'} onChange={handleChangeSelectedView}>
              Napi Státusz
            </Radio>

            <Radio value="PayRoll" checked={selectedView === 'PayRoll'} onChange={handleChangeSelectedView}>
              Elvárt
            </Radio>

            <Radio value="OverTime" checked={selectedView === 'OverTime'} onChange={handleChangeSelectedView}>
              Túlórák
            </Radio>

            <Radio value="RegisteredTime" checked={selectedView === 'RegisteredTime'} onChange={handleChangeSelectedView}>
              Mért órák
            </Radio>

            <SimpleMenu handle={<IconButton disabled={!rows} icon="insert_drive_file" />}>
              <MenuItem onClick={() => handleGenerateCSVFile('OverView')}>Áttekintő</MenuItem>
              <MenuItem onClick={() => handleGenerateCSVFile('WHNeeded')}>Elvárt órák</MenuItem>
              <MenuItem onClick={() => handleGenerateCSVFile('Overtimes')}>Túlórák</MenuItem>
              <MenuItem onClick={() => handleGenerateCSVFile('PHours')}>Projekt órák</MenuItem>
              <MenuItem onClick={() => handleGenerateCSVFile('LDays')}>Távollét napok</MenuItem>
              <MenuItem onClick={() => handleGenerateCSVFile('Activities')}>Aktivitások</MenuItem>
            </SimpleMenu>

            <IconButton disabled={!rows} icon={isFullScreenMode ? 'fullscreen_exit' : 'fullscreen'} onClick={onToogleFullScreenMode} />
          </div>
        </div>

        <List state={state} isCurrentMonth={isCurrentMonth} onStatusChange={handleDayStatusChange} isMonthRestrictedButEditable={state.isMonthRestrictedButEditable} restrictedBefore={state.restrictedBefore} />
      </div>

      <ResponseSnackbar response={apiResponse} onClose={handleCloseResponseBar} />
    </div>
  );
}

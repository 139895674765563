import React from 'react';

/* Navigation Things */
import { RouteComponentProps, Router } from '@reach/router';

/* Data Things */
import { ProductsProvider } from '../../../components/Contexts/Products';

/* Routes */
import { ProductDetails } from './Details';
import { List } from './List';

/* Styles */
import './styles.scss';

interface IProps extends RouteComponentProps {}

export function Products(_: IProps) {
  return (
    <ProductsProvider>
      <Router basepath="Manufacturing/Products">
        <List default />
        <ProductDetails path="/:id" />
      </Router>
    </ProductsProvider>
  );
}

import React from 'react';

/* Presentation Things */
import { Radio } from '@rmwc/radio';
import { Select } from '@rmwc/select';
import { Checkbox } from '@rmwc/checkbox';
import { TextField } from '@rmwc/textfield';
import FileSelector, { SelectedFile } from '../../Selectors/FileSelector';

/* Data Things */
import { DateTime } from 'luxon';
import { DATEFORMAT } from '../../../constants/constants';
import { formatBytes } from '../../../constants/helperFuntions';
import { useIsMounted } from '../../../hooks';
import { AddPersonBasicInfo } from '../../../routes/Organization/People/PeopleAdd/helper';
import { uploadProfilePicture } from '../../../store/lib/upload';
import { SexTypes, MARITAL_STATUSES_MAP } from '../../../constants/enums';

const MARITALSTATUSES = [{ label: 'Nincs megadva', value: 'null' }, ...Object.keys(MARITAL_STATUSES_MAP).map(key => ({ label: String(MARITAL_STATUSES_MAP[key]), value: String(key) }))];

const zeroAll = { hour: 0, minute: 0, second: 0, millisecond: 0 };
const today = DateTime.local();
const MAX_PICTURE_SIZE = 6000000; //bytes

type IProps = {
  model: AddPersonBasicInfo;
  onEditModel<T extends keyof AddPersonBasicInfo, K extends AddPersonBasicInfo[T]>(key: T, value: K): void;
};

export function AddBasicInfoSection({ model, onEditModel }: IProps) {
  const _isMounted = useIsMounted();

  const handleBirthDate = (date: string) => {
    const parseAble = DateTime.fromFormat(`${date}`, DATEFORMAT).set(zeroAll);
    if (parseAble.isValid && parseAble < today) {
      onEditModel('birthDate', parseAble);
    }
  };

  const handlePictureSelect = (pictures: SelectedFile) => {
    if (pictures.file.size < MAX_PICTURE_SIZE && !pictures.error) {
      return uploadProfilePictureToTempStorage(pictures.file);
    }
    return Promise.reject(pictures.error || 'Nem megfelelő a file típusa vagy túl nagy a mérete');
  };

  const handlePictureDelete = () => onEditModel('pictureTempFileId', null);

  const uploadProfilePictureToTempStorage = async (picture: File): Promise<{ locationId: number | null; message: string }> => {
    try {
      const formData = new FormData();
      formData.append('file', picture);
      const [{ error, id }] = await uploadProfilePicture(formData);
      if (!error) {
        _isMounted.current && onEditModel('pictureTempFileId', id);
        return Promise.resolve({ locationId: id, message: '' });
      } else throw new Error(error);
    } catch (error) {
      return Promise.reject(String(error.message || error));
    }
  };

  return (
    <div className="form-content basic-info">
      <Checkbox autoFocus checked={model.isPrivate} label="Privát profil" onChange={evt => onEditModel('isPrivate', !!evt.currentTarget.checked)} />

      <FileSelector label="Válasszon képet" maxFileSize={MAX_PICTURE_SIZE} onSelect={handlePictureSelect} onDelete={handlePictureDelete} accept=".png, .jpg" title="Fénykép" />
      <span className="file-selector-helper-text">Maximális fájlméret: {formatBytes(MAX_PICTURE_SIZE)}. Megengedett fájltípusok: jpg, png.</span>

      <div className="form-content-row">
        <TextField type="text" label="Titulus" maxLength={30} value={model.courtesyTitle} onChange={ev => onEditModel('courtesyTitle', ev.currentTarget.value)} />

        <TextField type="text" label="Vezetéknév" maxLength={80} required value={model.lastName} onChange={ev => onEditModel('lastName', ev.currentTarget.value)} />

        <TextField type="text" maxLength={80} label="Keresztnév" required value={model.firstName} onChange={ev => onEditModel('firstName', ev.currentTarget.value)} />

        <TextField type="text" maxLength={80} label="Második Keresztnév" value={model.middleName} onChange={ev => onEditModel('middleName', ev.currentTarget.value)} />
      </div>

      <div className="form-content-row">
        <div className="radio-control-wrap">
          <div className="label">Neme*</div>

          <div className="radio-buttons">
            <Radio value={SexTypes.Male} checked={model.sexTypeId === SexTypes.Male} onChange={() => onEditModel('sexTypeId', SexTypes.Male)}>
              Férfi
            </Radio>

            <Radio value={SexTypes.Female} checked={model.sexTypeId === SexTypes.Female} onChange={() => onEditModel('sexTypeId', SexTypes.Female)}>
              Nő
            </Radio>
          </div>
        </div>

        <TextField type="text" maxLength={200} label="Születési név" value={model.birthName} onChange={ev => onEditModel('birthName', ev.currentTarget.value)} />

        <TextField type="text" label="Születési hely" maxLength={100} required value={model.birthPlace} onChange={ev => onEditModel('birthPlace', ev.currentTarget.value)} />

        <TextField type="date" max={today.toFormat(DATEFORMAT)} required label="Születési idő" value={model.birthDate.toFormat(DATEFORMAT)} onChange={ev => handleBirthDate(ev.currentTarget.value)} />
      </div>

      <div className="form-content-row">
        <TextField type="text" label="Anyja neve" maxLength={200} required value={model.motherName} onChange={ev => onEditModel('motherName', ev.currentTarget.value)} />

        <Select
          label="Családi állapot"
          options={MARITALSTATUSES}
          value={model.maritalStatus !== null ? String(model.maritalStatus) : 'null'}
          onChange={ev => onEditModel('maritalStatus', ev.currentTarget.value === 'null' ? null : Number(ev.currentTarget.value))}
        />

        <TextField type="text" maxLength={80} label="Szinkronicáziós kódja" value={model.syncId} onChange={ev => onEditModel('syncId', ev.currentTarget.value)} />
      </div>
    </div>
  );
}

import React, { useCallback, useEffect, useReducer } from 'react';

/* Data Things */
import ResponseSnackbar, { ApiResponseType } from '../Portals/ResponseSnackbar';
import { securityGroups, initialState, SecurityGroupState } from '../../store/reducers/Settings/SecurityGroups';
import { ISecurityGroupRow } from '../../routes/Settings/SecurityGroups/helper';
import { getSecurityGroups } from '../../store/lib/Settings/securityGroups';
import { useIsMounted } from '../../hooks/useIsMounted';

interface ISecurityGroupsContext extends SecurityGroupState {
  onSetApiResponse: (apiResponse: ApiResponseType) => void;
  onSetGroup: (group: ISecurityGroupRow, isAPIResponse?: boolean) => void;
  onSearchInGroups: (searchText: string) => void;
}

const SecurityGroupsContext = React.createContext<ISecurityGroupsContext>({
  ...initialState,
  groups: {},
  onSetApiResponse: () => null,
  onSetGroup: () => null,
  onSearchInGroups: () => null
});

function SecurityGroupsProvider(props: React.PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(securityGroups, initialState);
  const _isMounted = useIsMounted();

  const getSecurityGroupsFromRemote = useCallback(async () => {
    try {
      const groups = await getSecurityGroups();
      if (groups.length > 0 && !groups.error && _isMounted.current) {
        dispatch({ type: 'success_get_groups', payload: { groups } });
      } else throw new Error(groups.error || 'Nincsenek biztonsági csoportok');
    } catch (err) {
      _isMounted.current && dispatch({ type: 'error_get_groups', payload: { error: String(err.message) } });
    }
  }, [_isMounted]);

  useEffect(() => void getSecurityGroupsFromRemote(), [getSecurityGroupsFromRemote]);

  const onSetGroup = useCallback((group: ISecurityGroupRow, isAPIResponse: boolean = false) => {
    dispatch({ type: 'on_set_group', payload: { group, isAPIResponse } });
  }, []);

  const onSetApiResponse = useCallback((apiResponse: ApiResponseType) => dispatch({ type: 'on_set_apiResponse', payload: { apiResponse } }), []);

  const onSearchInGroups = useCallback((searchText: string) => dispatch({ type: 'on_search_groups', payload: { searchText } }), []);
  return (
    <div className="ApplicationUsers">
      <ResponseSnackbar response={state.apiResponse} onClose={() => onSetApiResponse(null)} />
      <SecurityGroupsContext.Provider value={{ ...state, onSetGroup, onSetApiResponse, onSearchInGroups }}>{props.children}</SecurityGroupsContext.Provider>
    </div>
  );
}

const SecurityGroupsConsumer = SecurityGroupsContext.Consumer;
export { SecurityGroupsProvider, SecurityGroupsContext, SecurityGroupsConsumer };

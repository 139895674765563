import { ICreateInventoryDTO, IEditInventoryDTO, IInventoryDTO, IInventoryListDTO } from '../../../typings/DTOs';
import { api } from '../../../utils/api';

export const getInventories = (search: string, offset: number, limit: number, signal: AbortSignal) => api<IInventoryListDTO>('GET', `inventories?search=${search}&offset=${offset}&limit=${limit}`, undefined, signal);

export const getInventory = (id: number) => api<IInventoryDTO>('GET', `inventories/${id}`);

export const createInventory = (model: ICreateInventoryDTO) => api<IInventoryDTO>('POST', 'inventories', model);

export const editInventory = (model: IEditInventoryDTO) => api<IInventoryDTO>('PUT', `inventories/${model.id}`, model);

import React, { useCallback, useEffect, useReducer } from 'react';

/* Data Things */
import ResponseSnackbar, { ApiResponseType } from '../Portals/ResponseSnackbar';
import { usersReducer, initialState, UsersState } from '../../store/reducers/Settings/ApplicationUsers';
import { getApplicationUsers } from '../../store/lib/Settings/applicationUsers';
import { useIsMounted } from '../../hooks/useIsMounted';
import { IUserRow } from '../../routes/Settings/ApplicationUsers/helper';

interface IApplicationUsersContext extends UsersState {
  onSetApiResponse: (apiResponse: ApiResponseType) => void;
  onSetUser: (newUser: IUserRow, isAPIResponse?: boolean) => void;
  onSearchInUsers: (searchText: string) => void;
}

const ApplicationUsersContext = React.createContext<IApplicationUsersContext>({
  ...initialState,
  users: {},
  onSetApiResponse: () => null,
  onSetUser: () => null,
  onSearchInUsers: () => null
});

function ApplicationUsersProvider(props: React.PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(usersReducer, initialState);
  const _isMounted = useIsMounted();

  useEffect(() => {
    getApplicationUsers()
      .then(users => {
        if (users.length > 0) {
          _isMounted.current && dispatch({ type: 'success_get_users', payload: { users } });
        } else {
          throw new Error('Nincsenek felhasználók');
        }
      })
      .catch(err => {
        _isMounted.current && dispatch({ type: 'error_get_users', payload: { error: String(err.message) } });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSetUser = (user: IUserRow, isAPIResponse: boolean = false) => dispatch({ type: 'on_set_user', payload: { user, isAPIResponse } });

  const onSetApiResponse = (apiResponse: ApiResponseType) => dispatch({ type: 'on_set_apiResponse', payload: { apiResponse } });

  const onSearchInUsers = useCallback((searchText: string) => dispatch({ type: 'on_search_users', payload: { searchText } }), []);

  return (
    <div className="ApplicationUsers">
      <ResponseSnackbar response={state.apiResponse} onClose={() => onSetApiResponse(null)} />
      <ApplicationUsersContext.Provider value={{ ...{ ...state, onSetUser, onSetApiResponse, onSearchInUsers } }}>{props.children}</ApplicationUsersContext.Provider>
    </div>
  );
}

const ApplicationUsersConsumer = ApplicationUsersContext.Consumer;
export { ApplicationUsersProvider, ApplicationUsersContext, ApplicationUsersConsumer };

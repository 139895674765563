import { JobRoleDTO } from '../../../store/lib/MasterData/jobRoles';
import { ConcurrentEditViewModel } from '../../../store/lib/Organization/Peoples/employment';

export interface JobRole extends ConcurrentEditViewModel {
  jobRoleId: number;
  name: string;
  feorCode: string;
}

export const transformJobRoleDTO = (p: JobRoleDTO): JobRole => {
  return {
    feorCode: p.feorCode || '',
    jobRoleId: p.jobRoleId,
    name: p.name || '',
    updated: p.updated
  };
};

export const sortJobRoles = (jobRoles: JobRole[]) => jobRoles.sort((x, y) => x.feorCode.localeCompare(y.feorCode));

export const searchJobRoles = (roles: JobRole[], text: string) =>
  roles.filter(x => {
    const lowerSearchValue = text.toLowerCase();

    return x.name.toLowerCase().includes(lowerSearchValue) || x.feorCode.toLowerCase().includes(lowerSearchValue);
  });

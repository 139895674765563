import React, { useMemo } from 'react';

/* Data Things */
import { ActiveTab, IEmployee, sortPersonsByName } from '../../../routes/Organization/People/PeopleList/helper';

/* Presentation Things */
import CircularProgress from '../../CircularProgress';
import { Typography } from '@rmwc/typography';
import { ShowImage } from '../../Images/ShowImage';
import { Link } from '@reach/router';
import { Icon } from '@rmwc/icon';

interface IProps {
  activeTab: ActiveTab;
  people: { [id: number]: IEmployee };
  error: string;
  isLoading: boolean;
}

export const Employees = (props: IProps) => {
  const { people, error, isLoading, activeTab } = props;

  const peopleArray = useMemo(() => sortPersonsByName(Object.values(people).filter(x => x.isVisible && (activeTab === ActiveTab.Active ? x.isActive : true))), [people, activeTab]);

  return (
    <div className="ActiveEmployees">
      <div className="data-table">
        <div className="data-table__row data-table__row--title">
          <div className="flex-box-item data-table__cell person">
            <Typography use="caption">Név</Typography>
          </div>

          <div className="flex-box-item data-table__cell">
            <Typography use="caption">Telefon</Typography>
          </div>

          <div className="flex-box-item data-table__cell">
            <Typography use="caption">E-mail</Typography>
          </div>

          <div className="flex-box-item data-table__cell">
            <Typography use="caption">Munkakör</Typography>
          </div>

          <div className="row-spacer-header__end" />
        </div>

        <div className="data-table-body">
          {isLoading ? (
            <CircularProgress />
          ) : error.length > 0 || peopleArray.length < 1 ? (
            <div className="basic-error-text">{error || 'Nincs megjeleníthető alkalmazott'}</div>
          ) : (
            peopleArray.map(people => <Row people={people} key={people.personId} />)
          )}
        </div>
      </div>
    </div>
  );
};

const Row = ({ people }: { people: IEmployee }) => {
  const { firstName, lastName, courtesyTitle, middleName, pictureUrl, phoneNumber, email, jobRoles } = people.details;
  const name = useMemo(() => [courtesyTitle, lastName, firstName, middleName].filter(Boolean).join(' '), [courtesyTitle, lastName, firstName, middleName]);

  return (
    <div className="data-table__row">
      <div className="flex-box-item data-table__cell person">
        <div className="avatar-wrap">
          <ShowImage fullName={name} pictureURL={pictureUrl}>
            {people.isPrivate && <Icon className="private-icon" icon="lock" />}

            <Typography use="body1">{name}</Typography>
          </ShowImage>
        </div>
      </div>

      <div className="flex-box-item data-table__cell">{phoneNumber ? <a href={`tel:${phoneNumber}`}>{phoneNumber}</a> : <span className="not-found-data">Nincs megadva</span>}</div>

      <div className="flex-box-item data-table__cell">{email ? <a href={`mailto:${email}`}>{email}</a> : <span className="not-found-data">Nincs megadva</span>}</div>

      <div className="flex-box-item data-table__cell">{jobRoles && jobRoles.length > 0 ? jobRoles.join(', ') : <span className="not-found-data">Nincs megadva</span>}</div>

      <div className="flex-box-item data-table__cell--meta data-table__cell--end">
        <Link to={`/Organization/People/Details/${people.personId}`}>
          <Icon icon="keyboard_arrow_right" />
        </Link>
      </div>
    </div>
  );
};

import { IMenu } from './helper';

export const Menu: IMenu = {
  items: [
    {
      name: 'Szervezet',
      icon: { className: 'fa fa-sitemap fa-2x' },
      baseUrl: 'Organization',
      subMenus: [
        { name: 'Szervezeti felépítés', url: 'Chart' },
        { name: 'Emberek', url: 'People' }
      ]
    },

    {
      name: 'Munkaidő',
      icon: { className: 'material-icons', name: 'schedule' },
      baseUrl: 'TrackTime',
      subMenus: [
        { name: 'Jelenléti ív', url: 'MonthlyAttendence' },
        { name: 'Havi zárás', url: 'MonthlyClose' },
        { name: 'Projektek', url: 'Projects' },
        { name: 'Szinkronizáció', url: 'SyncStatus' }
      ]
    },

    {
      name: 'Törzsadatok',
      icon: { className: 'material-icons', name: 'storage' },
      baseUrl: 'MasterData',
      subMenus: [
        { name: 'Munkakörök', url: 'JobRoles' },
        { name: 'Aktivitás címkék', url: 'ActivityTags' },
        { name: 'Napi státusz', url: 'DayStatuses' }
      ]
    },

    {
      name: 'Konfiguráció',
      icon: { className: 'material-icons', name: 'tune' },
      baseUrl: 'Settings',
      subMenus: [
        { name: 'Típus definíciók' },
        { name: 'Szervezeti egység típusok', url: 'OUTypes' },
        { name: 'Biztonsági beállítások' },
        { name: 'Felhasználók', url: 'ApplicationUsers' },
        { name: 'Biztonsági csoportok', url: 'SecurityGroups' }
      ]
    },

    {
      name: 'Termelés',
      icon: { className: 'material-icons', name: 'precision_manufacturing' },
      baseUrl: 'Manufacturing',
      subMenus: [
        { name: 'Cikk', url: 'Products' },
        { name: 'Vevő', url: 'Customers' },
        { name: 'Megrendelés', url: 'Orders' },
        { name: 'Készlet kezelés', url: 'Inventories' }
      ]
    },

    /* ALWAYS LAST(profile) */
    {
      name: 'Profile',
      icon: { className: 'material-icons', name: 'profile' },
      baseUrl: 'Account',
      subMenus: [] //custom subMenus
    }
  ]
};

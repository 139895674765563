import React from 'react';
import { RowSyncStatus, ActivitySyncRow } from '../helper';
import Collapsible from 'react-collapsible';
import { Row, RowProps } from './Row';

type TableBodyProps = {
  activities: ActivitySyncRow[];
  onCheck(id: number, checked: boolean): void;
  onSync(ids: number[], isAuto: boolean);
  onStatusChange(rows: ActivitySyncRow[], newStatusCode: RowSyncStatus): void;
  isAutoSyncEnabled: boolean;
};

export class TableBody extends React.PureComponent<TableBodyProps, {}> {
  render() {
    return (
      <div className="data-table-body">
        {this.props.activities.map(activitie =>
          activitie.errorMessage ? <CollapesRow key={activitie.activitySyncId} activitie={activitie} {...this.props} /> : <Row key={activitie.activitySyncId} activitie={activitie} {...this.props} />
        )}
      </div>
    );
  }
}

export default TableBody;

const CollapesRow = React.memo(
  (props: RowProps) => {
    return (
      <Collapsible className={Boolean(props.activitie.errorMessage) ? 'active' : ''} triggerDisabled={!Boolean(props.activitie.errorMessage)} trigger={<Row {...props} />} transitionTime={100}>
        <div className="fail-reason">{props.activitie.errorMessage}</div>
      </Collapsible>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.activitie.activitySyncId === nextProps.activitie.activitySyncId &&
      prevProps.activitie.activitySyncRowVersion === nextProps.activitie.activitySyncRowVersion &&
      prevProps.activitie.isCheckAble === nextProps.activitie.isCheckAble &&
      prevProps.activitie.isChecked === nextProps.activitie.isChecked &&
      prevProps.activitie.isLoading === nextProps.activitie.isLoading &&
      prevProps.activitie.syncStatusCode === nextProps.activitie.syncStatusCode
    );
  }
);

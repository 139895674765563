import { AddOrEditOUDTO, CompanyForOrgHierarchyDTO, getAllEmployees, OUForOrgHierarchyDTO, OUPersonDTO } from '../../../store/lib/Organization/Structure';
import { ConcurrentAddOrEditViewModel } from '../../../constants/types';
import { OrganizationStructureAddOrEditState } from '../../../store/reducers/Organization/Structure/addOrEdit';
import { formatName } from '../../../constants/helperFuntions';
import { OUType } from '../../Settings/OUTypes/helper';
import { OU } from '../../../components/People/ProfileDetails/Employment/Edit/helper';

export interface CompanyForOrganization {
  id: number;
  name: string;
  logoUrl: string;
  childUnits: OrganizationUnit[];
}

export interface OrganizationUnit extends ConcurrentAddOrEditViewModel {
  id: number;
  typeId: number;
  colorCode: string;
  name: string;
  parent: {
    id: number | null;
    companyId: number;
  };
  manager: {
    id: number;
    name: string;
    pictureUrl: string;
    email: string;
    phone: string;
  } | null;
  deputyManager: {
    id: number;
    name: string;
    email: string;
    phone: string;
    pictureUrl: string;
  } | null;
  level: number;
  childUnits: OrganizationUnit[];
}

export interface OUPerson {
  personId: number;
  pictureUrl: string;
  courtesyTitle: string;
  lastName: string;
  firstName: string;
  middleName: string;
  phoneNumber: string;
  email: string;
  fullName: string;
  jobRoles: string[];
}

export const transformOUPersonDTO = (p: OUPersonDTO): OUPerson => {
  const courtesyTitle = p.courtesyTitle || '';
  const firstName = p.firstName || '';
  const lastName = p.lastName || '';
  const middleName = p.middleName || '';

  return {
    personId: p.personId,
    pictureUrl: p.pictureUrl || '',
    courtesyTitle,
    email: p.email || '',
    firstName,
    fullName: formatName(firstName, lastName, middleName, courtesyTitle),
    jobRoles: p.jobRoles ? p.jobRoles : [],
    lastName,
    middleName,
    phoneNumber: p.phoneNumber || ''
  };
};

export const transformCompanyForOrgHierarchyDTO = (p: CompanyForOrgHierarchyDTO): CompanyForOrganization => {
  return {
    id: p.companyId,
    name: p.name || '',
    logoUrl: p.logoUrl || '',
    childUnits: p.childUnits ? p.childUnits.map(x => transformOUForOrgHierarchyDTO(x)) : []
  };
};

export const transformOUForOrgHierarchyDTO = (p: OUForOrgHierarchyDTO, level: number = 1): OrganizationUnit => {
  return {
    id: p.ouId,
    typeId: p.ouTypeId,
    colorCode: p.colorCode || '',
    name: p.ouName || '',
    parent: {
      id: p.parentOUId,
      companyId: p.parentCompanyId
    },
    manager:
      p.managerPersonId !== null
        ? {
            id: p.managerPersonId,
            name: p.managerName || '',
            email: p.managerEmail || '',
            phone: p.managerPhone || '',
            pictureUrl: p.managerPictureUrl || ''
          }
        : null,
    deputyManager:
      p.deputyManagerPersonId !== null
        ? {
            id: p.deputyManagerPersonId,
            name: p.deputyManagerName || '',
            email: p.deputyManagerEmail || '',
            phone: p.deputyManagerPhone || '',
            pictureUrl: p.deputyManagePictureUrl || ''
          }
        : null,
    level,
    childUnits: p.childUnits.map(x => transformOUForOrgHierarchyDTO(x, level + 1)),
    updated: p.updated
  };
};

export const generateOragnizationUnitsFromCompany = (company: CompanyForOrganization, currentOrganizationUnitId?: number): OU[] => {
  const flatChildUnits = (p: OrganizationUnit[]) =>
    p.reduce((acc, curr) => {
      if (curr.id === currentOrganizationUnitId || curr.parent.companyId !== company.id) {
        return [...acc, ...flatChildUnits(curr.childUnits)];
      } else {
        return [...acc, { ouId: curr.id, name: curr.name }, ...flatChildUnits(curr.childUnits)];
      }
    }, [] as OU[]);

  return flatChildUnits(company.childUnits);
};

export const generateOUTypeOptions = (p: OUType[]) => {
  return p.map(x => ({ label: x.name, value: String(x.id) })).sort((x, y) => x.label.localeCompare(y.label));
};

export const generateCompanyOptions = (p: CompanyForOrganization[]) => {
  return p.map(x => ({ label: x.name, value: String(x.id) })).sort((x, y) => x.label.localeCompare(y.label));
};

export const generateOrganizationUnitOptions = (p: OU[]) => {
  return [{ label: 'Nincs', value: ' ' }, ...p.map(x => ({ label: x.name, value: String(x.ouId) })).sort((x, y) => x.label.localeCompare(y.label))];
};

export const generateEmployeeOptions = (p: { name: string; id: number }[]) => {
  return [{ label: 'Nincs', value: ' ' }, ...p.map(x => ({ label: x.name, value: String(x.id) })).sort((x, y) => x.label.localeCompare(y.label))];
};

export const trasformStateToAddOrEditOUDTO = (p: OrganizationStructureAddOrEditState, editableOrganization?: OrganizationUnit): AddOrEditOUDTO | undefined => {
  if (p.ouTypeId !== null && p.parentCompanyId !== null) {
    return {
      deputyManagerPersonId: p.deputyManagerPersonId,
      managerPersonId: p.managerPersonId,
      name: p.name,
      oUTypeId: p.ouTypeId,
      parentCompanyId: p.parentCompanyId,
      parentOUId: p.parentOUId,
      oUId: editableOrganization ? editableOrganization.id : null,
      updated: editableOrganization ? editableOrganization.updated : null
    };
  }
};

export const getEmployeesFromRemote = async () => {
  try {
    const employees = await getAllEmployees();
    if (!employees.error) return Promise.resolve(employees);
    else throw new Error(employees.error);
  } catch (error) {
    return Promise.reject(String(error.message || 'Sajnos nem sikerült lekérdezni az embereket'));
  }
};

import { ISelectableRootProjectDTO, ISelectableSubProjectDTO } from '../../../typings/DTOs';
import { ProjectDTO } from '../../../store/lib/TrackTime/projects';
import { Project } from '../../../typings/common';

export class ProjectWithSubproject extends Project {
  public isTimeRegisterable: boolean;
  public isActive: boolean;
  public subprojects: ProjectWithSubproject[] | null;
  public level: number;

  constructor(p: ISelectableRootProjectDTO | ProjectDTO, rootProject: Project | null = null, level = 1) {
    super(p.projectId, p.displayCode, p.displayName, p.colorCode, rootProject);

    this.isActive = p.isActive;
    this.isTimeRegisterable = p.isTimeRegisterable;
    this.subprojects = 'hasSubProjects' in p && p.hasSubProjects === true ? [] : null;
    this.level = level;
  }
}

export const flatSubprojects = (rootProject: ProjectWithSubproject, project: ISelectableSubProjectDTO, result: ProjectWithSubproject[], level = 1): ProjectWithSubproject[] => {
  if (project.subProjects) {
    project.subProjects.forEach(x => {
      result.push(new ProjectWithSubproject(x, rootProject, level + 1));
      flatSubprojects(rootProject, x, result, level + 1);
    });
  }

  return result;
};

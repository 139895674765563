import React from 'react';
import { IOvertimeRequestRow, ILeaveRequestRow, instanceOfLRRow } from './helper';
import { Typography } from '@rmwc/typography';

const RequestRow = ({ row }: { row: IOvertimeRequestRow | ILeaveRequestRow }) => {
  return (
    <div className="data-table__row">
      <div className="flex-box-group flex-box-group--responsive__1024 result-days">
        <div className="flex-box-group__item data-table__cell">
          <Typography use="caption">
            <span>{row.startDate ? row.startDate.setLocale('hu').toFormat('DD') : ''}</span>

            <span className="separator"> - </span>

            <span>{row.endDate ? row.endDate.setLocale('hu').toFormat('DD') : ''}</span>
          </Typography>
        </div>
      </div>

      {!instanceOfLRRow(row) && (
        <div className="flex-box-group__item data-table__cell maximum">
          <Typography use="caption">{row.maxMinutes ? (row.maxMinutes / 60).toFixed(1) + ' óra' : 'Korlátlan'}</Typography>
        </div>
      )}
    </div>
  );
};

export default RequestRow;

import React, { useContext, useMemo, useState, useRef } from 'react';
import { useIsMounted } from '../../../../hooks';

/* Data Things */
import { Product, transformProductToICreateProductDTO } from '../helper';
import { PRODUCT_ITEM_QUANTITY_UNIT_MAP } from '../../../../constants/constants';
import { Link, RouteComponentProps, useNavigate } from '@reach/router';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { ProductsContext } from '../../../../components/Contexts/Products';
import { SelectedFile } from '../../../../components/Selectors/FileSelector';

/* Presentation Things */
import CircularProgress from '../../../../components/CircularProgress';
import { DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableHeadCell, DataTableRow } from '@rmwc/data-table';
import { AddOrEditProduct } from '../AddOrEdit';
import { TablePager } from '../../../../components/Pagers';
import { Typography } from '@rmwc/typography';
import { ShowImage } from '../../../../components/Images/ShowImage';
import { Icon } from '@rmwc/icon';
import { Checkbox } from '@rmwc/checkbox';
import { SimpleDialog } from '@rmwc/dialog';
import { useKeyPress } from '../../../../hooks/useKeypress';

/* Style */
import '../../../../styles/common.scss';

interface Props extends RouteComponentProps {}

export function List(props: Props) {
  //Context
  const context = useContext(ProductsContext);
  const { open, isOpen } = useContext(SideSheetContext);

  //Hooks
  const _isMounted = useIsMounted();
  const searchInput = useRef(null);

  //Variables
  const products = useMemo(
    () =>
      Object.values(context.products)
        .filter(p => !p.parentId)
        .sort((a, b) => a.title.localeCompare(b.title)),
    [context.products]
  );

  const onSearch = (ev: React.ChangeEvent<HTMLInputElement>) => context.onSearch(String(ev.currentTarget.value));

  const onClearSearch = () => context.onSearch('');

  const onSubmitAdd = async (product: Product, primaryPicture: SelectedFile, otherPictures: SelectedFile[]): Promise<Boolean> => {
    try {
      const resp = await context.onCreateProduct(transformProductToICreateProductDTO(product, primaryPicture, otherPictures));
      if (typeof resp === 'number') {
        props.navigate && props.navigate(`/Manufacturing/Products/${resp}`);
        return Promise.resolve(true);
      } else throw new Error('Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
    } catch (error) {
      console.log(error);
      return Promise.reject(false);
    }
  };

  const onOpenAddSheet = () => open(<AddOrEditProduct onSubmit={onSubmitAdd} />, 'side-sheet-600');

  const onKeyPress = event => {
    if (document.activeElement !== searchInput.current && !isOpen && _isMounted) {
      onOpenAddSheet();
      event.preventDefault();
    }
  };

  useKeyPress(['+', 'f', 'F'], onKeyPress);

  return (
    <>
      <div className="data-table-title">
        <Typography className="title" use="body2">
          Cikkek
        </Typography>
      </div>

      <div className="fab-menu-container">
        <div className="mdc-sheet-fab--container">
          <div className="mdc-sheet-fab">
            <div className="mdc-fab mdc-fab--mini" aria-label="hozzáadás" onClick={onOpenAddSheet}>
              <i className="material-icons">add</i>
            </div>
          </div>
        </div>
      </div>

      <div className={`search-box ${(context.isLoading || context.error.length > 0) && 'disabled'}`}>
        <Icon icon="search" />

        <input className="search-box--input" type="text" placeholder="Keresés" onChange={onSearch} value={context.search} />

        <span className="search-box--start-detail" onClick={onClearSearch}>
          <Icon icon="close" />
        </span>
      </div>

      <DataTable>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>
              <DataTableHeadCell>Kép</DataTableHeadCell>
              <DataTableHeadCell>Megnevezés</DataTableHeadCell>
              <DataTableHeadCell>Egység</DataTableHeadCell>
              <DataTableHeadCell>Festetlen cikkszám</DataTableHeadCell>
              <DataTableHeadCell>Festett cikkszám</DataTableHeadCell>
              <DataTableHeadCell alignMiddle>Gyári szám</DataTableHeadCell>
              <DataTableHeadCell alignEnd />
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {context.isLoading ? (
              <DataTableRow>
                <DataTableCell colSpan={7}>
                  <CircularProgress />
                </DataTableCell>
              </DataTableRow>
            ) : context.error.length > 0 || products.length < 1 ? (
              <DataTableRow>
                <DataTableCell colSpan={7} className="basic-error-cell">
                  {context.error || 'Nincs megjeleníthető cikk'}
                </DataTableCell>
              </DataTableRow>
            ) : (
              products.map(row => <Row key={row.id} row={row} />)
            )}
          </DataTableBody>
        </DataTableContent>
        <TablePager page={context.page} total={context.totalLength} displayedCount={50} onChange={context.onChangePage} />
      </DataTable>
    </>
  );
}

const Row = ({ row }: { row: Product }) => {
  //context
  const { onCloneProduct } = useContext(ProductsContext);
  const navigate = useNavigate();

  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const { id, title, primaryPicture, itemNoRaw, itemNoReady, quantityUnit, hasSerialNumber } = row;

  const onOpenDialog = () => {
    setShowCopyDialog(true);
  };

  const onCloseDialog = async evt => {
    setShowCopyDialog(false);
    if (id && evt.detail.action === 'accept') {
      try {
        const newId = await onCloneProduct(id);
        await navigate(`/Manufacturing/Products/${newId}`);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <DataTableRow>
      <DataTableCell>
        {' '}
        <ShowImage pictureURL={primaryPicture ? primaryPicture.url : null} />
      </DataTableCell>
      <DataTableCell>{title}</DataTableCell>
      <DataTableCell>{PRODUCT_ITEM_QUANTITY_UNIT_MAP[quantityUnit]}</DataTableCell>
      <DataTableCell>{itemNoRaw}</DataTableCell>
      <DataTableCell>{itemNoReady}</DataTableCell>
      <DataTableCell alignMiddle>
        <Checkbox checked={hasSerialNumber} readOnly disabled className="" />
      </DataTableCell>
      <DataTableCell alignEnd className="more-icons arrow">
        <Icon icon="content_copy" onClick={onOpenDialog} title="Klónozás" />
        <Link to={`./${id}`}>
          <Icon icon="keyboard_arrow_right" />
        </Link>
      </DataTableCell>
      <SimpleDialog title="Biztosan másolatot készít?" body={'Cikk: #' + id + ' ' + title} acceptLabel="Igen" cancelLabel="Mégsem" open={showCopyDialog} onClose={onCloseDialog} />
    </DataTableRow>
  );
};

import React, { useState, useEffect } from 'react';

/* Presentation Things */
import { Button } from '@rmwc/button';
import { TextField } from '@rmwc/textfield';
import { LinearProgress } from '@rmwc/linear-progress';

/* Data Things */
import { useIsMounted } from '../../../../../hooks/useIsMounted';
import { PersonDetailsContactInfoDTO, AddressDTO, getContactInfoForEdit, putContactInfoForEdit } from '../../../../../store/lib/Organization/Peoples/peopleDetails';
import { PersonDetails, EditPersonContactInfo, transformEditPersonContactInfoDTO, transformEditPersonContactInfo, transformPersonDetailsContactInfoDTO } from '../helper';

type IProps = {
  close(): void;
  onEditPartialDetailsModel(p: Partial<PersonDetails>): void;
  personId: number;
};

export function EditContactInfoSection({ personId, onEditPartialDetailsModel, close }: IProps) {
  const _isMounted = useIsMounted();
  const [model, setModel] = useState<null | EditPersonContactInfo>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const isSaveAble = model && !isLoading && error.length === 0;

  useEffect(
    () => {
      getModelFromRemote(personId);
    },
    // eslint-disable-next-line
    [personId]
  );

  const getModelFromRemote = (personId: number) => {
    getContactInfoForEdit(personId)
      .then(resp => {
        _isMounted.current && setModel(transformEditPersonContactInfoDTO(resp));
        _isMounted.current && setIsLoading(false);
        _isMounted.current && setError('');
      })
      .catch(() => {
        _isMounted.current && setIsLoading(false);
        _isMounted.current && setError('Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
      });
  };

  const handleModelChange = <T extends keyof PersonDetailsContactInfoDTO, K extends PersonDetailsContactInfoDTO[T]>(key: T, value: K) => {
    setModel(prevModel => {
      if (prevModel) {
        return { ...prevModel, [key]: value };
      }
      return prevModel;
    });
  };

  const handleHomeAddressChange = <T extends keyof AddressDTO, K extends AddressDTO[T]>(key: T, value: K) => {
    setModel(prevModel => {
      if (prevModel) {
        return { ...prevModel, privateHomeAddress: { ...prevModel.privateHomeAddress, [key]: value } };
      }
      return prevModel;
    });
  };

  const handleResidenceAddressChange = <T extends keyof AddressDTO, K extends AddressDTO[T]>(key: T, value: K) => {
    setModel(prevModel => {
      if (prevModel) {
        return { ...prevModel, privateResidenceAddress: { ...prevModel.privateResidenceAddress, [key]: value } };
      }
      return prevModel;
    });
  };

  const handleSave = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (isSaveAble) {
      setIsLoading(true);
      putContactInfoForEdit(personId, transformEditPersonContactInfo(model!))
        .then(resp => {
          _isMounted.current && onEditPartialDetailsModel({ contactInfo: transformPersonDetailsContactInfoDTO(resp) });
          _isMounted.current && setIsLoading(false);
          close();
        })
        .catch(err => {
          console.log(err);
          _isMounted.current && setError('Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
          _isMounted.current && setIsLoading(false);
        });
    }
  };

  return (
    <>
      <div tabIndex={0} className="drawer-header">
        Kapcsolat adatok szerkesztése
      </div>

      <div className="form-content">
        <form onSubmit={handleSave}>
          {isLoading && <LinearProgress className="linear-progress" />}
          {error.length > 0 && <div className="basic-error-text">{error}</div>}

          <div className="form-content-container">
            <span className="title">I. Publikus</span>

            <div className="form-content-row">
              <TextField type="tel" label="Mobilszám" maxLength={13} value={model ? model.publicMobilePhone : ''} onChange={ev => handleModelChange('publicMobilePhone', ev.currentTarget.value)} />

              <TextField type="text" maxLength={13} label="Vezetékes" value={model ? model.publicLinePhone : ''} onChange={ev => handleModelChange('publicLinePhone', ev.currentTarget.value)} />

              <TextField type="text" maxLength={10} label="Mellék" value={model ? model.publicLinePhoneExtension : ''} onChange={ev => handleModelChange('publicLinePhoneExtension', ev.currentTarget.value)} />
            </div>

            <div className="form-content-row">
              <TextField type="email" maxLength={100} label="E-mail" value={model ? model.publicEmail : ''} onChange={ev => handleModelChange('publicEmail', ev.currentTarget.value)} />

              <TextField type="text" maxLength={300} label="LinkedIn Profil URL" value={model ? model.linkedin : ''} onChange={ev => handleModelChange('linkedin', ev.currentTarget.value)} />
            </div>
          </div>

          <div className="form-content-container">
            <div className="title">II. Privát</div>

            <div className="sub-title">Lakcím</div>

            <div className="form-content-row">
              <TextField type="text" maxLength={100} required label="Ország" value={model ? model.privateHomeAddress.country : ''} onChange={ev => handleHomeAddressChange('country', ev.currentTarget.value)} />

              <TextField type="number" maxLength={10} required label="Irányítószám" value={model ? model.privateHomeAddress.zip : ''} onChange={ev => handleHomeAddressChange('zip', ev.currentTarget.value)} />

              <TextField type="text" label="Város" maxLength={100} required value={model ? model.privateHomeAddress.city : ''} onChange={ev => handleHomeAddressChange('city', ev.currentTarget.value)} />
            </div>

            <div className="form-content-row">
              <TextField
                type="text"
                maxLength={500}
                label="Címsor 1"
                required
                value={model ? model.privateHomeAddress.addressLine1 : ''}
                onChange={ev => handleHomeAddressChange('addressLine1', ev.currentTarget.value)}
              />

              <TextField type="text" maxLength={500} label="Címsor 2" value={model ? model.privateHomeAddress.addressLine2 : ''} onChange={ev => handleHomeAddressChange('addressLine2', ev.currentTarget.value)} />
            </div>

            <div className="sub-title">Tartózkodási hely</div>

            <div className="form-content-row">
              <TextField type="text" required maxLength={100} label="Ország" value={model ? model.privateResidenceAddress.country : ''} onChange={ev => handleResidenceAddressChange('country', ev.currentTarget.value)} />

              <TextField type="number" maxLength={10} required label="Irányítószám" value={model ? model.privateResidenceAddress.zip : ''} onChange={ev => handleResidenceAddressChange('zip', ev.currentTarget.value)} />

              <TextField type="text" label="Város" maxLength={100} required value={model ? model.privateResidenceAddress.city : ''} onChange={ev => handleResidenceAddressChange('city', ev.currentTarget.value)} />
            </div>

            <div className="form-content-row">
              <TextField
                type="text"
                label="Címsor 1"
                maxLength={500}
                required
                value={model ? model.privateResidenceAddress.addressLine1 : ''}
                onChange={ev => handleResidenceAddressChange('addressLine1', ev.currentTarget.value)}
              />

              <TextField
                type="text"
                label="Címsor 2"
                maxLength={500}
                value={model ? model.privateResidenceAddress.addressLine2 : ''}
                onChange={ev => handleResidenceAddressChange('addressLine2', ev.currentTarget.value)}
              />
            </div>
          </div>

          <div className="form-content-row">
            <TextField type="tel" maxLength={13} label="Mobilszám" value={model ? model.privateMobilePhone : ''} onChange={ev => handleModelChange('privateMobilePhone', ev.currentTarget.value)} />

            <TextField type="email" label="E-mail" maxLength={100} value={model ? model.privateEmail : ''} onChange={ev => handleModelChange('privateEmail', ev.currentTarget.value)} />

            <TextField type="text" maxLength={300} label="Facebook Profil URL" value={model ? model.facebook : ''} onChange={ev => handleModelChange('facebook', ev.currentTarget.value)} />
          </div>

          <div className="form-content-row">
            <TextField type="text" maxLength={200} label="Legközelebbi hozzátartozójának neve" value={model ? model.nextOfKinName : ''} onChange={ev => handleModelChange('nextOfKinName', ev.currentTarget.value)} />

            <TextField type="text" maxLength={13} label="Legközelebbi hozzátartozójának száma" value={model ? model.nextOfKinPhone : ''} onChange={ev => handleModelChange('nextOfKinPhone', ev.currentTarget.value)} />
          </div>

          <Button label="Mentés" unelevated disabled={!isSaveAble} />
        </form>
      </div>
    </>
  );
}

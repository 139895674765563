import React from 'react';

/* Navigation Things */
import { RouteComponentProps, Router } from '@reach/router';

/* Data Things */
import { OrdersProvider } from '../../../components/Contexts/Orders';

/* Routes */
import { OrderDetails } from './Details';
import { OrderList } from './List';

/* Styles */
import './styles.scss';

interface IProps extends RouteComponentProps {}

export function Orders(_: IProps) {
  return (
    <OrdersProvider>
      <Router basepath="Manufacturing/Orders">
        <OrderList default />
        <OrderDetails path="/:id" />
      </Router>
    </OrdersProvider>
  );
}

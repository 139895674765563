import { ConcurrentAddOrEditViewModel } from '../../../constants/types';
import { AddOrEditProjectDTO, ProjectDTO } from '../../../store/lib/TrackTime/projects';

export interface IProject {
  projectId: number;
  parentProjectId: number | null;
  rootProjectId: number | null;
  projectCode: string;
  displayCode: string;
  displayName: string;
  resourceGroupTypeId: string;
  isSyncronized: boolean;
  isActive: boolean;
  colorCode: string;
  isTimeRegisterable: boolean;
  subProjects: IProject[];
}

export interface IAddOrEditProject extends ConcurrentAddOrEditViewModel {
  projectId: number | null;
  parentProjectId: number | null;
  projectCode: string;
  displayCode: string;
  displayName: string;
  resourceGroupTypeId: string;
  isActive: boolean;
  isTimeRegisterable: boolean;
  colorCode: string;
}

export const sortProjects = (a: IProject, b: IProject) => {
  if (a.isActive) {
    if (b.isActive) {
      return a.projectCode < b.projectCode ? -1 : 1;
    } else {
      return -1;
    }
  } else if (b.isActive) {
    if (a.isActive) {
      return a.projectCode < b.projectCode ? -1 : 1;
    } else {
      return 1;
    }
  } else {
    return a.projectCode < b.projectCode ? -1 : 1;
  }
};

export const transformProjectDTO = (p: ProjectDTO): IProject => {
  return {
    colorCode: p.colorCode || '',
    displayCode: p.displayCode || '',
    displayName: p.displayName || '',
    isActive: p.isActive,
    isSyncronized: p.isSyncronized,
    isTimeRegisterable: p.isTimeRegisterable,
    parentProjectId: p.parentProjectId,
    projectCode: p.projectCode || '',
    projectId: p.projectId,
    resourceGroupTypeId: p.resourceGroupTypeId || '',
    rootProjectId: p.rootProjectId,
    subProjects: p.subProjects ? p.subProjects.map(x => transformProjectDTO(x)) : []
  };
};

export const transformIAddOrEditProject = (p: IAddOrEditProject): AddOrEditProjectDTO => {
  return {
    ...p,
    colorCode: p.colorCode || null,
    displayCode: p.displayCode || null,
    displayName: p.displayName || null,
    projectCode: p.projectCode || null,
    resourceGroupTypeId: p.resourceGroupTypeId || null
  };
};

export const searchProjects = (roles: IProject[], text: string) =>
  roles.filter(x => {
    const lowerSearchValue = text.toLowerCase();

    return x.displayName.toLowerCase().includes(lowerSearchValue) || x.displayCode.toLowerCase().includes(lowerSearchValue);
  });

import React, { PureComponent } from 'react';
import { RouteComponentProps } from '@reach/router';
import { UnsyncedActivities, transformActivitySyncRow, ActivitySyncRow, RowSyncStatus, transformActivitySyncRowtoUpdateStatusDTO, toUTCDate } from './helper';
import { getUnsyncedActivities, updateActivityTimeSyncStatus, syncActivityTime, appSyncAutoCheckStatus, syncAutoCheck } from '../../store/lib/TrackTime/activitiesSync';
import { DateTime } from 'luxon';
import MassStatusChange from '../Portals/MassStatusChange';
import CircularProgress from '../CircularProgress';
import { TableHeader } from './Table/Header';
import { TableBody } from './Table/Body';
import ResponseSnackbar, { ApiResponseType } from '../Portals/ResponseSnackbar';

const style = require('./ActivitySync.scss');

interface IState {
  unsyncedActivities: UnsyncedActivities | null;
  selectedActivities: ActivitySyncRow[];
  selectedSyncStatusCodeModel: number | null;
  filteredPersonId: number | null;
  filteredProjectId: number | null;
  filteredStatusId: number | null;
  filteredStartDate: DateTime | null;
  filteredEndDate: DateTime | null;
  loading: boolean;
  isAutoSyncEnabled: boolean;
  apiResponse: ApiResponseType;
}

interface IProps extends RouteComponentProps {}

export class ActivitySync extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const filter = JSON.parse(localStorage.getItem('syncFilter') || 'null');
    this.state = {
      unsyncedActivities: null,
      selectedSyncStatusCodeModel: filter ? filter.filteredStatusId : null,
      selectedActivities: [],
      filteredPersonId: filter ? filter.filteredPersonId : null,
      filteredProjectId: filter ? filter.filteredProjectId : null,
      filteredStatusId: filter ? filter.filteredStatusId : null,
      filteredStartDate: filter && filter.filteredStartDate ? DateTime.fromSQL(filter.filteredStartDate) : null,
      filteredEndDate: filter && filter.filteredEndDate ? DateTime.fromSQL(filter.filteredEndDate) : null,
      loading: true,
      isAutoSyncEnabled: false,
      apiResponse: null
    };
  }

  private _isMounted;

  private getActivities = (rootProjectId: number | null = null, personId: number | null = null, status: RowSyncStatus | null = null, start: string | null = null, end: string | null = null) => {
    getUnsyncedActivities(rootProjectId, personId, status, start, end)
      .then(res => {
        if (!res.error) {
          this._isMounted &&
            this.setState(
              {
                unsyncedActivities: {
                  totalActivities: res.totalActivities,
                  totalFilteredActivities: res.totalFilteredActivities,
                  activities: res.activities.map(x => transformActivitySyncRow(x))
                },
                loading: false
              },
              () => {
                //@ts-ignore
                if (window.updateActivitySyncBadge) window.updateActivitySyncBadge(res.totalActivities);
              }
            );
        } else this._isMounted && this.setState({ loading: false, apiResponse: { type: 'error', message: res.error } });
      })
      .catch(
        err =>
          this._isMounted &&
          this.setState({
            loading: false,
            apiResponse: { type: 'error', message: String(err.message || 'Valami hiba történt a szinkronizálatlan aktivitások lekérdezése során') }
          })
      );
  };

  componentDidMount() {
    const { filteredStatusId, filteredPersonId, filteredProjectId, filteredEndDate, filteredStartDate } = this.state;
    const start = toUTCDate(filteredStartDate);
    const end = toUTCDate(filteredEndDate);
    this._isMounted = true;

    appSyncAutoCheckStatus()
      .then(res => {
        if (!res.error) {
          this._isMounted &&
            this.setState({ isAutoSyncEnabled: res.enabled }, () => {
              this.getActivities(filteredProjectId, filteredPersonId, filteredStatusId, start, end);
            });
        } else this._isMounted && this.setState({ loading: false, apiResponse: { type: 'error', message: res.error } });
      })
      .catch(err => this._isMounted && this.setState({ loading: false, apiResponse: { type: 'error', message: String(err.message || err) } }));
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { filteredStatusId, filteredPersonId, filteredProjectId, filteredEndDate, filteredStartDate, unsyncedActivities } = this.state;
    /* Ha új filter van vagy volt adat, de mindent megcsinált és még lenne fetchelni való adat */
    if (
      prevState.filteredPersonId !== filteredPersonId ||
      prevState.filteredStatusId !== filteredStatusId ||
      prevState.filteredProjectId !== filteredProjectId ||
      prevState.filteredStartDate !== filteredStartDate ||
      prevState.filteredEndDate !== filteredEndDate ||
      (prevState.unsyncedActivities && prevState.unsyncedActivities.activities.length > 0 && unsyncedActivities && unsyncedActivities.activities.length === 0 && unsyncedActivities.totalFilteredActivities > 0)
    ) {
      const start = toUTCDate(filteredStartDate);
      const end = toUTCDate(filteredEndDate);
      this.setState({ loading: true, selectedSyncStatusCodeModel: filteredStatusId }, () => {
        this.getActivities(filteredProjectId, filteredPersonId, filteredStatusId, start, end);
      });
    }

    /* Ha változnak az aktivitások akkor a selectedActivities-ek ujraszámlálása */
    if (prevState.unsyncedActivities !== unsyncedActivities) {
      const selectedActivities = unsyncedActivities ? unsyncedActivities.activities.filter(x => x.isCheckAble && x.isChecked) : [];
      //prettier-ignore
      this.setState(state => {
        return {
          selectedActivities,
          selectedSyncStatusCodeModel: selectedActivities.length === 0 && filteredStatusId===null ? null : state.selectedSyncStatusCodeModel
        };
      });
    }
    /* Ha törlünk sorokat vagy populálunk akkor az isCheckAble flaget is állítjuk */
    if (prevState.unsyncedActivities && unsyncedActivities) {
      if (prevState.selectedSyncStatusCodeModel !== this.state.selectedSyncStatusCodeModel) {
        this.setState(state => {
          return {
            unsyncedActivities: state.unsyncedActivities
              ? {
                  totalActivities: state.unsyncedActivities.totalActivities,
                  totalFilteredActivities: state.unsyncedActivities.totalFilteredActivities,
                  activities: state.unsyncedActivities.activities.map(x => (state.selectedActivities.length === 0 ? { ...x, isCheckAble: true } : x))
                }
              : null
          };
        });
      }
    }
  }

  private handleFilter = (personId: number | null, projectId: number | null, start: DateTime | null, end: DateTime | null, statusId: number | null) => {
    const filter = {
      filteredPersonId: personId,
      filteredProjectId: projectId,
      filteredStartDate: start,
      filteredEndDate: end,
      filteredStatusId: statusId
    };
    this.setState({ ...filter }, () => {
      localStorage.setItem('syncFilter', JSON.stringify({ ...filter, filteredStartDate: toUTCDate(start), filteredEndDate: toUTCDate(end) }));
    });
  };

  private handleRowSelect = (rowId: number, checked: boolean) => {
    if (this.state.unsyncedActivities) {
      let changedRow = this.state.unsyncedActivities.activities.find(x => x.activitySyncId === rowId);

      /* Ha bepipál egy elemet */
      if (checked === true && changedRow) {
        /* Ha ő az első kiválasztott elem */
        if (this.state.selectedActivities.length === 0) {
          this.setState(state => {
            return {
              selectedSyncStatusCodeModel: (changedRow as ActivitySyncRow).syncStatusCode,
              unsyncedActivities: state.unsyncedActivities
                ? {
                    totalActivities: state.unsyncedActivities.totalActivities,
                    totalFilteredActivities: state.unsyncedActivities.totalFilteredActivities,
                    activities: state.unsyncedActivities.activities.map(x =>
                      x.activitySyncId === rowId ? { ...x, isChecked: true, isCheckAble: true } : { ...x, isCheckAble: x.syncStatusCode === (changedRow as ActivitySyncRow).syncStatusCode }
                    )
                  }
                : null
            };
          });
        } else if (changedRow.isCheckAble) {
          this.setState(state => {
            return {
              unsyncedActivities: state.unsyncedActivities
                ? {
                    totalActivities: state.unsyncedActivities.totalActivities,
                    totalFilteredActivities: state.unsyncedActivities.totalFilteredActivities,
                    activities: state.unsyncedActivities.activities.map(x => (x.activitySyncId === rowId ? { ...x, isChecked: true } : x))
                  }
                : null
            };
          });
        }
        /* Ha kipipál egy elemet */
      } else if (checked === false && changedRow) {
        /* Ha ez az utolsó kipiálandó elem */
        if (this.state.selectedActivities.length === 1) {
          this.setState(state => {
            return {
              selectedSyncStatusCodeModel: null,
              selectedActivities: [],
              unsyncedActivities: state.unsyncedActivities
                ? {
                    totalActivities: state.unsyncedActivities.totalActivities,
                    totalFilteredActivities: state.unsyncedActivities.totalFilteredActivities,
                    activities: state.unsyncedActivities.activities.map(x => (x.activitySyncId === rowId ? { ...x, isChecked: false, isCheckAble: true } : { ...x, isCheckAble: true }))
                  }
                : null
            };
          });
        } else if (changedRow.isCheckAble) {
          this.setState(state => {
            return {
              unsyncedActivities: state.unsyncedActivities
                ? {
                    totalActivities: state.unsyncedActivities.totalActivities,
                    totalFilteredActivities: state.unsyncedActivities.totalFilteredActivities,
                    activities: state.unsyncedActivities.activities.map(x => (x.activitySyncId === rowId ? { ...x, isChecked: false } : x))
                  }
                : null
            };
          });
        }
      }
    }
  };

  private handleSelectAll = (checked: boolean) => {
    if (this.state.unsyncedActivities) {
      this.setState(state => {
        return {
          ...state,
          unsyncedActivities:
            state.unsyncedActivities !== null
              ? {
                  totalActivities: state.unsyncedActivities.totalActivities,
                  totalFilteredActivities: state.unsyncedActivities.totalFilteredActivities,
                  activities: state.unsyncedActivities.activities.map(activitie => {
                    return activitie.isCheckAble
                      ? {
                          ...activitie,
                          isChecked: checked
                        }
                      : { ...activitie, isChecked: false, isCheckAble: !checked };
                  })
                }
              : null
        };
      });
    }
  };

  private handleStatusChange = (rows: ActivitySyncRow[], newStatusCode: RowSyncStatus) => {
    //prettier-ignore
    const onLoading = (apiResponse:ApiResponseType,loaderType:boolean,callback) => {
      this._isMounted && this.setState(state=>{return{unsyncedActivities:state.unsyncedActivities ? {
        totalActivities:state.unsyncedActivities.totalActivities,
        totalFilteredActivities: state.unsyncedActivities.totalFilteredActivities,
        activities:state.unsyncedActivities.activities.map(x=> rows.find(y=> x.activitySyncId===y.activitySyncId) ? {...x,isLoading:loaderType}:x)
        }:null,
        apiResponse
      }},callback())
    }

    //prettier-ignore
    onLoading(null,true,() => {
      updateActivityTimeSyncStatus(transformActivitySyncRowtoUpdateStatusDTO(rows,newStatusCode))
      .then(resp => {
        if(!resp.error){
          const comingNewRows = resp.map(x=>transformActivitySyncRow(x));
          const comingDataStatusNotEqualToTheFilteredStatus = this.state.filteredStatusId !== null && comingNewRows[0].syncStatusCode !== this.state.filteredStatusId
          const isRowRemoveAble =  comingNewRows[0].syncStatusCode === RowSyncStatus.Synced || comingNewRows[0].syncStatusCode === RowSyncStatus.SkipForever || comingDataStatusNotEqualToTheFilteredStatus
          
          this._isMounted && this.setState(state=>{return{unsyncedActivities:state.unsyncedActivities ? {
            totalFilteredActivities: state.unsyncedActivities.totalFilteredActivities,
            totalActivities:isRowRemoveAble ? state.unsyncedActivities.totalActivities-rows.length : state.unsyncedActivities.totalActivities,
            /* 
            Ha az érekező aktivitások szinkronizáltak vagy skipelt vagy a kiválasztott filter nem egyenlő velük akkor kivesszük a listából,
            ellenkező esetben updateljük a row-t 
            */
            activities:isRowRemoveAble ? 
              state.unsyncedActivities.activities.filter(x=> comingNewRows.find(y=> y.activitySyncId===x.activitySyncId) ? false : true): 
              state.unsyncedActivities.activities.map(x=> comingNewRows.find(y=> x.activitySyncId===y.activitySyncId)||x),
            }:null,
            apiResponse: comingNewRows.length < rows.length ? 
            { type: "warrning", message:`${rows.length} / ${comingNewRows.length} státusz módosult`} : 
            { type: "success", message:"Sikeres státusz módosítás"}
          }},()=>{
              //@ts-ignore
              if(window.updateActivitySyncBadge && this.state.unsyncedActivities && isRowRemoveAble) {
                 //@ts-ignore
                 window.updateActivitySyncBadge(this.state.unsyncedActivities.totalActivities)
              }          
          })
        }else onLoading({type:"error",message:resp.error},false,()=>{})
      })
      .catch(err => onLoading({type:"error",message:err.toString()},false,()=>{}));
    })
  };

  private handleSync = (rowsId: number[], isAutoSync: boolean) => {
    //prettier-ignore
    const onLoading = (apiResponse:ApiResponseType,loaderType:boolean,callback) => {
      this._isMounted && this.setState(state=>{return{unsyncedActivities:state.unsyncedActivities ? {
        totalFilteredActivities: state.unsyncedActivities.totalFilteredActivities,
        totalActivities:state.unsyncedActivities.totalActivities,
        activities:state.unsyncedActivities.activities.map(x=> rowsId.find(y=> x.activitySyncId===y) ? {...x,isLoading:loaderType}:x)
        }:null,
        apiResponse
      }},callback())
    }
    /* A row-t el kell kezdeni "tölteni" */
    //prettier-ignore
    onLoading(null,true, () => {
      const api = !isAutoSync ? syncActivityTime(rowsId) : syncAutoCheck(rowsId)
        api
        .then(res => {
          if (!res.error) {
            this._isMounted && this.setState(state => {return {
                unsyncedActivities: state.unsyncedActivities ? {
                  totalFilteredActivities: state.unsyncedActivities.totalFilteredActivities,
                  totalActivities: state.unsyncedActivities.totalActivities - rowsId.length,
                  activities: state.unsyncedActivities.activities.filter(x => rowsId.find(y => y === x.activitySyncId) ? false: true )}: null,
                  apiResponse: { type: "success", message:"Sikeres művelet"}
              };
            },()=>{
              //@ts-ignore
              if(window.updateActivitySyncBadge && this.state.unsyncedActivities) { window.updateActivitySyncBadge(this.state.unsyncedActivities.totalActivities)}
            });
          } else onLoading({type:"error",message:res.error},false,()=>{});
        })
        .catch(err =>onLoading({type:"error",message:err.toString()},false,()=>{}));
    })
  };

  private selectAbleActivities = () => (this.state.unsyncedActivities ? this.state.unsyncedActivities.activities.filter(x => x.isCheckAble).length : 0);

  private handleApiResponseClose = (apiResponse: ApiResponseType) => this.setState({ apiResponse });

  render() {
    return (
      <div className="ActivitySync">
        <div className={style.ActivitySync}>
          <div className="data-table">
            <TableHeader
              selectedSyncStatusCodeModel={this.state.selectedSyncStatusCodeModel}
              selectAbleActivitiesLength={this.selectAbleActivities()}
              selectedActivitiesLength={this.state.selectedActivities.length}
              onFilter={this.handleFilter}
              onSelectAll={this.handleSelectAll}
              personId={this.state.filteredPersonId}
              projectId={this.state.filteredProjectId}
              start={this.state.filteredStartDate}
              end={this.state.filteredEndDate}
              statusId={this.state.filteredStatusId}
              loading={this.state.loading}
            />
            {this.state.loading ? (
              <CircularProgress />
            ) : (
              <>
                {this.state.unsyncedActivities && this.state.unsyncedActivities.activities.length > 0 ? (
                  <TableBody
                    onStatusChange={this.handleStatusChange}
                    onSync={this.handleSync}
                    onCheck={this.handleRowSelect}
                    activities={this.state.unsyncedActivities.activities}
                    isAutoSyncEnabled={this.state.isAutoSyncEnabled}
                  />
                ) : this.state.unsyncedActivities && this.state.unsyncedActivities.totalFilteredActivities === 0 ? (
                  <div className="data-table-body">
                    <div className="data-table__row not-found-data">Nincs megjeleníthető adat</div>
                  </div>
                ) : (
                  <div className="data-table-body">
                    <div className="data-table__row not-found-data">Kérlek frissítsd az oldalt</div>
                  </div>
                )}
              </>
            )}
            {this.state.selectedActivities.length > 0 && this.state.selectedSyncStatusCodeModel !== null && !this.state.loading && (
              <MassStatusChange
                onSync={this.handleSync}
                onStatusChange={this.handleStatusChange}
                statusCode={this.state.selectedSyncStatusCodeModel}
                selectedActivities={this.state.selectedActivities}
                isAutoSyncEnabled={this.state.isAutoSyncEnabled}
              />
            )}
          </div>

          {this.state.unsyncedActivities && this.state.unsyncedActivities.activities.length > 0 && !this.state.loading && (
            <>
              <span className="counter">{`${this.state.unsyncedActivities.activities.length} megjelenítve a(z) ${this.state.unsyncedActivities.totalFilteredActivities} elemből.`}</span>
            </>
          )}
        </div>
        {<ResponseSnackbar response={this.state.apiResponse} onClose={this.handleApiResponseClose} />}
      </div>
    );
  }
}

import React, { useCallback, useContext, useEffect, useState } from 'react';

/* Data Things */
import { generateEditSecurityGroupDTO, INITIAL_GROUP, ISecurityGroupRow, transformEditSecurityGroupDTO } from '../helper';
import { createSecurityGroup, editSecurityGroup, getSecurityGroup } from '../../../../store/lib/Settings/securityGroups';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { IClaimRow, transformClaimRowDTO } from '../../ApplicationUsers/helper';
import { getApplicationUsers, getClaims } from '../../../../store/lib/Settings/applicationUsers';
import { SecurityGroupsContext } from '../../../../components/Contexts/SecurityGroups';
import { generateWindowTitle } from '../../../../constants/helperFuntions';
import { useIsMounted } from '../../../../hooks/useIsMounted';
import { AccessTypes } from '../../../../constants/enums';

/* Presentation Things */
import { MultiSelector, MultiSelectorOption } from '../../../../components/Selectors/MultiSelector';
import { TextField, TextFieldProps } from '@rmwc/textfield';
import { LinearProgress } from '@rmwc/linear-progress';
import { Typography } from '@rmwc/typography';
import { Checkbox } from '@rmwc/checkbox';
import { Button } from '@rmwc/button';
import { Radio } from '@rmwc/radio';

// Styles
import './Create.scss';

interface Props extends RouteComponentProps<{ id: number }> {}

export const SecurityGroupCreateAndEdit = (props: Props) => {
  /* Refs */
  const navigate = useNavigate();
  const _isMounted = useIsMounted();

  /* Context */
  const { onSetApiResponse, onSetGroup, groups } = useContext(SecurityGroupsContext);

  /* Variables */
  const id = props.id !== undefined ? Number(props.id) : null;
  const isEdit = id !== null;

  //States
  const [editableGroup, setEditableGroup] = useState(id && groups[id] ? groups[id] : INITIAL_GROUP);
  const [isLoading, setIsLoading] = useState(true);
  const [people, setPeople] = useState<MultiSelectorOption[]>([]);
  const [claims, setClaims] = useState<IClaimRow[]>([]);

  const onGoBack = () => navigate('/Settings/SecurityGroups', { replace: true });

  const onMemberSelect = (id: string, selected: boolean) => {
    setEditableGroup(prev => ({ ...prev, memberIds: selected ? [...prev.memberIds, +id] : prev.memberIds.filter(x => x !== +id) }));
  };

  const onToogleClaim = (claim: IClaimRow, accessTypeId?: AccessTypes | null) => {
    const isDelete = editableGroup.claimAssigns[claim.claimId] && !accessTypeId;
    accessTypeId = accessTypeId === undefined ? AccessTypes.Self : accessTypeId;

    if (isDelete) delete editableGroup.claimAssigns[claim.claimId];

    setEditableGroup({
      ...editableGroup,
      claimAssigns: isDelete ? editableGroup.claimAssigns : { ...editableGroup.claimAssigns, [claim.claimId]: { claimId: claim.claimId, claimName: claim.claimName, accessTypeId } }
    });
  };

  const getClaimsFromRemote = async (): Promise<string | IClaimRow[]> => {
    const claims = await getClaims();
    if (!claims.error) return Promise.resolve(claims.map(x => transformClaimRowDTO(x)));
    else throw new Error(claims.error);
  };

  const getApplicationUsersFromRemote = async (): Promise<string | MultiSelectorOption[]> => {
    const people = await getApplicationUsers();
    if (!people.error) return Promise.resolve(people.map(x => ({ id: x.userId, label: x.userName || '' })));
    else throw new Error(people.error);
  };

  const getInitialDataForEdit = useCallback(async () => {
    const getGroupFromRemote = async (): Promise<string | ISecurityGroupRow> => {
      if (id !== null) {
        const resp = await getSecurityGroup(id);
        if (!resp.error) return transformEditSecurityGroupDTO(resp);
        else throw new Error('Valami hiba történt, nem tudtuk lekérni a biztonsági csoportokat');
      }
      return Promise.reject('Kérlek válassz ki egy csoportot');
    };

    try {
      const [people, claims, group] = await Promise.all([getApplicationUsersFromRemote(), getClaimsFromRemote(), getGroupFromRemote()]);
      if (typeof people !== 'string' && typeof claims !== 'string' && typeof group !== 'string' && _isMounted.current) {
        setEditableGroup(group);
        setClaims(claims);
        setPeople(people);
      } else throw new Error('Valami hiba történt, próbáld meg később');
    } catch (error) {
      _isMounted.current && onSetApiResponse({ type: 'error', message: String(error.message || error) });
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  }, [_isMounted, id, onSetApiResponse]);

  const getInitialDataForCreate = useCallback(async () => {
    try {
      const [people, claims] = await Promise.all([getApplicationUsersFromRemote(), getClaimsFromRemote()]);
      if (typeof people !== 'string' && typeof claims !== 'string' && _isMounted.current) {
        setClaims(claims);
        setPeople(people);
      } else throw new Error('Valami hiba történt, próbáld meg később');
    } catch (error) {
      _isMounted.current && onSetApiResponse({ type: 'error', message: String(error.message || error) });
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  }, [_isMounted, onSetApiResponse]);

  const onCreateGroupRemote = async (): Promise<string | ISecurityGroupRow> => {
    try {
      const resp = await createSecurityGroup(generateEditSecurityGroupDTO(editableGroup));
      if (resp && !resp.error) {
        return Promise.resolve(transformEditSecurityGroupDTO(resp));
      } else throw new Error(resp.error || 'Sikertelen létrehozás');
    } catch (error) {
      return Promise.reject(String(error.message) || 'Sikertelen létrehozás');
    }
  };

  const onEditGroupRemote = async (): Promise<string | ISecurityGroupRow> => {
    if (id !== null) {
      try {
        const resp = await editSecurityGroup(generateEditSecurityGroupDTO(editableGroup));
        if (resp && !resp.error) {
          return Promise.resolve(transformEditSecurityGroupDTO(resp));
        } else throw new Error(resp.error || 'Sikertelen módosítás');
      } catch (error) {
        return Promise.reject(String(error.message) || 'Sikertelen módosítás');
      }
    }
    return Promise.reject('Válassz ki egy csoportot');
  };

  const onChangeName = (ev: React.ChangeEvent<TextFieldProps>) => {
    ev.persist();
    setEditableGroup(prev => ({ ...prev, name: String(ev.target.value) }));
  };

  const onSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();
    setIsLoading(true);
    try {
      const group = await (isEdit ? onEditGroupRemote() : onCreateGroupRemote());
      if (typeof group !== 'string' && _isMounted.current) {
        onSetGroup(group, true);
        onGoBack();
      }
    } catch (error) {
      _isMounted.current && onSetApiResponse({ type: 'error', message: String(error) });
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  useEffect(() => void (editableGroup ? (document.title = generateWindowTitle(editableGroup.name)) : null), [editableGroup]);

  useEffect(() => void (isEdit ? getInitialDataForEdit() : getInitialDataForCreate()), [isEdit, getInitialDataForEdit, getInitialDataForCreate]);

  return (
    <form className="routes-content-container add-user" onSubmit={onSubmit}>
      {isLoading && <LinearProgress className="linear-progress full-top" />}

      <div className="data-table-title">
        <Typography className="title" use="body2">
          {isEdit ? 'Biztonsági csoport szerkesztése' : 'Új biztonsági csoport'}
        </Typography>
      </div>

      <div className="basic-data-fields">
        <TextField label="Név" maxLength={200} value={editableGroup.name} onChange={onChangeName} disabled={isLoading} autoFocus required />
      </div>

      <Typography className="sub-title" use="subtitle2">
        Csoport tagság
      </Typography>

      <MultiSelector {...{ options: people, onSelect: onMemberSelect, selectedIds: editableGroup.memberIds, placeholder: 'Csoport tagjai' }} />

      <Typography className="sub-title" use="subtitle2">
        Egyéni jogosultságok
      </Typography>

      <div className="data-table">
        <div className="data-table__row data-table__row--title">
          <div className="data-table__cell">Jogosultság neve</div>

          <div className="data-table__cell">Jogosultság szinje</div>
        </div>

        <div className="data-table-body">
          {claims.map(x => (
            <Row key={x.claimId} row={x} isActive={Boolean(editableGroup.claimAssigns[x.claimId])} onToogleClaim={onToogleClaim} claimAssigns={editableGroup.claimAssigns} />
          ))}
        </div>
      </div>

      <div className="routes-content-container-footer">
        <Button type="submit" unelevated label={isEdit ? 'Módosítom' : 'Létrehozom'} disabled={isLoading} />
        <Button type="button" label="Mégse" onClick={onGoBack} disabled={isLoading} />
      </div>
    </form>
  );
};

type RowProps = {
  row: IClaimRow;
  isActive: boolean;
  claimAssigns: ISecurityGroupRow['claimAssigns'];
  onToogleClaim(claim: IClaimRow, accessTypeId?: AccessTypes | null): void;
};

function Row(props: RowProps) {
  const handleToogleCheckbox = () => props.onToogleClaim(props.row, props.row.hasAccessTypes ? undefined : null);

  const handleToogleRadio = (e: React.ChangeEvent<HTMLInputElement>) => props.onToogleClaim(props.row, Number(e.target.value));

  const selectedAccessType = props.claimAssigns[props.row.claimId] ? props.claimAssigns[props.row.claimId].accessTypeId : undefined;

  return (
    <div className="data-table__row">
      <div className="data-table__cell">
        <Checkbox label={props.row.displayName} checked={props.isActive} onChange={handleToogleCheckbox} />
      </div>

      {props.row.hasAccessTypes && props.isActive && (
        <div className="data-table_cell">
          <Radio label="Csak saját" value={AccessTypes.Self} onChange={handleToogleRadio} checked={AccessTypes.Self === selectedAccessType} />

          <Radio label="Szervezeti egység szinten" value={AccessTypes.OU} onChange={handleToogleRadio} checked={AccessTypes.OU === selectedAccessType} />

          <Radio label="Mindenkinek" value={AccessTypes.All} onChange={handleToogleRadio} checked={AccessTypes.All === selectedAccessType} />
        </div>
      )}
    </div>
  );
}

import React from 'react';
import ReactDOM from 'react-dom';
import { Dialog, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';

export default class ConfirmSkip extends React.PureComponent<any> {
  private modalRoot = document.getElementById('confirmPopup');
  private el;
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  public componentDidMount() {
    if (this.modalRoot) {
      this.modalRoot.appendChild(this.el);
    }
  }

  public componentWillUnmount() {
    if (this.modalRoot) {
      this.modalRoot.removeChild(this.el);
    }
  }

  public render() {
    return ReactDOM.createPortal(
      <Dialog onClose={this.props.onClose} open={this.props.isOpen}>
        <DialogContent>
          <p>Biztos kihagyod?</p>
        </DialogContent>
        <DialogActions>
          <DialogButton action="dismiss">Nem</DialogButton>
          <DialogButton action="discard" isDefaultAction onClick={this.props.onSkip}>
            Igen
          </DialogButton>
        </DialogActions>
      </Dialog>,
      this.el
    );
  }
}

import { useCallback, useEffect, useRef } from 'react';
import { api } from '../utils/api';

export function useDownload(path: string, fileNameWithExtension: string): () => Promise<void> {
  const _abort = useRef(new AbortController()).current;

  const onDownload = useCallback(async () => {
    if (path && fileNameWithExtension) {
      try {
        const resp = await api<string>('GET', path, undefined, _abort.signal, undefined);
        if (resp) {
          const anchor = document.createElement('a');
          document.body.appendChild(anchor);
          anchor.href = resp;
          anchor.download = fileNameWithExtension;
          anchor.click();
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [path, fileNameWithExtension, _abort]);

  useEffect(() => {
    return () => _abort.abort();
  }, [_abort]);

  return onDownload;
}

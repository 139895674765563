import { api } from '../../../../utils/api';
import { ConcurrentAddOrEditViewModel } from '../../../../constants/types';

export interface CompanyForOrgHierarchyDTO {
  companyId: number;
  name: string | null;
  logoUrl: string | null;
  childUnits: OUForOrgHierarchyDTO[] | null;
}

export interface OUForOrgHierarchyDTO extends ConcurrentAddOrEditViewModel {
  ouId: number;
  colorCode: string | null;
  ouName: string | null;
  ouTypeId: number;
  parentOUId: number | null;
  parentCompanyId: number;
  managerPersonId: number | null;
  managerName: string | null;
  managerEmail: string | null;
  managerPhone: string | null;
  managerPictureUrl: string | null;
  deputyManagerPersonId: number | null;
  deputyManagerName: string | null;
  deputyManagerEmail: string | null;
  deputyManagerPhone: string | null;
  deputyManagePictureUrl: string | null;
  childUnits: OUForOrgHierarchyDTO[];
}

export const getCompaniesWithOrganizations = () => {
  return api<CompanyForOrgHierarchyDTO[]>('GET', `organizationChart`, null);
};

export interface OUPersonDTO {
  personId: number;
  pictureUrl: string | null;
  courtesyTitle: string | null;
  lastName: string | null;
  firstName: string | null;
  middleName: string | null;
  phoneNumber: string | null;
  email: string | null;
  jobRoles: string[] | null;
}

export interface AddOrEditOUDTO extends ConcurrentAddOrEditViewModel {
  oUId: number | null;
  name: string;
  oUTypeId: number;
  parentOUId: number | null;
  parentCompanyId: number;
  managerPersonId: number | null;
  deputyManagerPersonId: number | null;
}

export interface OUEditedDTO extends Exclude<OUForOrgHierarchyDTO, 'childUnits'> {}

export const getOrganizationUnitDetails = (id: number) => {
  return api<OUPersonDTO[]>('GET', `organizationChart/${id}`, null);
};

export const getAllEmployees = () => {
  return api<{ name: string | null; personId: number }[]>('GET', `organizationMember`, null);
};

export const createOrganizationUnit = (model: AddOrEditOUDTO) => {
  return api<OUEditedDTO>('POST', `organizationUnits`, model);
};

export const editOrganizationUnit = (id: number, model: AddOrEditOUDTO) => {
  return api<OUEditedDTO>('PUT', `organizationUnits/${id}`, model);
};

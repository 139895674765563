/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from 'react';

/* Data Things */
import { peopleReducer, initialState, PeopleState } from '../../store/reducers/Organization/Peoples/peopleReducer';
import { getAllEmployees, getAllEmployeesActive } from '../../store/lib/Organization/Peoples/organization';
import { getCurrentUserClaims } from '../../constants/helperFuntions';
import { PEOPLE_EDIT_CLAIM } from '../../constants/constants';
import { useIsMounted } from '../../hooks/useIsMounted';
import { IEmployee } from '../../routes/Organization/People/PeopleList/helper';

/* Presentation Things  */
import ResponseSnackbar, { ApiResponseType } from '../Portals/ResponseSnackbar';

interface IPeopleContext extends PeopleState {
  onFilterByName(searchString: string): void;
  onSetPartialPeople(newProfile: Partial<IEmployee>): void;
  onAddPeople(newPeople: IEmployee): void;
  onSetApiResponse(apiResponse: ApiResponseType): void;
}

const PeopleContext = React.createContext<IPeopleContext>({
  ...initialState,
  onFilterByName: () => null,
  onSetPartialPeople: () => null,
  onAddPeople: () => null,
  onSetApiResponse: () => null
});

function PeopleProvider(props: React.PropsWithChildren<{}>) {
  const _isMounted = useIsMounted();
  const [state, dispatch] = useReducer(peopleReducer, initialState);

  useEffect(() => {
    getCurrentUserClaim();
    getEmployeesFromRemote();
  }, []);

  const getCurrentUserClaim = () => {
    getCurrentUserClaims().then(claims => {
      if (_isMounted.current) {
        dispatch({ type: 'set_isUserCanEditUsers', payload: { isUserCanEditUsers: typeof claims[PEOPLE_EDIT_CLAIM] === 'string' } });
      }
    });
  };

  const getEmployeesFromRemote = async () => {
    try {
      //[TODO Jácint] after typescript update, delete the ts-ignore.
      //@ts-ignore
      const [all, active] = await Promise.allSettled([getAllEmployees(), getAllEmployeesActive()]);

      if (all.status === 'rejected' && active.status === 'rejected') {
        throw new Error('Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
      }

      if (_isMounted.current) {
        dispatch({
          type: 'success_get_people',
          payload: { all: all.status === 'fulfilled' ? all.value : [], active: active.status === 'fulfilled' ? active.value : [] }
        });
      }
    } catch (error) {
      _isMounted.current && dispatch({ type: 'error_get_people', payload: { error: error.message } });
    }
  };

  const onSetPartialPeople = (newProfile: Partial<IEmployee>) => dispatch({ type: 'set_partial_profile', payload: { newProfile } });

  const onSetApiResponse = (apiResponse: ApiResponseType) => dispatch({ type: 'set_apiResponse', payload: { apiResponse } });

  const onFilterByName = (searchText: string) => dispatch({ type: 'filterByName', payload: { searchText } });

  const onAddPeople = (people: IEmployee) => dispatch({ type: 'on_add_people', payload: { people } });

  return (
    <div className="People">
      <ResponseSnackbar response={state.apiResponse} onClose={() => onSetApiResponse(null)} />
      <PeopleContext.Provider value={{ ...state, onSetApiResponse, onAddPeople, onFilterByName, onSetPartialPeople }}>{props.children}</PeopleContext.Provider>
    </div>
  );
}

const PeopleConsumer = PeopleContext.Consumer;
export { PeopleProvider, PeopleContext, PeopleConsumer };

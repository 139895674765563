import { MaritalStatuses, SexTypes } from '../../../../constants/enums';
import { EmploymentDaysOffDTO, EmploymentContractDTO, PersonOUDTO } from './employment';
import { AddressDTO, PersonDetailsDTO } from './peopleDetails';
import { api } from '../../../../utils/api';

export interface AddPersonDTO {
  syncId: string | null;
  pictureTempFileId: number | null;
  firstName: string;
  lastName: string;
  middleName: string | null;
  courtesyTitle: string | null;
  sexTypeId: SexTypes;
  birthName: string | null;
  birthDate: string;
  birthPlace: string;
  motherName: string;
  maritalStatus: MaritalStatuses | null;
  publicMobilePhone: string | null;
  publicLinePhone: string | null;
  publicLinePhoneExtension: string | null;
  publicEmail: string | null;
  linkedin: string | null;
  privateHomeAddress: AddressDTO;
  privateResidenceAddress: AddressDTO;
  privateMobilePhone: string | null;
  privateEmail: string | null;
  facebook: string | null;
  nextOfKinName: string | null;
  nextOfKinPhone: string | null;
  socialSecurityNumber: string | null;
  addressCardNumber: string | null;
  idCardNumber: string | null;
  idCardExpiryDate: string | null;
  passportNumber: string | null;
  passportExpiryDate: string | null;
  driverLicenseNumber: string | null;
  driverLicenseExpiryDate: string | null;
  taxNumber: string | null;
  bankAccountNumber: string | null;
  isPrivate: boolean;
  competences: PersonCompetenceDTO[];
  tempFileIds: number[];
}

export type PersonCompetenceDTO = {
  competenceId: number;
  value: number;
};

export interface AddEmployeeDTO extends AddPersonDTO {
  OUs: PersonOUDTO[];
  employment: EmployeeEmployementDTO;
}

export type EmployeeEmployementDTO = {
  startDate: string;
  endDate: string | null;
  companyId: number;
  childrenInHousehold: number | null;
  taxReducement: number | null;
  daysOff: EmploymentDaysOffDTO[];
  resourceGroupTypeId: string | null;
  employmentContracts: EmploymentContractDTO[];
};

export const createEmployee = (model: AddEmployeeDTO) => {
  return api<PersonDetailsDTO>('POST', `people/`, model);
};

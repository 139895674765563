import React, { useContext } from 'react';

/* Presentation Things */
import CircularProgress from '../../../../components/CircularProgress';
import { DailyStatusAddOrEdit } from '../AddOrEdit';
import { DayStatusRow } from '../helper';
import { Typography } from '@rmwc/typography';
import { Checkbox } from '@rmwc/checkbox';
import { Icon } from '@rmwc/icon';

/* Data Things */
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';

interface Props {
  isLoading: boolean;
  error: string;
  dailyStatuses: { [id: number]: DayStatusRow };
  onSetStatus(status: DayStatusRow): void;
}

export const DailyStatusesList = ({ isLoading, dailyStatuses, error, onSetStatus }: Props) => {
  /* Variables */
  const statuses = Object.values(dailyStatuses);

  /* Context */
  const { open } = useContext(SideSheetContext);

  const onOpenEditSheet = (editableStatus: DayStatusRow) => {
    open(<DailyStatusAddOrEdit {...{ onSetStatus, editableStatus }} />, 'MasterDataAddOrEditSheet');
  };

  return (
    <div className="master-data">
      <div className="data-table-title">
        <Typography className="title" use="body2">
          Napi státuszok
        </Typography>
      </div>

      <div className="data-table">
        <div className="data-table__row data-table__row--title">
          <div className="data-table__cell data-table__cell--graphic">
            <Typography use="caption">Kód</Typography>
          </div>

          <div className="data-table__cell name">
            <Typography use="caption">Név</Typography>
          </div>

          <div className="data-table__cell align-center">
            <Typography use="caption">Rendszer kezelt</Typography>
          </div>

          <div className="data-table__cell align-center">
            <Typography use="caption">Jogosultság függő</Typography>
          </div>

          <div className="data-table__cell align-center">
            <Typography use="caption">Munkanapnak számít</Typography>
          </div>

          <div className="data-table__cell align-center">
            <Typography use="caption">Munkaidőnek számít</Typography>
          </div>

          <div className="data-table__cell align-center">
            <Typography use="caption">Fizetett munkaidő</Typography>
          </div>

          <div className="data-table__cell align-center">
            <Typography use="caption">Munkanapon választható</Typography>
          </div>

          <div className="data-table__cell align-center">
            <Typography use="caption">Pihenőnapon választható</Typography>
          </div>

          <div className="data-table__cell align-center">
            <Typography use="caption">Munkaszüneti napon választható</Typography>
          </div>

          <div className="row-spacer-header__end" />
        </div>

        {isLoading ? (
          <CircularProgress />
        ) : error.length > 0 || !statuses.length ? (
          <div className="basic-error-text">{error || 'Nincsenek munkakörök'}</div>
        ) : (
          <div className="data-table-body">
            {statuses.map(status => (
              <Row key={status.id} status={status} onEdit={onOpenEditSheet} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

function Row({ status, onEdit }: { status: DayStatusRow; onEdit(status: DayStatusRow) }) {
  const handleEdit = () => onEdit(status);

  return (
    <div className="data-table__row">
      <div className="data-table__cell data-table__cell--graphic" style={{ color: status.colorCode }}>
        {status.code}
      </div>

      <div className="data-table__cell name">{status.name}</div>

      <div className="data-table__cell align-center">
        <Checkbox disabled checked={status.isCodeHandled} readOnly />
      </div>

      <div className="data-table__cell align-center">
        <Checkbox disabled checked={status.isLimited} readOnly />
      </div>

      <div className="data-table__cell align-center">
        <Checkbox disabled checked={status.isWorkDay} readOnly />
      </div>

      <div className="data-table__cell align-center">
        <Checkbox disabled checked={status.isIncreaseWorkTime} readOnly />
      </div>

      <div className="data-table__cell align-center">
        <Checkbox disabled checked={status.isPaid} readOnly />
      </div>

      <div className="data-table__cell align-center">
        <Checkbox disabled checked={status.canSetOnWorkday} readOnly />
      </div>

      <div className="data-table__cell align-center">
        <Checkbox disabled checked={status.canSetOnRestday} readOnly />
      </div>

      <div className="data-table__cell align-center">
        <Checkbox disabled checked={status.canSetOnNonWorkday} readOnly />
      </div>

      <div className="data-table__cell--end">
        <Icon icon="keyboard_arrow_right" onClick={handleEdit} />
      </div>
    </div>
  );
}

import React, { useContext, useState } from 'react';

/* Data Things */
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { DEFAULT_PRODUCT_ATTACHMENT } from './helper';
import { ITaskAttachmentDTO } from '../../../../typings/DTOs';
import { transformTaskAttachmentToSelectedFile } from '../helper';
import { uploadFile } from '../../../../store/lib/upload';
import { replaceTaskAttachment } from '../../../../store/lib/Manufacturing/products';

/* Presentation Things */
import FileSelector, { SelectedFile } from '../../../../components/Selectors/FileSelector';
import { Button } from '@rmwc/button';
import { LinearProgress } from '@rmwc/linear-progress';
import { useIsMounted } from '../../../../hooks';
import { formatBytes } from '../../../../constants/helperFuntions';
import { Typography } from '@rmwc/typography';
import { SimpleDialog } from '@rmwc/dialog';

interface IProps {
  productId: number;
  attachment: ITaskAttachmentDTO | null | undefined;
  onSubmitAdd(attachment: SelectedFile[], taskId: number): Promise<Boolean>;
  onSubmitDel(taskId: number, attachmentId: number): Promise<Boolean>;
  onSubmitMod(attachmentId: number): Promise<Boolean>;
}

const MAX_FILE_SIZE = 8000000; //bytes

export function AddOrEditAttachment(props: IProps) {
  /* Contexts */
  const { close } = useContext(SideSheetContext);
  const _isMounted = useIsMounted();

  /* States */
  const [attachment, setAttachment] = useState(props.attachment || DEFAULT_PRODUCT_ATTACHMENT);
  const [uploadDocuments, setUploadDocuments] = useState<SelectedFile[]>([]);
  const [delDialog, setDelDialog] = useState(false);
  const [idToMod, setIdToMod] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitAdd = async () => {
    try {
      if (uploadDocuments.length) {
        setIsLoading(true);
        let resp = await props.onSubmitAdd(uploadDocuments!, props.productId);
        if (resp) {
          close();
        } else throw new Error('Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
      } else throw new Error('Kérlek válassz ki dokumentumot!');
    } catch (error) {
      console.log(error);
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const handleSubmitMod = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', uploadDocuments![0].file);
      await replaceTaskAttachment(formData, props.productId, idToMod);
      let resp = await props.onSubmitMod(idToMod);
      if (resp) {
        setIdToMod(0);
        close();
      } else throw new Error('Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
    } catch (error) {
      console.log(error);
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    idToMod > 0 ? handleSubmitMod() : handleSubmitAdd();
  };

  const onDeleteUploadDocument = (id: string) => {
    const ut = uploadDocuments.filter(x => x.id !== id);
    if (ut.length === 0) setIdToMod(0);
    setUploadDocuments(ut);
  };

  const onSelectDocument = async (selectedFile: SelectedFile, attachmentId?: number): Promise<{ locationId: number | null; message: string }> => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile.file);
      const [{ id, error }] = await uploadFile(formData);

      if (!error && id !== null && _isMounted.current) {
        setUploadDocuments(prev => [...prev, { ...selectedFile, locationId: id }]);
        setAttachment({ ...attachment, fileName: selectedFile.id!, fileLength: selectedFile.file.size });
        setIdToMod(attachmentId ? attachmentId : -1);
        return Promise.resolve({ locationId: id, message: '' });
      } else throw new Error(error || 'Valami hiba történt, kérlek próbáld újra később.');
    } catch (error) {
      const { message } = error as any;
      return Promise.reject({ locationId: null, message: String(message || selectedFile.error || error) });
    }
  };

  const sureDeleteDocument = () => {
    setDelDialog(true);
  };

  const onDeleteDocument = async () => {
    try {
      setIsLoading(true);
      let resp = await props.onSubmitDel(props.productId, attachment.id!);
      if (resp === true) {
        close();
      } else throw new Error('Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
    } catch (error) {
      console.log(error);
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  return (
    <>
      <div className="drawer-header">
        {isLoading && <LinearProgress className="linear-progress" />}
        Dokumentumok szerkesztése
      </div>

      <div className="form-content">
        <form onSubmit={onSubmit}>
          <div className="form-content-row full">
            <Typography use="subtitle2" className="color-blue">
              Feltöltés
            </Typography>
          </div>
          <div key={attachment.id} className={'mb-12'}>
            <FileSelector
              isMultiple={attachment.id === -1}
              label="Feltöltendő dokumentum"
              compactModder={attachment.id ? attachment.id : 0}
              initialFiles={attachment.id !== -1 ? [transformTaskAttachmentToSelectedFile(attachment)] : undefined}
              maxFileSize={MAX_FILE_SIZE}
              accept=".pdf, .jpeg, .jpg, .png, .tif"
              onSelect={onSelectDocument}
              onDelete={attachment.id === -1 ? onDeleteUploadDocument : undefined}
            />
            <div className="file-selector-helper-text">Maximális fájlméret: {formatBytes(MAX_FILE_SIZE)}.</div>
          </div>

          <div className="form-content-row full ">
            <Button type="submit" unelevated label="Mentés" disabled={idToMod === 0} className="mr-auto" />
            {attachment.id !== -1 && <Button type="button" label="Törlés" icon="delete" onClick={sureDeleteDocument} outlined className="error-outlined" />}
          </div>
        </form>
      </div>
      <ShowDelDialog
        open={delDialog}
        close={() => {
          setIdToMod(0);
          setDelDialog(false);
        }}
        accept={onDeleteDocument}
      />
    </>
  );
}

const ShowDelDialog = ({ open, close, accept }: { open: boolean; close: () => void; accept: () => void }) => {
  return (
    <>
      <SimpleDialog
        title="Biztos törli a fájlt?"
        body="A folyamat visszafordíthatalan"
        acceptLabel="Igen"
        cancelLabel="Mégsem"
        open={open}
        onClose={evt => {
          close();
          if (evt.detail.action === 'accept') {
            accept();
          }
        }}
      />
    </>
  );
};

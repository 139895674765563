import React from 'react';
import { useDownload } from '../../hooks/useDownload';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  href: string;
  fileNameWithExtension: string;
}

export const Download = ({ href, fileNameWithExtension, children, ...props }: React.PropsWithChildren<IProps>) => {
  const onDownload = useDownload(href, fileNameWithExtension);

  return (
    <a style={{ cursor: 'pointer' }} onClick={onDownload} title={fileNameWithExtension} {...props}>
      {children}
    </a>
  );
};

import React, { Component } from 'react';

/* Presentation Things */
import { LinearProgress } from '@rmwc/linear-progress';
import { ContractsSection } from '../ProfileDetails/Employment/Edit/ContractsSection';
import { DetailsSection } from '../ProfileDetails/Employment/Edit/DetailsSection';
import { DaysOffSection } from '../ProfileDetails/Employment/Edit/DaysOffSection';
import { OUsSections } from '../ProfileDetails/Employment/Edit/OUsSections';

/* Data Things */
import { IAddEmployeeModel } from '../../../routes/Organization/People/PeopleAdd/helper';
import { PersonOU, EmploymentContract, generateDaysOff } from '../ProfileDetails/Employment/Edit/helper';

interface IState {}

interface IProps {
  isLoading: boolean;
  model: IAddEmployeeModel['employment'];
  onEditModel<T extends keyof IAddEmployeeModel['employment'], K extends IAddEmployeeModel['employment'][T]>(key: T, value: K): void;
  onUpdateContract(contract: EmploymentContract | undefined, index: number): void;
  onAddContract(contract: EmploymentContract): void;
}

export class AddEmploymentSection extends Component<IProps, IState> {
  componentDidUpdate(prevProps: IProps, _: IState) {
    if (prevProps.model && this.props.model) {
      if (prevProps.model.startDate.year !== this.props.model.startDate.year) {
        this.props.onEditModel('daysOff', generateDaysOff(this.props.model.daysOff, this.props.model.startDate.year));
      }
    }
  }

  private handleAddOU = (newOu: PersonOU) => this.props.onEditModel('OUs', [...this.props.model.OUs, newOu]);

  private handleUpdateOU = (ou: PersonOU | undefined, index: number | undefined) => {
    // if ou is undefined it means DELETE
    if (ou && index !== undefined) {
      this.props.onEditModel(
        'OUs',
        this.props.model.OUs.map((_, i) => (i === index ? ou : _))
      );
    } else if (!ou && index !== undefined) {
      this.props.onEditModel(
        'OUs',
        this.props.model.OUs.filter((_, i) => i !== index)
      );
    } else {
      this.props.onEditModel('OUs', []);
    }
  };

  //private handleIsEmployee = (isEmployee: boolean) => this.props.onEditModel('isEmployee', isEmployee);

  render() {
    const { handleAddOU, handleUpdateOU } = this;
    const { model, onEditModel, onUpdateContract, onAddContract, isLoading } = this.props;
    const { isEmployee, companyId, daysOff } = model;

    return (
      <>
        {isLoading && <LinearProgress className="linear-progress" />}

        <div className="form-content employment">
          {/* <Checkbox autoFocus checked={model.isEmployee} label="Munkavállaló" onChange={evt => this.handleIsEmployee(!!evt.currentTarget.checked)} /> */}
          {
            //@ts-ignore
            <DetailsSection {...{ onEditModel, isEmployee, editModel: model }} />
          }

          {isEmployee && (
            <>
              <DaysOffSection {...{ onEditModel, daysOff, employmentStartYear: model.startDate.year }} />

              <ContractsSection {...{ onAddContract, onUpdateContract, contracts: model.employmentContracts, employmentStartDate: model.startDate }} />
            </>
          )}

          <OUsSections onAddOU={handleAddOU} onUpdateOU={handleUpdateOU} editableOUs={model.OUs} {...{ companyId }} />
        </div>
      </>
    );
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

/* Presentation Things */
import { TextField, TextFieldProps } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import { Select } from '@rmwc/select';

/* Data Things */
import { EditEmployeeEmployment, EmploymentContract, GetWorkScheduleByHour } from './helper';
import { GetWorkSchedulesByWeeklyHour } from '../../../../../store/lib/Organization/Peoples/employment';
import { transformJobRoleDTO, JobRole } from '../../../../../routes/MasterData/JobRoles/helper';
import { useIsMounted } from '../../../../../hooks/useIsMounted';
import { getJobRoles } from '../../../../../store/lib/MasterData/jobRoles';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { DATEFORMAT } from '../../../../../constants/constants';
import { DateTime } from 'luxon';

interface IProps {
  employmentStartDate: DateTime;
  contracts: EditEmployeeEmployment['employmentContracts'];
  onUpdateContract(contract: EmploymentContract | undefined, index: number): void;
  onAddContract(contract: EmploymentContract): void;
}

interface IContractProps {
  employmentStartDate: DateTime;
  contract: EmploymentContract;
  onUpdateContract(contract: EmploymentContract | undefined, index: number): void;
  onAddWorkSchedule(ws: WorkScheduleByHour): void;
  customWorkSchedules: WorkScheduleByHour;
  displayAbleJobRoles: {
    label: string;
    value: string;
  }[];
  contractIndex: number;
  isMultipleContract: boolean;
}

const zeroAll = { hour: 0, minute: 0, second: 0, millisecond: 0 };

type WorkScheduleByHour = { [hour: number]: GetWorkScheduleByHour[] };

export function ContractsSection(props: IProps) {
  const _isMounted = useIsMounted();
  const { contracts, onAddContract, onUpdateContract, employmentStartDate } = props;
  const [customWorkSchedules, setCustomWorkSchedules] = useState<WorkScheduleByHour>({});
  const [jobRoles, setJobRoles] = useState<JobRole[]>([]);

  useEffect(() => void getJobRolesFromRemote(), []);

  useEffect(() => {
    if (jobRoles.length > 0 && !contracts[0].jobRoleIds.length) {
      onUpdateContract({ ...contracts[0], jobRoleIds: [jobRoles[0].jobRoleId] }, 0);
    }
  }, [jobRoles]);

  useEffect(() => {
    if (contracts.length === 1) {
      props.onUpdateContract({ ...contracts[0], startDate: employmentStartDate }, 0);
    }
  }, [employmentStartDate]);

  const displayAbleJobRoles = jobRoles.map(x => ({ label: x.name, value: String(x.jobRoleId) }));

  const getJobRolesFromRemote = async () => {
    try {
      const jobRoles = await getJobRoles();
      if (jobRoles && _isMounted.current) {
        setJobRoles(jobRoles.map(x => transformJobRoleDTO(x)).sort((x, y) => (x.name < y.name ? -1 : 1)));
      }
    } catch (error) {}
  };

  const hanleCreateNewContract = () => {
    if (contracts[0]) {
      onAddContract({
        key: DateTime.local().valueOf(),
        jobRoleIds: [contracts[0].jobRoleIds[0]],
        weeklyWorkHours: contracts[0].weeklyWorkHours,
        startDate: contracts[0].startDate,
        employmentContractId: null,
        grossSalary: null,
        trialEndDate: contracts[0].trialEndDate,
        workScheduleId: contracts[0].workScheduleId
      });
    }
  };

  const handleAddWorkSchedule = (ws: WorkScheduleByHour) => setCustomWorkSchedules(prev => ({ ...prev, ...ws }));

  return (
    <div className="form-content-container employment-contract">
      <div className="title margin-top-19">
        Munkaszerződések
        <span className="material-icons edit-icon" aria-label="Add" onClick={hanleCreateNewContract}>
          add_circle_outline
        </span>
      </div>

      {contracts.map((contract, contractIndex) => (
        <Contract
          key={contract.key}
          contract={contract}
          contractIndex={contractIndex}
          displayAbleJobRoles={displayAbleJobRoles}
          isMultipleContract={props.contracts.length > 1}
          onUpdateContract={onUpdateContract}
          customWorkSchedules={customWorkSchedules}
          employmentStartDate={employmentStartDate}
          onAddWorkSchedule={handleAddWorkSchedule}
        />
      ))}
    </div>
  );
}

function Contract(props: IContractProps) {
  const { contract, contractIndex, onUpdateContract, isMultipleContract, displayAbleJobRoles, customWorkSchedules, onAddWorkSchedule, employmentStartDate } = props;
  const debouncedWeeklyHours = useDebounce(contract.weeklyWorkHours, 300);

  const SELECTABLE_WS = customWorkSchedules[debouncedWeeklyHours] ? customWorkSchedules[debouncedWeeklyHours].map(x => ({ label: x.workScheduleName, value: String(x.workScheduleId) })) : [];

  const [isCustomWorkSchedule, setIsCustomWorkSchedule] = useState(contract.workScheduleId !== null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!customWorkSchedules[debouncedWeeklyHours]) {
      getWorkSchedulesFromRemote(debouncedWeeklyHours);
    }
  }, [debouncedWeeklyHours]);

  useEffect(() => {
    if (!isCustomWorkSchedule) {
      handleWorkSchedule(null);
    } else if (isCustomWorkSchedule && contract.workScheduleId === null && SELECTABLE_WS[0]) {
      handleWorkSchedule(parseInt(SELECTABLE_WS[0].value));
    }
  }, [isCustomWorkSchedule]);

  const getWorkSchedulesFromRemote = (hour: number) => {
    setIsLoading(true);
    GetWorkSchedulesByWeeklyHour(hour)
      .then(resp => {
        if (resp[0]) {
          onAddWorkSchedule({ [hour]: resp });
          if (isCustomWorkSchedule && hour === contract.weeklyWorkHours) {
            const selectedWorkSchedule = resp.find(x => x.workScheduleId === contract.workScheduleId);
            handleWorkSchedule(selectedWorkSchedule ? selectedWorkSchedule.workScheduleId : null);
          }
        }
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const handleStartDate = (ev: React.FormEvent<TextFieldProps>) => {
    const parseAble = DateTime.fromFormat(`${ev.currentTarget.value}`, DATEFORMAT).set(zeroAll);
    onUpdateContract({ ...contract, startDate: parseAble }, contractIndex);
  };

  const handleTrialDate = (ev: React.FormEvent<TextFieldProps>) => {
    const parseAble = DateTime.fromFormat(`${ev.currentTarget.value}`, DATEFORMAT).set(zeroAll);

    if (parseAble.isValid) {
      onUpdateContract({ ...contract, trialEndDate: parseAble }, contractIndex);
    } else {
      onUpdateContract({ ...contract, trialEndDate: null }, contractIndex);
    }
  };

  const handleWeeklyHours = (ev: React.FormEvent<TextFieldProps>) => {
    const weeklyWorkHours = Number(ev.currentTarget.value);
    onUpdateContract({ ...contract, weeklyWorkHours: weeklyWorkHours <= 168 ? weeklyWorkHours : 168 }, contractIndex);
  };

  const handleChangeJobRole = (jobRoleId: string | undefined, jobRoleindex: number) => {
    const id = jobRoleId !== undefined ? parseInt(jobRoleId) : undefined; //undefined means DELETE
    const jobRoleIds = contract.jobRoleIds;

    if (id !== undefined) {
      jobRoleIds[jobRoleindex] = id;
    } else {
      jobRoleIds.splice(jobRoleindex, 1);
    }
    onUpdateContract({ ...contract, jobRoleIds }, contractIndex);
  };

  const handleAddJobRole = () => {
    let jobRoleIds = contract.jobRoleIds;
    jobRoleIds = [...jobRoleIds, jobRoleIds[jobRoleIds.length - 1]];
    onUpdateContract({ ...contract, jobRoleIds }, contractIndex);
  };

  const handleWorkSchedule = (workScheduleId: number | null) => onUpdateContract({ ...contract, workScheduleId }, contractIndex);

  const handleDeleteContract = () => onUpdateContract(undefined, contractIndex);

  return (
    <div className="indent-wrap">
      <div className="indent-line" />

      <div className="sub-title margin-bottom-6">
        <span className="date-time">Munkaszerződés {` ${contract.startDate.isValid ? contract.startDate.setLocale('hu').toFormat('D') : ''}`}</span>
        {isMultipleContract && (
          <span className="material-icons edit-icon" aria-label="Delete" onClick={handleDeleteContract}>
            delete
          </span>
        )}
      </div>

      <div className="form-content-row">
        <div className="mdc-form-field--with-helptext">
          <TextField required label="Hatályosság kezdete" type="date" value={contract.startDate.toFormat(DATEFORMAT)} onChange={handleStartDate} min={employmentStartDate.toFormat(DATEFORMAT)} />
        </div>

        <div className="mdc-form-field--with-helptext">
          <TextField label="Próbaidő vége" min={contract.startDate.toFormat(DATEFORMAT)} type="date" value={contract.trialEndDate ? contract.trialEndDate.toFormat(DATEFORMAT) : ' '} onChange={handleTrialDate} />
        </div>
      </div>

      <div className="form-content-row align-end">
        <div className="mdc-form-field--with-helptext">
          <Checkbox label="Alternatív munkarend alkalmazása" checked={isCustomWorkSchedule} onChange={ev => setIsCustomWorkSchedule(!!ev.currentTarget.checked)} />

          <TextField required label="Heti óraszám" type="number" min={0} max={168} value={contract.weeklyWorkHours} onChange={handleWeeklyHours} />
        </div>

        <div className="mdc-form-field--with-helptext work-schedule">
          <Select
            label="Munkarend"
            value={contract.workScheduleId === null ? undefined : String(contract.workScheduleId)}
            options={SELECTABLE_WS}
            required
            onChange={ev => handleWorkSchedule(parseInt(ev.currentTarget.value))}
            disabled={!isCustomWorkSchedule}
            invalid={!isLoading && SELECTABLE_WS.length < 1 && isCustomWorkSchedule}
            helpText={{
              persistent: false,
              validationMsg: true,
              children: <span>Ehhez a heti óraszámhoz nincs elérhető alternatív munkarend</span>
            }}
          />
        </div>
      </div>

      <div className="sub-title margin-bottom-6">
        Munkakörök
        <span className="material-icons edit-icon" aria-label="Add" onClick={handleAddJobRole}>
          add_circle
        </span>
      </div>

      <div className="form-content-row position inputs-without-helptext-layout">
        {contract.jobRoleIds.map((jobRoleId, index, jobRoleIds) => (
          <div className="positions" key={index}>
            <Select label="Munkakör neve" value={String(jobRoleId)} onChange={evt => handleChangeJobRole(evt.currentTarget.value, index)} options={displayAbleJobRoles} disabled={displayAbleJobRoles.length < 1} />
            {jobRoleIds.length > 1 && (
              <span className="material-icons edit-icon" aria-label="Delete" onClick={() => handleChangeJobRole(undefined, index)}>
                delete
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

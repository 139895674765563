import React, { useCallback, useEffect, useState } from 'react';

/* Presentation Things */
import CircularProgress from '../../../../components/CircularProgress';
import Collapsible from 'react-collapsible';
import { Typography } from '@rmwc/typography';
import { ShowImage } from '../../../../components/Images/ShowImage';
import { Icon } from '@rmwc/icon';
import { Link } from '@reach/router';

/* Data Things */
import { CompanyForOrganization, OrganizationUnit, OUPerson, transformOUPersonDTO } from '../helper';
import { generateIndentsForCollapseable } from '../../../../constants/helperFuntions';
import { getOrganizationUnitDetails } from '../../../../store/lib/Organization/Structure';
import { useIsMounted } from '../../../../hooks/useIsMounted';

type IProps = {
  company?: CompanyForOrganization;
  error: string;
  isLoading: boolean;
  onOpenAddorEditSheet(editable: OrganizationUnit): void;
};

type OrganizationProps = {
  organization: OrganizationUnit;
  nextElementLevel: number;
  currentLevelIndex: number;
  levelLenght: number;
  onOpenAddorEditSheet(editable: OrganizationUnit): void;
};

export const Company = ({ company, isLoading, error, onOpenAddorEditSheet }: IProps) => {
  if (company) company.childUnits.sort((x, y) => x.name.localeCompare(y.name));

  return (
    <>
      <div className="data-table-title">
        <div className="data-table-title__inner-wrap">
          <Typography className="title" use="body2">
            {company ? company.name : ''}
          </Typography>
        </div>
      </div>

      <div className="data-table__row data-table__row--title data-table__row--two-line">
        <div className="data-table__cell organization-unit-name">Szervezeti egység név</div>

        <div className="data-table__cell">Vezető</div>

        <div className="data-table__cell">
          <span className="data-table__cell-text">Telefon</span>
          <span className="data-table__cell-text">E-mail</span>
        </div>

        <div className="data-table__cell">Vezető helyettes</div>

        <div className="data-table__cell">
          <span className="data-table__cell-text">Telefon</span>
          <span className="data-table__cell-text">E-mail</span>
        </div>

        <div className="row-spacer-header__end row-spacer-header__end--three-item" />
      </div>

      {isLoading ? (
        <CircularProgress />
      ) : error.length > 0 ? (
        <div className="basic-error-text">{error}</div>
      ) : company ? (
        company.childUnits.map((organization, i) => (
          <Organization
            {...{
              key: organization.id,
              organization,
              currentLevelIndex: i,
              levelLenght: organization.childUnits.length,
              onOpenAddorEditSheet,
              nextElementLevel: 1
            }}
          />
        ))
      ) : null}
    </>
  );
};

const Organization = (props: OrganizationProps) => {
  const { organization, currentLevelIndex, levelLenght, nextElementLevel, onOpenAddorEditSheet } = props;
  const { manager, deputyManager } = organization;

  /* States */
  const [visibleContent, setVisibleContent] = useState<'details' | 'organizations' | null>(null);
  const [isOpening, setIsOpening] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const toogleIsOpened = (content: 'details' | 'organizations') => {
    setIsOpened(visibleContent !== content);
    setVisibleContent(content);
  };

  const onOpening = () => setIsOpening(true);
  const onClosing = () => setIsOpened(false);
  const onClose = () => setVisibleContent(null);
  const onOpen = () => setIsOpening(false);

  return (
    <>
      <div className="data-table__row data-table__row--two-line">
        <div className="data-table__cell organization-unit-name">
          {generateIndentsForCollapseable(organization.level, currentLevelIndex, levelLenght, isOpened, nextElementLevel)}

          <span className="organization-type" style={{ backgroundColor: organization.colorCode }}>
            {organization.name}
          </span>
        </div>

        <div className="data-table__cell">
          {manager && (
            <ShowImage fullName={manager.name} pictureURL={manager.pictureUrl}>
              {manager.name}
            </ShowImage>
          )}
        </div>

        <div className="data-table__cell">
          {manager && manager.phone && (
            <span className="data-table__cell-text">
              <a href={`tel:${manager.phone}`}>{manager.phone}</a>
            </span>
          )}

          {manager && manager.email && (
            <span className="data-table__cell-text">
              <a href={`mailto:${manager.email}`}>{manager.email}</a>
            </span>
          )}
        </div>

        <div className="data-table__cell">
          {deputyManager && (
            <ShowImage fullName={deputyManager.name} pictureURL={deputyManager.pictureUrl}>
              {deputyManager.name}
            </ShowImage>
          )}
        </div>

        <div className="data-table__cell">
          {deputyManager && deputyManager.phone && (
            <span className="data-table__cell-text">
              <a href={`tel:${deputyManager.phone}`}>{deputyManager.phone}</a>
            </span>
          )}

          {deputyManager && deputyManager.email && (
            <span className="data-table__cell-text">
              <a href={`mailto:${deputyManager.email}`}>{deputyManager.email}</a>
            </span>
          )}
        </div>

        <div className="data-table__cell--end">{visibleContent === 'details' ? <Icon icon="close" onClick={() => toogleIsOpened('details')} /> : <Icon icon="visibility" onClick={() => toogleIsOpened('details')} />}</div>

        <div className="data-table__cell--end">
          <Icon icon="edit" onClick={() => onOpenAddorEditSheet(organization)} />
        </div>

        {organization.childUnits.length > 0 ? (
          <div className="data-table__cell--end">
            <Icon icon={`keyboard_arrow_${visibleContent === 'organizations' ? 'up' : 'down'}`} onClick={() => toogleIsOpened('organizations')} />
          </div>
        ) : (
          <div className="data-table__cell--end" />
        )}
      </div>

      <Collapsible {...{ open: isOpened, onOpen, onOpening, onClosing, onClose, triggerDisabled: isOpening || visibleContent === null, trigger: '' }}>
        {visibleContent === 'organizations'
          ? organization.childUnits.map((org, i) => (
              <Organization
                {...{
                  key: org.id,
                  organization: org,
                  currentLevelIndex: i,
                  levelLenght: organization.childUnits.length,
                  onOpenAddorEditSheet,
                  nextElementLevel: i === organization.childUnits.length - 1 ? nextElementLevel : org.level
                }}
              />
            ))
          : visibleContent === 'details' && (
              <div className="peoples-table">
                <Members organizationId={organization.id} />
              </div>
            )}
      </Collapsible>
    </>
  );
};

const Members = ({ organizationId }: { organizationId: number }) => {
  /* States */
  const [isLoading, setIsLoading] = useState(true);
  const [members, setMembers] = useState<OUPerson[]>([]);
  const [error, setError] = useState('');

  /* Ref */
  const _isMounted = useIsMounted();

  const getMembersFromRemote = useCallback(async () => {
    try {
      const members = await getOrganizationUnitDetails(organizationId);

      if (!members.error) {
        _isMounted.current && setMembers(members.map(x => transformOUPersonDTO(x)).sort((x, y) => x.fullName.localeCompare(y.fullName)));
      } else throw new Error(members.error);
    } catch (error) {
      _isMounted.current && setError(String(error.message || error));
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  }, [_isMounted, organizationId]);

  useEffect(() => void getMembersFromRemote(), [getMembersFromRemote]);

  return isLoading ? (
    <CircularProgress />
  ) : (
    <div className="organization-unit-people-table">
      <div className="data-table-title">
        <Typography className="title" use="body2">
          Szervezeti egység tagjai
        </Typography>
      </div>
      <div className="data-table people-list">
        <div className="data-table__row data-table__row--title">
          <div className="data-table__cell">Teljes Név</div>

          <div className="data-table__cell">Telefon</div>

          <div className="data-table__cell">Email</div>

          <div className="data-table__cell">Munkakör</div>

          <div className="row-spacer-header__end" />
        </div>

        {error.length > 0 || !members.length ? (
          <div className="basic-error-text">{error || 'Nincsenek még emberek hozzáadva'}</div>
        ) : (
          members.map(member => (
            <div className="data-table__row" key={member.personId}>
              <div className="data-table__cell">
                <ShowImage initials={member.firstName.charAt(0) + member.lastName.charAt(0)} pictureURL={member.pictureUrl}>
                  {member.fullName}
                </ShowImage>
              </div>

              <div className="data-table__cell">{member.phoneNumber ? <a href={`tel:${member.phoneNumber}`}>{member.phoneNumber}</a> : ''}</div>

              <div className="data-table__cell">{member.email ? <a href={`mailto:${member.email}`}>{member.email}</a> : ''}</div>

              <div className="data-table__cell">{member.jobRoles.length ? member.jobRoles.join(', ') : ''}</div>

              <div className="data-table__cell--end">
                <Link to={`/Organization/People/Details/${member.personId}`}>
                  <Icon icon="keyboard_arrow_right" />
                </Link>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

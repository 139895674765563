import { OrderStatusTypes, PaintStatusTypes, ProductionSiteTypes } from '../../../constants/enums';
import { ICreateOrderDTO, IEditOrderDTO, IOrderDTO, IOrderTaskJobsDTO } from '../../../typings/DTOs';
import { DateTime } from 'luxon';
import { Project } from '../../../typings/common';
export class Order {
  public id: number;
  public project: Project | null;
  public customer: {
    id: number;
    companyName: string;
  };
  public productItem: {
    id: number;
    title: string;
  };
  public productionSite: ProductionSiteTypes;
  public orderNo: string;
  public quantity: number;
  public paintStatus: PaintStatusTypes | null;
  public orderDate: DateTime | null;
  public supplyDate: DateTime | null;
  public requestedShippingDate: DateTime | null;
  public confirmationDate: DateTime | null;
  public shippingDate: DateTime | null;
  public orderStatus: OrderStatusTypes;
  public notes: string;
  public taskJobs:
    | [
        {
          endTime: string | null;
          id: number;
          persons: [
            {
              courtesyTitle: string | null;
              firstName: string | null;
              id: number;
              lastName: string | null;
              middleName: string | null;
              photoUrl: string | null;
            }
          ];
          plannedStartTime: string | null;
          productItemId: number;
          quantityApproved: number | null;
          quantityDone: number | null;
          quantityExpected: number;
          serialNumbers: [string];
          startTime: string | null;
          taskName: string | null;
          standardTimeSeconds: number;
          totalActivityTimeSeconds: number | null;
        }
      ]
    | null;

  constructor(p: IOrderDTO) {
    this.id = p.id;
    this.customer = {
      id: p.customer.id,
      companyName: p.customer.companyName || ''
    };
    this.productItem = {
      id: p.productItem.id,
      title: p.productItem.title || ''
    };
    this.project = new Project(
      p.project.projectId,
      p.project.projectDisplayCode,
      p.project.projectDisplayName,
      p.project.projectColorCode,
      p.project.rootProjectId ? new Project(p.project.rootProjectId, p.project.rootProjectDisplayCode, p.project.rootProjectDisplayName, p.project.rootProjectColorCode, null) : null
    );
    this.productionSite = p.productionSite;
    this.orderNo = p.orderNo || '';
    this.quantity = p.quantity;
    this.paintStatus = p.paintStatus;
    this.orderDate = p.orderDate ? DateTime.fromISO(p.orderDate) : null;
    this.supplyDate = p.supplyDate ? DateTime.fromISO(p.supplyDate) : null;
    this.requestedShippingDate = p.requestedShippingDate ? DateTime.fromISO(p.requestedShippingDate) : null;
    this.confirmationDate = p.confirmationDate ? DateTime.fromISO(p.confirmationDate) : null;
    this.shippingDate = p.shippingDate ? DateTime.fromISO(p.shippingDate) : null;
    this.orderStatus = p.orderStatus;
    this.notes = p.notes || '';
    this.taskJobs = p.taskJobs;
  }
}

export class TaskJob {
  public endTime: DateTime | null;
  public id: number;
  public persons: [
    {
      courtesyTitle: string | null;
      firstName: string | null;
      id: number;
      lastName: string | null;
      middleName: string | null;
      photoUrl: string | null;
    }
  ];
  public plannedStartTime: DateTime | null;
  public productItemId: number;
  public quantityApproved: number | null;
  public quantityDone: number | null;
  public quantityExpected: number;
  public serialNumbers: [string];
  public startTime: DateTime | null;
  public taskName: string | null;
  public standardTimeSeconds: number;
  public totalActivityTimeSeconds: number | null;

  constructor(t: IOrderTaskJobsDTO) {
    this.endTime = t.endTime ? DateTime.fromISO(t.endTime) : null;
    this.id = t.id;
    this.persons = t.persons;
    this.plannedStartTime = t.plannedStartTime ? DateTime.fromISO(t.plannedStartTime) : null;
    this.productItemId = t.productItemId;
    this.quantityApproved = t.quantityApproved;
    this.quantityDone = t.quantityDone;
    this.quantityExpected = t.quantityExpected;
    this.serialNumbers = t.serialNumbers;
    this.startTime = t.startTime ? DateTime.fromISO(t.startTime) : null;
    this.taskName = t.taskName;
    this.standardTimeSeconds = t.standardTimeSeconds;
    this.totalActivityTimeSeconds = t.totalActivityTimeSeconds;
  }
}

export const transformOrderToICreateOrderDTO = (p: Order): ICreateOrderDTO => {
  return {
    confirmationDate: p.confirmationDate ? p.confirmationDate.toISODate() : null,
    orderDate: p.orderDate ? p.orderDate.toISODate() : null,
    supplyDate: p.supplyDate ? p.supplyDate.toISODate() : null,
    shippingDate: p.shippingDate ? p.shippingDate.toISODate() : null,
    requestedShippingDate: p.requestedShippingDate ? p.requestedShippingDate.toISODate() : null,
    customerId: p.customer.id,
    notes: p.notes || null,
    orderNo: p.orderNo,
    orderStatus: p.orderStatus,
    paintStatus: p.paintStatus,
    productItemId: p.productItem.id,
    productionSite: p.productionSite,
    projectId: p.project!.id,
    quantity: p.quantity
  };
};

export const transformOrderToIEditOrderDTO = (p: Order, id: number): IEditOrderDTO => {
  return {
    id,
    ...transformOrderToICreateOrderDTO(p)
  };
};

import React, { useContext, useMemo } from 'react';

/* Presentation Things */
import CircularProgress from '../../../../components/CircularProgress';
import { OUTypeAddOrEdit } from '../AddOrEdit';
import { Button } from '@rmwc/button';

/* Data Things */
import { OUType, sortOUTypes } from '../helper';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';

interface Props {
  isLoading: boolean;
  error: string;
  ouTypes: OUType[];
  onSetOUType(type: OUType): void;
  onAddOUType(tyőe: OUType): void;
}

export const OUTypesList = ({ isLoading, ouTypes, error, onSetOUType, onAddOUType }: Props) => {
  /* Variable */
  const types = useMemo(() => sortOUTypes(ouTypes), [ouTypes]);

  /* Context */
  const { open } = useContext(SideSheetContext);

  const onOpenEditSheet = (editableOUType: OUType) => open(<OUTypeAddOrEdit {...{ onSetOUType, editableOUType, onAddOUType }} />);

  return (
    <div className="organization-unit-type-container">
      {isLoading ? (
        <CircularProgress className="full-height" />
      ) : error.length > 0 || !types.length ? (
        <div className="basic-error-text">{error || 'Nincsenek munkakörök'}</div>
      ) : (
        types.map(type => <Row key={type.id} type={type} onEdit={onOpenEditSheet} />)
      )}
    </div>
  );
};

function Row({ type, onEdit }: { type: OUType; onEdit(type: OUType) }) {
  const handleEdit = () => onEdit(type);

  return (
    <div className="type-card" style={{ backgroundColor: type.colorCode }}>
      <div className="type-card__title">{type.name}</div>
      <Button dense className="type-card__button" label="Szerkesztem" onClick={handleEdit} />
    </div>
  );
}

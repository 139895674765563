import { ICreateProductDTO, IEditProductDTO, IProductDTO, IProductListDTO, ISelectableRootProductDTO, IProductAttachmentWriteDTO, IProductAttachmentsWriteDTO, IClientDevicesDTO } from '../../../typings/DTOs';
import { api } from '../../../utils/api';

export const getClientDevices = () => api<IClientDevicesDTO[]>('GET', `client-devices`);

export const getProducts = (search: string, offset: number, limit: number, signal: AbortSignal) => api<IProductListDTO>('GET', `products?search=${search}&offset=${offset}&limit=${limit}`, undefined, signal);

export const getProduct = (id: number) => api<IProductDTO>('GET', `products/${id}`);

export const createProduct = (model: ICreateProductDTO) => api<IProductDTO>('POST', 'products', model);

export const editProduct = (model: IEditProductDTO) => api<IProductDTO>('PUT', `products/${model.id}`, model);

export const cloneProduct = (id: number) => api<IProductDTO>('POST', `products/${id}/copy`);

export const getRootProducts = () => api<ISelectableRootProductDTO[]>('GET', 'products/parents');

export const createTaskAttachment = (model: IProductAttachmentsWriteDTO) => api('POST', `products/${model.productId}/attachments/batch`, model);

export const deleteTaskAttachment = (model: IProductAttachmentWriteDTO) => api('PUT', `products/${model.productId}/attachments/${model.attachmentId}/status/inactive`, model);

export const replaceTaskAttachment = (body: FormData, productId: number, replacedAttachmentId: number) => api('PUT', `products/${productId}/attachments/${replacedAttachmentId}`, {}, null, body);

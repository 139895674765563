import { ConcurrentEditViewModel } from '../../../store/lib/Organization/Peoples/employment';
import { getAllOUTypes, OUTypeDTO } from '../../../store/lib/Settings/ouTypes';

export interface OUType extends ConcurrentEditViewModel {
  id: number;
  name: string;
  colorCode: string;
}

export const transformOUTypeDTO = (p: OUTypeDTO): OUType => {
  return {
    id: p.ouTypeId,
    colorCode: p.colorCode || 'black',
    name: p.name || '',
    updated: p.updated
  };
};

export const getOUTypesFromRemote = async () => {
  try {
    const ouTypes = await getAllOUTypes();
    if (!ouTypes.error) return Promise.resolve(ouTypes);
    else throw new Error(ouTypes.error);
  } catch (error) {
    return Promise.reject(String(error.message || 'Sajnos nem sikerült lekérdezni a szervezeti egység típusokat'));
  }
};

export const sortOUTypes = (types: OUType[]) => types.sort((x, y) => x.name.localeCompare(y.name));

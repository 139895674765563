import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';

/* Data Things */
import { IProject, transformProjectDTO, IAddOrEditProject, transformIAddOrEditProject } from '../helper';
import { generateIndentsForCollapseable } from '../../../../constants/helperFuntions';
import { Link, RouteComponentProps } from '@reach/router';
import { addProject, getProject } from '../../../../store/lib/TrackTime/projects';
import { HEX_COLOR_PATTERN } from '../../../../constants/regex';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { ProjectsContext } from '../../../../components/Contexts/Projects';
import { useIsMounted } from '../../../../hooks/useIsMounted';

/* Presentation Things */
import CircularProgress from '../../../../components/CircularProgress';
import { TextField, TextFieldProps } from '@rmwc/textfield';
import { Switch, SwitchProps } from '@rmwc/switch';
import { PaletteSelector } from '../../../../components/Selectors/PaletteSelector';
import { LinearProgress } from '@rmwc/linear-progress';
import { Typography } from '@rmwc/typography';
import { Checkbox } from '@rmwc/checkbox';
import { Button } from '@rmwc/button';
import { Icon } from '@rmwc/icon';

// Styles
import './ProjectDetails.scss';

interface Props extends RouteComponentProps<{ id: string }> {}

interface AddOrEditFormProps {
  project: IProject | null;
  isEditProject: boolean;
  onAddProject(project: IProject): void;
  close(): void;
}

interface RowProps {
  row: IProject | null;
  level: number;
  levelLength: number;
  nextElementLevel: number;
  index: number;
  isEditing: boolean;
  onOpenAddOrEditSheet(project: IProject | null, isEditProject: boolean): void;
}

export const ProjectDetails = (props: Props) => {
  /* Ref */
  const newProjectId = useRef<number | null>(null);
  const _isMounted = useIsMounted();

  /* Contexts */
  const { onSetProject, projects } = useContext(ProjectsContext);
  const { open, close } = useContext(SideSheetContext);

  /* Variable */
  const isEditing = props.id !== undefined && newProjectId.current === null;
  const project: IProject | null = projects[props.id! || newProjectId.current!] || null;

  /* States */
  const [isLoading, setIsLoading] = useState(isEditing);
  const [error, setError] = useState('');

  const onGetProjectFromRemote = useCallback(
    async (id: number) => {
      try {
        const project = await getProject(id);
        if (project) {
          _isMounted.current && onSetProject(transformProjectDTO(project));
        }
      } catch (error) {
        _isMounted.current && setError(error.message || 'Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
      } finally {
        _isMounted.current && setIsLoading(false);
      }
    },
    [_isMounted, onSetProject]
  );

  const renderRows = (row: IProject, level: number = 0, index: number = 0, nextElementLevel: number = 0, levelLength?: number) => {
    return (
      <>
        <Row
          {...{
            key: row.projectId,
            row,
            level,
            isEditing,
            index,
            nextElementLevel,
            onOpenAddOrEditSheet,
            levelLength: levelLength || row.subProjects.length
          }}
        />
        {row.subProjects.map((x, i) => renderRows(x, level + 1, i, i === row.subProjects.length - 1 ? nextElementLevel : level + 1, row.subProjects.length))}
      </>
    );
  };

  const onAddProject = (newProject: IProject) => {
    onSetProject(newProject);
    if (!isEditing) {
      newProjectId.current = newProject.projectId;
    }
  };

  const onOpenAddOrEditSheet = (project: IProject | null, isEditProject: boolean) => {
    open(<AddOrEditForm {...{ project, isEditProject, onAddProject, close }} />, 'project-add-or-edit-form');
  };

  useEffect(() => {
    if (isEditing) {
      onGetProjectFromRemote(Number(props.id));
    }
  }, [props.id, onGetProjectFromRemote, isEditing]);

  return (
    <div className="data-table project-list project-details">
      <div className="data-table-title">
        <Link to="/TrackTime/Projects">
          <Typography className="title" use="body2">
            <Icon icon="keyboard_arrow_left" />
            Vissza
          </Typography>
        </Link>
      </div>

      <div className="data-table__row data-table__row--title">
        <div className="data-table__cell project-name">
          <Typography use="caption">Projekt kódja, neve</Typography>
        </div>

        <div className="data-table__cell align-center">
          <Typography use="caption">Aktív?</Typography>
        </div>

        <div className="data-table__cell align-center">
          <Typography use="caption">Rögzíthető rá?</Typography>
        </div>

        <div className="data-table__cell">
          <Typography use="caption">Erőforráscsoport kódja</Typography>
        </div>

        <div className="row-spacer-header__end" />
      </div>

      <div className="data-table-body">
        {isLoading ? (
          <CircularProgress />
        ) : error.length > 0 ? (
          <div className="data-table__row not-found-data error">{error}</div>
        ) : project ? (
          renderRows(project)
        ) : !isEditing ? (
          <Row {...{ isEditing, row: null, level: 0, levelLength: 0, index: 0, nextElementLevel: 0, onOpenAddOrEditSheet }} />
        ) : null}
      </div>
    </div>
  );
};

function Row(props: RowProps) {
  const { row, level, onOpenAddOrEditSheet, isEditing, index, levelLength, nextElementLevel } = props;

  return (
    <div className="data-table__row">
      <div className="data-table__cell project-name">
        {generateIndentsForCollapseable(level, index, levelLength, false, nextElementLevel)}

        <div className="project-color-code" style={{ backgroundColor: row ? row.colorCode : '' }} />
        <div className="project-name text-overflow">{row ? `${row.displayCode}, ${row.displayName}` : '-'}</div>
      </div>

      <div className="data-table__cell">
        <Checkbox readOnly disabled checked={row ? row.isActive : false} />
      </div>

      <div className="data-table__cell">
        <Checkbox readOnly disabled checked={row ? row.isTimeRegisterable : false} />
      </div>

      <div className="data-table__cell"> {row && row.resourceGroupTypeId ? row.resourceGroupTypeId : '-'} </div>

      <div className="data-table__cell--end">
        {(!row || (row && !row.isSyncronized)) && <Icon icon="add" onClick={() => onOpenAddOrEditSheet(row, false)} />}
        {isEditing && <Icon icon="edit" onClick={() => onOpenAddOrEditSheet(row, true)} />}
      </div>
    </div>
  );
}

function AddOrEditForm(props: AddOrEditFormProps) {
  const { project, isEditProject, onAddProject, close } = props;
  const _isMounted = useIsMounted();
  const isDisabled = Boolean(project && project.isSyncronized);

  /* States */
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [newProject, setNewProject] = useState<IAddOrEditProject>({
    // if the parentProjectId is not null, the add method appends the new project to the existing projects(serverside)
    parentProjectId: project ? (isEditProject ? project.parentProjectId : project.projectId) : null,

    colorCode: project && isEditProject ? project.colorCode : '',
    displayCode: project && isEditProject ? project.displayCode : '',
    displayName: project && isEditProject ? project.displayName : '',
    isActive: project && isEditProject ? project.isActive : false,
    isTimeRegisterable: project && isEditProject ? project.isTimeRegisterable : false,
    projectCode: project && isEditProject ? project.projectCode : '',
    projectId: project && isEditProject ? project.projectId : null,
    resourceGroupTypeId: project && isEditProject ? project.resourceGroupTypeId : '',
    updated: null
  });

  const onTextFieldChange = (ev: React.ChangeEvent<TextFieldProps>) => {
    ev.persist();
    //@ts-ignore
    setNewProject(prev => ({ ...prev, [ev.target.name]: String(ev.target.value || '') }));
  };

  const onSwitchChange = (ev: React.ChangeEvent<SwitchProps>) => {
    ev.persist();
    //@ts-ignore
    setNewProject(prev => ({ ...prev, [ev.target.name]: !!ev.target.checked }));
  };

  const onSelectColor = (colorCode: string) => setNewProject(prev => ({ ...prev, colorCode }));

  const onCloseError = () => setError('');

  const onSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();
    setIsLoading(true);
    try {
      const project = await addProject(transformIAddOrEditProject(newProject));
      if (project) {
        onAddProject(transformProjectDTO(project));
        close();
      } else throw new Error('Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
    } catch (error) {
      _isMounted.current && setError(error.message || 'Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="drawer-header">Projekt {!isEditProject ? 'hozzáadása' : 'szerkesztése'}</div>
      {isLoading && <LinearProgress />}

      {error.length > 0 && (
        <div className="error-box">
          <div className="basic-error-text">
            {error} <Icon icon="close" onClick={onCloseError} />
          </div>
        </div>
      )}

      <div className="drawer-body">
        <div className="mdc-form-fields">
          <TextField className="field" label="Projekt szinkronizációs kódja" maxLength={100} name="projectCode" value={newProject.projectCode} onChange={onTextFieldChange} disabled={isDisabled} />
        </div>

        <div className="mdc-form-fields">
          <TextField className="field" label="Projekt kódja" maxLength={100} required name="displayCode" value={newProject.displayCode} onChange={onTextFieldChange} disabled={isDisabled} />

          <TextField className="field" label="Projekt neve" maxLength={200} required name="displayName" value={newProject.displayName} onChange={onTextFieldChange} disabled={isDisabled} />
        </div>

        <div className="mdc-form-fields">
          <Switch className="field" label="Aktív?" name="isActive" checked={newProject.isActive} onChange={onSwitchChange} disabled={isDisabled} />

          <Switch className="field" label="Rögzíthető rá?" name="isTimeRegisterable" checked={newProject.isTimeRegisterable} onChange={onSwitchChange} disabled={isDisabled} />
        </div>

        <div className="mdc-form-fields">
          <TextField className="field" label="Erőforráscsoport kódja" name="resourceGroupTypeId" value={newProject.resourceGroupTypeId} onChange={onTextFieldChange} disabled={isDisabled} />
        </div>

        <div className="mdc-form-fields">
          <TextField
            className="field"
            label="Színkód"
            maxLength={7}
            name="colorCode"
            value={'#' + newProject.colorCode.substring(1)}
            onChange={onTextFieldChange}
            pattern={newProject.colorCode.length > 1 ? HEX_COLOR_PATTERN : undefined}
          />
        </div>

        <PaletteSelector {...{ onSelectColor }} />

        <Button unelevated type="submit" label={isEditProject ? 'Mentés' : 'Hozzáadom'} />
      </div>
    </form>
  );
}

import React, { useContext, useMemo, useState } from 'react';

/* Data Things */
import { IProject, searchProjects, sortProjects } from '../helper';
import { Link, RouteComponentProps } from '@reach/router';
import { PROJECTS_ROW_HEIGHT } from '../../../../constants/constants';
import { ProjectsContext } from '../../../../components/Contexts/Projects';
import { useOuterClick } from '../../../../hooks/useOuterClick';

/* Presentation Things */
import CircularProgress from '../../../../components/CircularProgress';
import { List, WindowScroller, AutoSizer, ListRowProps } from 'react-virtualized';
import { ProjectSync } from '../ProjectSync';
import { Typography } from '@rmwc/typography';
import { Checkbox } from '@rmwc/checkbox';
import { Icon } from '@rmwc/icon';

// Style
import './ProjectList.scss';

const PROJECT_SYNC_URI = '/TrackTime/Projects/Sync';

interface Props extends RouteComponentProps {}

export const ProjectList = (props: Props) => {
  //States
  const [searchText, setSearchText] = useState('');
  const [isOpenAddSheet, setIsOpenAddSheet] = useState(false);
  const [isOpenSynDialog, setIsOpenSyncDialog] = useState(props.uri === PROJECT_SYNC_URI ? true : false);

  //Context
  const context = useContext(ProjectsContext);

  //Variables
  const sortedProjects = useMemo(() => Object.values(context.projects).sort(sortProjects), [context.projects]);
  const projects = useMemo(() => searchProjects(sortedProjects, searchText), [sortedProjects, searchText]);

  //Ref
  const _fabRef = useOuterClick(() => setIsOpenAddSheet(false));

  const onOpenAddSheet = () => setIsOpenAddSheet(true);

  const onCloseSyncDialog = () => setIsOpenSyncDialog(false);

  const onSearch = (ev: React.ChangeEvent<HTMLInputElement>) => setSearchText(String(ev.currentTarget.value));

  const onOpenSyncDialog = () => {
    setIsOpenSyncDialog(true);
    setIsOpenAddSheet(false);
  };

  const rowRenderer = ({ index, style, isScrolling, isVisible, key }: ListRowProps) => {
    const project = projects[index];
    const isPlaceholder = isScrolling && !isVisible;

    return (
      <div style={style} key={key}>
        <Row {...{ project, isPlaceholder }} />
      </div>
    );
  };

  return (
    <>
      <ProjectSync open={isOpenSynDialog} onClose={onCloseSyncDialog} onListUpdate={context.onRefresh} />

      <div className="data-table-title">
        <Typography className="title" use="body2">
          Projektek
        </Typography>

        {!context.isLoading && !context.error.length && (
          <div className="fab-menu-container">
            <div className={`mdc-sheet-fab--container ${isOpenAddSheet ? 'mdc-sheet-fab--container-animate' : ''}`} ref={_fabRef}>
              <div className="mdc-sheet-fab">
                <div className="mdc-fab mdc-fab--mini" aria-label="Add" onClick={onOpenAddSheet}>
                  <i className="material-icons">add</i>
                </div>

                <ul className="mdc-list" role="menu" aria-hidden="true">
                  <li className="mdc-list-item" role="menuitem">
                    <Link className="mdc-menu--link" to="/TrackTime/Projects/Create">
                      Új ProHR projekt
                    </Link>
                  </li>
                  <li className="mdc-list-item" role="menuitem" onClick={onOpenSyncDialog}>
                    Szinkronizáció
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={`search-box ${(context.isLoading || context.error.length > 0) && 'disabled'}`}>
        <span className="search-box--start-detail">
          <span className="material-icons">search</span>
        </span>

        <input className="search-box--input" type="text" placeholder="Keresés" onChange={onSearch} value={searchText} />

        <span className="search-box--start-detail" onClick={() => setSearchText('')}>
          <span className="material-icons">close</span>
        </span>
      </div>

      <div className="data-table project-list">
        <div className="data-table__row data-table__row--title">
          <div className="data-table__cell project-code">
            <Typography use="caption">Projekt kód</Typography>
          </div>

          <div className="data-table__cell project">
            <Typography use="caption">Projekt név</Typography>
          </div>

          <div className="data-table__cell checkbox">
            <Typography use="caption">Aktív?</Typography>
          </div>

          <div className="data-table__cell checkbox">
            <Typography use="caption">Szinkronizált?</Typography>
          </div>

          <div className="row-spacer-header__end" />
        </div>

        <div className="data-table-body">
          {context.isLoading ? (
            <CircularProgress />
          ) : context.error.length > 0 || !projects.length ? (
            <div className="basic-error-text">{context.error || 'Nincsenek megjeleníthető projektek'}</div>
          ) : (
            <WindowScroller>
              {({ height, isScrolling, registerChild, scrollTop }) => (
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <div ref={registerChild}>
                      <List autoHeight height={height} isScrolling={isScrolling} rowCount={projects.length} rowHeight={PROJECTS_ROW_HEIGHT} rowRenderer={rowRenderer} scrollTop={scrollTop} width={width} />
                    </div>
                  )}
                </AutoSizer>
              )}
            </WindowScroller>
          )}
        </div>
      </div>
    </>
  );
};

const Row = ({ project, isPlaceholder }: { project: IProject; isPlaceholder: boolean }) => {
  return (
    <div className={`data-table__row ${isPlaceholder ? 'wireframe-design' : ''}`}>
      <div className="data-table__cell project-code">
        <span className="project-color-code" style={{ backgroundColor: project.colorCode }} />
        <span>{project.displayCode}</span>
      </div>

      <div className="data-table__cell text-overflow project">{project.displayName}</div>

      <div className="data-table__cell checkbox">{!isPlaceholder ? <Checkbox readOnly disabled checked={project.isActive} /> : <div className="wireframe-item" />}</div>

      <div className="data-table__cell sync">{project.isSyncronized && <div className="project-sync">Szinkronozált</div>}</div>

      <div className="data-table__cell--end">
        <Link to={`/TrackTime/Projects/Edit/${project.projectId}`}>
          <Icon icon="keyboard_arrow_right" />
        </Link>
      </div>
    </div>
  );
};

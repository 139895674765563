import { ICreateCustomerDTO, ICustomerDTO, IEditCustomerDTO } from '../../../typings/DTOs';
import { api } from '../../../utils/api';

export const getCustomers = () => api<ICustomerDTO[]>('GET', 'customers');

export const getCustomer = (id: number) => api<ICustomerDTO>('GET', `customers/${id}`);

export const editCustomer = (model: IEditCustomerDTO) => api<ICustomerDTO>('PUT', `customers/${model.id}`, model);

export const createCustomer = (model: ICreateCustomerDTO) => api<ICustomerDTO>('POST', 'customers', model);

import {
  ActiveTab,
  AddPersonBasicInfo,
  AddPersonPersonalInfo,
  AddPersonContactInfo,
  IAddEmployeeModel,
  isValidBasicInfo,
  isValidContactInfo,
  isValidPersonalInfo,
  isValidAttachments,
  isValidEmployment,
  TAB_ITEMS
} from '../../../../routes/Organization/People/PeopleAdd/helper';
import { EmploymentContract } from '../../../../components/People/ProfileDetails/Employment/Edit/helper';
import { AddressDTO } from '../../../lib/Organization/Peoples/peopleDetails';
import { DateTime } from 'luxon';
import { SexTypes } from '../../../../constants/enums';
import { SelectedFile } from '../../../../components/Selectors/FileSelector';

export const initState: AddState = {
  model: {
    basicInfo: {
      courtesyTitle: '',
      syncId: '',
      sexTypeId: SexTypes.Male,
      pictureTempFileId: null,
      firstName: '',
      lastName: '',
      middleName: '',
      motherName: '',
      birthDate: DateTime.local(),
      birthName: '',
      birthPlace: '',
      maritalStatus: null,
      isPrivate: false
    },
    personalInfo: {
      addressCardNumber: '',
      bankAccountNumber: '',
      driverLicenseExpiryDate: null,
      driverLicenseNumber: '',
      idCardExpiryDate: null,
      idCardNumber: '',
      passportExpiryDate: null,
      passportNumber: '',
      socialSecurityNumber: '',
      taxNumber: ''
    },
    contactInfo: {
      facebook: '',
      linkedin: '',
      nextOfKinName: '',
      nextOfKinPhone: '',
      privateEmail: '',
      privateHomeAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: '',
        zip: ''
      },
      privateMobilePhone: '',
      privateResidenceAddress: { addressLine1: '', addressLine2: '', city: '', country: '', zip: '' },
      publicEmail: '',
      publicLinePhone: '',
      publicLinePhoneExtension: '',
      publicMobilePhone: ''
    },
    employment: {
      isEmployee: true,
      httpError: '',
      OUs: [],
      childrenInHousehold: null,
      companyId: null,
      daysOff: [
        { year: DateTime.local().year, daysOff: 0 },
        { year: DateTime.local().year + 1, daysOff: 0 }
      ],
      employmentContracts: [
        {
          key: DateTime.local().valueOf(),
          employmentContractId: null,
          grossSalary: null,
          jobRoleIds: [],
          startDate: DateTime.local(),
          trialEndDate: null,
          weeklyWorkHours: 40,
          workScheduleId: null
        }
      ],
      endDate: null,
      resourceGroupTypeId: '',
      startDate: DateTime.local(),
      taxReducement: null
    },
    attachments: []
  },
  activeTab: ActiveTab.BasicInfo,
  tabStates: {
    [ActiveTab.BasicInfo]: null,
    [ActiveTab.Contact]: null,
    [ActiveTab.Personal]: null,
    [ActiveTab.Attachments]: null,
    [ActiveTab.Employment]: null
  },
  isLoading: false
};

export interface AddState {
  model: IAddEmployeeModel;
  isLoading: boolean;
  activeTab: ActiveTab;
  tabStates: {
    [ActiveTab.BasicInfo]: null | 'error' | 'success';
    [ActiveTab.Contact]: null | 'error' | 'success';
    [ActiveTab.Personal]: null | 'error' | 'success';
    [ActiveTab.Attachments]: null | 'error' | 'success';
    [ActiveTab.Employment]: null | 'error' | 'success';
  };
}

export type AddAction =
  | { type: 'set_partial_basicInfo'; payload: { partial: Partial<AddPersonBasicInfo> } }
  | { type: 'set_partial_personalInfo'; payload: { partial: Partial<AddPersonPersonalInfo> } }
  | { type: 'set_partial_contactInfo'; payload: { partial: Partial<AddPersonContactInfo> } }
  | { type: 'set_partial_employment'; payload: { partial: Partial<IAddEmployeeModel['employment']> } }
  | { type: 'add_contract_employment'; payload: { contract: EmploymentContract } }
  | { type: 'add_attachment'; payload: { attachment: SelectedFile } }
  | { type: 'delete_attachment'; payload: { id: string } }
  | { type: 'update_contract_employment'; payload: { contract: EmploymentContract | undefined; index: number } }
  | { type: 'update_address'; payload: { homeType: 'privateHomeAddress' | 'privateResidenceAddress'; key: keyof AddressDTO; value: string } }
  | { type: 'set_activeTab'; payload: { activeTab: ActiveTab } }
  | { type: 'set_isLoading'; payload: { isLoading: boolean } }
  | { type: 'error_save' };

export function addReducer(state: AddState = initState, action: AddAction): AddState {
  switch (action.type) {
    case 'set_activeTab': {
      const isLastTab = TAB_ITEMS.length === action.payload.activeTab;
      return {
        ...state,
        activeTab: Math.min(TAB_ITEMS.length, action.payload.activeTab),
        tabStates: !isLastTab
          ? /* VALIDATE JUST THE PREVIOUS TAB */
            {
              ...state.tabStates,
              [state.activeTab]: (() => {
                switch (state.activeTab) {
                  case ActiveTab.BasicInfo:
                    return isValidBasicInfo(state.model.basicInfo) ? 'success' : 'error';
                  case ActiveTab.Contact:
                    return isValidContactInfo(state.model.contactInfo) ? 'success' : 'error';
                  case ActiveTab.Personal:
                    return isValidPersonalInfo(state.model.personalInfo) ? 'success' : 'error';
                  case ActiveTab.Attachments:
                    return isValidAttachments(state.model.attachments) ? 'success' : 'error';
                  case ActiveTab.Employment:
                    return isValidEmployment(state.model.employment) ? 'success' : 'error';
                }
              })()
            }
          : {
              [ActiveTab.BasicInfo]: isValidBasicInfo(state.model.basicInfo) ? 'success' : 'error',
              [ActiveTab.Contact]: isValidContactInfo(state.model.contactInfo) ? 'success' : 'error',
              [ActiveTab.Personal]: isValidPersonalInfo(state.model.personalInfo) ? 'success' : 'error',
              [ActiveTab.Attachments]: isValidAttachments(state.model.attachments) ? 'success' : 'error',
              [ActiveTab.Employment]: isValidEmployment(state.model.employment) ? 'success' : 'error'
            }
      };
    }

    case 'set_partial_employment': {
      return {
        ...state,
        model: { ...state.model, employment: { ...state.model.employment, ...action.payload.partial } }
      };
    }

    case 'set_partial_basicInfo': {
      return {
        ...state,
        model: { ...state.model, basicInfo: { ...state.model.basicInfo, ...action.payload.partial } }
      };
    }

    case 'set_partial_contactInfo': {
      return {
        ...state,
        model: { ...state.model, contactInfo: { ...state.model.contactInfo, ...action.payload.partial } }
      };
    }

    case 'set_partial_personalInfo': {
      return {
        ...state,
        model: { ...state.model, personalInfo: { ...state.model.personalInfo, ...action.payload.partial } }
      };
    }

    case 'add_attachment': {
      return {
        ...state,
        model: {
          ...state.model,
          attachments: [...state.model.attachments, action.payload.attachment]
        }
      };
    }

    case 'delete_attachment': {
      return {
        ...state,
        model: {
          ...state.model,
          attachments: state.model.attachments.filter(x => x.id !== action.payload.id)
        }
      };
    }

    case 'add_contract_employment': {
      return {
        ...state,
        model: {
          ...state.model,
          employment: { ...state.model.employment, employmentContracts: [action.payload.contract, ...state.model.employment.employmentContracts] }
        }
      };
    }

    case 'update_contract_employment': {
      return {
        ...state,
        model: action.payload.contract
          ? //UPDATE
            {
              ...state.model,
              employment: {
                ...state.model.employment,
                employmentContracts: state.model.employment.employmentContracts.map((x, i) => (i === action.payload.index ? action.payload.contract! : x))
              }
            }
          : //DELETE
            {
              ...state.model,
              employment: {
                ...state.model.employment,
                employmentContracts: state.model.employment.employmentContracts.filter((_, i) => i !== action.payload.index)
              }
            }
      };
    }

    case 'update_address': {
      return {
        ...state,
        model: {
          ...state.model,
          contactInfo: {
            ...state.model.contactInfo,
            [action.payload.homeType]: { ...state.model.contactInfo[action.payload.homeType], [action.payload.key]: action.payload.value }
          }
        }
      };
    }

    case 'set_isLoading': {
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    }

    case 'error_save': {
      return {
        ...state,
        isLoading: false
      };
    }

    default:
      return state;
  }
}

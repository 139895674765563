import { ProductItemQuantityUnit } from '../../../../constants/enums';
import { Product, Task, TaskAttachment } from '../helper';

export const DEFAULT_PRODUCT = (parentId: number | null): Product => {
  return {
    id: -1,
    itemNoRaw: '',
    itemNoReady: '',
    parentId,
    primaryPicture: null,
    otherPictures: [],
    quantityUnit: ProductItemQuantityUnit.Amount,
    conversionRates: [],
    subProducts: [],
    tasks: [],
    title: '',
    notes: '',
    hasSerialNumber: false,
    attachments: null
  };
};

export const DEFAULT_TASK: Task = {
  id: null,
  isQARequired: false,
  name: '',
  orderOfTask: 1,
  standardTimeSec: 60,
  assignedAttachmentIds: null
};

export const DEFAULT_PRODUCT_ATTACHMENT: TaskAttachment = {
  id: -1,
  fileName: '',
  extension: '',
  fileUrl: '',
  fileLength: 0
};

import { api } from '../../../utils/api';
import { ConcurrentEditViewModel } from '../Organization/Peoples/employment';

export interface OUTypeDTO extends ConcurrentEditViewModel {
  ouTypeId: number;
  name: string | null;
  colorCode: string | null;
}

export interface AddOrEditOUTypeDTO extends ConcurrentEditViewModel {
  ouTypeId: number | null;
  name: string;
  colorCode: string;
}

export const getAllOUTypes = () => {
  return api<OUTypeDTO[]>('GET', `organizationUnitTypes`, null);
};

export const createOUType = (model: AddOrEditOUTypeDTO) => {
  return api<OUTypeDTO>('POST', `organizationUnitTypes`, model);
};

export const editOUType = (model: AddOrEditOUTypeDTO) => {
  return api<OUTypeDTO>('PUT', `organizationUnitTypes`, model);
};

import React, { Component } from 'react';
import './CircularProgress.scss';

interface IProps {
  className?: string;
}

class CircularProgress extends Component<IProps, {}> {
  public render() {
    return (
      <span className={['circular-progress-wrap', this.props.className].filter(Boolean).join(' ')}>
        <svg className="mdc-circular-progress" viewBox="25 25 50 50">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#14EBF1" />
              <stop offset="100%" stopColor="#029CF6" />
            </linearGradient>
          </defs>
          <circle className="mdc-circular-progress__path" cx="50" cy="50" r="20" fill="none" stroke="url(#gradient)" strokeWidth="6" strokeMiterlimit="10" />
        </svg>
      </span>
    );
  }
}

export default CircularProgress;

import React from 'react';

/* Presentation Things */
import { Icon } from '@rmwc/icon';

type Props = {
  onChange(page: number): void;
  page: number;
  total: number;
  displayedCount: number;
};

export const TablePager = ({ onChange, page, total, displayedCount }: Props) => {
  const onPrevPage = () => onChange(page - 1);

  const onNexPage = () => onChange(page + 1);

  return (
    <div className="data-table-footer">
      <button onClick={onPrevPage} disabled={!page}>
        <Icon icon="keyboard_arrow_left" />
        Előző
      </button>

      <button onClick={onNexPage} disabled={(page + 1) * displayedCount >= total}>
        Következő
        <Icon icon="keyboard_arrow_right" />
      </button>
    </div>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

/* Data Things */
import { DateTime } from 'luxon';
import { DATEFORMAT } from '../../../../../constants/constants';
import { useIsMounted } from '../../../../../hooks/useIsMounted';
import { getCompanies } from '../../../../../store/lib/Organization/Peoples/employment';
import { EmployeeEmployement } from '../../../../../routes/Organization/People/PeopleAdd/helper';
import { CompanyForOrganization, transformCompanyForOrgHierarchyDTO } from '../../../../../routes/Organization/Structure/helper';

/* Presentation Things */
import { Select, SelectProps } from '@rmwc/select';
import { TextField, TextFieldProps } from '@rmwc/textfield';

type DetailsModel = {
  companyId: number | null;
  startDate: DateTime;
  endDate: DateTime | null;
  resourceGroupTypeId: string;
  childrenInHousehold: number | null;
  taxReducement: number | null;
};

type IProps = {
  lastEmploymentEndDate: DateTime | null;
  isEmployee: boolean;
  editModel: DetailsModel | EmployeeEmployement;
  onEditModel: <T extends keyof DetailsModel, K extends DetailsModel[T]>(key: T, value: K) => void;
};

const zeroAll = { hour: 0, minute: 0, second: 0, millisecond: 0 };

export function DetailsSection({ lastEmploymentEndDate = null, editModel, onEditModel, isEmployee = true }: IProps) {
  const _isMounted = useIsMounted();
  const [companies, setCompanies] = useState<CompanyForOrganization[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const COMPANIES_SELECT = companies.map(x => ({ label: x.name, value: x.id.toString() }));

  useEffect(() => {
    getCompaniesFromRemote();
  }, []);

  useEffect(() => {
    if (lastEmploymentEndDate) {
      onEditModel('startDate', lastEmploymentEndDate.plus({ day: 1 }));
    }
  }, [lastEmploymentEndDate]);

  const getCompaniesFromRemote = async () => {
    try {
      const companies = await getCompanies();
      if (companies && _isMounted.current) {
        setCompanies(companies.map(x => transformCompanyForOrgHierarchyDTO(x)).sort((x, y) => (x.name < y.name ? -1 : 1)));
        if (editModel.companyId === null) {
          onEditModel('companyId', companies[0].companyId);
        }
      }
    } catch (error) {
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const handleStartDate = (ev: React.FormEvent<TextFieldProps>) => {
    let parseAble = DateTime.fromFormat(`${ev.currentTarget.value}`, DATEFORMAT).set(zeroAll);
    if (parseAble.isValid) {
      onEditModel('startDate', parseAble);
    }
  };

  const handleEndDate = (ev: React.FormEvent<TextFieldProps>) => {
    let parseAble = DateTime.fromFormat(`${ev.currentTarget.value}`, DATEFORMAT).set(zeroAll);

    if (parseAble.isValid) {
      onEditModel('endDate', parseAble);
    } else {
      onEditModel('endDate', null);
    }
  };

  const handleResourceGroupType = (ev: React.FormEvent<TextFieldProps>) => {
    onEditModel('resourceGroupTypeId', ev.currentTarget.value === undefined ? '' : String(ev.currentTarget.value));
  };

  const handleChildrenInHousehold = (ev: React.FormEvent<TextFieldProps>) => {
    onEditModel('childrenInHousehold', ev.currentTarget.value === undefined ? null : Number(ev.currentTarget.value));
  };

  const handleTaxReducement = (ev: React.FormEvent<TextFieldProps>) => {
    onEditModel('taxReducement', ev.currentTarget.value === undefined ? null : Number(ev.currentTarget.value));
  };

  const handleCompany = (ev: React.FormEvent<SelectProps>) => {
    onEditModel('companyId', ev.currentTarget.value === undefined ? null : Number(ev.currentTarget.value));
  };

  return (
    <div className="form-content-container">
      <div className="form-content-row">
        <div className="mdc-form-field--with-helptext">
          <Select
            label="Cég"
            required
            value={editModel.companyId !== null ? String(editModel.companyId) : undefined}
            onChange={handleCompany}
            invalid={!isLoading && !companies.length}
            options={COMPANIES_SELECT}
            helpText={{
              persistent: false,
              validationMsg: true,
              children: 'Nincsenek rendelkezésre álló cégek'
            }}
          />
        </div>

        {isEmployee && (
          <>
            <TextField
              min={lastEmploymentEndDate ? lastEmploymentEndDate.plus({ day: 1 }).toFormat(DATEFORMAT) : undefined}
              max={editModel.endDate ? editModel.endDate.toFormat(DATEFORMAT) : undefined}
              label="Munkaviszony kezdete"
              required
              type="date"
              value={editModel.startDate.toFormat(DATEFORMAT)}
              onChange={handleStartDate}
            />

            <TextField
              min={editModel.startDate.plus({ day: 1 }).toFormat(DATEFORMAT)}
              label="Munkaviszony vége"
              type="date"
              value={editModel.endDate ? editModel.endDate.toFormat(DATEFORMAT) : ' '}
              onChange={handleEndDate}
            />
          </>
        )}
      </div>

      {isEmployee && (
        <div className="form-content-row">
          <div className="mdc-form-field--with-helptext">
            <TextField label="Erőforráscsoport kódja" type="text" maxLength={50} value={editModel.resourceGroupTypeId} onChange={handleResourceGroupType} />
          </div>

          <div className="mdc-form-field--with-helptext">
            <TextField label="Egy háztartásban élő gyerekek száma" min={0} type="number" value={editModel.childrenInHousehold || ' '} onChange={handleChildrenInHousehold} />
          </div>

          <div className="mdc-form-field--with-helptext">
            <TextField label="Családi adókedvezmény összege" type="number" value={editModel.taxReducement || ' '} min={0} onChange={handleTaxReducement} />
          </div>
        </div>
      )}
    </div>
  );
}

DetailsSection.defaultProps = {
  isEmployee: true
};

import React, { useContext, useState } from 'react';

/* Data Things */
import { DayStatusRow, INITIAL_STATE, transformDayStatusRowDTO } from '../helper';
import { AddOrEditDayStatusRowDTO, createOrEditDayStatus } from '../../../../store/lib/MasterData/dailyStatuses';
import { HEX_COLOR_PATTERN } from '../../../../constants/regex';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { useIsMounted } from '../../../../hooks/useIsMounted';

/* Presentation Things */
import ResponseSnackbar, { ApiResponseType } from '../../../../components/Portals/ResponseSnackbar';
import { List, ListItem, ListItemText, ListItemPrimaryText, ListItemSecondaryText } from '@rmwc/list';
import { PaletteSelector } from '../../../../components/Selectors/PaletteSelector';
import { LinearProgress } from '@rmwc/linear-progress';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import { Button } from '@rmwc/button';

interface Props {
  editableStatus?: DayStatusRow;
  onSetStatus(status: DayStatusRow): void;
}

export const DailyStatusAddOrEdit = (props: Props) => {
  /* Variables */
  const isEdit = Boolean(props.editableStatus);
  const dayStatusId = isEdit ? props.editableStatus!.id : null;

  /* States */
  const [apiResponse, setApiResponse] = useState<ApiResponseType>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState(isEdit ? props.editableStatus! : INITIAL_STATE);

  /* Context */
  const { close } = useContext(SideSheetContext);

  /* Refs */
  const _isMounted = useIsMounted();

  const handleTextFieldChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev.persist();
    setState(prev => ({ ...prev, [ev.target.name]: String(ev.target.value) }));
  };

  const onToogleIsIncreaseWorkTime = () => setState(prev => ({ ...prev, isIncreaseWorkTime: !prev.isIncreaseWorkTime }));

  const onToogleCanSetOnNonWorkday = () => setState(prev => ({ ...prev, canSetOnNonWorkday: !prev.canSetOnNonWorkday }));

  const onToogleCanSetOnWorkday = () => setState(prev => ({ ...prev, canSetOnWorkday: !prev.canSetOnWorkday }));

  const onToogleCanSetOnRestday = () => setState(prev => ({ ...prev, canSetOnRestday: !prev.canSetOnRestday }));

  const onToogleIsLimited = () => setState(prev => ({ ...prev, isLimited: !prev.isLimited }));

  const onToogleIsWorkDay = () => setState(prev => ({ ...prev, isWorkDay: !prev.isWorkDay }));

  const onToogleIsPaid = () => setState(prev => ({ ...prev, isPaid: !prev.isPaid }));

  const onSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();
    setIsLoading(true);
    try {
      const model: AddOrEditDayStatusRowDTO = { dayStatusId, ...state };
      const resp = await createOrEditDayStatus(model);
      if (!resp.error && _isMounted.current) {
        props.onSetStatus(transformDayStatusRowDTO(resp));
        setApiResponse({ type: 'success', message: `Sikeres új napi státusz ${isEdit ? 'szerkesztés' : 'hozzáadás'}` });
        setTimeout(() => _isMounted.current && close(), 500);
      } else throw new Error(resp.error);
    } catch (error) {
      if (_isMounted.current) {
        setApiResponse({ type: 'error', message: String(error.message || 'Sajnáljuk, valami hiba történt a napi státusz mentése során.') });
      }
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const onSelectColor = (colorCode: string) => setState(state => ({ ...state, colorCode }));

  return (
    <>
      <ResponseSnackbar response={apiResponse} onClose={() => setApiResponse(null)} />

      <div className="drawer-header">{isEdit ? 'Napi státusz szerkesztése' : 'Új napi státusz'}</div>
      {isLoading && <LinearProgress />}

      <div className="form-content">
        <form onSubmit={onSubmit}>
          <div className="form-content-row">
            <TextField required maxLength={2} type="text" label="Kód" value={state.code} name="code" onChange={handleTextFieldChange} autoFocus />
          </div>

          <div className="form-content-row">
            <TextField required maxLength={50} type="text" label="Megnevezés" name="name" onChange={handleTextFieldChange} value={state.name} />
          </div>

          <div className="form-content-row">
            <TextField maxLength={7} label="Színkód" name="colorCode" value={'#' + state.colorCode.substring(1)} onChange={handleTextFieldChange} pattern={HEX_COLOR_PATTERN} />
          </div>

          <PaletteSelector onSelectColor={onSelectColor} />

          {(!isEdit || (isEdit && !props.editableStatus!.isCodeHandled)) && (
            <List twoLine nonInteractive>
              <ListItem onClick={onToogleIsLimited}>
                <Checkbox checked={state.isLimited} />
                <ListItemText>
                  <ListItemPrimaryText>Jogosultság függő</ListItemPrimaryText>
                  <ListItemSecondaryText>A napi státusz beállítása csak megfelelő jogosultsággal lehetséges</ListItemSecondaryText>
                </ListItemText>
              </ListItem>

              <ListItem onClick={onToogleIsWorkDay}>
                <Checkbox checked={state.isWorkDay} />
                Dolgozott munkanapnak számít
              </ListItem>

              <ListItem onClick={onToogleIsIncreaseWorkTime}>
                <Checkbox checked={state.isIncreaseWorkTime} />
                <ListItemText>
                  <ListItemPrimaryText>Munkaidőnek számít</ListItemPrimaryText>
                  <ListItemSecondaryText>Napi alapórával csökkenti az elvárt rögzítendő időt</ListItemSecondaryText>
                </ListItemText>
              </ListItem>

              <ListItem onClick={onToogleIsPaid}>
                <Checkbox checked={state.isPaid} />
                <ListItemText>
                  <ListItemPrimaryText>Fizetett munkaidő</ListItemPrimaryText>
                  <ListItemSecondaryText>Napi alapórával beleszámít az óradíj megállapításába</ListItemSecondaryText>
                </ListItemText>
              </ListItem>

              <ListItem onClick={onToogleCanSetOnWorkday}>
                <Checkbox checked={state.canSetOnWorkday} />
                Munkanapon válaszható
              </ListItem>

              <ListItem onClick={onToogleCanSetOnRestday}>
                <Checkbox checked={state.canSetOnRestday} />
                Pihenőnapon válaszható
              </ListItem>

              <ListItem onClick={onToogleCanSetOnNonWorkday}>
                <Checkbox checked={state.canSetOnNonWorkday} />
                Munkaszüneti napon válaszható
              </ListItem>
            </List>
          )}
          <Button unelevated label={isEdit ? 'Szerkesztés' : 'Hozzáadás'} type="submit" />
        </form>
      </div>
    </>
  );
};

DailyStatusAddOrEdit.defaultProps = {
  editableStatus: undefined
};

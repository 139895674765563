import React, { useCallback, useContext, useEffect, useState } from 'react';

/* Data Things */
import { ActivityTag, transformTagUpdatedVM } from './helper';
import { RouteComponentProps } from '@reach/router';
import { SideSheetContext } from '../../../components/Contexts/SideSheet';
import { getActivityTags } from '../../../store/lib/MasterData/activityTags';
import { useIsMounted } from '../../../hooks/useIsMounted';

/* Routes  */
import { ActivityTagAddOrEdit } from './AddOrEdit';
import { ActivityTagsList } from './List';

/* Presentation Things */
import { TextFieldProps } from '@rmwc/textfield';
import { Typography } from '@rmwc/typography';
import { Fab } from '@rmwc/fab';

// Styles
import '../MasterData.scss';

interface IProps extends RouteComponentProps {}

export function ActivityTags(_: IProps) {
  /* States */
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [tags, setTags] = useState<ActivityTag[]>([]);

  /* Refs */
  const _isMounted = useIsMounted();

  /* Context */
  const { open } = useContext(SideSheetContext);

  const getActivityTagsFromRemote = useCallback(async () => {
    try {
      const tags = await getActivityTags();
      if (!tags.error) {
        _isMounted.current && setTags(tags.map(x => transformTagUpdatedVM(x)));
      } else throw new Error(tags.error);
    } catch (error) {
      _isMounted.current && setError(String(error.message || 'Sajnáljuk, valami hiba történt a cimkék betöltés során. Kérlek próbáld újra!'));
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  }, [_isMounted]);

  const onOpenAddSheet = () => open(<ActivityTagAddOrEdit {...{ onSetActivityTag, onAddActivityTag }} />, 'MasterDataAddOrEditSheet');

  const onClearSearch = () => setSearchText('');

  const onSearch = (ev: React.ChangeEvent<TextFieldProps>) => setSearchText(String(ev.currentTarget.value));

  const onSetActivityTag = (tag: ActivityTag) => setTags(tags => tags.map(x => (x.id === tag.id ? tag : x)));

  const onAddActivityTag = (tag: ActivityTag) => setTags(tags => [...tags, tag]);

  useEffect(() => {
    setTags(tags => tags.map(x => ({ ...x, isVisible: x.name.toLowerCase().includes(searchText.toLowerCase()) })));
  }, [searchText]);

  useEffect(() => void getActivityTagsFromRemote(), [getActivityTagsFromRemote]);

  return (
    <div className="activity-tags">
      {!isLoading && !error.length && (
        <div className="fab-menu-container">
          <Fab mini icon="add" onClick={onOpenAddSheet} />
        </div>
      )}

      <div className="layout-inner-content">
        <div className="data-table-title">
          <Typography className="title" use="body2">
            Aktivitás cimkék
          </Typography>
        </div>

        <div className={`search-box ${(isLoading || error.length > 0) && 'disabled'}`}>
          <span className="search-box--start-detail">
            <span className="material-icons">search</span>
          </span>

          <input className="search-box--input" type="text" placeholder="Keresés" onChange={onSearch} value={searchText} />

          <span className="search-box--start-detail" onClick={onClearSearch}>
            <span className="material-icons">close</span>
          </span>
        </div>

        <ActivityTagsList {...{ tags, isLoading, error, onSetActivityTag, onAddActivityTag }} />
      </div>
    </div>
  );
}

import React, { useCallback, useContext, useEffect, useState } from 'react';

/* Data Things */
import { JobRole, transformJobRoleDTO } from './helper';
import { RouteComponentProps } from '@reach/router';
import { SideSheetContext } from '../../../components/Contexts/SideSheet';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { getJobRoles } from '../../../store/lib/MasterData/jobRoles';

/* Routes  */
import { JobRoleAddOrEdit } from './AddOrEdit';
import { JobRolesList } from './List';

/* Presentation Things */
import { Fab } from '@rmwc/fab';

// Styles
import '../MasterData.scss';

interface IProps extends RouteComponentProps {}

export function JobRoles(_: IProps) {
  /* States */
  const [isLoading, setIsLoading] = useState(true);
  const [jobRoles, setJobRoles] = useState<{ [jobRoleId: number]: JobRole }>({});
  const [error, setError] = useState('');

  /* Refs */
  const _isMounted = useIsMounted();

  /* Context */
  const { open } = useContext(SideSheetContext);

  const getJobRolesFromRemote = useCallback(async () => {
    try {
      const jobRoles = await getJobRoles();
      if (!jobRoles.error) {
        _isMounted.current && setJobRoles(jobRoles.reduce((acc, curr) => ({ ...acc, [curr.jobRoleId]: transformJobRoleDTO(curr) }), {}));
      } else throw new Error(jobRoles.error);
    } catch (error) {
      _isMounted.current && setError(String(error.message || 'Sajnáljuk, valami hiba történt a munkakörök betöltés során. Kérlek próbáld újra!'));
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  }, [_isMounted]);

  const onSetJobRole = (jobRole: JobRole) => setJobRoles(prev => ({ ...prev, [jobRole.jobRoleId]: jobRole }));

  const onOpenAddSheet = () => open(<JobRoleAddOrEdit {...{ onSetJobRole }} />, 'MasterDataAddOrEditSheet');

  useEffect(() => void getJobRolesFromRemote(), [getJobRolesFromRemote]);

  return (
    <>
      {!isLoading && !error.length && (
        <div className="fab-menu-container">
          <Fab mini icon="add" onClick={onOpenAddSheet} />
        </div>
      )}

      <JobRolesList {...{ jobRoles, isLoading, error, onSetJobRole }} />
    </>
  );
}

import React from 'react';
import ReactDOM from 'react-dom';
import { Snackbar } from '@rmwc/snackbar';

export type ApiResponseType = { type: 'error' | 'warrning' | 'success'; message: string } | null;

export default class ResponseSnackbar extends React.PureComponent<{ response: ApiResponseType; onClose(response: ApiResponseType): void }> {
  private modalRoot = document.getElementById('errorSnackbar');
  private el;
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  public componentDidMount() {
    if (this.modalRoot) {
      this.modalRoot.appendChild(this.el);
    }
  }

  public componentWillUnmount() {
    if (this.modalRoot) {
      this.modalRoot.removeChild(this.el);
    }
  }
  public render() {
    return ReactDOM.createPortal(<div>{this.props.response && <Snackbar open className={this.props.response.type} message={this.props.response.message} onClose={() => this.props.onClose(null)} />}</div>, this.el);
  }
}

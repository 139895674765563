import React, { useContext, useMemo, useState } from 'react';

/* Presentation Things */
import CircularProgress from '../../../../components/CircularProgress';
import { List, WindowScroller, AutoSizer, ListRowProps } from 'react-virtualized';
import { JobRoleAddOrEdit } from '../AddOrEdit';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';

/* Data Things */
import { JobRole, sortJobRoles, searchJobRoles } from '../helper';
import { JOB_ROLES_ROW_HEIGHT } from '../../../../constants/constants';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';

interface Props {
  isLoading: boolean;
  error: string;
  jobRoles: { [id: number]: JobRole };
  onSetJobRole(jobRole: JobRole): void;
}

export const JobRolesList = ({ isLoading, jobRoles, error, onSetJobRole }: Props) => {
  /* State */
  const [searchText, setSearchText] = useState('');

  /* Variable */
  const sortedRoles = useMemo(() => sortJobRoles(Object.values(jobRoles)), [jobRoles]);
  const roles = useMemo(() => searchJobRoles(sortedRoles, searchText), [sortedRoles, searchText]);

  /* Context */
  const { open } = useContext(SideSheetContext);

  const rowRenderer = ({ index, style, key }: ListRowProps) => {
    const jobRole = roles[index];

    return (
      <div style={style} key={key}>
        <Row {...{ jobRole, onEdit: onOpenEditSheet }} />
      </div>
    );
  };

  const onSearch = (ev: React.ChangeEvent<HTMLInputElement>) => setSearchText(String(ev.currentTarget.value));

  const onOpenEditSheet = (editableJobRole: JobRole) => open(<JobRoleAddOrEdit {...{ onSetJobRole, editableJobRole }} />, 'MasterDataAddOrEditSheet');

  return (
    <div className="job-roles">
      <div className="data-table-title">
        <Typography className="title" use="body2">
          Munkakörök
        </Typography>
      </div>

      <div className={`search-box ${(isLoading || error.length > 0) && 'disabled'}`}>
        <span className="search-box--start-detail">
          <span className="material-icons">search</span>
        </span>

        <input className="search-box--input" type="text" placeholder="Keresés" onChange={onSearch} value={searchText} />

        <span className="search-box--start-detail" onClick={() => setSearchText('')}>
          <span className="material-icons">close</span>
        </span>
      </div>

      <div className="data-table">
        <div className="data-table__row data-table__row--title">
          <div className="data-table__cell">
            <Typography use="caption">FEOR</Typography>
          </div>

          <div className="data-table__cell">
            <Typography use="caption">Megnevezés</Typography>
          </div>

          <div className="row-spacer-header__end" />
        </div>

        <div className="data-table-body">
          {isLoading ? (
            <CircularProgress />
          ) : error.length > 0 || !roles.length ? (
            <div className="basic-error-text">{error || 'Nincsenek megjeleníthető munkakörök'}</div>
          ) : (
            <WindowScroller>
              {({ height, isScrolling, registerChild, scrollTop }) => (
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <div ref={registerChild}>
                      <List autoHeight height={height} isScrolling={isScrolling} rowCount={roles.length} rowHeight={JOB_ROLES_ROW_HEIGHT} rowRenderer={rowRenderer} scrollTop={scrollTop} width={width} />
                    </div>
                  )}
                </AutoSizer>
              )}
            </WindowScroller>
          )}
        </div>
      </div>
    </div>
  );
};

function Row({ jobRole, onEdit }: { jobRole: JobRole; onEdit(role: JobRole) }) {
  const handleEdit = () => onEdit(jobRole);

  return (
    <div className="data-table__row">
      <div className="data-table__cell">{jobRole.feorCode}</div>

      <div className="data-table__cell">{jobRole.name}</div>

      <div className="data-table__cell--end">
        <Icon icon="keyboard_arrow_right" onClick={handleEdit} />
      </div>
    </div>
  );
}

import React, { useState } from 'react';

/* Presentation Things */
import { MenuItem, MenuSurface, MenuSurfaceAnchor } from '@rmwc/menu';
import { ProjectDisplay } from '../../Common/ProjectDisplay';
import { StaticCheckbox } from './StaticCheckbox';
import { IconButton } from '@rmwc/icon-button';
import { Typography } from '@rmwc/typography';
import { ShowImage } from '../../Images/ShowImage';
import CircularProgress from '../../CircularProgress';
import ConfirmSkip from '../../Portals/ConfirmSkip';

/* Data Things */
import { ActivitySyncRow, STATUSES, RowSyncStatus } from '../helper';

export type RowProps = {
  activitie: ActivitySyncRow;
  onCheck(id: number, checked: boolean): void;
  onSync(ids: number[], isAuto: boolean);
  onStatusChange(rows: ActivitySyncRow[], newStatusCode: RowSyncStatus): void;
  isAutoSyncEnabled: boolean;
};

export const Row = React.memo(
  ({ activitie, onCheck, onStatusChange, onSync, isAutoSyncEnabled }: RowProps) => {
    /* State */
    const [isSkipOpen, setIsSkipOpen] = useState(false);
    const [open, setOpen] = useState(false);

    /* Variables */
    const name = [activitie.personRow.lastName, activitie.personRow.middleName, activitie.personRow.firstName].filter(Boolean).join(' ');

    const handleCheck = ev => {
      ev.stopPropagation();
      onCheck(activitie.activitySyncId, !activitie.isChecked);
    };

    const handleStatusChange = (code: number) => {
      onStatusChange([activitie], code);
      setOpen(false);
    };

    const handleSync = (ev: any) => {
      if (ev.stopPropagation) {
        ev.stopPropagation();
      }
      onSync([activitie.activitySyncId], false);
      setOpen(false);
    };

    const handleAutoSync = (ev: any) => {
      if (ev.stopPropagation) {
        ev.stopPropagation();
      }
      onSync([activitie.activitySyncId], true);
      setOpen(false);
    };

    const onOpen = (ev: any) => {
      if (ev.stopPropagation) {
        ev.stopPropagation();
      }
      setOpen(true);
    };

    const onClose = (ev: any) => {
      if (ev.stopPropagation) {
        ev.stopPropagation();
      }
      setOpen(false);
    };

    return (
      <div className="data-table__row">
        {activitie.isLoading && <CircularProgress />}
        <div className="data-table__cell--graphic" onClick={e => activitie.isCheckAble && handleCheck(e)}>
          {activitie.isCheckAble && <StaticCheckbox checked={activitie.isChecked} />}
        </div>

        <div className="flex-box-item data-table__cell person">
          <ShowImage fullName={name} pictureURL={activitie.personRow.photoUrl}>
            <Typography use="body1">{name}</Typography>
          </ShowImage>
        </div>

        <div className="flex-box-group flex-box-group--responsive__1480">
          <div className="flex-box-group__item data-table__cell project-cell">
            <ProjectDisplay project={activitie.project} />
          </div>

          <div className="flex-box-group__item data-table__cell comment">
            <span title={activitie.comment ? activitie.comment : undefined} className={`${activitie.comment ? '' : 'empty-cell'} project-comment`}>
              {activitie.comment ? activitie.comment : 'Nincs kitöltve'}
            </span>
          </div>
        </div>

        <div className="flex-box-item data-table__cell applied">
          <Typography use="body1">{activitie.appliedDate.setLocale('hu').toFormat('D')}</Typography>
        </div>

        <div className="flex-box-group flex-box-group--column">
          <div className="flex-box-group__item data-table__cell">
            <Typography use="body1">{activitie.startTime.toFormat('TT')}</Typography>
          </div>

          <div className="flex-box-group__item data-table__cell">
            <Typography use="body1">{activitie.endTime ? activitie.endTime.toFormat('TT') : '-'}</Typography>
          </div>
        </div>

        <div className="flex-box-item data-table__cell">
          <Typography use="body1">
            {activitie.isDeleted ? '-' : ''}
            {activitie.endTime ? activitie.endTime.diff(activitie.startTime).toFormat('hh:mm:ss') : '-'}
          </Typography>
        </div>

        <div className="flex-box-item data-table__cell">
          <Typography use="body1">{activitie.lastSyncedAt ? activitie.lastSyncedAt.toFormat('D') : '-'}</Typography>
        </div>

        <div className="flex-box-item data-table__cell status">
          <Typography
            use="body1"
            className={[`${activitie.syncStatusCode === RowSyncStatus.Pending ? 'warning' : activitie.syncStatusCode === RowSyncStatus.Failed ? 'error' : ''}`, `${activitie.errorMessage ? 'sync-date' : ''}`]
              .filter(Boolean)
              .join(' ')}>
            {STATUSES[activitie.syncStatusCode].name}
          </Typography>
        </div>

        <span className="material-icons play-arrow mdc-menu-surface--anchor" />

        <MenuSurfaceAnchor>
          <MenuSurface anchorCorner="topLeft" open={open} onClose={onClose}>
            {RowSyncStatus.Failed === activitie.syncStatusCode || RowSyncStatus.Never === activitie.syncStatusCode ? (
              <>
                <MenuItem onClick={handleSync}>Szinkronizálás</MenuItem>

                <MenuItem
                  onClick={ev => {
                    ev.stopPropagation();
                    setIsSkipOpen(true);
                  }}>
                  Kihagyás
                </MenuItem>
              </>
            ) : RowSyncStatus.Pending === activitie.syncStatusCode ? (
              <>
                <MenuItem
                  onClick={ev => {
                    ev.stopPropagation();
                    handleStatusChange(RowSyncStatus.Synced);
                  }}>
                  Sikeres szinkronizálás
                </MenuItem>

                <MenuItem
                  onClick={ev => {
                    ev.stopPropagation();
                    handleStatusChange(RowSyncStatus.Failed);
                  }}>
                  Sikertelen szinkronizálás
                </MenuItem>

                {isAutoSyncEnabled && <MenuItem onClick={handleAutoSync}>Automatikus ellenőrzés</MenuItem>}
              </>
            ) : (
              ''
            )}
          </MenuSurface>

          <IconButton icon="more_vert" onClick={onOpen} />
        </MenuSurfaceAnchor>
        {isSkipOpen && <ConfirmSkip isOpen={isSkipOpen} onClose={() => setIsSkipOpen(false)} onSkip={() => handleStatusChange(RowSyncStatus.SkipForever)} />}
      </div>
    );
  },
  (prevProps: RowProps, nextProps: RowProps) => {
    return (
      prevProps.activitie.activitySyncId === nextProps.activitie.activitySyncId &&
      prevProps.activitie.activitySyncRowVersion === nextProps.activitie.activitySyncRowVersion &&
      prevProps.activitie.isCheckAble === nextProps.activitie.isCheckAble &&
      prevProps.activitie.isChecked === nextProps.activitie.isChecked &&
      prevProps.activitie.isLoading === nextProps.activitie.isLoading &&
      prevProps.activitie.syncStatusCode === nextProps.activitie.syncStatusCode
    );
  }
);

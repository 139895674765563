import React from 'react';
import FileSelector, { SelectedFile } from '../../Selectors/FileSelector';
import { uploadFile } from '../../../store/lib/upload';
import { formatBytes } from '../../../constants/helperFuntions';
import { useIsMounted } from '../../../hooks';

type IProps = {
  attachments: SelectedFile[];
  onAdd(attachment: SelectedFile): void;
  onDelete(id: SelectedFile['id']): void;
};

const MAX_FILE_SIZE = 8000000; //bytes

export function AddAttachmentsSection({ attachments, onAdd, onDelete }: IProps) {
  const _isMounted = useIsMounted();

  const handleFileSelect = async (selectedFile: SelectedFile): Promise<{ locationId: number | null; message: string }> => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile.file);
      const [{ id, error }] = await uploadFile(formData);

      if (!error && id !== null) {
        _isMounted.current && onAdd({ ...selectedFile, locationId: id, isLoading: false, error: '' });
        return Promise.resolve({ locationId: id, message: '' });
      } else throw new Error(error || 'Valami hiba történt, kérlek próbáld újra később.');
    } catch (error) {
      return Promise.reject({ locationId: null, message: String(error.message || selectedFile.error || error) });
    }
  };

  return (
    <div className="add-attachments">
      <FileSelector initialFiles={attachments} label="Csatolmányok" isMultiple maxFileSize={MAX_FILE_SIZE} accept=".pdf, .doc, .docx, .xls, .txt, .jpg, .png" onSelect={handleFileSelect} onDelete={onDelete} />
      <span className="file-selector-helper-text">Maximális fájlméret: {formatBytes(MAX_FILE_SIZE)}.</span>
    </div>
  );
}

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

/* Navigation Things */
import { Link, NavigateFn, RouteComponentProps } from '@reach/router';

/* Data Things */
import { ORDER_STATUS_MAP, PRODUCTION_SITE_MAP } from '../../../../constants/enums';
import { Order, transformOrderToICreateOrderDTO } from '../helper';
import { OrdersContext } from '../../../../components/Contexts/Orders';
import { toDateString } from '../../../../constants/helperFuntions';
import { useIsMounted, usePrevious, useDebounce } from '../../../../hooks';
import { getOrdersCustomers, getOrdersProducts, getOrdersProjects } from '../../../../store/lib/Manufacturing/orders';
import { ISelectableOrdersFilterDTO } from '../../../../typings/DTOs';

/* Presentation Things */
import CircularProgress from '../../../../components/CircularProgress';
import { DataTable, DataTableContent, DataTableRow, DataTableHead, DataTableHeadCell, DataTableBody, DataTableCell, DataTableHeadCellProps } from '@rmwc/data-table';
import { SimpleMenuSurface } from '@rmwc/menu';
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { ProjectDisplay } from '../../../../components/Common/ProjectDisplay';
import { TablePager } from '../../../../components/Pagers';
import { Typography } from '@rmwc/typography';
import { Checkbox } from '@rmwc/checkbox';
import { Tab, TabBar } from '@rmwc/tabs';
import { Icon } from '@rmwc/icon';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';

/* Screens */
import { OrderAddOrEdit } from '../AddOrEdit';

/* Style */
import '../../../../styles/common.scss';

interface IProps extends RouteComponentProps {}

interface IDataTableHeadCellWithFilterProps extends DataTableHeadCellProps {
  onFilter?: (value: string) => void;
  onCheckbox?: (id: number) => void;
  onClearFilter?: () => void;
  onAscending?: () => void;
  onDescending?: () => void;
  activeFilter?: boolean;
  upwardActive?: boolean;
  downwardActive?: boolean;
  tableHasActiveFilter?: boolean;
  number?: boolean;
  string?: boolean;
  date?: boolean;
  value?: string;
  checkboxes?: number[];
  className?: string;
  getData?: () => any;
}

export function OrderList(props: IProps) {
  //Context
  const { open } = useContext(SideSheetContext);
  const context = useContext(OrdersContext);

  //State
  const [search, setSearch] = useState('');
  const [searchOrderNo, setSearchOrderNo] = useState('');

  //Hooks
  const _isMounted = useIsMounted();

  const debounceSearch = useDebounce(search, 500);
  const prevDebounceSearch = usePrevious(debounceSearch);
  const onSearch = (ev: React.ChangeEvent<HTMLInputElement>) => setSearch(String(ev.currentTarget.value));
  const onClearSearch = () => {
    setSearch('');
    context.onSearch('');
  };

  useEffect(() => {
    if (debounceSearch !== prevDebounceSearch) context.onSearch(debounceSearch);
  }, [context, debounceSearch, prevDebounceSearch]);

  const debounceSearchOrderNo = useDebounce(searchOrderNo, 500);
  const prevDebounceSearchOrderNo = usePrevious(debounceSearchOrderNo);
  const onSearchOrderNo = (value: string) => setSearchOrderNo(value);
  const onClearSearchOrderNo = () => {
    setSearchOrderNo('');
    context.onSearchOrderNo('');
  };

  useEffect(() => {
    if (debounceSearchOrderNo !== prevDebounceSearchOrderNo) context.onSearchOrderNo(debounceSearchOrderNo);
  }, [context, debounceSearchOrderNo, prevDebounceSearchOrderNo]);

  const onSearchCustomerIds = (id: number) => {
    if (!context.customerIds.includes(id)) {
      context.onSearchCustomerIds([...context.customerIds, id]);
    } else {
      context.onSearchCustomerIds(context.customerIds.filter(s => s !== id));
    }
  };
  const onClearCustomerIds = () => context.onSearchCustomerIds([]);

  const onSearchProjectIds = (id: number) => {
    if (!context.projectIds.includes(id)) {
      context.onSearchProjectIds([...context.projectIds, id]);
    } else {
      context.onSearchProjectIds(context.projectIds.filter(s => s !== id));
    }
  };
  const onClearProjectIds = () => context.onSearchProjectIds([]);

  const onSearchProductItemIds = (id: number) => {
    if (!context.productItemIds.includes(id)) {
      context.onSearchProductItemIds([...context.productItemIds, id]);
    } else {
      context.onSearchProductItemIds(context.productItemIds.filter(s => s !== id));
    }
  };
  const onClearProductItemIds = () => context.onSearchProductItemIds([]);

  const onClearAll = () => {
    setSearchOrderNo('');
    context.onSearchOrderNo('');
    context.onSearchCustomerIds([]);
    context.onSearchProjectIds([]);
    context.onSearchProductItemIds([]);
  };

  const onSort = (field: number, desc: boolean) => context.onSort(field, desc);

  const onSubmit = async (model: Order) => {
    try {
      if (model.project) {
        await context.onCreateOrder(transformOrderToICreateOrderDTO(model));

        return Promise.resolve('Ok');
      } else throw new Error('Kérlek válassz ki projektet');
    } catch (error) {
      const { message } = error as Error;
      _isMounted.current && context.onSetApiResponse({ type: 'error', message: String(message || error) });
      return Promise.reject(error);
    }
  };

  const onOpenAddSheet = () => open(<OrderAddOrEdit onSubmit={onSubmit} />, 'side-sheet-600');

  const onActivateTab = evt => {
    context.onIsActive(!evt.detail.index);
  };

  return (
    <div className="max-h-screen">
      {!context.isLoading && !context.error.length && (
        <div className="fab-menu-container">
          <div className="mdc-sheet-fab--container">
            <div className="mdc-sheet-fab">
              <div className="mdc-fab mdc-fab--mini" aria-label="hozzáadás" onClick={onOpenAddSheet}>
                <i className="material-icons">add</i>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="data-table-title">
        <Typography className="title" use="body2">
          Megrendelések
        </Typography>
      </div>

      <TabBar activeTabIndex={Number(!context.isActive)} onActivate={onActivateTab}>
        <Tab>Aktív</Tab>
        <Tab>Számlázva / Törölve</Tab>
      </TabBar>

      <div className={`search-box-with-filter ${(context.isLoading || context.error.length > 0) && 'disabled'}`}>
        <Button
          dense
          label="Szűrés törlése"
          icon="filter_alt_off"
          className="filter-clear-button"
          onClick={onClearAll}
          disabled={context.orderNo === '' && !context.customerIds[0] && !context.projectIds[0] && !context.productItemIds[0]}
        />
        <div className={`search-box ${(context.isLoading || context.error.length > 0) && 'disabled'}`}>
          <Icon icon="search" />
          <input className="search-box--input" type="text" placeholder="Keresés gyári számra" onChange={onSearch} value={search} />
          <Icon icon="close" onClick={onClearSearch} />
        </div>
      </div>

      <DataTable stickyRows={1} stickyColumns={1} className="order-list">
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>
              <DataTableHeadCellWithFilter checkboxes={context.customerIds} onCheckbox={onSearchCustomerIds} onClearFilter={onClearCustomerIds} getData={getOrdersCustomers} activeFilter={context.customerIds.length > 0}>
                Vevő
              </DataTableHeadCellWithFilter>
              <DataTableHeadCellWithFilter checkboxes={context.projectIds} onCheckbox={onSearchProjectIds} onClearFilter={onClearProjectIds} getData={getOrdersProjects} activeFilter={context.projectIds.length > 0}>
                Projekt
              </DataTableHeadCellWithFilter>
              <DataTableHeadCellWithFilter
                checkboxes={context.productItemIds}
                onCheckbox={onSearchProductItemIds}
                onClearFilter={onClearProductItemIds}
                getData={getOrdersProducts}
                onAscending={() => onSort(2, false)}
                onDescending={() => onSort(2, true)}
                upwardActive={context.sortBy === 2 && !context.sortByDesc}
                downwardActive={context.sortBy === 2 && context.sortByDesc}
                activeFilter={context.productItemIds.length > 0}>
                Cikk
              </DataTableHeadCellWithFilter>
              <DataTableHeadCellWithFilter value={searchOrderNo} onFilter={onSearchOrderNo} onClearFilter={onClearSearchOrderNo} activeFilter={context.orderNo !== ''} className="order-col" string>
                Megrendelés szám
              </DataTableHeadCellWithFilter>
              <DataTableHeadCellWithFilter activeFilter={false}>Telephely</DataTableHeadCellWithFilter>
              <DataTableHeadCellWithFilter
                onAscending={() => onSort(3, false)}
                onDescending={() => onSort(3, true)}
                upwardActive={context.sortBy === 3 && !context.sortByDesc}
                downwardActive={context.sortBy === 3 && context.sortByDesc}
                number>
                Mennyiség
              </DataTableHeadCellWithFilter>
              <DataTableHeadCellWithFilter
                onAscending={() => onSort(5, false)}
                onDescending={() => onSort(5, true)}
                upwardActive={context.sortBy === 5 && !context.sortByDesc}
                downwardActive={context.sortBy === 5 && context.sortByDesc}
                date>
                Beérkezés dátuma
              </DataTableHeadCellWithFilter>
              <DataTableHeadCellWithFilter
                onAscending={() => onSort(6, false)}
                onDescending={() => onSort(6, true)}
                upwardActive={context.sortBy === 6 && !context.sortByDesc}
                downwardActive={context.sortBy === 6 && context.sortByDesc}
                date>
                Igényelt kiádasi dátum
              </DataTableHeadCellWithFilter>
              <DataTableHeadCellWithFilter
                onAscending={() => onSort(7, false)}
                onDescending={() => onSort(7, true)}
                upwardActive={context.sortBy === 7 && !context.sortByDesc}
                downwardActive={context.sortBy === 7 && context.sortByDesc}
                date>
                Visszaigazolás dátuma
              </DataTableHeadCellWithFilter>
              <DataTableHeadCellWithFilter>Állapot</DataTableHeadCellWithFilter>
              <DataTableHeadCellWithFilter>Megjegyzés</DataTableHeadCellWithFilter>
              <DataTableHeadCellWithFilter />
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {context.isLoading ? (
              <DataTableRow>
                <DataTableCell colSpan={14}>
                  <CircularProgress />
                </DataTableCell>
              </DataTableRow>
            ) : context.error.length > 0 || context.orders.length < 1 ? (
              <DataTableRow>
                <DataTableCell colSpan={14} className="basic-error-cell">
                  {context.error || 'Nincs megjeleníthető megrendelés'}
                </DataTableCell>
              </DataTableRow>
            ) : (
              context.orders.map(order => <Row key={order.id} order={order} navigate={props.navigate!} />)
            )}
          </DataTableBody>
        </DataTableContent>

        <TablePager page={context.page} total={context.totalLength} displayedCount={100} onChange={context.onChangePage} />
      </DataTable>
    </div>
  );
}

const Row = ({ order, navigate }: { order: Order; navigate: NavigateFn }) => {
  const navigateToDetails = () => navigate(`./${order.id}`);

  return (
    <DataTableRow onClick={navigateToDetails} className="row">
      <DataTableCell>{order.customer.companyName}</DataTableCell>
      <DataTableCell>{order.project ? <ProjectDisplay project={order.project} /> : '-'}</DataTableCell>
      <DataTableCell>{order.productItem.title}</DataTableCell>
      <DataTableCell className="order-col">{order.orderNo}</DataTableCell>
      <DataTableCell>{PRODUCTION_SITE_MAP[order.productionSite]}</DataTableCell>
      <DataTableCell alignEnd>{order.quantity}</DataTableCell>
      <DataTableCell alignMiddle>{order.supplyDate ? toDateString(order.supplyDate) : '-'}</DataTableCell>
      <DataTableCell alignMiddle>{order.requestedShippingDate ? toDateString(order.requestedShippingDate) : '-'}</DataTableCell>
      <DataTableCell alignMiddle>{order.confirmationDate ? toDateString(order.confirmationDate) : '-'}</DataTableCell>
      <DataTableCell>{ORDER_STATUS_MAP[order.orderStatus]}</DataTableCell>
      <DataTableCell className="notes" title={order.notes}>
        {order.notes}
      </DataTableCell>
      <DataTableCell className="arrow">
        <Link to={`./${order.id}`}>
          <Icon icon="keyboard_arrow_right" />
        </Link>
      </DataTableCell>
    </DataTableRow>
  );
};

const DataTableHeadCellWithFilter = ({
  children,
  className,
  onFilter,
  onCheckbox,
  onClearFilter,
  onAscending,
  onDescending,
  activeFilter,
  upwardActive,
  downwardActive,
  number,
  string,
  date,
  value,
  checkboxes,
  getData,
  ...props
}: IDataTableHeadCellWithFilterProps) => {
  /* State */
  const [isOpen, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [isRead, setRead] = useState(false);
  const [list, setList] = useState<ISelectableOrdersFilterDTO[]>([]);

  /* Refs */
  const _inputRef = useRef<HTMLInputElement>(null);

  const onSearch = (ev: React.ChangeEvent<HTMLInputElement>) => setSearch(String(ev.currentTarget.value).toLowerCase());
  const onClearSearchText = () => {
    setSearch('');
    onClearFilter && onClearFilter();
  };

  const onSearchString = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onFilter && onFilter(String(ev.currentTarget.value).toLowerCase());
  };
  const onClearSearch = () => {
    onClearFilter && onClearFilter();
  };

  useEffect(() => {
    (async () => {
      if (getData && isOpen && !isRead) {
        try {
          const data = await getData();
          setList(data);
          setRead(true);
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [getData, isOpen, isRead]);

  useEffect(() => {
    setTimeout(() => isOpen && _inputRef.current && _inputRef.current.focus(), 500);
  }, [isOpen]);

  const prevCheckboxes = usePrevious(checkboxes);
  useEffect(() => {
    !isOpen && checkboxes !== prevCheckboxes && setSearch('');
  }, [isOpen, checkboxes, prevCheckboxes]);

  const filteredList = useMemo(() => list.filter(l => l.displayName.toLowerCase().search(search) > -1), [list, search]);

  const toggleOpen = () => setOpen(!isOpen);

  const onClickCheckbox = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onCheckbox && onCheckbox(parseInt(ev.currentTarget.value));
  };

  return (
    <DataTableHeadCell className={`${className}`} {...props}>
      {children}
      {onFilter || onCheckbox ? (
        <div className="filter-container">
          <SimpleMenuSurface anchorCorner="bottomLeft" handle={<Icon icon="filter_alt" onClick={toggleOpen} className={`filter ${activeFilter ? 'active' : ''}`} title="Szűrés" />} hoistToBody>
            <div className="filter-popup">
              {date ? (
                <div className="dates">
                  <TextField type="date" label="-tól" />
                  <span>|</span>
                  <TextField type="date" label="-ig" />
                </div>
              ) : number ? (
                <div className="dates">
                  <TextField type="number" label={children} />
                </div>
              ) : string ? (
                <div className="search-box">
                  <Icon icon="search" />
                  <input ref={_inputRef} className="search-box--input" type="text" placeholder="Keresés" value={value} onChange={onSearchString} />
                  <Icon icon="close" onClick={onClearSearch} />
                </div>
              ) : (
                <>
                  <div className="search-box">
                    <Icon icon="search" />
                    <input ref={_inputRef} className="search-box--input" type="text" placeholder="Keresés" value={search} onChange={onSearch} />
                    <Icon icon="close" onClick={onClearSearchText} />
                  </div>
                  <div className="filter-list">
                    {filteredList.map(l => (
                      <label key={l.id}>
                        <Checkbox value={l.id} checked={checkboxes && checkboxes.includes(l.id)} onChange={onClickCheckbox} />
                        <span>{l.displayName}</span>
                      </label>
                    ))}
                  </div>
                </>
              )}
            </div>
          </SimpleMenuSurface>
          {onAscending && <Icon icon="arrow_upward" onClick={onAscending} className={`filter ${upwardActive ? 'active' : ''}`} title="Növekvő sorrend" />}
          {onDescending && <Icon icon="arrow_downward" onClick={onDescending} className={`filter ${downwardActive ? 'active' : ''}`} title="Csökkenő sorrend" />}
        </div>
      ) : (
        onAscending &&
        onDescending && (
          <div className="sorts">
            <Icon icon="arrow_upward" onClick={onAscending} className={`filter ${upwardActive ? 'active' : ''}`} title="Növekvő sorrend" />{' '}
            <Icon icon="arrow_downward" onClick={onDescending} className={`filter ${downwardActive ? 'active' : ''}`} title="Csökkenő sorrend" />
          </div>
        )
      )}
    </DataTableHeadCell>
  );
};

import React, { useCallback, useContext, useEffect, useReducer } from 'react';

/* Data Things */
import { organiztaionStructureReducer, initialState } from '../../../store/reducers/Organization/Structure';
import { getCompaniesWithOrganizations, OUEditedDTO } from '../../../store/lib/Organization/Structure';
import { RouteComponentProps } from '@reach/router';
import { SideSheetContext } from '../../../components/Contexts/SideSheet';
import { OrganizationUnit } from './helper';
import { useIsMounted } from '../../../hooks/useIsMounted';

/* Presentation Things */
import { OrganizationStrucutreAddOrEdit } from './AddOrEdit';
import { Company } from './List';
import { Fab } from '@rmwc/fab';

import './OrganizationStructureList.scss';

interface IProps extends RouteComponentProps {}

export function OrganizationStructure(props: IProps) {
  /* State */
  const [state, dispatch] = useReducer(organiztaionStructureReducer, initialState);

  /* Ref */
  const _isMounted = useIsMounted();

  /* Context */
  const { open } = useContext(SideSheetContext);

  const getCompaniesFromRemote = useCallback(async () => {
    try {
      const companies = await getCompaniesWithOrganizations();
      if (!companies.error) {
        _isMounted.current && dispatch({ type: 'success_get_companies', payload: { companies } });
      } else throw new Error(companies.error);
    } catch (error) {
      _isMounted.current && dispatch({ type: 'error_get_companies', payload: { error: String(error.message || error) } });
    } finally {
      _isMounted.current && dispatch({ type: 'set_partial_state', payload: { isLoading: false } });
    }
  }, [_isMounted]);

  const onUpdateOrganization = (organizationUnit: OUEditedDTO, isEdit: boolean) => {
    if (isEdit) {
      dispatch({ type: 'edit_organizationUnit', payload: { organizationUnit } });
    } else {
      dispatch({ type: 'add_organizationUnit', payload: { organizationUnit } });
    }
  };

  const onOpenAddorEditSheet = (organization?: OrganizationUnit) => {
    open(<OrganizationStrucutreAddOrEdit companies={state.companies} editableOrganization={organization} onUpdateOrganization={onUpdateOrganization} />);
  };

  useEffect(() => void getCompaniesFromRemote(), [getCompaniesFromRemote]);

  return (
    <div className="data-table organization-unit">
      {!state.error.length && (
        <div className="fab-menu-container">
          <Fab mini icon="add" onClick={() => onOpenAddorEditSheet()} />
        </div>
      )}

      <div className="data-table-body">
        <Company company={state.companies[0]} onOpenAddorEditSheet={onOpenAddorEditSheet} isLoading={state.isLoading} error={state.error} />
      </div>
    </div>
  );
}

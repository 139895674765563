import { generateWindowTitle } from '../../constants/helperFuntions';

type Header = {
  name: string;
};

type SubMenuItem = {
  name: string;
  url: string;
};

export type Icon = { className: string; name?: string };

type MenuItem = {
  name: string;
  icon: Icon;
  baseUrl: string;
  subMenus: Array<SubMenuItem | Header>;
};

export type ActiveMenuStruct = { main: number | null; sub: number | null };

export interface IMenu {
  items: MenuItem[];
}

export function instanceOfSubMenuItem(object: Header | SubMenuItem): object is SubMenuItem {
  return 'url' in object;
}

export function getSelectedAndActiveMenuFromUrl(url: string): ActiveMenuStruct {
  /*  [TODO Jácint] Rewirte this piece of shit :DDD */
  const [main, sub] = url.split('/');

  if (main === 'Organization') {
    if (sub === 'Chart') return { main: 0, sub: 0 };
    else if (sub === 'People') return { main: 0, sub: 1 };
    return { main: 0, sub: null };
  }

  if (main === 'TrackTime') {
    if (sub === 'MonthlyAttendence') return { main: 1, sub: 0 };
    else if (sub === 'MonthlyClose') return { main: 1, sub: 1 };
    else if (sub === 'Projects') return { main: 1, sub: 2 };
    else if (sub === 'SyncStatus') return { main: 1, sub: 3 };
    return { main: 1, sub: null };
  }

  if (main === 'MasterData') {
    if (sub === 'JobRoles') return { main: 2, sub: 0 };
    else if (sub === 'ActivityTags') return { main: 2, sub: 1 };
    else if (sub === 'DayStatuses') return { main: 2, sub: 2 };
    return { main: 2, sub: null };
  }

  if (main === 'Settings') {
    //Header-0
    if (sub === 'OUTypes') return { main: 3, sub: 1 };
    //Header-2
    else if (sub === 'ApplicationUsers') return { main: 3, sub: 3 };
    else if (sub === 'SecurityGroups') return { main: 3, sub: 4 };
    return { main: 3, sub: null };
  }

  if (main === 'Manufacturing') {
    if (sub === 'Products') return { main: 4, sub: 0 };
    else if (sub === 'Customers') return { main: 4, sub: 1 };
    else if (sub === 'Orders') return { main: 4, sub: 2 };
    else if (sub === 'Inventories') return { main: 4, sub: 3 };
    return { main: 4, sub: null };
  }

  return { main: null, sub: null };
}

export function generateWindowTitleFromCurrentRoute(location: string, menus: MenuItem[]) {
  const [main, sub] = location.split('/');

  if (main && main.length > 0) {
    for (let i = 0; i < menus.length; i++) {
      if (menus[i].baseUrl === main) {
        const subName = sub && sub.length > 0 ? menus[i].subMenus.find(x => (x as SubMenuItem).url === sub) : undefined;

        if (subName) {
          return generateWindowTitle(subName.name);
        } else {
          return generateWindowTitle(menus[i].name);
        }
      }
    }
  }

  return generateWindowTitle('');
}

import React from 'react';

/* Data things */
import { SideSheetProvider } from '../../components/Contexts/SideSheet';
import { ISecuredProps } from '../../components/Auth/SecuredComponent';
import { useDimensions } from '../../hooks/useDimension';
import { withProfile } from '../../components/HOCs/withProfile';

/* Presentation Things */
import { Menu as PureMenu } from '../../components/Menu';
import Header from '../../components/Menu/Header';

/* Styles */
import 'react-virtualized/styles.css';

const Menu = withProfile(PureMenu);

interface IProps extends ISecuredProps {}

const MasterLayout = (props: React.PropsWithChildren<IProps>) => {
  const [ref, dimensions] = useDimensions();

  return (
    <>
      <Header router={props} />
      <div className="drawer-container" ref={ref}>
        <Menu router={props} windowWidth={dimensions.width} />
        <SideSheetProvider>{props.children}</SideSheetProvider>
      </div>
    </>
  );
};

export default MasterLayout;

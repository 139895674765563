/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

/* Presentation Things */
import { Button } from '@rmwc/button';
import { TextField } from '@rmwc/textfield';
import { LinearProgress } from '@rmwc/linear-progress';

/* Data Things */
import { getPersonalInfoForEdit, putPersonalInfoForEdit } from '../../../../../store/lib/Organization/Peoples/peopleDetails';
import { PersonDetails, transformEditPersonalInfoDTO, EditPersonalInfo, transformEditPersonalInfo, transformPersonDetailsPersonalInfoDTO } from '../helper';
import { TAX_NUMBER_REGEX, SOCIAL_SECURITY_NUMBER } from '../../../../../constants/regex';
import { useIsMounted } from '../../../../../hooks/useIsMounted';
import { DATEFORMAT } from '../../../../../constants/constants';
import { DateTime } from 'luxon';

type IProps = {
  close(): void;
  onEditPartialDetailsModel(p: Partial<PersonDetails>): void;
  personId: number;
};

type ExpiryDates = 'idCardExpiryDate' | 'passportExpiryDate' | 'driverLicenseExpiryDate';

const zeroAll = { hour: 0, minute: 0, second: 0, millisecond: 0 };

export function EditPersonalInfoSection({ personId, onEditPartialDetailsModel, close }: IProps) {
  const _isMounted = useIsMounted();
  const [model, setModel] = useState<null | EditPersonalInfo>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const isSaveAble = model && !isLoading && error.length === 0;

  useEffect(() => {
    getModelFromRemote(personId);
  }, [personId]);

  const handleExpiryDate = (key: ExpiryDates, date: string) => {
    let expiryDate = DateTime.fromFormat(`${date}`, DATEFORMAT).set(zeroAll);
    if (expiryDate.isValid) {
      setModel(prev => (prev ? { ...prev, [key]: expiryDate } : prev));
    } else {
      setModel(prev => (prev ? { ...prev, [key]: null } : prev));
    }
  };

  const getModelFromRemote = (personId: number) => {
    getPersonalInfoForEdit(personId)
      .then(resp => {
        _isMounted.current && setModel(transformEditPersonalInfoDTO(resp));
        _isMounted.current && setIsLoading(false);
        _isMounted.current && setError('');
      })
      .catch(() => {
        _isMounted.current && setIsLoading(false);
        _isMounted.current && setError('Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
      });
  };

  const handleModelChange = <T extends keyof EditPersonalInfo, K extends EditPersonalInfo[T]>(key: T, value: K) => {
    setModel(prevModel => {
      if (prevModel) {
        return { ...prevModel, [key]: value };
      }
      return prevModel;
    });
  };

  const handleSave = (ev: React.FormEvent) => {
    ev.preventDefault();

    setIsLoading(true);
    putPersonalInfoForEdit(personId, transformEditPersonalInfo(model!))
      .then(resp => {
        _isMounted.current && onEditPartialDetailsModel({ personalInfo: transformPersonDetailsPersonalInfoDTO(resp) });
        _isMounted.current && setIsLoading(false);
        close();
      })
      .catch(err => {
        console.log(err);
        _isMounted.current && setError('Sajnáljuk, valami hiba történt. Kérlek próbáld újra!');
        _isMounted.current && setIsLoading(false);
      });
  };

  return (
    <>
      <div tabIndex={0} className="drawer-header">
        Személyes adatok szerkesztése
      </div>

      <div className="form-content">
        <form onSubmit={handleSave}>
          {isLoading && <LinearProgress className="linear-progress" />}
          {error.length > 0 && <div className="basic-error-text">{error}</div>}

          <div className="form-content-row">
            <TextField
              type="text"
              maxLength={10}
              label="Adóazonosító jel"
              title="Helytelen adóazonosító jel"
              pattern={TAX_NUMBER_REGEX.source}
              value={model ? model.taxNumber : ''}
              onChange={ev => handleModelChange('taxNumber', ev.currentTarget.value)}
            />

            <TextField
              type="text"
              maxLength={9}
              label="TAJ szám"
              title="Helytelen TAJ szám"
              pattern={SOCIAL_SECURITY_NUMBER.source}
              value={model ? model.socialSecurityNumber : ''}
              onChange={ev => handleModelChange('socialSecurityNumber', ev.currentTarget.value)}
            />

            <TextField type="text" maxLength={8} label="Lakcímkártya száma" value={model ? model.addressCardNumber : ''} onChange={ev => handleModelChange('addressCardNumber', ev.currentTarget.value)} />
          </div>

          <div className="form-content-row">
            <TextField type="text" maxLength={8} label="Személyigazolvány száma" value={model ? model.idCardNumber : ''} onChange={ev => handleModelChange('idCardNumber', ev.currentTarget.value)} />

            <TextField
              type="date"
              label="Személyigazolvány lejárata"
              value={model && model.idCardExpiryDate ? model.idCardExpiryDate.toFormat(DATEFORMAT) : ' '}
              onChange={ev => handleExpiryDate('idCardExpiryDate', ev.currentTarget.value)}
            />
          </div>

          <div className="form-content-row">
            <TextField type="text" maxLength={9} label="Útlevél száma" value={model ? model.passportNumber : ''} onChange={ev => handleModelChange('passportNumber', ev.currentTarget.value)} />

            <TextField
              type="date"
              label="Útlevél lejárata"
              value={model && model.passportExpiryDate ? model.passportExpiryDate.toFormat(DATEFORMAT) : ' '}
              onChange={ev => handleExpiryDate('passportExpiryDate', ev.currentTarget.value)}
            />
          </div>

          <div className="form-content-row">
            <TextField type="text" maxLength={12} label="Jogosítvány száma" value={model ? model.driverLicenseNumber : ''} onChange={ev => handleModelChange('driverLicenseNumber', ev.currentTarget.value)} />

            <TextField
              type="date"
              label="Jogosítvány lejárata"
              value={model && model.driverLicenseExpiryDate ? model.driverLicenseExpiryDate.toFormat(DATEFORMAT) : ' '}
              onChange={ev => handleExpiryDate('driverLicenseExpiryDate', ev.currentTarget.value)}
            />
          </div>

          <Button label="Mentés" unelevated disabled={!isSaveAble} type="submit" />
        </form>
      </div>
    </>
  );
}

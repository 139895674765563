import React, { useContext, useState } from 'react';

/* Data Things */
import { SideSheetContext } from '../../../../components/Contexts/SideSheet';
import { DEFAULT_TASK } from './helper';
import { useIsMounted } from '../../../../hooks';
import { Product, Task } from '../helper';

/* Presentation Things */
import { LinearProgress } from '@rmwc/linear-progress';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import { Button } from '@rmwc/button';
import { toHHMMSS } from '../../../../utils/hhmmss';
import { Typography } from '@rmwc/typography';
import { ImageList, ImageListItem, ImageListSupporting } from '@rmwc/image-list';
import '@material/image-list/dist/mdc.image-list.css';
import { ShowImage } from '../../../../components/Images/ShowImage';
import { getExtension } from '../../../../utils/ext';
import { Icon } from '@rmwc/icon';

interface IProps {
  product: Product;
  task?: Task;
  orderNo?: number;
  onSubmit(task: Task): Promise<Boolean>;
}

export function AddOrEditTask(props: IProps) {
  /* Contexts */
  const { close } = useContext(SideSheetContext);
  const _isMounted = useIsMounted();

  /* States */
  DEFAULT_TASK.orderOfTask = props.orderNo ? props.orderNo : 1;
  const [editableTask, setEditableTask] = useState(props.task || DEFAULT_TASK);
  const [assignedAttachmentIds, setAssignedAttachmentIds] = useState(editableTask.assignedAttachmentIds || []);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const resp = await props.onSubmit({ ...editableTask, assignedAttachmentIds });
      if (resp === true) {
        close();
      }
    } catch (error) {
      console.log(error);
    } finally {
      _isMounted.current && setIsLoading(false);
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    switch (e.target.type) {
      case 'number':
        setEditableTask(prev => ({ ...prev, [e.target.name]: Number(e.target.value) }));
        break;

      case 'checkbox':
        setEditableTask(prev => ({ ...prev, [e.target.name]: e.target.checked }));
        break;

      default:
        setEditableTask(prev => ({ ...prev, [e.target.name]: String(e.target.value) }));
        break;
    }
  };

  const onTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    switch (e.target.name) {
      case 'standardTimeH':
        setEditableTask(prev => ({ ...prev, standardTimeSec: prev.standardTimeSec - parseInt(toHHMMSS(prev.standardTimeSec, 'hours')) * 3600 + Number(e.target.value) * 3600 }));
        break;
      case 'standardTimeM':
        setEditableTask(prev => ({ ...prev, standardTimeSec: prev.standardTimeSec - parseInt(toHHMMSS(prev.standardTimeSec, 'minutes')) * 60 + Number(e.target.value) * 60 }));
        break;
      case 'standardTimeS':
        setEditableTask(prev => ({ ...prev, standardTimeSec: prev.standardTimeSec - parseInt(toHHMMSS(prev.standardTimeSec, 'seconds')) + Number(e.target.value) }));
        break;
    }
  };

  const selectAttachment = (id: number) => {
    if (assignedAttachmentIds.includes(id)) {
      setAssignedAttachmentIds(assignedAttachmentIds.filter(a => a !== id));
    } else {
      setAssignedAttachmentIds([...assignedAttachmentIds, id]);
    }
  };

  return (
    <>
      <div className="drawer-header">
        {isLoading && <LinearProgress className="linear-progress" />}
        {props.task ? 'Munkafolyamat szerkesztése' : 'Új munkafolyamat'}
      </div>

      <div className="form-content">
        <form onSubmit={handleSubmit}>
          <div className="form-content-row full">
            <TextField required type="text" label="Megnevezés" name="name" value={editableTask.name} onChange={onInputChange} autoFocus />
          </div>

          <div className="form-content-row full">
            <TextField required type="number" label="Norma(óra)" name="standardTimeH" value={toHHMMSS(editableTask.standardTimeSec, 'hours')} onChange={onTimeChange} />
            <TextField required type="number" label="Norma(perc)" name="standardTimeM" value={toHHMMSS(editableTask.standardTimeSec, 'minutes')} onChange={onTimeChange} />
            <TextField required type="number" label="Norma(mp)" name="standardTimeS" value={toHHMMSS(editableTask.standardTimeSec, 'seconds')} onChange={onTimeChange} />
          </div>

          <div className="form-content-row full">
            <TextField required type="number" label="Sorrend" name="orderOfTask" value={editableTask.orderOfTask} onChange={onInputChange} />
          </div>

          <div className="form-content-row full">
            <Checkbox label="MEO" name="isQARequired" checked={editableTask.isQARequired} onChange={onInputChange} />
          </div>

          {props.product.attachments && props.product.attachments.length > 0 && (
            <>
              <Typography use="subtitle2" className="color-blue">
                Csatolmányok
              </Typography>

              <ImageList>
                {props.product.attachments.map(attachment => (
                  <div key={attachment.id} className={assignedAttachmentIds.includes(attachment.id!) ? 'task-attachment-selected' : 'task-attachment'} onClick={() => selectAttachment(attachment.id!)}>
                    <ImageListItem>
                      {attachment.fileName && getExtension(attachment.fileName) === 'pdf' ? (
                        <Icon icon="picture_as_pdf" className="pdf" />
                      ) : (
                        <ShowImage imageClassName="select-task-attachment" wrapperClassName="product-image-wrap" pictureURL={attachment.fileUrl} />
                      )}
                      <ImageListSupporting className="support">{attachment.fileName}</ImageListSupporting>
                    </ImageListItem>
                  </div>
                ))}
              </ImageList>
            </>
          )}

          <div className="form-content-row full margin-top-20">
            <Button unelevated label={props.task ? 'Szerkesztés' : 'Hozzáadás'} type="submit" />
          </div>
        </form>
      </div>
    </>
  );
}
